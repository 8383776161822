import { Box, Grid, Typography } from "@mui/material";
import "./payment_success.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Payment_success = () => {
    const navigate = useNavigate();

    return (
        <>
            <Grid container className="Payment_success_container">
                <Grid item md={3}>

                </Grid>
                <Grid item md={6}>
                    <Box className="box_success">
                        <Typography className="typo_heding">
                            Payment Successfully Inititated
                        </Typography>
                        <Typography className="typo_dec">
                        Your payment request has been received and is currently being processed. We will  notify you on email once it's successful. You can review the transaction status on the balance screen under pending transactions
                        </Typography>
                        <Button  onClick={() => navigate('/balance')} className="button_Goback">
                        Go Back
                        </Button>
                    </Box>
                </Grid>
                <Grid item md={3}>

                </Grid>
            </Grid>
        </>
    )
}
export default Payment_success;