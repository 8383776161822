import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  getUser,
  isUserAuthenticated,
  authHeader,
} from '../../../../../../_helpers';
import axios from 'axios';
import moment from 'moment';
import { getEnvironment } from '../../../../../../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL:process.env.REACT_APP_STAGING_API_URL
     ;
const Popup = (props: any) => {
  const [show, setShowlist] = useState(props.show == true ? 0 : '');
  const [selectedMethod_two, setSelectedMethod_two] = useState('');
  const [CsvUrl, setCsvUrl] = useState<any>([]);
  const [select_pdf, setSelect_pdf] = useState('');

  console.log(CsvUrl, 'CsvUrlCsvUrl');

  const onClose_topup = () => {
    props.onClose();
    setShowlist('300');
    // setBankResponseshow(false);
  };

  const getCsvurl = async (datalist: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };
    const stateDateApi = !!props.startdate
      ? moment(props.startdate).format('YYYY-MM-DD')
      : '';
    const endDateApi = !!props.enddate
      ? moment(props.enddate).format('YYYY-MM-DD')
      : '';
    const response = await axios
      .get(
        `${baseURL}/export-csv?search=${
          props.search
        }&type=${
          selectedMethod_two == 'Niobi' ? '' : selectedMethod_two
        }&start-date=${stateDateApi}&end-date=${endDateApi}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          setCsvUrl(response.data);
          console.log(response.data, 'response.data');
          if (!!response && response?.data?.data)
            window.location.href = response?.data?.data;
          // setPdfUrl(csvUrl.pdf);
        } else {
        }
      });
  };
  return (
    <>
      <Modal
        show={show == 0 ? true : false}
        onHide={() => {
          setShowlist('');
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> How do you want to export statement?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="label">Download Options</div>
          <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${
                selectedMethod_two === 'Niobi' ? 'active' : ''
              }`}
              onClick={() => setSelectedMethod_two('Niobi')}
            >
              <div className="heding_centewr_data">
                <div
                  style={{
                    width: '45px',
                    height: '45px',
                    margin: 'auto',
                    marginBottom: '4px',
                  }}
                >
                  <img
                    width={'100%'}
                    height={'100%'}
                    src="/Niobi-Logo (3).png"
                  />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: 'unset' }}>
                  Niobi
                </p>
              </div>
            </div>
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${
                selectedMethod_two === 'quickbooks' ? 'active' : ''
              }`}
              onClick={() => setSelectedMethod_two('quickbooks')}
            >
              <div className="heding_centewr_data">
                <div
                  style={{
                    width: '35px',
                    height: '35px',
                    margin: 'auto',
                    marginBottom: '4px',
                  }}
                >
                  <img width={'100%'} src="/images.jpeg" />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: 'unset' }}>
                  Quickbooks
                </p>
              </div>
            </div> */}
          </div>
          {/* <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${
                selectedMethod_two === 'sage' ? 'active' : ''
              }`}
              onClick={() => setSelectedMethod_two('sage')}
            >
              <div className="heding_centewr_data">
                <div
                  style={{
                    width: '35px',
                    height: '35px',
                    margin: 'auto',
                    marginBottom: '4px',
                  }}
                >
                  <img width={'100%'} src="/Sage-logo_svg.svg.png" />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: 'unset' }}>
                  Sage
                </p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${
                selectedMethod_two === 'zoho' ? 'active' : ''
              }`}
              onClick={() => setSelectedMethod_two('zoho')}
            >
              <div className="heding_centewr_data">
                <div
                  style={{
                    width: '35px',
                    height: '35px',
                    margin: 'auto',
                    marginBottom: '4px',
                  }}
                >
                  <img
                    width={'100%'}
                    src="/356-3560958_zoho-books-logo-hd-png-download.png"
                  />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: 'unset' }}>
                  Zoho Books
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${
                selectedMethod_two === 'xero' ? 'active' : ''
              }`}
              onClick={() => setSelectedMethod_two('xero')}
            >
              <div className="heding_centewr_data">
                <div
                  style={{
                    width: '35px',
                    height: '35px',
                    margin: 'auto',
                    marginBottom: '4px',
                  }}
                >
                  <img width={'100%'} src="/download.png" />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: 'unset' }}>
                  Xero
                </p>
              </div>
            </div>
          </div> */}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="white-btn"
            onClick={() => {
              onClose_topup();
            }}
          >
            Cancel
          </Button>
          <Button
            id="next_button_dataaa"
            className="green-btn"
            disabled={selectedMethod_two === ''}
            onClick={() => {
              setShowlist(1);
              getCsvurl('');
              props.OnSave('');
            }}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Popup;
