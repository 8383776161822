import './Detail_heder.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../../../../../../_actions";
import { useNavigate } from "react-router-dom";
import { getUser } from '../../../../../../../../_helpers';

const Detail_heder = (props: any) => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    let navigate = useNavigate();
    const userD = getUser()?.data ?? null;

    console.log(props, "Datadeteld123");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [loading6, setLoading6] = useState(false);

    useEffect(() => {
        if (props?.id) {
            dispatch(dashboardActions.getBilldetels(props?.id) as any);
            //   setTimeout(() => (
            //     setRedusar(true)
            //   ), 3000)
        }
    }, [props.id])
    const Redayrecat = () => {
        navigate("/balance?popup=" + props?.id, { replace: true, state: { popup: props?.id } })
    }
    const getPayableToApprove = () => {
        setLoading(true)
        dispatch(dashboardActions.getPayableToApprove(props?.id) as any);
        setTimeout(() => {
            setLoading(false)
        }, 6000)
    }
    const getPayableToReject = () => {
        setLoading2(true)
        dispatch(dashboardActions.getPayableToReject(props?.id) as any);
        setTimeout(() => {
            setLoading2(false)
        }, 6000)
    }
    const getPayableToPending = () => {
        setLoading3(true)
        dispatch(dashboardActions.getPayableToPending(props?.id) as any);
        setTimeout(() => {
            setLoading3(false)
        }, 6000)
    }
    const getPayableToPay = () => {
        setLoading6(true)
        Redayrecat()
        // dispatch(dashboardActions.getPayableToPay(props?.id,Redayrecat) as any);
        setTimeout(() => {
            setLoading6(false)
        }, 6000)
    }
    const getPayableToCancel = () => {
        setLoading4(true)
        dispatch(dashboardActions.getPayableToCancel(props?.id) as any);
        setTimeout(() => {
            setLoading4(false)
        }, 6000)
    }
    console.log(props, "safSAFfsfprops");
console.log(!!props?.dataset?.counterpart_id &&
    !!props?.dataset?.amount &&
    !!props?.dataset?.document_id &&
    !!props?.dataset?.due_date &&
    !!props?.dataset?.invoice_date &&
    props?.dataset?.tags.length != 0 &&
    !!props?.dataset?.type &&
    !!props?.dataset?.end_date &&
    props?.data?.payable?.recurring_job != null,
    !!props?.dataset?.counterpart &&
    !!props?.dataset?.amount &&
    !!props?.dataset?.document_id &&
    !!props?.dataset?.due_date &&
    !!props?.dataset?.invoice_date &&
    props?.dataset?.tags.length != 0 &&
    props?.data?.payable?.recurring_job == null,
    !!props?.dataset?.counterpart,
    !!props?.dataset?.amount,
    !!props?.dataset?.document_id,
    !!props?.dataset?.due_date,
    !!props?.dataset?.invoice_date,
    props?.dataset?.tags.length != 0,
    props?.data?.payable?.recurring_job != null,
    !!props?.dataset?.type,
    !!props?.dataset?.end_date,"props?.dataset?.tags.length");

    const getPayableUpdate = () => {
        const findItem_name = props?.dataset?.line_items?.find((data: any) => data.name == '')
        const findQuantity = props?.dataset?.line_items?.find((data: any) => data.quantity == '')
        const findUnit_Price = props?.dataset?.line_items?.find((data: any) => data.Unit_Price == '')
        const findVat = props?.dataset?.line_items?.find((data: any) => data.tax == '')
        var body: any =
            props?.data?.payable?.recurring_job != null && props?.dataset?.type == "Weekly" && props?.dataset?.newPayee == false ? body = {
                "line_items": props?.dataset?.line_items,
                "recurring_bill_data": {
                    "counterpart": props?.dataset.counterpart,
                    "submitted_by": props?.dataset.submitted_by,
                    "amount": props?.dataset?.amount,
                    "document_id": props?.dataset?.document_id,
                    "due_date": props?.dataset?.due_date,
                    "invoice_date": props?.dataset?.invoice_date,
                    "invoice_record": props?.dataset?.invoice_record,
                    "description": props?.dataset?.description,
                    "tags": props?.dataset?.tags
                },
                "type": props?.dataset?.type,
                "end_date": props?.dataset?.end_date,
                day: props?.dataset?.day
            } : props?.data?.payable?.recurring_job != null && props?.dataset?.type == "Monthly" && props?.dataset?.newPayee == false ? body = {
                "line_items": props?.dataset?.line_items,
                "recurring_bill_data": {
                    "counterpart": props?.dataset.counterpart,
                    "submitted_by": props?.dataset.submitted_by,
                    "amount": props?.dataset?.amount,
                    "document_id": props?.dataset?.document_id,
                    "due_date": props?.dataset?.due_date,
                    "invoice_date": props?.dataset?.invoice_date,
                    "invoice_record": props?.dataset?.invoice_record,
                    "description": props?.dataset?.description,
                    "tags": props?.dataset?.tags
                },
                "type": props?.dataset?.type,
                "end_date": props?.dataset?.end_date,
                every: props?.dataset?.every
            } : props?.data?.payable?.recurring_job != null && props?.dataset?.type == "Yearly" && props?.dataset?.newPayee == false ? body = {
                "line_items": props?.dataset?.line_items,
                "recurring_bill_data": {
                    "counterpart": props?.dataset.counterpart,
                    "submitted_by": props?.dataset.submitted_by,
                    "amount": props?.dataset?.amount,
                    "document_id": props?.dataset?.document_id,
                    "due_date": props?.dataset?.due_date,
                    "invoice_date": props?.dataset?.invoice_date,
                    "invoice_record": props?.dataset?.invoice_record,
                    "description": props?.dataset?.description,
                    "tags": props?.dataset?.tags
                },
                "type": props?.dataset?.type,
                "end_date": props?.dataset?.end_date,
                every: props?.dataset?.every,
                month: props?.dataset?.month
            } : props?.data?.payable?.recurring_job != null && props?.dataset?.type == "Weekly" && props?.dataset?.newPayee == true ? body = {
                "line_items": props?.dataset?.line_items,
                "recurring_bill_data": {
                    "counterpart": props?.dataset.counterpart,
                    "submitted_by": props?.dataset.submitted_by,
                    "amount": props?.dataset?.amount,
                    "document_id": props?.dataset?.document_id,
                    "due_date": props?.dataset?.due_date,
                    "invoice_date": props?.dataset?.invoice_date,
                    "invoice_record": props?.dataset?.invoice_record,
                    "description": props?.dataset?.description,
                    "tags": props?.dataset?.tags
                },
                "type": props?.dataset?.type,
                "end_date": props?.dataset?.end_date,
                day: props?.dataset?.day
            } : props?.data?.payable?.recurring_job != null && props?.dataset?.type == "Monthly" && props?.dataset?.newPayee == true ? body = {
                "line_items": props?.dataset?.line_items,
                "recurring_bill_data": {
                    "counterpart": props?.dataset.counterpart,
                    counterpart_type: props?.dataset.counterpart_type,
                    "submitted_by": props?.dataset.submitted_by,
                    "amount": props?.dataset?.amount,
                    "document_id": props?.dataset?.document_id,
                    "due_date": props?.dataset?.due_date,
                    "invoice_date": props?.dataset?.invoice_date,
                    "invoice_record": props?.dataset?.invoice_record,
                    "description": props?.dataset?.description,
                    "tags": props?.dataset?.tags
                },
                "type": props?.dataset?.type,
                "end_date": props?.dataset?.end_date,
                every: props?.dataset?.every
            } : props?.data?.payable?.recurring_job != null && props?.dataset?.type == "Yearly" && props?.dataset?.newPayee == true ? body = {
                "line_items": props?.dataset?.line_items,
                "recurring_bill_data": {
                    "counterpart": props?.dataset.counterpart,
                    "submitted_by": props?.dataset.submitted_by,
                    "amount": props?.dataset?.amount,
                    "document_id": props?.dataset?.document_id,
                    "due_date": props?.dataset?.due_date,
                    "invoice_date": props?.dataset?.invoice_date,
                    "invoice_record": props?.dataset?.invoice_record,
                    "description": props?.dataset?.description,
                    "tags": props?.dataset?.tags
                },
                "type": props?.dataset?.type,
                "end_date": props?.dataset?.end_date,
                every: props?.dataset?.every,
                month: props?.dataset?.month
            } : props?.data?.payable?.recurring_job == null && props?.dataset?.newPayee == true ?
                body = {
                    line_items: props?.dataset?.line_items,
                    "recurring_bill_data": {
                        "counterpart": props?.dataset.counterpart,
                        "submitted_by": props?.dataset.submitted_by,
                        "amount": props?.dataset?.amount,
                        "document_id": props?.dataset?.document_id,
                        "due_date": props?.dataset?.due_date,
                        "invoice_date": props?.dataset?.invoice_date,
                        "invoice_record": props?.dataset?.invoice_record,
                        "description": props?.dataset?.description,
                        "tags": props?.dataset?.tags,

                    },
                } : body = {
                    line_items: props?.dataset?.line_items,
                    "recurring_bill_data": {
                        "counterpart": props?.dataset.counterpart,
                        "submitted_by": props?.dataset.submitted_by,
                        "amount": props?.dataset?.amount,
                        "document_id": props?.dataset?.document_id,
                        "due_date": props?.dataset?.due_date,
                        "invoice_date": props?.dataset?.invoice_date,
                        "invoice_record": props?.dataset?.invoice_record,
                        "description": props?.dataset?.description,
                        "tags": props?.dataset?.tags,

                    },
                }
        // if (props?.dataset?.type == "Weekly") {
        //     // body.push()
        //     body.day == props?.dataset?.day
        // } else if (props?.dataset?.type == "Monthly") {
        //     body.every == props?.dataset?.every
        // } else if (props?.dataset?.type == "Yearly") {
        //     body.every == props?.dataset?.every
        //     body.month == props?.dataset?.month
        // }
        console.log(body, props?.dataset, "bodybody123");

        console.log(findItem_name, findQuantity, findUnit_Price, findVat, !findItem_name && !findQuantity && !findUnit_Price && !findVat && props?.data?.payable?.recurring_job != null, "bodybodybody");

        if (!findItem_name && !findQuantity && !findUnit_Price && props?.data?.payable?.recurring_job != null && props?.dataset?.newPayee == false && !!props?.dataset?.counterpart?.counterpart_id) {
            setLoading5(true)
            dispatch(dashboardActions.getPayableToUpdate(props?.id, body) as any);
            setTimeout(() => {
                setLoading5(false)
            }, 6000)
            console.log(body, "bodybodybody");
        } else if (!findItem_name && !findQuantity && !findUnit_Price && props?.data?.payable?.recurring_job != null && props?.dataset?.newPayee == true && props?.dataset?.counterpart?.counterpart_type == "individual" && !!props?.dataset?.counterpart?.first_name && !!props?.dataset?.counterpart?.last_name) {
            setLoading5(true)
            dispatch(dashboardActions.getPayableToUpdate(props?.id, body) as any);
            setTimeout(() => {
                setLoading5(false)
            }, 6000)
        } else if (!findItem_name && !findQuantity && !findUnit_Price && props?.data?.payable?.recurring_job != null && props?.dataset?.newPayee == true && props?.dataset?.counterpart?.counterpart_type == "organization" && !!props?.dataset?.counterpart?.legal_name) {
            setLoading5(true)
            dispatch(dashboardActions.getPayableToUpdate(props?.id, body) as any);
            setTimeout(() => {
                setLoading5(false)
            }, 6000)
        } else if (props?.data?.payable?.recurring_job == null && !!props?.dataset?.counterpart?.counterpart_id) {
            setLoading5(true)
            dispatch(dashboardActions.getPayableToUpdate(props?.id, body) as any);
            setTimeout(() => {
                setLoading5(false)
            }, 6000)
            console.log(body, "bodybodybody");
        }  else if (props?.data?.payable?.recurring_job == null && props?.dataset?.newPayee == true && props?.dataset?.counterpart?.counterpart_type == "individual" && !!props?.dataset?.counterpart?.first_name && !!props?.dataset?.counterpart?.last_name) {
            setLoading5(true)
            dispatch(dashboardActions.getPayableToUpdate(props?.id, body) as any);
            setTimeout(() => {
                setLoading5(false)
            }, 6000)
            console.log(body, "bodybodybody");
        } else if (props?.data?.payable?.recurring_job == null && props?.dataset?.newPayee == true && props?.dataset?.counterpart?.counterpart_type == "organization" && !!props?.dataset?.counterpart?.legal_name) {
            setLoading5(true)
            dispatch(dashboardActions.getPayableToUpdate(props?.id, body) as any);
            setTimeout(() => {
                setLoading5(false)
            }, 6000)
            console.log(body, "bodybodybody");
        }
    }
    //   const getPayableToApprove=()=>{
    //     dispatch(dashboardActions.getPayableToApprove(props?.id) as any);
    //   }
    console.log(!!props?.dataset?.counterpart_id &&
        !!props?.dataset?.amount &&
        !!props?.dataset?.document_id &&
        !!props?.dataset?.due_date &&
        !!props?.dataset?.invoice_date
        , "!!props?.dataset?.counterpart_id");

    return (

        <>
            <ThemeProvider theme={theme}>
                <Grid container className='container_Detail_heder'>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box className='container_Detail_heder_box'>
                            <IconButton className="close_button"
                                onClick={() => { props.closeModal() }}>
                                <Close /></IconButton>

                            <Typography className='heding_tagemain'>{props?.data?.payable?.recurring_job == null ? props?.data?.data?.counterpart_name : props?.data?.data?.counterpart_name}</Typography>
                            <Typography
                                className={
                                    props?.data?.data?.status == "new" ? "new_stasts" :
                                        props?.data?.data?.status == "paid" ? "prid_stasts" :
                                            props?.data?.data?.status == "approve_in_progress" ? "Pending_typography" :
                                                props?.data?.data?.status == "draft" ? "defolt_typogarafi" :
                                                    props?.data?.data?.status == "waiting_to_be_paid" ? "waiting_to_be_paid_typo" :
                                                        props?.data?.data?.status == "canceled" || props?.data?.data?.status == "rejected" ? "canceled_typogarafi"
                                                            : ""}>
                                {props?.data?.data?.status == "approve_in_progress" ? "Pending" :
                                    props?.data?.data?.status == "new" ? "New" :
                                        props?.data?.data?.status == "draft" ? "Draft" :
                                            props?.data?.data?.status == "rejected" ? "Rejected" :
                                                props?.data?.data?.status == "paid" ? "Paid" :
                                                    props?.data?.data?.status == "canceled" ? "Canceled" :
                                                        props?.data?.data?.status == "waiting_to_be_paid" ? "Waiting_to_be_paid" :
                                                            props?.data?.data?.status}</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box className='box_main_teobutton'>
                            {props?.data?.data?.status == "approve_in_progress"&&!!userD &&
                      userD?.role_name &&
                      userD?.role_name != 'Accountant'  ?
                                <>
                                    <Button className='button_fatsstastr'
                                        onClick={() => { getPayableToReject() }}>
                                        {loading2 == true && <span className="spinner-border spinner-border-sm mr-1"></span>}Reject</Button>
                                    <Button className='Approve_button' onClick={() => { getPayableToApprove() }}>
                                        {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}Approve</Button></> :
                                props?.data?.data?.status == "waiting_to_be_paid" ?
                                    <Button className='Approve_button' onClick={() => { getPayableToPay() }}>
                                        {loading6 == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Pay</Button>
                                    : props?.data?.data?.status == "draft" ? <>
                                        <Button className='cansal_buttonn' 
                                        onClick={() => { getPayableToCancel() }}>
                                            {loading4 == true && <span className="spinner-border spinner-border-sm mr-1"></span>}Cancel</Button>
                                        {!!props?.dataset?.counterpart &&
                                            !!props?.dataset?.amount &&
                                            !!props?.dataset?.document_id &&
                                            !!props?.dataset?.due_date &&
                                            !!props?.dataset?.invoice_date &&
                                            props?.dataset?.tags.length != 0 &&
                                            props?.data?.payable?.recurring_job == null ?
                                            <Button className='save_Button' onClick={() => {
                                                getPayableUpdate()
                                            }}>{loading5 == true && 
                                            <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            Save</Button> : !!props?.dataset?.counterpart &&
                                                !!props?.dataset?.amount &&
                                                !!props?.dataset?.document_id &&
                                                !!props?.dataset?.due_date &&
                                                !!props?.dataset?.invoice_date &&
                                                props?.dataset?.tags.length != 0 &&
                                                !!props?.dataset?.type &&
                                                !!props?.dataset?.end_date &&
                                                props?.data?.payable?.recurring_job != null ? 
                                                <Button className='save_Button' onClick={() => {
                                                    getPayableUpdate()
                                                }}>{loading5 == true && <span className="spinner-border spinner-border-sm mr-1"></span>}Save</Button> :
                                                <Button className='save_Button' disabled>Save</Button>}</> : props?.data?.data?.status == "new" ?
                                        <>
                                            <Button className='cansal_buttonn' onClick={() => { getPayableToCancel() }}>
                                                {loading4 == true && <span className="spinner-border spinner-border-sm mr-1">
                                                    </span>}Cancel</Button>
                                            {!!props?.dataset?.counterpart &&
                                                !!props?.dataset?.amount &&
                                                !!props?.dataset?.document_id &&
                                                !!props?.dataset?.due_date &&
                                                !!props?.dataset?.invoice_date &&
                                                props?.dataset?.tags.length != 0 &&
                                                props?.data?.payable?.recurring_job == null ?
                                                <Button className='save_Button' onClick={() => {
                                                    getPayableUpdate()
                                                }}>
                                                    {loading5 == true && <span className="spinner-border spinner-border-sm mr-1"></span>}Save</Button> : 
                                                !!props?.dataset?.counterpart &&
                                                    !!props?.dataset?.amount &&
                                                    !!props?.dataset?.document_id &&
                                                    !!props?.dataset?.due_date &&
                                                    !!props?.dataset?.invoice_date &&
                                                    props?.dataset?.tags.length != 0 &&
                                                    !!props?.dataset?.type &&
                                                    !!props?.dataset?.end_date &&
                                                    props?.data?.payable?.recurring_job != null ? <Button className='save_Button' onClick={() => {
                                                        getPayableUpdate()
                                                    }}>
                                                    {loading5 == true && 
                                                    <span className="spinner-border spinner-border-sm mr-1">
                                                        </span>}Save</Button> :
                                                    <Button className='save_Button' disabled>Save</Button>}
                                            <Button className='Approve_button' onClick={() => { getPayableToPending() }}>{loading3 == true && <span className="spinner-border spinner-border-sm mr-1"></span>}Submit for Approval</Button></> :
                                        props?.data?.data?.status == "paid" ? "" : ""}
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}
export default Detail_heder;
const Close = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path d="M0.757812 11.2428L6.00081 5.99984L11.2438 11.2428M11.2438 0.756836L5.99981 5.99984L0.757812 0.756836" stroke="#1D1D1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)