import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { entityActions } from '../../../../../../../../../_actions';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader';
import { ErrorMessage, FieldArray, getIn } from 'formik';

const LimitedLiabilityStepTwo = ({
  formik,
  idFront,
  idFrontLabel,
  idBackLabel,
  idBack,
  kraPin,
  kraPinLabel,
  selfie,
  selfieLabel,
  handleImage,
  handleRemoveImage,
  shareHolderLabels,
  handleAddNewDirector,
  loader,
}: any) => {
  const [render, setRender] = useState(true);

  const defaultDirector: any = {
    fullName: '',
    gender: '',
    mobile: '',
    idNumber: '',
    idFront: '',
    idBack: '',
    kraPin: '',
    kraPinFront: '',
    selfie: '',
  };
  console.log('Limited Liability Step Two', shareHolderLabels);
  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const [kraAccordion, setKraAccordion] = useState<boolean>(false);
  const [idAccordion, setIdAccordion] = useState<boolean>(false);

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getGender() as any);
  }, [dispatch]);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState<any>('');
  // const [invoiceImage, setinvoiceImage] = useState<any>('');
  // const selfieLabel = 'Selfie Photo';
  // const idFrontLabel = 'Photo of the frontside of the ID or passport';
  // const idBackLabel = 'Photo of the backside of the ID or passport';
  // const kraPinLabel = 'Photo of the KRA PIN Certificate of the business owner';
  // const [selfie, setSelfie] = useState(selfieLabel);
  // const [idFront, setIdFront] = useState(idFrontLabel);
  // const [idBack, setIdBack] = useState(idBackLabel);
  // const [kraPin, setKraPin] = useState(kraPinLabel);
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  // const genders = ['Male', 'Female', 'Other'];

  const genders = useSelector((state: any) => state.getGenderReducer.data);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const defaultPaterneship = {
    idType: '101',
    firstName: '',
    lastName: '',
    idNumber: '',
    gender: '',
    countryCode: '',
    mobile: '',
    kraPin: '',
    idFrontSideFile: '',
    idFrontSideFileType: '',
    idBackSideFile: '',
    idBackSideFileType: '',
    selfieFile: '',
    selfieFileType: '',
    kraPinFile: '',
    kraPinFileType: '',
  };

  console.log('Limited Liability Step Two', formik.values.shareholders);
  console.log('Limited Liability Step Two errors', formik.errors);

  // const handleRemoveClick = (index: any, id: any) => {
  //   console.log(index);
  //   setDirectors((directors: any) =>
  //     directors.filter((_: any, i: any) => i !== index)
  //   );
  // };
  return (
    <>
      <p className="complete-profile__businesstypo">Director's details</p>
      <p>
        submit at least 2 personal information of the board members or
        directors,including:
      </p>
      <FieldArray name="shareholders">
        {({ push, remove }) => (
          <>
            {formik.values.shareholders.map((shareholder: any, i: number) => {
              const firstName = `shareholders[${i}].firstName`;
              const touchedFirstName = getIn(formik.touched, firstName);
              const errorFirstName = getIn(formik.errors, firstName);

              const gender = `shareholders[${i}].gender`;
              const touchedGender = getIn(formik.touched, gender);
              const errorGender = getIn(formik.errors, gender);

              const idNumber = `shareholders[${i}].idNumber`;
              const touchedIdNumber = getIn(formik.touched, idNumber);
              const errorIdNumber = getIn(formik.errors, idNumber);

              const kraPinField = `shareholders[${i}].kraPin`;
              const touchedKraPin = getIn(formik.touched, kraPinField);
              const errorKraPin = getIn(formik.errors, kraPinField);

              const mobile = `shareholders[${i}].mobile`;
              const touchedMobile = getIn(formik.touched, mobile);
              const errorMobile = getIn(formik.errors, mobile);

              const selfiePhoto = `shareholders[${i}].selfieFile`;
              const touchedSelfie = getIn(formik.touched, selfiePhoto);
              const errorSelfie = getIn(formik.errors, selfiePhoto);

              const idBackPhoto = `shareholders[${i}].idBackSideFile`;
              const touchedIdback = getIn(formik.touched, idBackPhoto);
              const errorIdBack = getIn(formik.errors, idBackPhoto);

              const idFrontPhoto = `shareholders[${i}].idFrontSideFile`;
              const touchedIdFront = getIn(formik.touched, idFrontPhoto);
              const errorIdFront = getIn(formik.errors, idFrontPhoto);

              const kraPinPhoto = `shareholders[${i}].kraPinFile`;
              const touchedKraPinFile = getIn(formik.touched, kraPinPhoto);
              const errorKraPinFile = getIn(formik.errors, kraPinPhoto);
              return (
                <>
                  <div
                    className="complete-profile__btn_box"
                    style={{ marginTop: '20px' }}
                  >
                    {i !== 0 ? (
                      <p className="complete-profile__director">
                        Director {i + 1}
                      </p>
                    ) : (
                      ''
                    )}
                    {i > 0 ? (
                      <Button
                        className="complete-profile__removebtn"
                        onClick={() => remove(i)}
                      >
                        Remove
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="calss_accordion_contenar">
                    <div
                      className="maian_adaat_listtts"
                      onClick={() => {
                        if (paymentacodiyan === false) {
                          setPaymentacodiyan(true);
                          setDataccodiyan(false);
                        } else {
                          setPaymentacodiyan(false);
                        }
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <div className="avayar_text">
                          {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
                          <p className="accordion_p_data_contean">
                            Personal details
                          </p>
                        </div>
                        {paymentacodiyan === false ? (
                          <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                        ) : (
                          <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                        )}
                      </ThemeProvider>
                    </div>

                    {paymentacodiyan === true ? (
                      <Form>
                        <div className="input-form-wrapper dot">
                          <label>Full Names </label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={firstName}
                              value={formik.values.shareholders[i].firstName}
                              onChange={formik.handleChange}
                              type="text"
                              isInvalid={Boolean(
                                touchedFirstName && errorFirstName
                              )}
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={firstName} component="div" />
                          </p>
                        </div>
                        <div className="input-form-wrapper dot">
                          <label>Gender</label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Select
                              name={gender}
                              value={formik.values.shareholders[i].gender}
                              onChange={formik.handleChange}
                              isInvalid={Boolean(touchedGender && errorGender)}
                            >
                              <option>Gender</option>
                              {genders?.map((gender: any) => (
                                <option value={gender.key} key={gender.id}>
                                  {gender.value}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={gender} component="div" />
                          </p>
                        </div>
                        <div className="input-form-wrapper dot">
                          <label>Mobile number</label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={mobile}
                              value={formik.values.shareholders[i].mobile}
                              onChange={formik.handleChange}
                              isInvalid={Boolean(touchedMobile && errorMobile)}
                              type="text"
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={mobile} component="div" />
                          </p>
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data
                                    ?.selfie?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.selfie?.file_name
                                    : shareHolderLabels[i].selfieLabel}
                                </span>
                                {/* {selfie === selfieLabel ? (
                            <UploadIcon />
                          ) : (
                            <CloseIcon />
                          )} */}
                              </div>
                              <input
                                type="file"
                                name={selfiePhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {Boolean(touchedSelfie && errorSelfie) ? (
                                  <ErrorMessage
                                    name={selfiePhoto}
                                    component="div"
                                  />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].selfieLabel === selfieLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                // remove_data(
                                //   groupOneName[i].KRN_certi_new
                                // );
                                handleRemoveImage('selfie', i);
                                console.log('remove');
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                      </Form>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="calss_accordion_contenar">
                    <div
                      className="maian_adaat_listtts"
                      onClick={() => {
                        if (idAccordion === false) {
                          setIdAccordion(true);
                          setDataccodiyan(false);
                        } else {
                          setIdAccordion(false);
                        }
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <div className="avayar_text">
                          <p className="accordion_p_data_contean">ID details</p>
                        </div>
                        {idAccordion === false ? (
                          <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                        ) : (
                          <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                        )}
                      </ThemeProvider>
                    </div>

                    {idAccordion === true ? (
                      <>
                        <div className="input-form-wrapper dot">
                          <label>ID or Passport number </label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={idNumber}
                              onChange={formik.handleChange}
                              value={formik.values.shareholders[i].idNumber}
                              isInvalid={Boolean(
                                touchedIdNumber && errorIdNumber
                              )}
                              type="text"
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={idNumber} component="div" />
                          </p>
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data
                                    ?.idFront?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.idFront?.file_name
                                    : shareHolderLabels[i].idFrontLabel}
                                </span>
                                {/* {idFront === idFrontLabel ? (
                            <UploadIcon />
                          ) : (
                            <CloseIcon />
                          )} */}
                              </div>
                              <input
                                type="file"
                                name={idFrontPhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {Boolean(touchedIdFront && errorIdFront) ? (
                                  <ErrorMessage
                                    name={idFrontPhoto}
                                    component="div"
                                  />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].idFrontLabel ===
                          idFrontLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                // remove_data(
                                //   groupOneName[i].KRN_certi_new
                                // );
                                handleRemoveImage('idFront', i);
                                console.log('remove');
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data
                                    ?.idBack?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.idBack?.file_name
                                    : shareHolderLabels[i].idBackLabel}
                                </span>
                                {/* {idBack === idBackLabel ? (
                            <UploadIcon />
                          ) : (
                            <CloseIcon />
                          )} */}
                              </div>
                              <input
                                type="file"
                                name={idBackPhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {Boolean(touchedIdback && errorIdBack) ? (
                                  <ErrorMessage
                                    name={idBackPhoto}
                                    component="div"
                                  />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].idBackLabel === idBackLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                // remove_data(
                                //   groupOneName[i].KRN_certi_new
                                // );
                                handleRemoveImage('idBack', i);
                                console.log('remove');
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="calss_accordion_contenar">
                    <div
                      className="maian_adaat_listtts"
                      onClick={() => {
                        if (kraAccordion === false) {
                          setKraAccordion(true);
                          setDataccodiyan(false);
                        } else {
                          setKraAccordion(false);
                        }
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <div className="avayar_text">
                          {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
                          <p className="accordion_p_data_contean">
                            KRA Details
                          </p>
                        </div>
                        {kraAccordion === false ? (
                          <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                        ) : (
                          <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                        )}
                      </ThemeProvider>
                    </div>

                    {kraAccordion === true ? (
                      <>
                        <div className="input-form-wrapper dot">
                          <label>Personal KRA PIN Number</label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={`shareholders[${i}].kraPin`}
                              value={formik.values.shareholders[i].kraPin}
                              onChange={formik.handleChange}
                              isInvalid={Boolean(touchedKraPin && errorKraPin)}
                              type="text"
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={kraPinField} component="div" />
                          </p>
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data?.kra
                                    ?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.kra?.file_name
                                    : shareHolderLabels[i].kraPinLabel}
                                </span>
                                {/* {kraPin === kraPinLabel ? (
                            <UploadIcon />
                          ) : (
                            <CloseIcon />
                          )} */}
                              </div>
                              <input
                                type="file"
                                name={kraPinPhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {Boolean(
                                  touchedKraPinFile && errorKraPinFile
                                ) ? (
                                  <ErrorMessage
                                    name={kraPinPhoto}
                                    component="div"
                                  />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].kraPinLabel === kraPinLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                // remove_data(
                                //   groupOneName[i].KRN_certi_new
                                // );
                                handleRemoveImage('kra', i);
                                console.log('remove');
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              );
            })}
            <div
              className="complete-profile__btn_box"
              style={{ marginTop: '20px' }}
            >
              <Button
                className="complete-profile__ADDBTNHHA"
                onClick={() => {
                  push(defaultPaterneship);
                  handleAddNewDirector();
                }}
              >
                + Add another director
              </Button>
            </div>
          </>
        )}
      </FieldArray>
      {loader && <Loader />}
    </>
  );
};

export default LimitedLiabilityStepTwo;
