import React, { useContext } from 'react'
import { PayablesTable, PayableDetails } from '@team-monite/ui-widgets-react';
// import { MoniteApp } from '@team-monite/sdk-api';
import { useSearchParams } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { Button, Dropdown, DropdownMenuItem } from '@team-monite/ui-kit-react';
import styled from '@emotion/styled';
import { PayableDetail } from './components/PayableGrid/PayableGrid';
import { getPayableGridData } from '../mock/payableMock';
import { PayableHeader } from './components/PayableHeader/PayableHeader';


export const Payables = () => {

  var localUser: any = localStorage.getItem('user');

  // const monite = new MoniteApp({
  //   apiUrl: 'https://api.sandbox.monite.com/v1',  // Or 'https://api.monite.com/v1' to use Production
  //   entityId: JSON.parse(localUser).data.hasEntity ? JSON.parse(localUser).data.entity.monite_id : 'c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1',  // X-Monite-Entity-Id
  //   token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXlsb2FkIjp7ImNsaWVudF9pZCI6ImQ4NjJlYjg3LTc1ZDEtNDEyOS05MWI1LTVjNzk2ZmJjNjVhZiIsImNyZWF0ZWRfYXQiOiIyMDIyLTExLTIxVDA3OjU3OjM1LjE0MDk0NiJ9LCJleHAiOjE2NjkwMTkyNTV9.olw2i7B4_GYfaGYLRmalkQAL5-FNnFg7SphCtKGZe14'   // Access token (of an entity user or a partner)
  // });

  const PAYABLE_ID = 'id';

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get(PAYABLE_ID);

  const onRowClick = (id: string) => {
    searchParams.set(PAYABLE_ID, id);
    setSearchParams(searchParams);
  };

  const closeModal = () => {
    searchParams.delete(PAYABLE_ID);
    setSearchParams(searchParams);
  };

  // monite.api.payable.getList()  // Returns Promise<Response>
  //   .then(res => {
  //     console.log(`Number of payables: ${res.data.length}`);
  //   });

  return (
    <>
      {/* <PageHeader
        title='Niobi Balance'
        extra={
          <Dropdown button={<Button color='#025041'>Upload document</Button>}>
            <DropdownMenuItem
            // onClick={() => setType(CounterpartType.ORGANIZATION)} 
            >
              Payable
            </DropdownMenuItem>
          </Dropdown>
        }
      /> */}


      {/*<PayableHeader title="Niobi Balance"></PayableHeader>*/}
      <PayableDetail data={getPayableGridData()}></PayableDetail>
      {/* <PayablesTable onRowClick={onRowClick} /> */}
      {/* {id && <PayableDetails id={id} onClose={closeModal} />} */}
    </>
  )
}
