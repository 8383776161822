import { Box } from "@mui/material";
import styles from './dashboard_nodata.module.scss';
import { Link } from "react-router-dom";

interface Props {
    button: any;
    img: any;
    text: any;
    Link:any;
}
const Dashboard_nodata = (props: Props) => {

    return (
        <>
            <Box className={styles.box_class_conteann}>
                <div>
                    <div className={styles.box_contenar_data_nodata}>
                        <img src={props.img} />
                    </div>
                    <div className={styles.text_img_footer}>{props.text}</div>
                    
                    {/* <Link className={props.Link=="/dashboard"?styles.nodata_link_block:styles.nodata_link} to={props.Link}>{props.button}</Link> */}
                </div>
            </Box>
        </>
    )
}
export default Dashboard_nodata;