import { entityConstants } from "../_constants/entity.constants";

export function getEntityTypesReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GETENTITYITEMS_SUCCESS:
            return {
                data: action.data
            };
        default:
            return state
    }
}

export function getEntityActivitiesReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GETENTITYITEMS_FROM_STORE:
            return {
                loading: true
            };
        case entityConstants.GET_ENTITY_ACTIVITY_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case entityConstants.GETENTITYITEMS_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function getBillsslist(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.BILLLIST_REQUEST:
            return {
                loading: true
            };
        case entityConstants.BILLLIST_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case entityConstants.BILLLIST_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}

export function createEntityReducer(state = {}, action: any) {
    console.log(action,action?.entity?.data,action?.entity,action?.data,"sdfszffzsffff");
    
    switch (action.type) {
        case entityConstants.ADDTOENTITY_REQUEST:
            return {
                loading: true
            };
        case entityConstants.ADDTOENTITY_SUCCESS:
            return {
                data: action.entity.data,
                loading: false
            };
        case entityConstants.ADDTOENTITY_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}

export function updateEntity(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.UPDATEENTITY_REQUEST:
            return {
                registering: true,
                refreshing: 'true',
                loading: true,
            }
        case entityConstants.UPDATEENTITY_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {

                    data: action.data,
                    loading: false,
                }
            }
        case entityConstants.UPDATEENTITY_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function getEntities(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GETENTITY_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.GETENTITY_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case entityConstants.GETENTITY_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function getBusinessDetails(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.BUSINESS_DETAILS_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.BUSINESS_DETAILS_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case entityConstants.BUSINESS_DETAILS_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export const selectAllData = (state: any) => {
    return {
        'GETENTITYITEMS_SUCCESS': state.getEntityTypesReducer.data,
        'GET_ENTITY_ACTIVITY_SUCCESS': state.getEntityActivitiesReducer.data,
        'UPDATEENTITY_SUCCESS': state.updateEntity.data,
        'UPDATEENTITY_REQUEST': state.updateEntity.loading,
        'UPDATEENTITY_FAILURE': state.updateEntity.error,
        'BILLLIST_SUCCESS': state.getBillsslist.data,
        'BILLLIST_REQUEST': state.getBillsslist.loading,
        'BILLLIST_FAILURE': state.getBillsslist.error,
        'GETENTITY_SUCCESS': state.getEntities.data,
        'GETENTITY_REQUEST': state.getEntities.loading,
        'GETENTITY_FAILURE': state.getEntities.error,
        'BUSINESS_DETAILS_SUCCESS': state.getBusinessDetails.data,
        'BUSINESS_DETAILS_REQUEST': state.getBusinessDetails.loading,
        'BUSINESS_DETAILS_FAILURE': state.getBusinessDetails.error,
        'ADDTOENTITY_SUCCESS': state.createEntityReducer.data,
        'ADDTOENTITY_REQUEST': state.createEntityReducer.loading,
        'ADDTOENTITY_FAILURE': state.createEntityReducer.error,
    };
}