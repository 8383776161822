import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import "../RecipientBank/RecipientBank.scss";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isUserAuthenticated } from "../../../../../../_helpers";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { userActions, dashboardActions } from "../../../../../../_actions";
import moment from 'moment';
import { useSearchParams } from "react-router-dom";

const RTGSPayment = (props:any) => {
    const [entity, setEntity] = useState<any>({
        bank_name: '',
        account_name: '',
        account_number: '',
        branch_name:""
    })
    const [bankList, setBankList] = useState<any>([{
        "id": 5,
        "bank_name": "Prime Bank",
        "bank_code": "10",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 6,
        "bank_name": "Cooperative Bank",
        "bank_code": "11",
        "created_at": null,
        "updated_at": null
    }]);
    const [datadisplay, setDatadisplay] = useState('')
    const [bankvalid, setBankvlid] = useState('1')
    const [loading, setLoading] = React.useState(false);
    const [render, setRender] = useState(true);
    const [bankListRender, setBankListRender] = useState<any>(true);
    const [bank_name, setBank_name] = useState('')
    const [apidata, setApidata] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
    })
    const [searchParams, setSearchParams] = useSearchParams();

    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const dispatch = useDispatch();

    useEffect(() => {
        const popup = searchParams.get('type');
        if (!!popup) {
          setDatadisplay(popup)
        }else {
          setDatadisplay('')
        }
      }, [searchParams]);
    const onCancel = () => {
        props.onCancel();
        setBankvlid("1")
    }
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(paymentJourneyActions.getPesaBankList() as any)
        }
    }, [render, isUserAuthenticated]);
    useEffect(() => {
        if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
            setBankListRender(false);
            setBankList(dataList.GETPESABANKLIST_SUCCESS);
            if (!!props.counterpart) {
                const object = {
                    bank_name: '',
                    branch_name: props.counterpart.bank_branch,
                    account_name: !!props?.counterpart?.account_name?props?.counterpart?.account_name:"",
                    account_number: !!props?.counterpart?.bank_account_number?props.counterpart?.bank_account_number:"",
                }
                const findBankId = dataList.GETPESABANKLIST_SUCCESS.find((o: any) => o.bank_name == props.counterpart.bank_name)
                if (!!findBankId) {
                    object.bank_name = findBankId.id
                }
                setBank_name(findBankId)
                setEntity(object)
            }
        }
    }, [dataList, bankListRender]);
    const validationSchema = Yup.object().shape({
        bank_name: Yup.string()
            .required('Please select the bank name.'),
        // branch_name: Yup.string()
        //     .required('Bank Name is required'),
        account_name: Yup.string()
            .required('Please enter the account name.'),
        account_number: Yup.number()
            .typeError('Our apologies, this Group account does not support transactions with third-party merchants. Instead, consider opting for a Direct M-Pesa transfer.')
            .required('Please enter the account number.'),
    })
    const onSave = (bank: any) => {
        console.log(bank,"logg21211121");
        
        props.onSave(bank);
    }
    console.log(props,"propsprops123");
    // var Date_formet=new Date();
    // console.log(moment(Date_formet).format("YYYY-MM-DD"),"Date_formetDate_formet");
    
    return (
        <>
         
        <div className='recipient-bank-container'>
            <p className='heding_typename' style={{padding:"0px 0px 0px 27px"}}>
            {props?.payOption} payment
            </p>
            <div style={{textAlign:"left",marginBottom:"6px",padding:"0px 0px 0px 27px"}} className='recipient-bank-container__heading'>
            Select Recipient Bank
            </div>
            <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var Date_formet=new Date();
                    const findBankDetails = bankList.find((o: any) => o.id == values.bank_name)
                    const body = {
                        bank_name: findBankDetails.bank_name,
                        bank_code: findBankDetails.niobi_bank_code,
                        branch_name:values.branch_name,
                        payment_reference:props?.nameRef,
                        "payment_channel":props?.payOption,
                        transaction_date:moment(Date_formet).format("YYYY-MM-DD"),
                        currency:datadisplay=="Express Payment USD"?"USD":"KES",
                        payment_method:"bank_transfer",
                        account_name: values.account_name,
                        account_number: values.account_number,
                        counterpart: props.body_data.counterpart,
                        amount: props.body_data.amount,
                        tags: props.body_data.tags,
                        counterpart_type: props.body_data.counterpart_type,
                        is_tax: props.body_data.is_tax,
                        vat: props?.body_data?.vat
                    }                    
                    dispatch(userActions.getOtp('') as any)
                    props.transactionExist('CMFB')
                    onSave(body)
                  
                }}
            >
                {({ errors, touched, isSubmitting, handleChange, values }) => (
                    <div className='company-details-container__wrapper'>
                        <Form>
                            <div className='user-detail-container__wrapper'>
                                <div className='input-form-wrapper dot'>
                                    
                                    <label>Bank name</label>
                                    <Field name="bank_name" as="select"
                                        // onChange={(e:any)=>{setBank_name(e.target.value)}}
                                        className={'text-field select-box' + (errors.bank_name && touched.bank_name ? ' is-invalid' : '')} >
                                        <option value="">--Select bank name--</option>
                                        {Array.isArray(bankList)
                                            ? bankList?.map((element: any) => {
                                                return <option key={element.id} value={element.id}>{element.bank_name}</option>
                                            })
                                            : "data not found"}
                                    </Field>
                                    <ErrorMessage name="bank_name" component="div" className="invalid-feedback red" />
                                </div>
                            <div className="input-form-wrapper m-10">
                                    <label>Bank branch</label>
                                    <Field
                                        name="branch_name"
                                        type="text"
                                        
                                        placeholder="Branch Name"
                                        className={
                                            "text-field field-enable"
                                        }
                                    />
                                  
                                </div>
                                <div className="input-form-wrapper mb-4 dot">
                                    <label>Account name</label>
                                    <Field
                                        name="account_name"
                                        type="text"
                                        placeholder="Account Name"
                                        className={
                                            "text-field field-enable" +
                                            (errors.account_name && touched.account_name
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="account_name"
                                        component="div"
                                        className="invalid-feedback red"
                                    />
                                </div>
                                <div className="input-form-wrapper m-10 dot">
                                    <label>Account number</label>
                                    <Field
                                        name="account_number"
                                        type="text"
                                        placeholder="Account Number"
                                        className={
                                            "text-field field-enable" +
                                            (errors.account_number && touched.account_number
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="account_number"
                                        component="div"
                                        className="invalid-feedback red"
                                    />
                                </div>
                                <div className="auth-trans-container__btn-row">
                                    <div>
                                        <Button className='complete-profile__saventmm' variant='custom'
                                            onClick={() => { onCancel(); }}
                                        >   Cancel  </Button>
                                    </div>
                                    <div>
                                        <Button type='submit'
                                        disabled={loading}
                                            style={{ display: "flex", alignItems: "center" }}
                                            className='complete-profile__submitbtnlist' >

                                            <p style={{textTransform:"capitalize"}}>Continue</p>{loading == true && <div style={{ marginLeft: "6px" }} className="spinner-border spinner-border-sm mr-1"></div>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div >

        </>
    )
}
export default RTGSPayment;