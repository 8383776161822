import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import { Form, InputGroup } from 'react-bootstrap';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';
import { ErrorMessage } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader';

const LimitedLiability = ({
  formik,
  certificateFile,
  certificateLabel,
  crLabel,
  crFile,
  memorandumLabel,
  memorandumFile,
  handleImage,
  loader,
  fileSize,
  resolutionLabel,
  resolutionFile,
  kraLabel,
  handleRemoveImage,
  ultimateBeneficiaryLabel,
  ultimateBeneficiaryFile,
  articleOfAssociationLabel,
  articleOfAssociationFile,
  kraFile,
}: any) => {
  const industries = useSelector(
    (state: any) => state.getIndustriesReducer.data
  );

  const operationModes = useSelector(
    (state: any) => state.getBusinessOperationModesReducer.data
  );

  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const [kraAccordion, setKraAccordion] = useState<boolean>(false);

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  // const [chack_cox_data, setChack_cox_data] = useState(false);
  const [invoicefile, setinvoicefile] = useState('Upload supporting documents');

  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getIndustries() as any);
    dispatch(choiceActions.getOperatingModes() as any);
  }, [dispatch]);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  // const imageUrl =
  //   'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  return (
    <>
      <p className="complete-profile__businesstypo">Business Details</p>
      <div className="calss_accordion_contenar">
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (paymentacodiyan === false) {
              setPaymentacodiyan(true);
              setDataccodiyan(false);
            } else {
              setPaymentacodiyan(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div className="avayar_text">
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">Company Details</p>
            </div>
            {paymentacodiyan === false ? (
              <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
            ) : (
              <ExpandLessIcon style={{ color: '#1D1D1D' }} />
            )}
          </ThemeProvider>
        </div>

        {paymentacodiyan === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Incorporation Name</label>
              <InputGroup className="doc-input-wrapper ">
                <Form.Control
                  name="businessName"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.businessName && formik.errors.businessName
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessName" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Number of Company Certificate</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="businessCerNum"
                  value={formik.values.businessCerNum}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.businessCerNum &&
                    Boolean(formik.errors.businessCerNum)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessCerNum" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Address of the Incorporation</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="businessAddress"
                  value={formik.values.businessAddress}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.businessAddress &&
                    Boolean(formik.errors.businessAddress)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessAddress" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Industry of the Company</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Select
                  name="businessIndustry"
                  value={formik.values.businessIndustry}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.businessIndustry &&
                    Boolean(formik.errors.businessIndustry)
                  }
                >
                  <option>Business Industry</option>
                  {Array.isArray(industries)
                    ? industries?.map((element: any) => {
                        return (
                          <option key={element.id} value={element.key}>
                            {element.value}
                          </option>
                        );
                      })
                    : 'data not found'}
                </Form.Select>
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessIndustry" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Business Operating Mode</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Select
                  name="operatingMode"
                  value={formik.values.operatingMode}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.operatingMode &&
                    Boolean(formik.errors.operatingMode)
                  }
                >
                  <option>Business Operating Modes</option>
                  {Array.isArray(operationModes)
                    ? operationModes?.map((element: any) => {
                        return (
                          <option key={element.id} value={element.key}>
                            {element.value}
                          </option>
                        );
                      })
                    : 'data not found'}
                </Form.Select>
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="operatingMode" component="div" />
              </p>
            </div>

            {formik.values.operatingMode === 5 && (
              <div className="input-form-wrapper dot">
                <label>Specify other operating mode</label>
                <InputGroup className="doc-input-wrapper">
                  <Form.Control
                    name="operatingModeOther"
                    value={formik.values.specify}
                    onChange={formik.handleChange}
                    type="text"
                    isInvalid={
                      formik.touched.specify && Boolean(formik.errors.specify)
                    }
                  />
                </InputGroup>
                <p className="error-message">
                  {' '}
                  <ErrorMessage name="businessAddress" component="div" />
                </p>
              </div>
            )}
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data
                        ?.businessCertificate?.file_name != null
                        ? formik.values?.businessDocs?.data?.data
                            ?.businessCertificate?.file_name
                        : certificateFile}
                    </span>
                    {/* {certificateFile === certificateLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                  </div>
                  <input
                    type="file"
                    name="businessCertificate"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.businessCertificate ? (
                      <ErrorMessage
                        name="businessCertificate"
                        component="div"
                      />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {certificateFile === certificateLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('businessCertificate');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '25px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.cr?.file_name !=
                        null
                        ? formik.values?.businessDocs?.data?.data?.cr?.file_name
                        : crFile}
                    </span>
                    {/* {crFile === crLabel ? <UploadIcon /> : <CloseIcon />} */}
                  </div>
                  <input
                    type="file"
                    name="cr"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.cr ? (
                      <ErrorMessage name="cr" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {crFile === crLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('cr');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '25px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.memorandum
                        ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data?.memorandum
                            ?.file_name
                        : memorandumFile}
                    </span>
                    {/* {memorandumFile === memorandumLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                  </div>
                  <input
                    type="file"
                    name="memorandum"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.memorandum ? (
                      <ErrorMessage name="memorandum" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {memorandumFile === memorandumLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('memorandum');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '25px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.resolution
                        ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data?.resolution
                            ?.file_name
                        : resolutionFile}
                    </span>
                    {/* {resolutionFile === resolutionLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                  </div>
                  <input
                    type="file"
                    name="resolution"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.resolution ? (
                      <ErrorMessage name="resolution" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {resolutionFile === resolutionLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    handleRemoveImage('resolution');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '25px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data
                        ?.ultimateBeneficiary?.file_name != null
                        ? formik.values?.businessDocs?.data?.data
                            ?.ultimateBeneficiary?.file_name
                        : ultimateBeneficiaryFile}
                    </span>
                    {/* {resolutionFile === resolutionLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                  </div>
                  <input
                    type="file"
                    name="ultimateBeneficiary"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.resolution ? (
                      <ErrorMessage
                        name="ultimateBeneficiary"
                        component="div"
                      />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {ultimateBeneficiaryFile === ultimateBeneficiaryLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    handleRemoveImage('ultimateBeneficiary');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '25px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data
                        ?.articleOfAssociation?.file_name != null
                        ? formik.values?.businessDocs?.data?.data
                            ?.articleOfAssociation?.file_name
                        : articleOfAssociationFile}
                    </span>
                    {/* {resolutionFile === resolutionLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                  </div>
                  <input
                    type="file"
                    name="articleOfAssociation"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.resolution ? (
                      <ErrorMessage
                        name="articleOfAssociation"
                        component="div"
                      />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {articleOfAssociationFile === articleOfAssociationLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    handleRemoveImage('articleOfAssociation');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div className="calss_accordion_contenar">
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (kraAccordion === false) {
              setKraAccordion(true);
              setDataccodiyan(false);
            } else {
              setKraAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div className="avayar_text">
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">KRA Details</p>
            </div>
            {kraAccordion === false ? (
              <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
            ) : (
              <ExpandLessIcon style={{ color: '#1D1D1D' }} />
            )}
          </ThemeProvider>
        </div>

        {kraAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>KRA PIN Number</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kraPin"
                  value={formik.values.kraPin}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kraPin && Boolean(formik.errors.kraPin)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kraPin" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.kraPinCertificate
                        ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data
                            ?.kraPinCertificate?.file_name
                        : kraFile}
                    </span>
                  </div>
                  <input
                    type="file"
                    name="kraPinCertificate"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.kraPinCertificate ? (
                      <ErrorMessage name="kraPinCertificate" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {kraFile === kraLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('kraPinCertificate');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {loader && <Loader />}
    </>
  );
};

export default LimitedLiability;
