import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useDispatch, useSelector } from "react-redux";
import { selectPaymentJourneyData } from '../../../../../../_reducers/paymentJourney.reducer';
import "./Payoptions.scss";


const LimitTransactionModel = (props: any) => {

  console.log('limit modal props =>', props);
  
  const [showModal, setShow] = useState(props.show);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  console.log(`show modal prop:${showModal}`);
  const onClose = () => {
    setShow(false);
    props.onClose(false);
    props.setNewBalanceCheck(false);
    props.setLimitModal(false);
    props.setChackBalance(true);
  };

  return (
    <>

      <ThemeProvider theme={theme}>
        <Box className="CloseContenar">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="">
          <p className='limitModal_heading'>How do you want to pay?</p>
        </Box>
      </ThemeProvider>

      <Modal.Body>
        <div className="limitModal_Buttons">
            <div className='buttons_wrapper'>
          <div>
            <Button className='rtgf_eft_btn' variant='custom'
            onClick={() =>{
            if(props?.paymentName === "Proceed with RTGS"){
             props.setSelectMethodType("RTGS")
             props.setLoaderPage(true)
             props.setStartAPI(true)
            
              console.log("RTGS Running!")

              onClose()
            } else if(props?.paymentName === "Proceed with EFT"){
             props.setSelectMethodType("EFT")
             props.setLoaderPage(true)
             props.setStartAPI(true)

              console.log("EFT Running!")

              onClose()
            }}}
            >{props?.paymentName} </Button>
            </div>
           <div>
             <Button style={{ display: "flex", alignItems: "center" }} className='pesalink_btn'
             onClick={() =>{
            props.setSelectMethodType("Bank")
            props.setLoaderPage(true)
            props.setStartAPI(true)
              console.log("PESALINK Running!")

              onClose()
            }}
             >
              <p style={{textTransform:"capitalize"}}>Choose Pesalink Instead</p>
              </Button>
            </div>
            </div>
        </div>
      </Modal.Body>
    </>
  )
}
export default LimitTransactionModel;
