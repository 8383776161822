import React, { FC, useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel } from '@mui/material';
import SoleProprietoryStepOne from './SoleProprietoryStepOne';
import SoleProprietory from './SoleProprietory';
import '../Settings.scss';
import { Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { choiceActions } from '../../../../../../../../../_actions';
import { useDispatch } from 'react-redux';

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
}
const RegistrationForm: FC<Props> = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0);

  const [snapshot, setSnapshot] = useState(initialValues);

  console.log('snapshot.....:', snapshot);

  const dispatch = useDispatch();

  const steps = React.Children.toArray(children) as React.ReactElement[];

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const certificateLabel = 'Photo of the Business Certificate';
  const [certificateFile, setCertificateFile] = useState(certificateLabel);
  const selfieLabel = 'Selfie Photo of the business owner';
  const idFrontLabel =
    'Photo of the frontside of the ID Card of the business owner';
  const idBackLabel =
    'Photo of the backside of the ID Card of the business owner';
  const kraLabel = 'Photo of the KRA PIN Certificate of the business owner';
  const [selfieFile, setSelfieFile] = useState(selfieLabel);
  const [idFrontFile, setIdFrontFile] = useState(idFrontLabel);
  const [idBackFile, setIdBackFile] = useState(idBackLabel);
  const [kraFile, setKraFile] = useState(kraLabel);
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [fileSize, setFileSize] = useState<any>('');
  const [loader, setLoader] = useState(false);

  // const handleImage = (e: any) => {
  //   if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
  //     setFileSize(e.target.files[0].size);
  //     setCertificateFile(e.target.files[0].name);
  //     getBase64(e.target.files[0], (result: any) => {
  //       setinvoiceImage(result);
  //       const payload = {
  //         title: 'certificateFile',
  //         document_type: '1',
  //         mediaBase64: result,
  //         type: 'business_documents',
  //         mediaType: 'KYCF00010',
  //         contentType: 'image',
  //       };
  //       dispatch(
  //         choiceActions.uploadMedia(payload, () =>
  //           console.log('testing')
  //         ) as any
  //       );
  //       // setSubmitted(true);
  //       // formik.setFieldValue('businessCertificate', result);
  //     });
  //   }
  // };
  const handleLoader = (value: boolean) => {
    setLoader(value);
  };

  const handleImage = (e: any, formik: any) => {
    let field = e.target.name;
    if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
      setFileSize(e.target.files[0].size);
      let title: any = null;
      let mediaType: any = null;
      switch (e.target.name) {
        case 'selfie':
          setSelfieFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00006';
          break;
        case 'idFront':
          setIdFrontFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00001';
          break;
        case 'idBack':
          setIdBackFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00002';
          break;
        case 'kra':
          setKraFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00009';
          break;
        case 'businessCertificate':
          setCertificateFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00010';
          break;
        default:
          break;
      }
      getBase64(e.target.files[0], (result: any) => {
        setinvoiceImage(result);
        formik.setFieldValue(field, result);
        const payload = {
          title: title,
          document_type: '1',
          mediaBase64: result,
          type: 'business_documents',
          mediaType,
          contentType: 'image',
        };
        dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
      });
    }
  };

  const handleRemoveImage = (fileName: string) => {
    if (fileName !== null && fileName !== undefined) {
      switch (fileName) {
        case 'selfie':
          setSelfieFile(selfieLabel);
          setinvoiceImage('');
          break;
        case 'idFront':
          setIdFrontFile(idFrontLabel);
          setinvoiceImage('');
          break;
        case 'idBack':
          setIdBackFile(idBackLabel);
          setinvoiceImage('');
          break;
        case 'kra':
          setKraFile(kraLabel);
          setinvoiceImage('');
          break;
        case 'businessCertificate':
          setCertificateFile(certificateLabel);
          setinvoiceImage('');
          break;
        default:
          break;
      }
    }
  };

  const isLastStep = stepNumber === totalSteps - 1;

  const handleNext = (values: FormikValues) => {
    console.log(values);
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber + 1);
  };

  const handleBack = (values: FormikValues) => {
    console.log('Clicked back button');
    console.log('values in back', values);
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber - 1);
    console.log('After changing state', stepNumber);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    console.log('current values', values);
    console.log('******hm getting here****');
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      // actions.setTouched({});
      handleNext(values);
    }
  };

  return (
    <>
      <div className="complete-profile__heading">
        Please provide the following documents
      </div>
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Stepper
              activeStep={stepNumber}
              style={{ width: '40%', margin: '10px auto' }}
              sx={{
                '.MuiStepConnector-root span': {
                  borderColor: '#025041',
                },

                '.MuiSvgIcon-root': {
                  borderRadius: '50%',
                  border: '1px solid #025041',
                },
                '.MuiSvgIcon-root:not(.Mui-completed)': {
                  color: 'white',
                },
                '.MuiStepIcon-text': {
                  fill: '#025041',
                  fontWeight: 500,
                },
                '.MuiSvgIcon-root.Mui-active': {
                  color: '#025041',
                  padding: '3px',
                  borderRadius: '50%',
                  border: '1px solid #025041',
                  marginY: '-3px',
                },
                '.Mui-active .MuiStepIcon-text': {
                  fill: 'white',
                },
                '.MuiStepIcon-root.Mui-completed': {
                  color: '#025041', // Set the fill color for completed steps
                },
              }}
            >
              {steps.map((currentStep, index) => {
                const label = currentStep.props.stepName;
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {stepNumber === 0 && (
              <>
                <SoleProprietoryStepOne
                  formik={formik}
                  certificateFile={certificateFile}
                  handleImage={handleImage}
                  loader={loader}
                  certificateLabel={certificateLabel}
                  handleRemoveImage={handleRemoveImage}
                />
              </>
            )}
            {stepNumber === 1 && (
              <>
                <SoleProprietory
                  formik={formik}
                  loader={loader}
                  handleImage={handleImage}
                  idFrontFile={idFrontFile}
                  idFrontLabel={idFrontLabel}
                  idBackFile={idBackFile}
                  idBackLabel={idBackLabel}
                  selfieFile={selfieFile}
                  selfieLabel={selfieLabel}
                  kraFile={kraFile}
                  kraLabel={kraLabel}
                  handleRemoveImage={handleRemoveImage}
                />
              </>
            )}
            {/* <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={stepNumber === steps.length - 1}
        >
          {stepNumber === steps.length - 1 ? 'Submit' : 'Next'}
        </Button>
        {stepNumber > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBack}
            sx={{ marginLeft: 8 }}
          >
            Back
          </Button>
        )}
      </Grid> */}
            <Box className="complete-profile__boxbtndisplay">
              {stepNumber > 0 && (
                <button
                  className="complete-profile__goback"
                  onClick={() => handleBack(formik.values)}
                >
                  Back
                </button>
              )}
              {stepNumber === 0 && (
                <button type="submit" className="complete-profile__goback">
                  Save
                </button>
              )}

              <button type="submit" className="complete-profile__submitbtnlist">
                {isLastStep ? 'Submit' : 'Next'}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default RegistrationForm;
