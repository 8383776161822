import { authHeader, handleResponse } from '../_helpers';
import moment from 'moment';
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL = 
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;

export const balanceService = {
  getBalanceList,
  getTopUpDetails,
  getBalanceListtwo,
  getExportCsv,
  getBankDetailsById,
  savePaymentDetails,
  Stk_deposit_service,
  getPdf_service,
  getPdf_Topup_service,
  PostKasPayment,
  getTopUpDetails_doller,
  savePaymentcurrencyDetails,
  TransStatustopup,
  getPdf_Topup_serea,
};

// get balance types api
function getBalanceList(datalist) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  var pagelimit = 10;
  if (!!datalist.enddate && !!datalist.search && !!datalist.startdate) {
    console.log('apilis1', datalist);
    return fetch(
      `${baseURL}/wallet/get-wallet-data?search=${
        datalist.search
      }&start-date=${moment(datalist.startdate).format(
        'YYYY-MM-DD'
      )}&end-date=${moment(datalist.enddate).format('YYYY-MM-DD')}&page=${
        datalist.pagedata
      }&limit=${pagelimit}`,
      requestOptions
    ).then(handleResponse);
  } else if (!!datalist.enddate && !!datalist.search) {
    console.log('apilis2', datalist);
    return fetch(
      `${baseURL}/wallet/get-wallet-data?search=${
        datalist.search
      }&start-date=&end-date=${moment(datalist.enddate).format(
        'YYYY-MM-DD'
      )}&page=${datalist.pagedata}&limit=${pagelimit}`,
      requestOptions
    ).then(handleResponse);
  } else if (!!datalist.startdate && !!datalist.search) {
    console.log('apilis3', datalist);
    return fetch(
      `${baseURL}/wallet/get-wallet-data?search=${
        datalist.search
      }&start-date=${moment(datalist.startdate).format(
        'YYYY-MM-DD'
      )}&end-date=&page=${datalist.pagedata}&limit=${pagelimit}`,
      requestOptions
    ).then(handleResponse);
  } else if (!!datalist.search) {
    console.log('apilis4', datalist);
    return fetch(
      `${baseURL}/wallet/get-wallet-data?search=${datalist.search}&start-date=&end-date=&page=${datalist.pagedata}&limit=${pagelimit}`,
      requestOptions
    ).then(handleResponse);
  } else if (!!datalist.enddate) {
    return fetch(
      `${baseURL}/wallet/get-wallet-data?search=&start-date=&end-date=${moment(
        datalist.enddate
      ).format('YYYY-MM-DD')}&page=${datalist.pagedata}&limit=${pagelimit}`,
      requestOptions
    ).then(handleResponse);
    console.log('apilis5', datalist);
  } else if (!!datalist.startdate) {
    console.log('apilis6', datalist);
    return fetch(
      `${baseURL}/wallet/get-wallet-data?search=&end-date=&start-date=${moment(
        datalist.startdate
      ).format('YYYY-MM-DD')}&page=${datalist.pagedata}&limit=${pagelimit}`,
      requestOptions
    ).then(handleResponse);
  } else {
    console.log('apilis7', datalist);
    return fetch(
      `${baseURL}/wallet/get-wallet-data?search=&end-date=&start-date=&page=${datalist.pagedata}&limit=${pagelimit}`,
      requestOptions
    ).then(handleResponse);
  }
}
function getBalanceListtwo(datalist) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-wallet-data?search=${
      datalist.search
    }&start-date=${moment(datalist.startdate).format(
      'YYYY-MM-DD'
    )}&end-date=${moment(datalist.enddate).format('YYYY-MM-DD')}&page=&limit=`,
    requestOptions
  ).then(handleResponse);
}
function getTopUpDetails() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/top-up-details`, requestOptions).then(
    handleResponse
  );
}
function getTopUpDetails_doller() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-currencies`, requestOptions).then(
    handleResponse
  );
}
function getPdf_Topup_service() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-topup-shareable-pdf-link`, requestOptions).then(
    handleResponse
  );
}

function getExportCsv(searcData) {
  console.log(searcData, 'searcData');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/export-csv?${searcData}`, requestOptions).then(
    handleResponse
  );
}

function getBankDetailsById(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/bank/get-bank-details?id=${req.id}&currency=${req.type}`,
    requestOptions
  ).then(handleResponse);
}
function getPdf_service(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-shareable-pdf-link/${req}`, requestOptions).then(
    handleResponse
  );
}
function getPdf_Topup_serea(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/get-shareable-stk-push-pdf-link/${req}`,
    requestOptions
  ).then(handleResponse);
}
function savePaymentDetails(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/bank/save-payment-details`, requestOptions).then(
    handleResponse
  );
}
function savePaymentcurrencyDetails(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/top-up/payment-portal/save-currency-payments`, requestOptions).then(
    handleResponse
  );
}
function PostKasPayment(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/bank/save-payment-details`, requestOptions).then(
    handleResponse
  );
}
function Stk_deposit_service(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/stk-deposit`, requestOptions).then(handleResponse);
}
function TransStatustopup(shortcode) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-transaction-status/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}
