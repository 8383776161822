import React, { FC, useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import LimitedLiability from './LimitedLiability';
import LimitedLiabilityStepTwo from './LimitedLiabilityStepTwo';
import '../Settings.scss';
import {
  Formik,
  FormikValues,
  FormikHelpers,
  FormikConfig,
  setIn,
} from 'formik';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { choiceActions } from '../../../../../../../../../_actions';
import { useDispatch } from 'react-redux';

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  stepOneInfo: any;
  stepTwoInfo: any;
}

// const steps = ['', ''];
const LimitedLiabilityForm: FC<Props> = ({
  children,
  initialValues,
  stepOneInfo,
  stepTwoInfo,
  onSubmit,
}) => {
  const [stepNumber, setStepNumber] = useState(0);

  const [snapshot, setSnapshot] = useState(initialValues);
  const steps = React.Children.toArray(children) as React.ReactElement[];

  console.log('steps.....:', steps);

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const isLastStep = stepNumber === totalSteps - 1;
  const dispatch = useDispatch();

  const certificateLabel = 'Certificate of Incorporation';
  const crLabel = 'Company CR 12 (photo)';
  const memorandumLabel =
    'Memorandum and articles of association (scanned copy or photo)';
  const resolutionLabel =
    'Original board resolutions on company letter head (scanned copy or photo)';
  const kraLabel = 'KRA PIN Certificate';
  const ultimateBeneficiaryLabel =
    'Ultimate Beneficiary owners (scanned copy or photo)';
  const articleOfAssociationLabel =
    'article of associations (scanned copy or photo)';
  const [certificateFile, setCertificateFile] = useState(certificateLabel);
  const [crFile, setCrFile] = useState(crLabel);
  const [memorandumFile, setMemorandumFile] = useState(memorandumLabel);
  const [resolutionFile, setResolutionFile] = useState(resolutionLabel);
  const [ultimateBeneficiaryFile, setUltimateBeneficiaryFile] = useState(
    ultimateBeneficiaryLabel
  );
  const [articleOfAssociationFile, setArticleOfAssociationFile] = useState(
    articleOfAssociationLabel
  );
  const [kraFile, setkraFile] = useState(kraLabel);
  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [loader, setLoader] = useState(false);
  const shareHolderDocLabel: any = {
    selfieLabel: 'Selfie Photo',
    idFrontLabel: 'Photo of the frontside of the ID or passport',
    idBackLabel: 'Photo of the backside of the ID or passport',
    kraPinLabel: 'Photo of the KRA PIN Certificate of the business owner',
  };
  const [shareHolderLabels, setShareHolderLabels] = useState<any>([
    shareHolderDocLabel,
  ]);
  const selfieLabel = 'Selfie Photo';
  const idFrontLabel = 'Photo of the frontside of the ID or passport';
  const idBackLabel = 'Photo of the backside of the ID or passport';
  const kraPinLabel = 'Photo of the KRA PIN Certificate of the business owner';
  const [selfie, setSelfie] = useState(selfieLabel);
  const [idFront, setIdFront] = useState(idFrontLabel);
  const [idBack, setIdBack] = useState(idBackLabel);
  const [kraPin, setKraPin] = useState(kraPinLabel);

  const handleAddNewDirector = () => {
    setShareHolderLabels([...shareHolderLabels, shareHolderDocLabel]);
  };

  const handleUpdateLabel = (index: any, fieldName: any, label: any) => {
    const updatedLabels = [...shareHolderLabels];
    updatedLabels[index][fieldName + 'Label'] = label;
    setShareHolderLabels(updatedLabels);
  };

  const resetLabel = (index: any, fieldName: any) => {
    // Make a copy of the shareHolderLabels array
    const updatedLabels = [...shareHolderLabels];

    // Reset the label for the specified field name at the specified index
    updatedLabels[index][fieldName + 'Label'] =
      shareHolderDocLabel[fieldName + 'Label'];

    // Update the state with the modified labels
    setShareHolderLabels(updatedLabels);
  };
  const handleLoader = (value: boolean) => {
    setLoader(value);
  };
  const handleImage = (e: any, formik?: any, index?: any) => {
    let field = e.target.name;
    console.log('field', field);
    if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
      setFileSize(e.target.files[0].size);
      let title: any = null;
      let mediaType: any = null;
      if (field === 'businessCertificate') {
        setCertificateFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00024';
      } else if (field === 'cr') {
        setCrFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00011';
      } else if (field === 'memorandum') {
        setMemorandumFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00012';
      } else if (field === 'resolution') {
        setResolutionFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00014';
      } else if (field === 'kraPinCertificate') {
        setkraFile(e.target.files[0].name);
        mediaType = 'KYCF00013';
        title = field;
      } else if (field === 'ultimateBeneficiary') {
        setUltimateBeneficiaryFile(e.target.files[0].name);
        mediaType = 'KYCF00023';
        title = field;
      } else if (field === 'articleOfAssociation') {
        setArticleOfAssociationFile(e.target.files[0].name);
        mediaType = 'KYCF00015';
        title = field;
      } else if (field.includes('selfieFile')) {
        // console.log('selfieFile getting here', e.target.files[0].name);
        // setSelfie(e.target.files[0].name);
        handleUpdateLabel(index, 'selfie', e.target.files[0].name);
        mediaType = 'KYCF00006';
        title = field;
      } else if (field.includes('idFrontSideFile')) {
        // setIdFront(e.target.files[0].name);
        handleUpdateLabel(index, 'idFront', e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00001';
      } else if (field.includes('idBackSideFile')) {
        handleUpdateLabel(index, 'idBack', e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00002';
      } else if (field.includes('kraPinFile')) {
        handleUpdateLabel(index, 'kraPin', e.target.files[0].name);
        mediaType = 'KYCF00009';
        title = field;
      }
      getBase64(e.target.files[0], (result: any) => {
        setinvoiceImage(result);
        if (typeof formik !== 'undefined') {
          formik.setFieldValue(field, result);
        }
        const payload = {
          title: title,
          document_type: '2',
          mediaBase64: result,
          type: 'business_documents',
          mediaType,
          contentType: 'image',
        };
        dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
      });
    }
  };

  const handleRemoveImage = (fileName: string, index?: any) => {
    if (fileName !== null && fileName !== undefined) {
      switch (fileName) {
        case 'businessCertificate':
          setCertificateFile(certificateLabel);
          setinvoiceImage('');
          break;
        case 'cr':
          setCrFile(crLabel);
          setinvoiceImage('');
          break;
        case 'memorandum':
          setMemorandumFile(memorandumLabel);
          setinvoiceImage('');
          break;
        case 'resolution':
          setResolutionFile(resolutionLabel);
          setinvoiceImage('');
          break;
        case 'kraPinCertificate':
          setkraFile(kraLabel);
          setinvoiceImage('');
          break;
        case 'ultimateBeneficiary':
          setUltimateBeneficiaryFile(ultimateBeneficiaryLabel);
          setinvoiceImage('');
          break;
        case 'articleOfAssociation':
          setArticleOfAssociationFile(articleOfAssociationLabel);
          setinvoiceImage('');
          break;
        case 'selfie':
          resetLabel(index, 'selfie');
          setSelfie(selfieLabel);
          setinvoiceImage('');
          break;
        case 'idFront':
          resetLabel(index, 'idFront');
          setIdFront(idFrontLabel);
          setinvoiceImage('');
          break;
        case 'idBack':
          resetLabel(index, 'idBack');
          setIdBack(idBackLabel);
          setinvoiceImage('');
          break;
        case 'kra':
          resetLabel(index, 'kraPin');
          setKraPin(kraPinLabel);
          setinvoiceImage('');
          break;
        default:
          break;
      }
    }
  };

  const handleNext = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber + 1);
  };

  const handleBack = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      handleNext(values);
    }
  };

  return (
    <>
      {/* <button
        onClick={handleBack}
        className="back_btn"
        disabled={stepNumber === 0}
      >
        Back
      </button> */}
      <div className="complete-profile__heading">
        Please provide the following documents
      </div>

      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Stepper
              activeStep={stepNumber}
              style={{ width: '40%', margin: '10px auto' }}
              sx={{
                '.MuiStepConnector-root span': {
                  borderColor: '#025041',
                },

                '.MuiSvgIcon-root': {
                  borderRadius: '50%',
                  border: '1px solid #025041',
                },
                '.MuiSvgIcon-root:not(.Mui-completed)': {
                  color: 'white',
                },
                '.MuiStepIcon-text': {
                  fill: '#025041',
                  fontWeight: 500,
                },
                '.MuiSvgIcon-root.Mui-active': {
                  color: '#025041',
                  padding: '3px',
                  borderRadius: '50%',
                  border: '1px solid #025041',
                  marginY: '-3px',
                },
                '.Mui-active .MuiStepIcon-text': {
                  fill: 'white',
                },
                '.MuiStepIcon-root.Mui-completed': {
                  color: '#025041', // Set the fill color for completed steps
                },
              }}
            >
              {steps.map((currentStep, index) => {
                const label = currentStep.props.stepName;
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {stepNumber === 0 && (
              <>
                <LimitedLiability
                  formik={formik}
                  certificateFile={certificateFile}
                  certificateLabel={certificateLabel}
                  crLabel={crLabel}
                  crFile={crFile}
                  memorandumLabel={memorandumLabel}
                  memorandumFile={memorandumFile}
                  resolutionLabel={resolutionLabel}
                  resolutionFile={resolutionFile}
                  ultimateBeneficiaryLabel={ultimateBeneficiaryLabel}
                  ultimateBeneficiaryFile={ultimateBeneficiaryFile}
                  articleOfAssociationLabel={articleOfAssociationLabel}
                  articleOfAssociationFile={articleOfAssociationFile}
                  kraLabel={kraLabel}
                  handleRemoveImage={handleRemoveImage}
                  handleImage={handleImage}
                  fileSize={fileSize}
                  loader={loader}
                  kraFile={kraFile}
                />
              </>
            )}
            {stepNumber === 1 && (
              <>
                <LimitedLiabilityStepTwo
                  formik={formik}
                  idFront={idFront}
                  idFrontLabel={idFrontLabel}
                  shareHolderLabels={shareHolderLabels}
                  handleAddNewDirector={handleAddNewDirector}
                  idBackLabel={idBackLabel}
                  idBack={idBack}
                  kraPin={kraPin}
                  kraPinLabel={kraPinLabel}
                  selfie={selfie}
                  selfieLabel={selfieLabel}
                  handleImage={handleImage}
                  handleRemoveImage={handleRemoveImage}
                  loader={loader}
                />
              </>
            )}
            <Box className="complete-profile__boxbtndisplay">
              {stepNumber > 0 && (
                <button
                  className="complete-profile__goback"
                  onClick={() => handleBack(formik.values)}
                >
                  Back
                </button>
              )}
              {stepNumber === 0 && (
                <button type="submit" className="complete-profile__goback">
                  Save
                </button>
              )}
              <button type="submit" className="complete-profile__submitbtnlist">
                {isLastStep ? 'Submit' : 'Next'}
              </button>
            </Box>
          </form>
        )}
      </Formik>
      {/* <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={activeStep === steps.length - 1}
        >
          {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
        </Button>
        {activeStep > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBack}
            sx={{ marginLeft: 8 }}
          >
            Back
          </Button>
        )}
      </Grid> */}
    </>
  );
};

export default LimitedLiabilityForm;
export const FormStep = ({ stepName = '', children }: any) => children;
