import { authHeader, handleResponse } from '../_helpers';
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;
console.log(getEnvironment(), baseURL, "getEnvironment()123");
export const paymentJourneyService = {
  getFees,
  getFeesNew,
  
  getInvite,
  getPesaBankList,
  AddBankDisbursement,
  Express_Payment_Service,
  AddBankDisbursement_experes,
  Create_integration,
  getIntegration,
  Create_credentials,
  getintegration_apikey,
  credentials_delete,
  getdurationlist,
  Create_budget,
  budget_list_data_service,
  budget_viwe_list_service,
  budget_delete_servicers,
  edit_budget,
  getBulk_payment,
  getbulk_payments_status_active,
  getSave_data,
  getPayBILLNew,
  get_accounting_types,
  create_connection,
  get_connections,
  disable_connection,
  getPaymentLink_shortcode,
  CreatePayViaLink,
  TransStatusLink,
  TransLinkPdf,
  invoicesend,
  invoicesendreceiver,
  Bankdetails,
  PaymentLinkApiList,
  paymentLinkApiCreate,
  getShareablePdfLink,
  PaymentLinkApiDeleteServicers,
  PaymentLinkApiUpdateActive,
  StanbicVerifyAccount,
  getCountries,
  getCountriesV2,
  getCountriesDetails,
  getCurrencies,
  getCurrenciesV2,
  getCountriesByCurrency,
  AddBankExperesUsd,
  ChackBalance,
  ChackBalancePaymentLink,
  getCountriesBalances,
  checkTransactionType,
  NiobiSignatureactions,
  getValidateAccount
};

// get fees api
function checkTransactionType(amount) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(amount)
  }

  return fetch(`${baseURL}/check-transaction-type`, requestOptions).then(handleResponse)
}
function getValidateAccount(account_number,method) {
  const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
  }
  return fetch(`${baseURL}/validate-account/${account_number}/${method}`, requestOptions).then(handleResponse)
}
function ChackBalance(currency,amount, payment_method,) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/bank/check-balance-v2/${currency}/${amount}/${payment_method}`,
    requestOptions
  ).then(handleResponse);
}
function ChackBalancePaymentLink(country_id,currency,amount) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payment-link-api/check-limit/${country_id}/${currency}/${amount}`,
    requestOptions
  ).then(handleResponse);
}
function getFees(amount, payment_method) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-fees/${amount}/${payment_method}`,
    requestOptions
  ).then(handleResponse);
}
function getShareablePdfLink(ref, id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-shareable-pdf-link/${ref}/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getInvite(PointerParams) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(PointerParams),
  };
  console.log(PointerParams, 'PointerParams');
  return fetch(`${baseURL}/invite-to-niobi`, requestOptions).then(
    handleResponse
  );
}
function getSave_data(PointerParams) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(PointerParams),
  };
  console.log(PointerParams, 'PointerParams');
  return fetch(
    `${baseURL}/counterparts/update-counterpart`,
    requestOptions
  ).then(handleResponse);
}
function getBulk_payment(PointerParams) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(PointerParams),
  };
  console.log(PointerParams, 'PointerParams');
  return fetch(`${baseURL}/create-bulk-payment-bills`, requestOptions).then(
    handleResponse
  );
}
function getFeesNew(amount) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/wallet/get-fees/${amount}`, requestOptions).then(
    handleResponse
  );
}
function getCountries() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-countries`,
    requestOptions
  ).then(handleResponse);
}
function getCountriesV2() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/v2/get-countries`,
    requestOptions
  ).then(handleResponse);
}
function getCountriesBalances(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/get-countries-with-currency-balances`,
    requestOptions
  ).then(handleResponse);
}
function getCountriesDetails(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-country-payment-methods/${data}`,
    requestOptions
  ).then(handleResponse);
}
function getCountriesByCurrency(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-countries-by-currency?currency=${data}`,
    requestOptions
  ).then(handleResponse);
}
function getCurrencies() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-currencies`,
    requestOptions
  ).then(handleResponse);
}
function getCurrenciesV2() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/v2/get-currencies`,
    requestOptions
  ).then(handleResponse);
}
function getPayBILLNew(amount) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/get-failed-and-likely-to-faild-accounts/${amount}`,
    requestOptions
  ).then(handleResponse);
}
function StanbicVerifyAccount(amount) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(amount),
  };

  return fetch(`${baseURL}/stanbic/verify-account`, requestOptions).then(
    handleResponse
  );
}
function getintegration_apikey(amount) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/integration/get-integration/${amount}`,
    requestOptions
  ).then(handleResponse);
}

function credentials_delete(amount) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/integration/delete-credential/${amount}`,
    requestOptions
  ).then(handleResponse);
}
function budget_list_data_service(amount) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/budget/get-budget-list?id=${amount.id}&date=${amount.date}&type=${amount.type}`,
    requestOptions
  ).then(handleResponse);
}
function budget_viwe_list_service(amount) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/budget/get-budget/${amount}`, requestOptions).then(
    handleResponse
  );
}

function getPesaBankList() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/bank/get-pesalink-bank-list`, requestOptions).then(
    handleResponse
  );
}
function budget_delete_servicers(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/budget/delete/${id}`, requestOptions).then(
    handleResponse
  );
}
function PaymentLinkApiDeleteServicers(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/payment-link-api/delete/${id}`, requestOptions).then(
    handleResponse
  );
}

// budget/get-budget-duration-list
function getdurationlist() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/budget/get-budget-duration-list`,
    requestOptions
  ).then(handleResponse);
}
function getIntegration() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/integration/get-integrations`, requestOptions).then(
    handleResponse
  );
}
function get_accounting_types() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/codat/get-accounting-types`, requestOptions).then(
    handleResponse
  );
}
function get_connections() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/codat/get-connections`, requestOptions).then(
    handleResponse
  );
}
function AddBankDisbursement(bankDetail) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${baseURL}/bank/bank-disbursement`, requestOptions).then(
    handleResponse
  );
}
function Create_budget(bankDetail) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${baseURL}/budget/create`, requestOptions).then(handleResponse);
}
function edit_budget(bankDetail, id) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${baseURL}/budget/update/${id}`, requestOptions).then(
    handleResponse
  );
}
// PaymentLinkApiUpdateActive
function PaymentLinkApiUpdateActive(bankDetail, id) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${baseURL}/payment-link-api/update/${id}`, requestOptions).then(
    handleResponse
  );
}
function Create_integration(tetil) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(tetil),
  };
  return fetch(`${baseURL}/integration/create`, requestOptions).then(
    handleResponse
  );
}
function getbulk_payments_status_active(tetil) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(tetil),
  };
  return fetch(`${baseURL}/get-bulk-payments-status`, requestOptions).then(
    handleResponse
  );
}
function Create_credentials(tetil) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(tetil),
  };
  return fetch(
    `${baseURL}/integration/create-credentials`,
    requestOptions
  ).then(handleResponse);
}
function AddBankDisbursement_experes(bankDetail) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${baseURL}/express-bank-payment`, requestOptions).then(
    handleResponse
  );
}
function AddBankExperesUsd(bankDetail) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${baseURL}/bank/save-payment-details-by-currency`,
    requestOptions
  ).then(handleResponse);
}
function create_connection(bankDetail) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${baseURL}/codat/create-connection`, requestOptions).then(
    handleResponse
  );
}
function disable_connection(bankDetail) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${baseURL}/codat/disable-connection`, requestOptions).then(
    handleResponse
  );
}
function Express_Payment_Service(datalist) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(datalist),
  };
  return fetch(`${baseURL}/v3/payments-portal/niobi-unified-payments`, requestOptions).then(
    handleResponse
  );
}
function NiobiSignatureactions(datalist) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(datalist),
  };
  return fetch(`${baseURL}/niobi-signature`, requestOptions).then(
    handleResponse
  );
}
function getPaymentLink_shortcode(shortcode, environment) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${environment == "pro" ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_STAGING_API_URL}/payment-link-api/get-by-short-code/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}
function CreatePayViaLink(datalist, environment) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(datalist),
  };
  return fetch(`${environment == "pro" ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL}/payment-link-api/pay`, requestOptions).then(
      handleResponse
    );
}
function TransStatusLink(shortcode, environment) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${environment == "pro" ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_STAGING_API_URL}/payment-link-api/get-transaction-status/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}
function PaymentLinkApiList(shortcode) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-by-id/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}
function TransLinkPdf(ref, shortcode) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/payment-link-api/get-shareable-pdf-link/${ref}/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}

function invoicesend(datalist) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(datalist),
  };
  return fetch(`${baseURL}/invoice/send-to-me`, requestOptions).then(
    handleResponse
  );
}
function paymentLinkApiCreate(datalist) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(datalist),
  };
  return fetch(`${baseURL}/payment-link-api/create`, requestOptions).then(
    handleResponse
  );
}
function invoicesendreceiver(datalist) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(datalist),
  };
  return fetch(`${baseURL}/invoice/send-to-receiver`, requestOptions).then(
    handleResponse
  );
}
function Bankdetails(ref) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(
    `${baseURL}/bank/get-bank-details-by-bank_code/${ref}`,
    requestOptions
  ).then(handleResponse);
}
