import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { getUser } from '../../../../../_helpers';
import { useNavigate } from 'react-router-dom';

const ApprovalsModel = (props: any) => {
  const [showModal, setShow] = useState(props.show);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [showCurrency, setShowCurrency] = useState(false);
  const user = getUser()?.data ?? null;
  const navigate = useNavigate();

  const handleNiobiApi = () => {
    setShow(false);
    setShowCurrency(true);
    // props.onconfirm(true);
  };
  const handleNiobiApp = () => {
    setShow(false);
    setShowCurrency(true);
    // props.onconfirm(true);
  };

  console.log(`show modal prop:${showModal}`);
  const onClose = () => {
    setShow(false);
    props.onClose(false);
  };
  return (
    <>

      <Modal.Header closeButton>
        <Modal.Title>Approve</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="label">Select </div>
        <div className="type-card-list">
          <div
            id="main_fast_buttun_data"
            className={`type-card-twoo ${selectedMethod === 'Approve Bill' ? 'active' : ''
              }`}
            onClick={() => setSelectedMethod('Approve Bill')}
          >
            <span id="sdFsfsfsfsfff" className="type-card__name">
              Approve Bill
            </span>
          </div>
          <div
            className={`type-card-twoo ${selectedMethod === 'Approve Bulk Payments' ? 'active' : ''
              }`}
            onClick={() => setSelectedMethod('Approve Bulk Payments')}
          >
            <span id="sdFsfsfsfsfff" className="type-card__name">
              Approve Bulk Payments
            </span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="white-btn" onClick={onClose}>
          Cancel
        </Button>

        <Button
          className="green-btn"
          disabled={selectedMethod === ''}
          onClick={() => {
            if (selectedMethod === 'Approve Bulk Payments') {
              navigate('/approvals/approve_payment');
             
              onClose()
            } else {
              navigate('/approvals/bills-approval');
              onClose()
            }
          }}
        >
          Next
        </Button>
      </Modal.Footer>
    </>
  )
}
export default ApprovalsModel;