import React, { FC, useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import PartnershipStepOne from './PartnershipStepOne';
import PartnershipStepTwo from './PartnershipStepTwo';
import '../Settings.scss';
import { Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { consumers } from 'stream';
import { useDispatch } from 'react-redux';
import { choiceActions } from '../../../../../../../../../_actions';

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  stepOneInfo: any;
  stepTwoInfo: any;
}

const PartnershipForm: FC<Props> = ({
  children,
  initialValues,
  stepOneInfo,
  stepTwoInfo,
  onSubmit,
}) => {
  const [stepNumber, setStepNumber] = useState(0);

  const [snapshot, setSnapshot] = useState(initialValues);
  const steps = React.Children.toArray(children) as React.ReactElement[];

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const dispatch = useDispatch();
  const isLastStep = stepNumber === totalSteps - 1;

  const certificateLabel =
    ' Certificate of registration (scanned copy or photo)';
  const partnershipLabel =
    ' Certified parternship deed (scanned copy or photo)';
  const mandateLabel = ' A clear mandate on the mode of operations';
  const [certificateFile, setCertificateFile] = useState(certificateLabel);
  const [partnershipFile, setPaternshipFile] = useState(partnershipLabel);
  const [mandateFile, setMandateFile] = useState(mandateLabel);
  const selfieLabel = 'Selfie Photo';
  const idFront = 'Photo of the frontside of the ID or passport';
  const idBackLabel = 'Photo of the backside of the ID or passport';
  const kraLabel = 'Photo of the KRA PIN Certificate of the business owner';
  const [selfieFile, setSelfieFile] = useState(selfieLabel);
  const [idFrontFile, setIdFrontFile] = useState(idFront);
  const [idBackFile, setIdBackFile] = useState(idBackLabel);
  const [kraFile, setKraFile] = useState(kraLabel);
  const [loader, setLoader] = useState(false);
  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const shareHolderDocLabel: any = {
    selfieLabel: 'Selfie Photo',
    idFrontLabel: 'Photo of the frontside of the ID or passport',
    idBackLabel: 'Photo of the backside of the ID or passport',
    kraPinLabel: 'Photo of the KRA PIN Certificate of the business owner',
  };
  const [shareHolderLabels, setShareHolderLabels] = useState<any>([
    shareHolderDocLabel,
  ]);

  const handleAddNewPartner = () => {
    setShareHolderLabels([...shareHolderLabels, shareHolderDocLabel]);
  };

  const handleUpdateLabel = (index: any, fieldName: any, label: any) => {
    const updatedLabels = [...shareHolderLabels];
    updatedLabels[index][fieldName + 'Label'] = label;
    setShareHolderLabels(updatedLabels);
  };

  const resetLabel = (index: any, fieldName: any) => {
    // Make a copy of the shareHolderLabels array
    const updatedLabels = [...shareHolderLabels];

    // Reset the label for the specified field name at the specified index
    updatedLabels[index][fieldName + 'Label'] =
      shareHolderDocLabel[fieldName + 'Label'];

    // Update the state with the modified labels
    setShareHolderLabels(updatedLabels);
  };

  const handleLoader = (value: boolean) => {
    setLoader(value);
  };
  const handleImage = (e: any, formik?: any, index?: any) => {
    console.log('hitted');
    let field = e.target.name;
    if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
      setFileSize(e.target.files[0].size);
      let title: any = null;
      let mediaType: any = null;
      if (field === 'businessCertificate') {
        mediaType = 'KYCF00016';
        setCertificateFile(e.target.files[0].name);
        title = field;
      } else if (field === 'partnershipDeed') {
        mediaType = 'KYCF00017';
        setPaternshipFile(e.target.files[0].name);
        title = field;
      } else if (field === 'mandate') {
        mediaType = 'KYCF00014';
        setMandateFile(e.target.files[0].name);
        title = field;
      } else if (field.includes('selfieFile')) {
        handleUpdateLabel(index, 'selfie', e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00006';
      } else if (field.includes('idFrontSideFile')) {
        handleUpdateLabel(index, 'idFront', e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00001';
      } else if (field.includes('idBackSideFile')) {
        handleUpdateLabel(index, 'idBack', e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00002';
      } else if (field.includes('kraPinFile')) {
        // setKraFile(e.target.files[0].name);
        handleUpdateLabel(index, 'kraPin', e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00009';
      }
      getBase64(e.target.files[0], (result: any) => {
        setinvoiceImage(result);
        if (typeof formik !== 'undefined') {
          formik.setFieldValue(field, result);
        }
        const payload = {
          title: title,
          document_type: '3',
          mediaBase64: result,
          type: 'business_documents',
          mediaType,
          contentType: 'image',
        };
        dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
      });
    }
  };

  const handleRemoveImage = (fileName: string, index?: any) => {
    if (fileName) {
      switch (fileName) {
        case 'businessCertificate':
          setCertificateFile(certificateLabel);
          setinvoiceImage('');
          break;
        case 'partnershipDeed':
          setPaternshipFile(partnershipLabel);
          setinvoiceImage('');
          break;
        case 'mandate':
          setMandateFile(mandateLabel);
          setinvoiceImage('');
          break;
        case 'selfie':
          resetLabel(index, 'selfie');
          setSelfieFile(selfieLabel);
          setinvoiceImage('');
          break;
        case 'idFront':
          resetLabel(index, 'idFront');
          setIdFrontFile(idFront);
          setinvoiceImage('');
          break;
        case 'idBack':
          resetLabel(index, 'idBack');
          setIdBackFile(idBackLabel);
          setinvoiceImage('');
          break;
        case 'kra':
          resetLabel(index, 'kraPin');
          setKraFile(kraLabel);
          setinvoiceImage('');
          break;
        default:
          break;
      }
    }
  };
  const handleNext = (values: FormikValues) => {
    console.log('VVVVV values =========>', values);
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber + 1);
  };

  const handleBack = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      handleNext(values);
    }
  };

  // const isSaveDisabled = () => {
  //   if (stepNumber === 0 && Object.values(stepOneInfo).some((x) => x === '')) {
  //     return true;
  //   }
  //   return false;
  // };

  // const isSubmitDisabled = () => {
  //   if (!isLastStep || Object.values(stepTwoInfo).some((x) => x === '')) {
  //     return true;
  //   }
  //   return false;
  // };

  // const handleChange = (event: any) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  return (
    <>
      {/* <button
        onClick={handleBack}
        className="back_btn"
        disabled={stepNumber === 0}
      >
        Back
      </button> */}
      <div className="complete-profile__heading">
        Please provide the following documents
      </div>
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Stepper
              activeStep={stepNumber}
              style={{ width: '40%', margin: '10px auto' }}
              sx={{
                '.MuiStepConnector-root span': {
                  borderColor: '#025041',
                },

                '.MuiSvgIcon-root': {
                  borderRadius: '50%',
                  border: '1px solid #025041',
                },
                '.MuiSvgIcon-root:not(.Mui-completed)': {
                  color: 'white',
                },
                '.MuiStepIcon-text': {
                  fill: '#025041',
                  fontWeight: 500,
                },
                '.MuiSvgIcon-root.Mui-active': {
                  color: '#025041',
                  padding: '3px',
                  borderRadius: '50%',
                  border: '1px solid #025041',
                  marginY: '-3px',
                },
                '.Mui-active .MuiStepIcon-text': {
                  fill: 'white',
                },
                '.MuiStepIcon-root.Mui-completed': {
                  color: '#025041', // Set the fill color for completed steps
                },
              }}
            >
              {steps.map((currentStep, index) => {
                const label = currentStep.props.stepName;
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {stepNumber === 0 && (
              <>
                <PartnershipStepOne
                  formik={formik}
                  certificateFile={certificateFile}
                  certificateLabel={certificateLabel}
                  mandateFile={mandateFile}
                  mandateLabel={mandateLabel}
                  partnershipFile={partnershipFile}
                  partnershipLabel={partnershipLabel}
                  handleImage={handleImage}
                  handleRemoveImage={handleRemoveImage}
                  loader={loader}
                />
              </>
            )}
            {stepNumber === 1 && (
              <>
                <PartnershipStepTwo
                  formik={formik}
                  idBackFile={idBackFile}
                  idBackLabel={idBackLabel}
                  idFrontFile={idFrontFile}
                  idFrontLabel={idFront}
                  kraFile={kraFile}
                  kraLabel={kraLabel}
                  selfieFile={selfieFile}
                  selfieLabel={selfieLabel}
                  handleImage={handleImage}
                  handleRemoveImage={handleRemoveImage}
                  shareHolderLabels={shareHolderLabels}
                  handleAddNewPartner={handleAddNewPartner}
                  loader={loader}
                />
              </>
            )}
            {/* <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={stepNumber === steps.length - 1}
          >
            {stepNumber === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
          {stepNumber > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBack}
              sx={{ marginLeft: 8 }}
            >
              Back
            </Button>
          )}
        </Grid> */}
            <Box className="complete-profile__boxbtndisplay">
              {stepNumber > 0 && (
                <button
                  className="complete-profile__goback"
                  onClick={() => handleBack(formik.values)}
                >
                  Back
                </button>
              )}
              {stepNumber === 0 && (
                <button type="submit" className="complete-profile__goback">
                  Save
                </button>
              )}
              <button type="submit" className="complete-profile__submitbtnlist">
                {isLastStep ? 'Submit' : 'Next'}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
export default PartnershipForm;
