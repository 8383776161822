import "./Gethelp.scss";
import React, { useEffect, useState } from "react";
import HubspotContactForm from './hubspot';

export const Gethelp = () => {
    const [showProfile, setshowProfile] = useState(true);
    const [documentSubmitted, setDocumentSubmitted] = useState(false);
    const [documentVerified, setDocumentVerified] = useState(false);

    useEffect(() => {
      // const script = document.createElement('script');
    
      // script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
      // script.async = true;
    
      // document.body.appendChild(script);
    
      // return () => {
      //   document.body.removeChild(script);
      // }
    }, []);

    
    return (
        <div className='gethelp'>
          <div className="balance__header">
            <div className="balance__header__name">Raise a Ticket</div>
           
          </div>
          <div className="dashboard-container">

            <HubspotContactForm 
                region="eu1"
                portalId="26625235"
                formId='a4f12eea-2633-4b14-8eab-49cde2066649'
                />
            </div>
        </div>
    )
}

