import React, { useEffect, useState } from 'react';
import "./Payoptions.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectAllPayablesListData } from "../../../../../../_reducers/payables.reducer";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Button, InputGroup } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Avatar from "@mui/material/Avatar";
import { selectPaymentJourneyData } from '../../../../../../_reducers/paymentJourney.reducer';
import { Box, CircularProgress } from '@mui/material';
import LimitTransactionModel from "./limitTransactionModal";
import Modal from 'react-bootstrap/Modal';


interface Props {
    onSelect: any;
    amount: any;
    item: any;
    balance: any;
    fees: any;
    button_dess: any;
    onSelect_modefe: any;
    chackBalance: any;
    ChackBalancefun: any;
}

export const Payoptions = (props: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [datadisplay, setDatadisplay] = useState('')
    const [selectMethodType, setSelectMethodType] = useState('')
    const [limitModal, setLimitModal] = useState(false);
    const [newBalanceCheck, setNewBalanceCheck] = useState(false);
    const [paymentName, setPaymentName] = useState("")


    const balancelistData = useSelector((state) =>
        selectAllPayablesListData(state)
    );
    const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false)
    const onSelect = (el: string) => {
        props.onSelect(el);

    }
    const onSelect_modefe = (el: string) => {
        props.onSelect_modefe(el)
    }
    useEffect(() => {
        const popup = searchParams.get('type');
        if (popup == 'Express Payment KES') {
            setDatadisplay(popup)
        } else if (popup == 'Express Payment USD') {
            setDatadisplay(popup)
        } else {
        }
    }, [searchParams]);

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const [item, setItem] = useState<any>();
    const [itemRender, setItemRender] = useState(true);
    const [errorShow, setErrorShow] = useState(false);
    const [mPesahighShow, setMPesahighShow] = useState(false);
    const [pesalinkhighShow, setPesalinkhighShow] = useState(false);
    const [data_ahahhahhajaja, setData_sjshhsgsjs] = useState(false)
    const [selectMethod, setSelectMethod] = useState("")
    const [selectType, setSelectType] = useState("")
    const [startAPI, setStartAPI] = useState(false)
    const [chackBalance, setChackBalance] = useState(true)
    const[LoaderPage,setLoaderPage]=useState(false)
    let navigate = useNavigate();
    const dataList = useSelector((state) => selectPaymentJourneyData(state))

    console.log(props.chackBalance,props.button_dess == true , "props.chackBalance");

    useEffect(() => {
        if (!!props.balance && props.fees && props.item && props.item.amount) {
            if ((props.balance[0]?.available_balance / 100) <= (props.fees['pesalink'] + props.item.amount)) {
                setErrorShow(true)
            } else {
                setErrorShow(false)
            }
            if (1000000 <= props.item.amount) {
                setPesalinkhighShow(true)
            } else {
                setPesalinkhighShow(false)
            }
            if (250000 < props.item.amount) {
                setMPesahighShow(true)
            } else {
                setMPesahighShow(false)
            }
        }
    }, [props]);
    useEffect(() => {
        if (
            itemRender &&
            balancelistData?.GETSELECTEDPAYABLES_SUCCESS !== undefined
        ) {
            setItemRender(false);
            setItem(balancelistData.GETSELECTEDPAYABLES_SUCCESS);
        }
    }, [balancelistData, itemRender]);
    console.log(props.button_dess == true, "props.button_dess == true");

    useEffect(() => {
        if (startAPI && dataList?.CHACKBALANCE_SUCCESS !== undefined) {
            if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
                setStartAPI(false)
                setLoaderPage(false)
                if (selectMethodType == "RTGS") {
                    setSelectType("RTGS")
                    onSelect('RTGS')
                } else if (selectMethodType == "EFT") {
                    setSelectType("RTGS")
                    onSelect('EFT')
                } else if (selectMethodType == "Bank") {
                    if (pesalinkhighShow == false
                    ) {
                        onSelect('Bank')
                    }
                }

                setChackBalance(true);
            } else {
                setChackBalance(false);
                setStartAPI(false)
                setLoaderPage(false)
            }
        }
    }, [startAPI, dataList?.CHACKBALANCE_SUCCESS])

    useEffect(()=>{
        if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
                setNewBalanceCheck(true);
            } else {
                setNewBalanceCheck(false);
            }
    }, [dataList?.CHACKBALANCE_SUCCESS])


    return (
        <>
           {LoaderPage && (
            <ThemeProvider theme={theme}>
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: 150,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <CircularProgress color="success" />
              </Box>
            </ThemeProvider>
          )}
        {!datadisplay ?
            <div className='m-pesa-container'>
                <div className='m-pesa-container__heading'>
                    <h4>Please enter payment details</h4>
                </div>
                <div className='m-pesa-container__option-wrapper'>
                    {props.button_dess == true || datadisplay == '' ?
                        <div className='m-pesa-container__pay-option' onClick={() => {
                            if (mPesahighShow == false && props.chackBalance == true) {
                                onSelect('MPesa')
                            }
                        }}>
                            <div className='option-container'>
                                <MPessa />
                                <span>M-Pesa</span>
                            </div>
                            <div className='fee-area'>
                                <div className='arrow'>
                                    <RightArrow />
                                </div>
                            </div>
                        </div> : <div id={data_ahahhahhajaja == true ? 'ajjahaggagga' : "ajjahaggagga_two"} className={data_ahahhahhajaja == true ? 'm-pesa-container__pay-option_two' : "m-pesa-container__pay-option"}
                            onClick={() => {
                                onSelect_modefe('')
                                setData_sjshhsgsjs(true)

                            }}
                        >
                            <div className='option-container'>
                                <MPessa />
                                <span>M-Pesa</span>
                            </div>
                            <div className='fee-area'>
                                <div className='arrow'>
                                    <RightArrow />
                                </div>
                            </div>
                        </div>}
                    {errorShow == false && mPesahighShow == true ? (<div className='mpesa-container__errorMsg'>The amount exceeds the maximum limit of KES 250,000 for M-Pesa payments. Please enter a lower amount to proceed with M-Pesa or select a different payment method.</div>) : ""}
                    {(props.button_dess == true || datadisplay == '')
                        ?
                        <div style={{ position: 'relative' }} className='m-pesa-container__pay-option' onClick={() => {
                            if (props.chackBalance == true && pesalinkhighShow == false
                            ) {
                                onSelect('Bank')
                            }
                        }}>
                            <div className='option-container'>
                                <BankTransfer />
                                <span>Bank Transfer  <span className='bottomtext'>via Pesalink</span></span>

                            </div>
                            <div className='fee-area'>
                                <div className='arrow'>
                                    <RightArrow />
                                </div>
                            </div>

                        </div> :
                        <div id={data_ahahhahhajaja == true ? 'ajjahaggagga' : "ajjahaggagga_two"} style={{ position: 'relative' }}
                            className={data_ahahhahhajaja == true ? 'm-pesa-container__pay-option_two' : "m-pesa-container__pay-option"}
                            onClick={() => {

                                setData_sjshhsgsjs(true)
                                onSelect_modefe('')

                            }}
                        >
                            <div className='option-container'>
                                <BankTransfer />
                                <span>Bank Transfer  <span className='bottomtext'>via Pesalink</span></span>

                            </div>
                            <div className='fee-area'>
                                <div className='arrow'>
                                    <RightArrow />
                                </div>
                            </div>

                        </div>
                    }
                    {props.chackBalance == false ? (<div className='mpesa-container__errorMsg'>
                        Please top up your account to cover the payment.
                    </div>)
                        : pesalinkhighShow == true ?
                            (<div className='mpesa-container__errorMsg'>
                                The amount exceeds the maximum limit of KES 999,999 for Pesalink payments. Please enter a lower amount to proceed with the Pesalink payment.</div>) : ""}
                </div>
            </div>
            :
            <div className='m-pesa-container'>
                <div className="calss_accordion_contenar">
                    <ThemeProvider theme={theme}>
                        <div className="maian_adaat_listtts" onClick={() => {
                            if (paymentacodiyan == false) {
                                setPaymentacodiyan(true)
                            } else {
                                setPaymentacodiyan(false)
                            }
                        }}>
                            <div className="avayar_text">

                                <Avatar className="Avatar_fast">3</Avatar>
                                <p className="accordion_p_data_contean">Payment Method</p>
                            </div>

                            {paymentacodiyan == false ?
                                <ExpandMoreIcon style={{ color: "#1D1D1D" }} /> : <ExpandLessIcon style={{ color: "#1D1D1D" }} />}
                        </div>
                        {paymentacodiyan == true ?
                            <div className="accordion_details_contean">
                                <div className="mainana_cantena_daat_potrrr">
                                    {props.button_dess == true || datadisplay == '' ?
                                        <Button className={data_ahahhahhajaja == true ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                            // disabled={props.chackBalance==true?false:true}   
                                            onClick={() => {
                                                if (mPesahighShow == false) {
                                                    onSelect('MPesa')
                                                }
                                            }}>
                                            M-Pesa
                                        </Button> : <Button
                                            id={data_ahahhahhajaja == true ? 'ajjahaggagga' : "ajjahaggagga_two"}
                                            className='calss_not_active_data'
                                            onClick={() => {
                                                onSelect_modefe('')
                                                setData_sjshhsgsjs(true)

                                            }}>
                                            M-Pesa
                                        </Button>}
                                    {(props.button_dess == true || datadisplay == '')
                                        ?
                                        <Button 
                                        className={data_ahahhahhajaja == true ? "Minn_button_mpesa_datata" : "calss_not_active_data"} 
                                        onClick={() => {
                                            
                                            setSelectMethod("Bank transfer");
                                            setNewBalanceCheck(false);

                                        }}
                                        // disabled={props.chackBalance==true?false:true}
                                        >
                                            Bank transfer
                                        </Button> : 
                                        <Button
                                            id={data_ahahhahhajaja == true ? 'ajjahaggagga' : "ajjahaggagga_two"}
                                            className='calss_not_active_data'
                                            onClick={() => {
                                                setData_sjshhsgsjs(true)
                                            onSelect_modefe('')
                                                
                                            }}>
                                            Bank Transfer
                                        </Button>
                                        }

                                </div>
                                {/* {props.chackBalance==false?<div className='mpesa-container__errorMsg'>Please top up your account to cover the payment.</div>:""} */}
                                {
                                    pesalinkhighShow == true ? (<div className='mpesa-container__errorMsg'>The amount exceeds the maximum limit of KES 999,999 for Pesalink payments. Please enter a lower amount to proceed with the Pesalink payment.</div>) : ""}

                            </div>
                            : ""}
                        {selectMethod == "Bank transfer" ?
                            <div style={{ display: "block" }} className="mainana_cantena_daat_potrrr">
                                {/* <Button style={{ width: "100%", marginBottom: "10px" }}
                                    className={selectType == "RTGS" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                    onClick={() => {

                                        setSelectMethodType("RTGS")
                                        props.ChackBalancefun('rtgs')
                                        setLoaderPage(true)
                                        setTimeout(()=>{
                                            setStartAPI(true)
                                        },5000)
                                    }}>
                                    RTGS
                                </Button>
                                <Button style={{ width: "100%", marginBottom: "10px" }}
                                    className={selectType == "EFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                    onClick={() => {

                                        setSelectMethodType("EFT")
                                        props.ChackBalancefun('eft')
                                        setLoaderPage(true)
                                        setTimeout(()=>{
                                            setStartAPI(true)
                                        },5000)
                                    }}>
                                    EFT
                                </Button>
                                <Button style={{ width: "100%", marginBottom: "10px" }}
                                    className={selectType == "EFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                    onClick={() => {
                                        setSelectMethodType("Bank")
                                        props.ChackBalancefun('pesalink')
                                        setLoaderPage(true)
                                        // onSelect('EFT')
                                        setTimeout(()=>{
                                            setStartAPI(true)
                                        },5000)

                                    }}>
                                    Pesalink
                                </Button> */}

                                <Button style={{ width: "100%", marginBottom: "10px" }}
                                        className={selectType == "EFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                        onClick={() => {
                                            // setSelectType("RTGS")
                                            // // onSelect('EFT')
                                            // if (errorShow == false && pesalinkhighShow == false
                                            // ) {
                                            //     onSelect('Bank')
                                            // }
                                            setLimitModal(false);
                                            setSelectMethodType("Bank")
                                            props.ChackBalancefun('pesalink')
                                            setNewBalanceCheck(false);
                                            setLoaderPage(true)
                                            // onSelect('EFT')
                                            setTimeout(() => {
                                                setNewBalanceCheck(false);
                                                setStartAPI(true)
                                            }, 5000)
                                        }}>
                                        Pesalink
                                        <br />
                                        <span className="description">Debits within 1 hour</span>
                                        <p className='description_limit'>Upto 1M KES per transaction</p>
                                    </Button>
                                    <Button style={{ width: "100%", marginBottom: "10px" }}
                                        className={selectType == "RTGS" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                        onClick={() => {
                                            // setSelectType("RTGS")
                                            // onSelect('RTGS')
                                            setLimitModal(true)
                                            setPaymentName("Proceed with RTGS")

                                            // setSelectMethodType("RTGS")
                                            props.ChackBalancefun('rtgs')
                                            // setLoaderPage(true)
                                            // setTimeout(() => {
                                            //     setStartAPI(true)
                                            // }, 5000)
                                        }}>
                                        RTGS
                                        <br />
                                        <span className="description">Debits within 2-3 hours</span>
                                        <p className='description_limit'>No transaction limit</p>
                                    </Button>
                                    <Button style={{ width: "100%", marginBottom: "10px" }}
                                        className={selectType == "EFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                        onClick={() => {
                                            // setSelectType("RTGS")
                                            // onSelect('EFT')
                                            setLimitModal(true)
                                            setPaymentName("Proceed with EFT")


                                            // setSelectMethodType("EFT")
                                            props.ChackBalancefun('eft')
                                            // setLoaderPage(true)
                                            // setTimeout(() => {
                                            //     setStartAPI(true)
                                            // }, 5000)
                                        }}>
                                        EFT
                                        <br />
                                        <span className="description">Debits within 2-3 business days</span>
                                        <p className='description_limit'>No transaction limit</p>

                                    </Button>
                           
                            </div> : ""}
                         {dataList?.CHACKBALANCE_SUCCESS?.success == false ? <div className='mpesa-container__errorMsg'>
                                Please top up your account to cover the payment.
                            </div> : ""}
                    </ThemeProvider>
                </div>
            </div>
        }

        {limitModal && newBalanceCheck && (
        <>
          <Modal
            show={limitModal}
            onHide={() => {
                setLimitModal(false);
            }}
            backdrop="static"
            keyboard={false}
            aria-labelledby="ModalHeader"
            className="topup-modal"
            centered
            size="lg"
          >
            <LimitTransactionModel 
              show={limitModal}
              paymentName={paymentName}
              setSelectMethodType={setSelectMethodType}
              setStartAPI={setStartAPI}
              setLoaderPage={setLoaderPage}
              setNewBalanceCheck={setNewBalanceCheck}
              setLimitModal={setLimitModal}
              setChackBalance={setChackBalance}
              onClose={() => {
                setLimitModal(false);
              }}
              onSave={(el: boolean) => {
                setLimitModal(false);
              }}
              onconfirm={(el: boolean) => {
                setLimitModal(false);
              }}
              />
          </Modal>
        </>
      )}
        </>
    )
}

const MPessa = () => (
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="20" rx="2" fill="#F3F3F3" />
        <path d="M19 3.5H13C12.4477 3.5 12 3.94772 12 4.5V15.5C12 16.0523 12.4477 16.5 13 16.5H19C19.5523 16.5 20 16.0523 20 15.5V4.5C20 3.94772 19.5523 3.5 19 3.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 5.5H20" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 14.5H20" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const BankTransfer = () => (
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="20" rx="2" fill="#F3F3F3" />
        <path d="M22.0002 8.66699C22.0877 8.66701 22.1744 8.64979 22.2553 8.61629C22.3362 8.5828 22.4097 8.53369 22.4716 8.47178C22.5335 8.40987 22.5826 8.33637 22.6161 8.25547C22.6496 8.17458 22.6669 8.08788 22.6668 8.00032V6.00032C22.6669 5.86044 22.6229 5.72409 22.5411 5.61062C22.4593 5.49715 22.3438 5.41232 22.2111 5.36816L16.2111 3.36816C16.0742 3.32259 15.9262 3.32259 15.7892 3.36816L9.78922 5.36816C9.65649 5.41232 9.54104 5.49715 9.45923 5.61062C9.37743 5.72409 9.33344 5.86044 9.3335 6.00032V8.00032C9.33347 8.08788 9.3507 8.17458 9.3842 8.25547C9.41769 8.33637 9.4668 8.40987 9.52871 8.47178C9.59062 8.53369 9.66412 8.5828 9.74501 8.61629C9.82591 8.64979 9.91261 8.66701 10.0002 8.66699H10.6668V13.4565C10.278 13.5935 9.94107 13.8474 9.70234 14.1835C9.46361 14.5196 9.33476 14.9214 9.3335 15.3337V16.667C9.33347 16.7545 9.3507 16.8412 9.3842 16.9221C9.41769 17.003 9.4668 17.0765 9.52871 17.1384C9.59062 17.2004 9.66412 17.2495 9.74501 17.283C9.82591 17.3165 9.91261 17.3337 10.0002 17.3337H22.0002C22.0877 17.3337 22.1744 17.3165 22.2553 17.283C22.3362 17.2495 22.4097 17.2004 22.4716 17.1384C22.5335 17.0765 22.5826 17.003 22.6161 16.9221C22.6496 16.8412 22.6669 16.7545 22.6668 16.667V15.3337C22.6656 14.9214 22.5367 14.5196 22.298 14.1835C22.0593 13.8474 21.7223 13.5935 21.3335 13.4565V8.66699H22.0002ZM21.3335 16.0003H10.6668V15.3337C10.667 15.1569 10.7373 14.9874 10.8623 14.8624C10.9873 14.7375 11.1567 14.6672 11.3335 14.667H20.6668C20.8436 14.6672 21.0131 14.7375 21.138 14.8624C21.263 14.9874 21.3333 15.1569 21.3335 15.3337V16.0003ZM12.0002 13.3337V8.66699H13.3335V13.3337H12.0002ZM14.6668 13.3337V8.66699H17.3335V13.3337H14.6668ZM18.6668 13.3337V8.66699H20.0002V13.3337H18.6668ZM10.6668 7.33366V6.48079L16.0002 4.70279L21.3335 6.48079V7.33366H10.6668Z" fill="#111111" />
    </svg>
)
const RightArrow = () => (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.36015 4.52685L1.58682 0.760187C1.52484 0.697702 1.45111 0.648106 1.36987 0.61426C1.28863 0.580414 1.20149 0.562988 1.11348 0.562988C1.02548 0.562988 0.938339 0.580414 0.8571 0.61426C0.77586 0.648106 0.702126 0.697702 0.640151 0.760187C0.515983 0.885095 0.446289 1.05406 0.446289 1.23019C0.446289 1.40631 0.515983 1.57528 0.640151 1.70019L3.94015 5.03352L0.640151 8.33352C0.515983 8.45843 0.446289 8.6274 0.446289 8.80352C0.446289 8.97964 0.515983 9.14861 0.640151 9.27352C0.701894 9.33651 0.775524 9.38663 0.856774 9.42096C0.938024 9.45529 1.02528 9.47315 1.11348 9.47352C1.20169 9.47315 1.28894 9.45529 1.37019 9.42096C1.45144 9.38663 1.52507 9.33651 1.58682 9.27352L5.36015 5.50685C5.42782 5.44443 5.48183 5.36866 5.51876 5.28432C5.5557 5.19999 5.57477 5.10892 5.57477 5.01685C5.57477 4.92479 5.5557 4.83372 5.51876 4.74938C5.48183 4.66505 5.42782 4.58928 5.36015 4.52685Z" fill="#DDDDDD" />
    </svg>
)