import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import './AuthoriseTransaction.scss';
import { getUser } from '../../../../../../_helpers';
import { payablesActions } from '../../../../../../_actions/payables.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllPayablesListData } from '../../../../../../_reducers/payables.reducer';

interface Props {
  onSave: any;
  onCancel: any;
  id: any;
  loading: any;
  time: any;
  handleSubmit_resend: any;
  enableButton: any;
  // paymentDeatils: any;
  // counterpart: any;
  // type: any;
  // loading: boolean;
}

export const AuthoriseTransaction = (props: Props) => {
  const [option, setOption] = useState('');
  const dispatch = useDispatch();
  const [otp, setOtp] = useState<any>('');
  const [checkType, setCheckType] = useState<any>();
  const [otpRender, setOtpRender] = useState(true);

  const onSave = () => {
    props.onSave();
  };
  const onCancel = () => {
    props.onCancel();
  };
  const handleSubmit_resend = () => {
    props.handleSubmit_resend(1);
  };
  const user = getUser()?.data ?? null;

  const handleSubmit = (e: any) => {
    const { name, value }: any = e.target;
    setOtp({ ...otp, [name]: value });
  };

  const onSubmitted = () => {
    let request = {
      ...otp,
      data: props.id,
      // payment_details: props.paymentDeatils,
      // counterpart: props.counterpart,
      // type: props.type,
    };

    dispatch(payablesActions.verifyOtpRequest(request) as any);
    props.onSave();
  };
  const otpdata = useSelector((state) => selectAllPayablesListData(state));
  // console.log(otpdata.OTPVIREFAT_SUCCESS, 'otpdata');
  useEffect(() => {
    if (otpRender && !!otpdata?.OTPVIREFAT_SUCCESS) {
      setOtpRender(false);
      setCheckType(otpdata.OTPVIREFAT_SUCCESS);
    }
  }, [otpRender, otpdata]);

  function hidePhoneNo(phoneNo: any) {
    // replace the characters with asterisks
    return phoneNo.slice(0, 6) + '*****' + phoneNo.slice(12);
  }
  function hideEmail(email: any) {
    const [username, domain] = email.split('@');
    const firstChar = username.charAt(0);
    const lastChar = username.charAt(username.length - 1);
    const hiddenUsername = `${firstChar}${'*'.repeat(
      username.length - 2
    )}${lastChar}`;
    return `${hiddenUsername}@${domain}`;
  }

  return (
    <div className="auth-trans-container">
      <div className="auth-trans-container__heading">Authorise Transaction</div>
      <div className="auth-trans-container__desc">
        To authorise the transaction, please enter the security code that was
        sent to{' '}
        {!!checkType && !!checkType.title
          ? checkType.title == 'mobile'
            ? !!user && hidePhoneNo(user.mobile)
            : !!user && hideEmail(user.email)
          : !!user && hidePhoneNo(user.mobile)}
      </div>

      <div>
        <div className="auth-trans-container__label">Security code</div>
        <InputGroup className="input-wrapper input">
          <Form.Control
            className="auth-trans-form-input"
            placeholder="123456"
            value={otp?.otp}
            onChange={handleSubmit}
            name="otp"
            aria-describedby="basic-addon3"
          />
        </InputGroup>
      </div>
      <div
        id="div_authoridse_cls"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
        className="auth-trans-container__btn-row"
      >
        <div id="resend_otp">
          <Button
            className="auth-trans-container__btn-row__white"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </div>
        <div
          id="sumintbutton_autorise"
          className="auth-trans-container__btn-row"
          style={{ marginTop: '0px' }}
        >
          {props.enableButton == false ? (
            <Button
            id="resend_otp"
              style={{ marginRight: '10px' }}
              className="auth-trans-container__btn-row__green"
            >
              Resend OTP in {props.time > 0 ? props.time - 1 : props.time} Sec
            </Button>
          ) : (
            <Button
            id="resend_otp"
              style={{ marginRight: '10px' }}
              className="auth-trans-container__btn-row__green"
              onClick={() => {
                handleSubmit_resend();
              }}
            >
              Resend OTP
            </Button>
          )}
          <Button
            className="auth-trans-container__btn-row__green"
            id="resend_otp"
            onClick={() => {
              // onSave();
              onSubmitted();
            }}
          >
            {props.loading == true && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Authorise
          </Button>
        </div>
      </div>
    </div>
  );
};

const DownArrow = () => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.03613 4.91386L8.8028 1.14053C8.86529 1.07855 8.91488 1.00482 8.94873 0.92358C8.98257 0.84234 9 0.755203 9 0.667195C9 0.579187 8.98257 0.49205 8.94873 0.41081C8.91488 0.329571 8.86529 0.255837 8.8028 0.193862C8.67789 0.0696944 8.50893 0 8.3328 0C8.15668 0 7.98771 0.0696944 7.8628 0.193862L4.52947 3.49386L1.22947 0.193862C1.10456 0.0696944 0.935593 0 0.759469 0C0.583344 0 0.414377 0.0696944 0.289469 0.193862C0.226477 0.255605 0.176363 0.329235 0.142032 0.410485C0.1077 0.491735 0.0898352 0.57899 0.089469 0.667195C0.0898352 0.7554 0.1077 0.842655 0.142032 0.923905C0.176363 1.00516 0.226477 1.07879 0.289469 1.14053L4.05613 4.91386C4.11856 4.98153 4.19433 5.03554 4.27866 5.07248C4.363 5.10941 4.45407 5.12848 4.54613 5.12848C4.6382 5.12848 4.72927 5.10941 4.81361 5.07248C4.89794 5.03554 4.97371 4.98153 5.03613 4.91386Z"
      fill="#DDDDDD"
    />
  </svg>
);
const UpArrow = () => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.05371 0.214556L0.287043 3.98789C0.224557 4.04986 0.174961 4.1236 0.141115 4.20484C0.107269 4.28608 0.0898438 4.37322 0.0898438 4.46122C0.0898438 4.54923 0.107269 4.63637 0.141115 4.71761C0.174961 4.79885 0.224557 4.87258 0.287043 4.93456C0.411951 5.05872 0.580919 5.12842 0.757043 5.12842C0.933166 5.12842 1.10213 5.05872 1.22704 4.93456L4.56038 1.63456L7.86038 4.93456C7.98528 5.05872 8.15425 5.12842 8.33037 5.12842C8.5065 5.12842 8.67547 5.05872 8.80037 4.93456C8.86337 4.87281 8.91348 4.79918 8.94781 4.71793C8.98214 4.63668 9.00001 4.54943 9.00037 4.46122C9.00001 4.37302 8.98214 4.28576 8.94781 4.20451C8.91348 4.12326 8.86337 4.04963 8.80037 3.98789L5.03371 0.214556C4.97128 0.146886 4.89551 0.0928807 4.81118 0.0559425C4.72685 0.0190043 4.63578 -6.58035e-05 4.54371 -6.58035e-05C4.45164 -6.58035e-05 4.36057 0.0190043 4.27624 0.0559425C4.19191 0.0928807 4.11614 0.146886 4.05371 0.214556Z"
      fill="#DDDDDD"
    />
  </svg>
);
