import React from 'react';
import { navigationData } from './consts';
import MenuItem from './MenuItem';
//import { navigationData } from 'features/app/Layout/Menu/consts';
import { Box, Button, Typography } from '@mui/material';
// import * as Styled from './styles';
import styles from './menu.module.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getUser, isUserAuthenticated } from '../../../../_helpers';
import { settingSelector } from '../../../../_reducers/setting.reducer';
import { useDispatch, useSelector } from 'react-redux';
import ProductionSelectionModal from '../Settings/components/Profile/components/AccountSetup/ProductSelectionModal';
import { useNavigate } from 'react-router-dom';
import { settingActions } from '../../../../_actions/setting.actions';
import Modal from 'react-bootstrap/Modal';
import VerifiedPopup from '../Settings/components/Profile/components/VerifiedPopup';
import { Model_Reject } from '../Settings/components/Profile/components/Model_Reject';
import { ModelumentVerified } from '../Settings/components/Profile/components/modelsoon';
import ApprovalsModel from './Model/ApprovalsModel';
import { dispatchStore } from '../../../../Store/store';
import { userActions } from '../../../../_actions';

const Menu_List = ({ setState, setShow }: any) => {
  const pathname = window.location.pathname;
  console.log(Object.values(navigationData), 'Object.values(navigationData)');
  const iconColor = '#025041';
  console.log(pathname, 'pathnamepathname');
  const userDetails = localStorage.getItem('user');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const user = getUser()?.data ?? null;
  const userData = useSelector((state) => settingSelector(state));
  const [productSelectionModal, setProductSelectionModal] =
    React.useState(false);
  const [ApprovalsSelectionModal, setApprovalsSelectionModal] =
    React.useState(false);
  const [renderEntity_two, setRenderEntity_two] = React.useState(false);
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const [status, setstatus] = React.useState('');
  const [renderEntity, setRenderEntity] = React.useState(true);
  const [render, setRender] = React.useState(true);
  const [users, setUsers] = React.useState<any>();
  const [loader, setLoader] = React.useState(false);
  console.log(status, 'statusstatus');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  console.log(user, 'useruser');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  React.useEffect(() => {
    if (!!user && render) {
      setRender(false);
      setUsers(getUser);
      dispatch(settingActions.getEntityDocuments() as any);
      setRenderEntity_two(true);
      setLoader(true);
    }
  }, [render, user]);
  const LogoutApi = () => {
    dispatchStore(
      userActions.logout('logout', 'Logged out successfully') as any
    );
  };
  const handleShow = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };
  React.useEffect(() => {
    if (
      renderEntity_two &&
      dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log('dataEntity123', dataEntity.GETENTITYDOCUMENT_SUCCESS);
      if (!!dataEntity.GETENTITYDOCUMENT_SUCCESS.data) {
        var GetData = dataEntity?.GETENTITYDOCUMENT_SUCCESS?.data;
        setLoader(false);
        // setType(GetData.document_type);
        // setUserDetail(true);
        console.log(GetData.length, 'GetData.length');

        setstatus(GetData.status);
      }
      setRenderEntity_two(false);
    }
  }, [renderEntity_two, dataEntity]);
  console.log(Object.values(navigationData), 'Object.values(navigationData)');
  const openInNewTab = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <>
      {/* <Styled.Menu> */}
      {Object.values(navigationData).map((item: any, index) => {
        console.log(item, 'sdzfsffffff');
        return (
          <>
            {item.label === 'Inflows' ||
              item.label === 'Outflows' ||
              item.label === 'Tools' ||
              item.label === 'Workspace' ||
              item.label==="Integration"||
              item.label === 'Get Help' ? (
              <Accordion
                defaultExpanded={
                  item.label === 'Integration'
                    ? true
                    : false
                }
                // expanded={expanded==== item.label}
                // onChange={handleChange(item.label)}
                className={styles.Accordion_daatatatta}
              >
                <AccordionSummary
                  id="Get_Help_list"
                  className={styles.batninnitmenu_twoo}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <div>{item?.Icon}</div> <p>{item.label}</p>
                </AccordionSummary>
                <AccordionDetails className={styles.jhduhaudhdahddh}>
                  {item?.children?.map((item: any, index: any) => {
                    console.log(item?.children, 'item?.children?');

                    return (
                      <>
                        {item === '' ? (
                          ''
                        ) : (
                          <Button
                            id={styles.submenu_id}
                            className={
                              pathname === item?.url
                                ? styles.active_btn_sub
                                : styles.batninnitmenu_sub
                            }
                            onClick={() => {
                              console.log(item.label, 'item.label');
                              setState({
                                left: false,
                              })
                              if (item.label === 'Account Setup') {
                                if (
                                  (!!userDetails &&
                                    JSON.parse(userDetails).data.entity
                                      ?.status === 'NEW') ||
                                  (!!userDetails &&
                                    JSON.parse(userDetails).data.entity
                                      ?.status === 'UNVERIFIED')
                                ) {
                                  setProductSelectionModal(true);
                                  console.log('aDsaDddADD');
                                } else {
                                  // navigate('/settings/account-setup/verification');
                                  console.log('aDsaDddADD');
                                  navigate('/settings/document-upload', {
                                    state: {
                                      selectedType:
                                        !!userDetails &&
                                        JSON.parse(userDetails).data.entity
                                          ?.entity_type_id,
                                    },
                                  });
                                }
                              } else if (item.label === 'Products') {
                                navigate('/dashboard');
                                localStorage.setItem('newUser', 'true');
                              } else if (item.label === 'Approvals') {
                                setApprovalsSelectionModal(true);
                              } else if (item.label === "API Documentation") {
                                openInNewTab("https://docs.niobi.co/introduction")
                              } else {
                                navigate(item.url);
                                console.log('aDsaDddADD');
                              }
                            }}
                          >
                            <div>{item?.Icon}</div>{' '}
                            <p>{item?.label ? item?.label : ''}</p>
                          </Button>
                        )}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : item === '' ? (
              ''
            ) : (
              <Button
                id={
                  item.label === 'Dashboard'
                    ? 'fast_list_log'
                    : item.label === 'Payees'
                      ? 'fast_secend_log'
                      : item.label === 'Bills'
                        ? 'thred_log'
                        : item.label === 'Budget'
                          ? 'Budgeting_list'
                          : item.label === 'Balance'
                            ? 'Balance_list'
                            : item.label === 'Invoice Generator'
                              ? 'invoice_porduct_tool'
                              : ''
                }
                className={
                  pathname === item.url
                    ? styles.active_btn
                    : styles.batninnitmenu
                }
                onClick={() => {

                  if (item.label === 'Logout') {
                    setShow(true)
                    setState({
                      left: false,
                    })

                  } else {
                    navigate(item.url);
                    setState({
                      left: false,
                    })
                  }
                }}
              >
                <div>{item.Icon}</div> <p>{item.label}</p>
              </Button>
            )}
          </>
        );
      })}
      {/* </Styled.Menu> */}

      {ApprovalsSelectionModal && (
        <>
          <Modal
            show={ApprovalsSelectionModal}
            onHide={() => {
              setApprovalsSelectionModal(false);
            }}
            backdrop="static"
            keyboard={false}
            aria-labelledby="ModalHeader"
            className="topup-modal"
            centered
            size="lg"
          >
            <ApprovalsModel
              show={ApprovalsSelectionModal}
              onClose={() => {
                setApprovalsSelectionModal(false);
                // setShowBalanceGrid(true);
              }}
              onSave={(el: boolean) => {
                setApprovalsSelectionModal(false);
                // setCereat_Teabal(true)
              }}
              onconfirm={(el: boolean) => {
                setApprovalsSelectionModal(false);
                // setIsOpen(true)
              }}
            />
          </Modal>
        </>)}

      {productSelectionModal && (
        <>
          <Modal
            show={productSelectionModal}
            onHide={() => {
              setProductSelectionModal(false);
            }}
            backdrop="static"
            keyboard={false}
            aria-labelledby="ModalHeader"
            className="topup-modal"
            centered
            size="lg"
          >
            {status == 'waiting_for_submission' ? (
              <ProductionSelectionModal
                show={productSelectionModal}
                onClose={() => {
                  setProductSelectionModal(false);
                  // setShowBalanceGrid(true);
                }}
                onSave={(el: boolean) => {
                  setProductSelectionModal(false);
                  // setCereat_Teabal(true)
                }}
                onconfirm={(el: boolean) => {
                  setProductSelectionModal(false);
                  // setIsOpen(true)
                }}
              />
            ) : status == 'approved' &&
              users?.data?.entity?.status == 'VERIFIED' ? (
              <VerifiedPopup
                page="fastpage"
                onSave={() => {
                  setProductSelectionModal(false);
                }}
              />
            ) : status == 'rejected' ? (
              <Model_Reject
                onSave={() => {
                  setProductSelectionModal(false);
                }}
              />
            ) : status == 'waiting_for_approval' ? (
              <ModelumentVerified
                page="fastpage"
                onSave={() => {
                  setProductSelectionModal(false);
                }}
              />
            ) : (
              ''
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default Menu_List;
