import React, { useState } from 'react';
import "../../Settings.scss";
import { CompleteProfile } from './components/CompleteProfile';
import { DocumentsSubmitted } from './components/DocumentsSubmitted';
import { DocumentVerified } from './components/DocumentVerfied';
import { getBase64 } from "../../../../../../_helpers/common";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

export const Verification = () => {
    const [showProfile, setshowProfile] = useState(true);
    const [documentSubmitted, setDocumentSubmitted] = useState(false);
    const [documentVerified, setDocumentVerified] = useState(false);
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });

    return (
        // <ThemeProvider theme={theme}>
            <div className='accoun-management'>
                <div className='accoun-management__header' style={{marginBottom: '35px'}}>
                  Verification
                </div>
                <div className='profile' style={{height:"100%"}}>
                    {showProfile && (
                        <CompleteProfile
                            onSave={() => {
                                setshowProfile(false);
                                setDocumentSubmitted(true);
                            }} />
                    )}
                    {documentSubmitted && (
                        <DocumentsSubmitted onSave={() => {
                            setDocumentSubmitted(false);
                            setDocumentVerified(true);
                        }} />
                    )}
                    {documentVerified && (
                        <DocumentVerified onSave={() => {
                            setDocumentVerified(false);
                            setshowProfile(true);
                        }} />
                    )}
                </div>
            </div>
        // </ThemeProvider>
    )
}

