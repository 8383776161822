import "./TopupDetail.scss";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllBalanceLIstData} from "../../../../../../_reducers/balance.reducer";
import {balanceActions} from "../../../../../../_actions/balance.actions";
import {isUserAuthenticated} from "../../../../../../_helpers";

interface Props {
  topupMethod: boolean;
  onReturn: any;
}

export const TopupDetail = (props: Props) => {
  const [topupDetails, setTopupDetails] = useState<any>();
  const [topupDetailsRender, setTopupDetailsRender] = useState(true);
  const data = useSelector((state) => selectAllBalanceLIstData(state));

  useEffect(() => {
    if (topupDetailsRender && data?.GET_TOPUP_DETAILS_SUCCESS !== undefined) {
      setTopupDetailsRender(false);
      setTopupDetails(data.GET_TOPUP_DETAILS_SUCCESS);
    }
  }, [data, topupDetailsRender]);

  // console.log('TopUp Details: ', topupDetails);

  const onClose = () => {
    props.onReturn(false);
  };

  return (
    <>
      <div className="topup">

        {props.topupMethod === true ? 
        <>
        <div className="topup__heading">Top up my M-Pesa</div>
        <div className="topup__description">
          <p>
          Top up your balance via M-Pesa using the following details:
            </p>
        </div>
        <div className="topup__table">
            <div className="d-flex b-bot">
              <div className="left">Paybill No.</div>
              <div className="right">1234126</div>
            </div>
            <div className="d-flex">
              <div className="left">Niobi Account No.</div>
              <div className="right">209381290</div>
            </div>
          </div>
        </> : 
        <div className="topup__heading">Coming Soon 111</div>
        }
        <div className="topup__button">
          <input
            type="button"
            value="Go Back"
            className="green-btn"
            onClick={onClose}
          />
        </div>
      </div>
    </>
  );
};

export default TopupDetail;
