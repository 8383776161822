import { alertActions } from '.';
import { dashboardService } from '../_services/dashboard.service';
import { dashboardConstants } from '../_constants/dashboard.constants';
import toast from 'react-hot-toast';

export const dashboardActions = {
  getBalance,
  getBills,
  getChartData,
  documentverification,
  getBalance_currency_rate,
  getRecent,
  getBudgetdetails,
  getUpdatedetails,
  getCategoryExpenses,
  getPayeeExpenses,
  getPaymentExpenses,
  getBilldetels,
  getPayableToApprove,
  getPayableToReject,
  getPayableToPending,
  getPayableToUpdate,
  getPayableToCancel,
  getPayableToPay,
  getPayins,
  getPaymentMethodPayins,
  getunifiedPaymentChannels,
  UpdateUnifiedPayments
};

function getBalance(Link: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getBalance(Link)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GETDASHBOARD_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_DASHBOARD_BALANCE_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: dashboardConstants.GETDASHBOARD_FAILURE };
  }
}
function getunifiedPaymentChannels(Link: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getunifiedPaymentChannels(Link)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_UNIFIED_PAYMENT_CHANNELS_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_UNIFIED_PAYMENT_CHANNELS_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: dashboardConstants.GET_UNIFIED_PAYMENT_CHANNELS_FAILURE };
  }
}
function getBalance_currency_rate(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getBalance_currency_rate(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_CURRENCY_RATE_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_CURRENCY_RATE_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: dashboardConstants.GET_CURRENCY_RATE_FAILURE };
  }
}
function getBills(data: any, currency: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getBills(data, currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GETDASHBOARD_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_DASHBOARD_BILL_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GETDASHBOARD_FAILURE, error };
  }
}
function UpdateUnifiedPayments(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .UpdateUnifiedPayments(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_UPDATE_UNIFIED_PAYMENTS_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_UPDATE_UNIFIED_PAYMENTS_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_UPDATE_UNIFIED_PAYMENTS_FAILURE, error };
  }
}
function getRecent(currency: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getRecent(currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_DASHBOARD_RECENT_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_DASHBOARD_RECENT_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_DASHBOARD_RECENT_FAILURE, error };
  }
}
function getBudgetdetails() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getBudgetdetails()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_REQUEST };
  }
  function success(data: any) {
    return {
      type: dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_SUCCESS,
      data,
    };
  }
  function failure(error: string) {
    return {
      type: dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_FAILURE,
      error,
    };
  }
}
function getUpdatedetails() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getUpdatedetails()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_REQUEST };
  }
  function success(data: any) {
    return {
      type: dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_SUCCESS,
      data,
    };
  }
  function failure(error: string) {
    return {
      type: dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_FAILURE,
      error,
    };
  }
}
function getBilldetels(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getBilldetels(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
          } else {
            toast.error(response.message, { duration: 8000 });
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYABLE_BY_ID_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYABLE_BY_ID_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYABLE_BY_ID_FAILURE, error };
  }
}
function getPayableToApprove(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayableToApprove(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
            toast.success(response.message, { duration: 8000 });
            window.location.reload();
          } else {
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYABLE_TO_APPROVE_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYABLE_TO_APPROVE_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYABLE_TO_APPROVE_FAILURE, error };
  }
}
function getPayableToReject(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayableToReject(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
            toast.success(response.message, { duration: 8000 });
            window.location.reload();
          } else {
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYABLE_TO_REJECT_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYABLE_TO_REJECT_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYABLE_TO_REJECT_FAILURE, error };
  }
}
function getPayableToPending(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayableToPending(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
            toast.success(response.message, { duration: 8000 });
            window.location.reload();
          } else {
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYABLE_TO_PENDING_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYABLE_TO_PENDING_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYABLE_TO_PENDING_FAILURE, error };
  }
}
function getPayableToPay(data: any, Redayrecat: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayableToPay(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
            toast.success(response.message, { duration: 8000 });
            Redayrecat();
          } else {
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYABLE_TO_PAY_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYABLE_TO_PAY_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYABLE_TO_PAY_FAILURE, error };
  }
}
function getPayableToCancel(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayableToCancel(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
            toast.success(response.message, { duration: 8000 });
            window.location.reload();
          } else {
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYABLE_TO_CANCEL_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYABLE_TO_CANCEL_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYABLE_TO_CANCEL_FAILURE, error };
  }
}
function getPayableToUpdate(id: any, data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayableToUpdate(id, data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
            toast.success(response.message, { duration: 8000 });
            window.location.reload();
          } else {
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYABLE_TO_UPDATE_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYABLE_TO_UPDATE_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYABLE_TO_UPDATE_FAILURE, error };
  }
}

function getCategoryExpenses(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getCategoryExpenses(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return {
      type: dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_REQUEST,
    };
  }
  function success(data: any) {
    return {
      type: dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS,
      data,
    };
  }
  function failure(error: string) {
    return {
      type: dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE,
      error,
    };
  }
}
function getPayins(data: any, currency: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayins(data, currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYINS_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYINS_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_PAYINS_FAILURE, error };
  }
}
function getPaymentMethodPayins(data: any, currency: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPaymentMethodPayins(data, currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_PAYMENT_METHOD_PAYINS_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_PAYMENT_METHOD_PAYINS_SUCCESS, data };
  }
  function failure(error: string) {
    return {
      type: dashboardConstants.GET_PAYMENT_METHOD_PAYINS_FAILURE,
      error,
    };
  }
}
function getPayeeExpenses(data: any, currency: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPayeeExpenses(data, currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_REQUEST };
  }
  function success(data: any) {
    return {
      type: dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS,
      data,
    };
  }
  function failure(error: string) {
    return {
      type: dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_FAILURE,
      error,
    };
  }
}
function getPaymentExpenses(data: any, currency: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getPaymentExpenses(data, currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_REQUEST };
  }
  function success(data: any) {
    return {
      type: dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS,
      data,
    };
  }
  function failure(error: string) {
    return {
      type: dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE,
      error,
    };
  }
}
function getChartData(startdate: any, enddate: any, currency: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .getChartData(startdate, enddate, currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.GET_DASHBOARD_CHART_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.GET_DASHBOARD_CHART_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.GET_DASHBOARD_CHART_FAILURE, error };
  }
}
function documentverification() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dashboardService
      .documentverification()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response));
          } else {
            dispatch(failure(response.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: dashboardConstants.DOCUMENT_VERIFICATION_REQUEST };
  }
  function success(data: any) {
    return { type: dashboardConstants.DOCUMENT_VERIFICATION_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: dashboardConstants.DOCUMENT_VERIFICATION_FAILURE, error };
  }
}
