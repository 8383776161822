import { styled } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';


const SkeletonCom = ({ loader, element }: any) => {

    return (
        <>
            {loader ?
                <Skeleton>
                    {element}
                </Skeleton> :
                <>
                    {element}
                </>}
        </>
    )
}
export default SkeletonCom;