import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { alpha, createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../Balance.scss'


const SelectPopup = (props: any) => {
  const [show, setShowlist] = useState(props.show == true ? 0 : '');
  const [selectedMethod_two, setSelectedMethod_two] = useState('');

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const onClose_topup = () => {
    props.onClose();
    setShowlist('300');
    // setBankResponseshow(false);
  };
  const OnSave = () => {
    props.OnSave(selectedMethod_two);
    // if (selectedMethod_two == 'Export as CSV') {
    //   window.location.href = !!props.data && props.data.data
    //     ? props.data.data
    //     : 'https://dev.users.niobi.co/storage/csv/csv-4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6-2023-04-06-09-20-41.csv'
    // }
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal
          show={show == 0 ? true : false}
          onHide={() => {
            setShowlist('');
          }}
          backdrop="static"
          keyboard={false}
          aria-labelledby="ModalHeader"
          className="topup-modal"
          centered
          size="lg"
        >

          <Box className="CloseContenar">
            <IconButton onClick={() => {
              onClose_topup();
            }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="heding_contean">
            <Typography>How do you want to top up?
            </Typography>

          </Box>
          <Modal.Body>
            <div className="label">Download Options</div>
            <div className="type-card-list">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={`type-card ${selectedMethod_two === 'Export as PDF' ? 'active' : ''
                  }`}
                onClick={() => {
                  props.OnSave('Export as PDF');
                  setShowlist(1);

                }}
              >
                <div className="heding_centewr_data">
                  <p className="kes_text_stylee">PDF</p>
                  {/* <p className="Kenyan_heding_text">United States Dollar</p> */}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={`type-card ${selectedMethod_two === 'Export as CSV' ? 'active' : ''
                  }`}
                onClick={() => {

                  props.OnSave('Export as CSV');
                  setShowlist(1);
                }}
              >
                <div className="heding_centewr_data">
                  <p className="kes_text_stylee">CSV</p>
                  {/* <p className="Kenyan_heding_text">Kenyan Shilling</p> */}
                </div>
              </div>

            </div>

          </Modal.Body>

          {/* <Modal.Footer>
            <Button
              id="next_button_dataaa"
              className="green-btn"
              disabled={selectedMethod_two === ''}
              onClick={() => {
                setShowlist(1);
                OnSave()
              }}
            >
              Next
            </Button>
          </Modal.Footer> */}
        </Modal>
      </ThemeProvider>
    </>
  )
}
export default SelectPopup;