import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Form as BootstrapForm } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './VerifyIdentity.scss';
import { useDispatch, useSelector } from 'react-redux';
import { payablesActions } from '../../../../../../../../_actions/payables.actions';
import { choiceActions } from '../../../../../../../../_actions';
import { userActions } from '../../../../../../../../_actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { settingActions } from '../../../../../../../../_actions/setting.actions';
import { selectAllPayablesListData } from '../../../../../../../../_reducers/payables.reducer';
import { getUser } from '../../../../../../../../_helpers';
import { toast } from '@team-monite/ui-widgets-react';

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
});

interface Props {
  onSave: any;
  onCancel: any;
  id: any;
  loading: any;
  time: any;
  handleSubmit_resend: any;
  enableButton: any;
  // paymentDeatils: any;
  // counterpart: any;
  // type: any;
  // loading: boolean;
}

const VerifyIdentity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [authorizeEnabled, setAuthorizeEnabled] = useState(false);
  const [otpRender, setOtpRender] = useState(false);
  let organizationType = location.state?.selectedType;
  let selectedCurrency = location.state?.selectedCurrency;
  const otpdata = useSelector((state) => selectAllPayablesListData(state));
  const user = getUser()?.data ?? '';

  const onboardingInfo: any = useSelector(
    (state: any) => state.getOnboardingInfoReducer.data
  );

  useEffect(() => {
    if (!onboardingInfo) {
      dispatch(choiceActions.getStoreOnboardingInfo() as any);
    }
  }, [dispatch, onboardingInfo]);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    if (selectedCurrency && selectedCurrency !== 'KES') {
      const request = {
        otpCode: values.otp,
        user_id: String(onboardingInfo?.user_id),
        business_id: String(onboardingInfo?.id),
        businessId: String(onboardingInfo?.id),
        id: String(onboardingInfo?.id),
      };
      dispatch(
        choiceActions.verifyOtp(request, () => {
          dispatch(settingActions.submitEntityDocuments() as any);
          toast.success('OTP verified successfully', { duration: 5000 });
          toast.success('Documents uploaded successfully', { duration: 5000 });
          setTimeout(() => {
            setOtpRender(true);
            navigate('/');
          }, 5000);
        }) as any
      );
    } else {
      console.log('In Normal flow');
      const request = {
        ...values,
        data: Date.now(),
      };
      dispatch(payablesActions.verifyOtpRequest(request) as any).then(
        (response: any) => {
          console.log('response', response);
        }
      );
    }
  };

  console.log(otpdata.OTP_SUCCESS, otpdata, otpRender, 'otpdata');
  useEffect(() => {
    if (otpdata?.OTP_SUCCESS) {
      dispatch(settingActions.submitEntityDocuments() as any);
      toast.success('OTP verified successfully', { duration: 5000 });
      toast.success('Documents uploaded successfully', { duration: 5000 });
      setTimeout(() => {
        navigate('/');
      }, 5000);
    }
  }, [otpdata]);

  const onCancel = () => {
    navigate('/settings/document-upload', { state: { fromCancel: true } });
  };

  const onResend = () => {
    if (selectedCurrency && selectedCurrency !== 'KES') {
      dispatch(
        choiceActions.resendOtp(
          {
            businnessId: `${user?.entity?.id}`,
            otpType: 'email',
          },
          () => navigate('/settings/verify-identity')
        ) as any
      );
    } else {
      dispatch(userActions.getOtp('') as any);
      setAuthorizeEnabled(true);
    }
  };

  return (
    <div className="auth-trans-container">
      <div className="auth-trans-container__heading">Verify Identity</div>
      <div className="auth-trans-container__desc">
        Before we proceed with submitting your account application please verify
        your identity by confirming the OTP sent to your mobile number
      </div>

      {/* Formik integration */}
      <Formik
        initialValues={{ otp: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="auth-trans-container__label">Security code</div>
            <InputGroup className="input-wrapper input">
              <Field
                as={BootstrapForm.Control}
                className="auth-trans-form-input"
                name="otp"
                aria-describedby="basic-addon3"
              />
            </InputGroup>
            <ErrorMessage
              className="error-message"
              name="otp"
              component="div"
            />

            <div
              id="div_authoridse_cls"
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
              className="auth-trans-container__btn-row"
            >
              <div
                id="sumintbutton_autorise"
                className="auth-trans-container__btn-row"
                style={{ marginTop: '0px' }}
              >
                <Button
                  className="auth-trans-container__btn-row__white"
                  onClick={onCancel}
                  type="button"
                >
                  Cancel
                </Button>

                <Button
                  className="auth-trans-container__btn-row__green"
                  onClick={onResend}
                  type="button"
                >
                  Resend OTP
                </Button>
                <Button
                  className="auth-trans-container__btn-row__green"
                  type="submit"
                >
                  Authorise
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyIdentity;
