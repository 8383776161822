import React from "react";
import { Button } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
import { dashboardActions } from "../../../../../_actions";
import { useDispatch, useSelector } from "react-redux";
import styles from './Topup_fasttime.module.scss';

interface Props {
    show: boolean;
    onClose: any;
}
const Topup_fasttime = (props: Props) => {
    const [show_data, setShow_data] = React.useState(props.show)
    const dispatch = useDispatch();

    const OnSave = () => {
        setShow_data(false)
        props.onClose()
        dispatch(dashboardActions.documentverification() as any);
        localStorage.setItem("user_verification", 'true')
        // documentverification
    }

    return (
        <>
            <Modal
                show={show_data}
                onHide={OnSave}
                backdrop="static"
                keyboard={false}
                aria-labelledby='ModalHeader'
                className='topup-modal'
                centered
                size="lg"
            >
                <div className='document-verified'
                    style={{
                        width: "598px",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 12px rgba(117, 114, 114, 0.25)",
                        borderRadius: "8px",
                        padding: "48px 64px",
                        margin: "0px 0px",
                        textAlign: "center"
                    }}>
                    {/* <div className='document-verified__heading'>Account Setup Awaiting Verification.</div> */}

                    <div className='document-verified__desc'>
                        <span className={styles.Congratulations_CLASS}>Congratulations!</span>
                        <p style={{paddingTop:'17px'}}> Your account setup is complete
                        and has been successfully verified.
                        You have now access to all features on our platform</p>
                       

                        <br />
                        {/* <br />
                        In the meantime,
                        you have access to certain features on our platform and
                        we will let you know as soon as the verification is processed
                        by our team, typically within 1 business day.
                        <br />
                        <br />
                        In case of any queries, reach out to us for support <span>@niobi.co</span> */}
                    </div>
                    <Button id={styles.Go_back} className='document-verified__btn'
                        onClick={() => { OnSave() }}
                    >Close </Button>
                </div>
            </Modal>
        </>
    )
}
export default Topup_fasttime;