export const paymentJourneyConstants = {
  GETFEES_REQUEST: 'GETFEES_REQUEST',
  GETFEES_SUCCESS: 'GETFEES_SUCCESS',
  GETFEES_FROM_STORE: 'GETFEES_FROM_STORE',
  // Balance
  CHACKBALANCE_REQUEST: 'CHACKBALANCE_REQUEST',
  CHACKBALANCE_SUCCESS: 'CHACKBALANCE_SUCCESS',
  CHACKBALANCE_FAILURE: 'CHACKBALANCE_FAILURE',

  CHACKBALANCE_PAYMENT_LINK_REQUEST: 'CHACKBALANCE_PAYMENT_LINK_REQUEST',
  CHACKBALANCE_PAYMENT_LINK_SUCCESS: 'CHACKBALANCE_PAYMENT_LINK_SUCCESS',
  CHACKBALANCE_PAYMENT_LINK_FAILURE: 'CHACKBALANCE_PAYMENT_LINK_FAILURE',

  GETFEES_FAILURE: 'GETFEES_FAILURE',

  GETVALIDATE_ACCOUNT_REQUEST: "GETVALIDATE_ACCOUNT_REQUEST",
  GETVALIDATE_ACCOUNT_SUCCESS: "GETVALIDATE_ACCOUNT_SUCCESS",
  GETVALIDATE_ACCOUNT_FAILURE: "GETVALIDATE_ACCOUNT_FAILURE",

  GETSHAREABLEPDFLINK_REQUEST: 'GETSHAREABLEPDFLINK_REQUEST',
  GETSHAREABLEPDFLINK_SUCCESS: 'GETSHAREABLEPDFLINK_SUCCESS',
  GETSHAREABLEPDFLINK_FAILURE: 'GETSHAREABLEPDFLINK_FAILURE',

  ADDBANK_EXPERES_USD_REQUEST: "ADDBANK_EXPERES_USD_REQUEST",
  ADDBANK_EXPERES_USD_SUCCESS: "ADDBANK_EXPERES_USD_SUCCESS",
  ADDBANK_EXPERES_USD_FAILURE: "ADDBANK_EXPERES_USD_FAILURE",

  CHECK_TRANSACTION_TYPE_REQUEST: "CHECKTRANSACTIONTYPE_REQUEST",
  CHECK_TRANSACTION_TYPE_SUCCESS: "CHECK_TRANSACTION_TYPE_SUCCESS",
  CHECK_TRANSACTION_TYPE_FAILURE: "CHECK_TRANSACTION_TYPE_FAILURE",

  GETNEWFEES_REQUEST: 'GETNEWFEES_REQUEST',
  GETNEWFEES_SUCCESS: 'GETNEWFEES_SUCCESS',
  GETNEWFEES_FAILURE: 'GETNEWFEES_FAILURE',
  GETNEWFEES_FROM_STORE: 'GETNEWFEES_FROM_STORE',

  INVITE_REQUEST: 'INVITE_REQUEST',
  INVITE_SUCCESS: 'INVITE_SUCCESS',
  INVITE_FAILURE: 'INVITE_FAILURE',

  GET_BULKPAYMENT_REQUEST: 'GET_BULKPAYMENT_REQUEST',
  GET_BULKPAYMENT_SUCCESS: 'GET_BULKPAYMENT_SUCCESS',
  GET_BULKPAYMENT_FAILURE: 'GET_BULKPAYMENT_FAILURE',

  GET_PAYBILL_NEW_REQUEST: 'GET_PAYBILL_NEW_REQUEST',
  GET_PAYBILL_NEW_SUCCESS: 'GET_PAYBILL_NEW_SUCCESS',
  GET_PAYBILL_NEW_FAILURE: 'GET_PAYBILL_NEW_FAILURE',

  GET_ACCOUNTING_TYPE_REQUEST: 'GET_ACCOUNTING_TYPE_REQUEST',
  GET_ACCOUNTING_TYPE_SUCCESS: 'GET_ACCOUNTING_TYPE_SUCCESS',
  GET_ACCOUNTING_TYPE_FAILURE: 'GET_ACCOUNTING_TYPE_FAILURE',

  CREATE_CONNECTION_REQUEST: 'CREATE_CONNECTION_REQUEST',
  CREATE_CONNECTION_SUCCESS: 'CREATE_CONNECTION_SUCCESS',
  CREATE_CONNECTION_FAILURE: 'CREATE_CONNECTION_FAILURE',

  DISABLE_CONNECTION_REQUEST: 'DISABLE_CONNECTION_REQUEST',
  DISABLE_CONNECTION_SUCCESS: 'DISABLE_CONNECTION_SUCCESS',
  DISABLE_CONNECTION_FAILURE: 'DISABLE_CONNECTION_FAILURE',

  GET_CONNECTION_REQUEST: 'GET_CONNECTION_REQUEST',
  GET_CONNECTION_SUCCESS: 'GET_CONNECTION_SUCCESS',
  GET_CONNECTION_FAILURE: 'GET_CONNECTION_FAILURE',

  SAVE_DATA_REQUEST: "SAVE_DATA_REQUEST",
  SAVE_DATA_SUCCESS: "SAVE_DATA_SUCCESS",
  SAVE_DATA_FAILURE: "SAVE_DATA_FAILURE",

  INVITE_STORE: 'INVITE_STORE',
  GETPESABANKLIST_REQUEST: 'GETPESABANKLIST_REQUEST',
  GETPESABANKLIST_SUCCESS: 'GETPESABANKLIST_SUCCESS',
  GETPESABANKLIST_FAILURE: 'GETPESABANKLIST_FAILURE',

  GETBUDGET_LIST_REQUEST: 'GETBUDGET_LIST_REQUEST',
  GETBUDGET_LIST_SUCCESS: 'GETBUDGET_LIST_SUCCESS',
  GETBUDGET_LIST_FAILURE: 'GETBUDGET_LIST_FAILURE',

  DELETE_PAYMENT_LINK_REQUEST: "DELETE_PAYMENT_LINK_REQUEST",
  DELETE_PAYMENT_LINK_SUCCESS: "DELETE_PAYMENT_LINK_SUCCESS",
  DELETE_PAYMENT_LINK_FAILURE: "DELETE_PAYMENT_LINK_FAILURE",

  DELETE_BUDGET_REQUEST: "DELETE_BUDGET_REQUEST",
  DELETE_BUDGET_SUCCESS: "DELETE_BUDGET_SUCCESS",
  DELETE_BUDGET_FAILURE: "DELETE_BUDGET_FAILURE",

  GETINTEGRATION_REQUEST: 'GETINTEGRATION_REQUEST',
  GETINTEGRATION_SUCCESS: 'GETINTEGRATION_SUCCESS',
  GETINTEGRATION_FAILURE: 'GETINTEGRATION_FAILURE',

  GETDURATIONLIST_REQUEST: 'GETDURATIONLIST_REQUEST',
  GETDURATIONLIST_SUCCESS: 'GETDURATIONLIST_SUCCESS',
  GETDURATIONLIST_FAILURE: 'GETDURATIONLIST_FAILURE',

  GETINTEGRATION_APIKEY_REQUEST: 'GETINTEGRATION_APIKEY_REQUEST',
  GETINTEGRATION_APIKEY_SUCCESS: 'GETINTEGRATION_APIKEY_SUCCESS',
  GETINTEGRATION_APIKEY_FAILURE: 'GETINTEGRATION_APIKEY_FAILURE',

  DELETE_CREDENTIALS_REQUEST: "DELETE_CREDENTIALS_REQUEST",
  DELETE_CREDENTIALS_SUCCESS: "DELETE_CREDENTIALS_SUCCESS",
  DELETE_CREDENTIALS_FAILURE: "DELETE_CREDENTIALS_FAILURE",

  ADDBANK_DESBURSEMENT_REQUEST: 'ADDBANK_DESBURSEMENT_REQUEST',
  ADDBANK_DESBURSEMENT_SUCCESS: 'ADDBANK_DESBURSEMENT_SUCCESS',
  ADDBANK_DESBURSEMENT_FAILURE: 'ADDBANK_DESBURSEMENT_FAILURE',

  CREATE_BUDGET_REQUEST: 'CREATE_BUDGET_REQUEST',
  CREATE_BUDGET_SUCCESS: 'CREATE_BUDGET_SUCCESS',
  CREATE_BUDGET_FAILURE: 'CREATE_BUDGET_FAILURE',

  PAYMENT_LINK_API_CREATE_REQUEST: 'PAYMENT_LINK_API_CREATE_REQUEST',
  PAYMENT_LINK_API_CREATE_SUCCESS: 'PAYMENT_LINK_API_CREATE_SUCCESS',
  PAYMENT_LINK_API_CREATE_FAILURE: 'PAYMENT_LINK_API_CREATE_FAILURE',

  EDIT_BUDGET_REQUEST: 'EDIT_BUDGET_REQUEST',
  EDIT_BUDGET_SUCCESS: 'EDIT_BUDGET_SUCCESS',
  EDIT_BUDGET_FAILURE: 'EDIT_BUDGET_FAILURE',

  PAYMENTLINKUPDATE_REQUEST: 'PAYMENTLINKUPDATE_REQUEST',
  PAYMENTLINKUPDATE_SUCCESS: 'PAYMENTLINKUPDATE_SUCCESS',
  PAYMENTLINKUPDATE_FAILURE: 'PAYMENTLINKUPDATE_FAILURE',

  LIST_BUDGET_REQUEST: 'LIST_BUDGET_REQUEST',
  LIST_BUDGET_SUCCESS: 'LIST_BUDGET_SUCCESS',
  LIST_BUDGET_FAILURE: 'LIST_BUDGET_FAILURE',

  ADDBANK_DESBURSEMENT_EXPERES_REQUEST: 'ADDBANK_DESBURSEMENT_EXPERES_REQUEST',
  ADDBANK_DESBURSEMENT_EXPERES_SUCCESS: 'ADDBANK_DESBURSEMENT_EXPERES_SUCCESS',
  ADDBANK_DESBURSEMENT_EXPERES_FAILURE: 'ADDBANK_DESBURSEMENT_EXPERES_FAILURE',

  CREATE_CREDENTIALS_REQUEST: "CREATE_CREDENTIALS_REQUEST",
  CREATE_CREDENTIALS_SUCCESS: "CREATE_CREDENTIALS_SUCCESS",
  CREATE_CREDENTIALS_FAILURE: "CREATE_CREDENTIALS_FAILURE",

  CREATE_INTEGRATION_REQUEST: "CREATE_INTEGRATION_REQUEST",
  CREATE_INTEGRATION_SUCCESS: "CREATE_INTEGRATION_SUCCESS",
  CREATE_INTEGRATION_FAILURE: "CREATE_INTEGRATION_FAILURE",

  GET_BULK_PAYMENTS_STATUS_REQUEST: "GET_BULK_PAYMENTS_STATUS_REQUEST",
  GET_BULK_PAYMENTS_STATUS_SUCCESS: "GET_BULK_PAYMENTS_STATUS_SUCCESS",
  GET_BULK_PAYMENTS_STATUS_FAILURE: "GET_BULK_PAYMENTS_STATUS_FAILURE",

  EXPRESSPAYMENT_REQUEST: "EXPRESSPAYMENT_REQUEST",
  EXPRESSPAYMENT_SUCCESS: "EXPRESSPAYMENT_SUCCESS",
  EXPRESSPAYMENT_FAILURE: "EXPRESSPAYMENT_FAILURE",

  NIOBI_SIGNATURE_REQUEST: "NIOBI_SIGNATURE_REQUEST",
  NIOBI_SIGNATURE_SUCCESS: "NIOBI_SIGNATURE_SUCCESS",
  NIOBI_SIGNATURE_FAILURE: "NIOBI_SIGNATURE_FAILURE",

  PAYMENTLINKSHORTCODE_REQUEST: "PAYMENTLINKSHORTCODE_REQUEST",
  PAYMENTLINKSHORTCODE_SUCCESS: "PAYMENTLINKSHORTCODE_SUCCESS",
  PAYMENTLINKSHORTCODE_FAILURE: "PAYMENTLINKSHORTCODE_FAILURE",

  PAYLINK_REQUEST: "PAYLINK_REQUEST",
  PAYLINK_SUCCESS: "PAYLINK_SUCCESS",
  PAYLINK_FAILURE: "PAYLINK_FAILURE",

  TRANSPAYLINK_REQUEST: "TRANSPAYLINK_REQUEST",
  TRANSPAYLINK_SUCCESS: "TRANSPAYLINK_SUCCESS",
  TRANSPAYLINK_FAILURE: "TRANSPAYLINK_FAILURE",

  PAYMENTLINKAPILIST_REQUEST: "PAYMENTLINKAPILIST_REQUEST",
  PAYMENTLINKAPILIST_SUCCESS: "PAYMENTLINKAPILIST_SUCCESS",
  PAYMENTLINKAPILIST_FAILURE: "PAYMENTLINKAPILIST_FAILURE",

  BANK_DETELS_REQUEST: "BANK_DETELS_REQUEST",
  BANK_DETELS_SUCCESS: "BANK_DETELS_SUCCESS",
  BANK_DETELS_FAILURE: "BANK_DETELS_FAILURE",

  TRANSPAYLINK_PDF_REQUEST: "TRANSPAYLINK_PDF_REQUEST",
  TRANSPAYLINK_PDF_SUCCESS: "TRANSPAYLINK_PDF_SUCCESS",
  TRANSPAYLINK_PDF_FAILURE: "TRANSPAYLINK_PDF_FAILURE",

  INVOICE_SENDTOME_REQUEST: "INVOICE_SENDTOME_REQUEST",
  INVOICE_SENDTOME_SUCCESS: "INVOICE_SENDTOME_SUCCESS",
  INVOICE_SENDTOME_FAILURE: "INVOICE_SENDTOME_FAILURE",

  INVOICE_SENDTO_RECEIVER_REQUEST: "INVOICE_SENDTO_RECEIVER_REQUEST",
  INVOICE_SENDTO_RECEIVER_SUCCESS: "INVOICE_SENDTO_RECEIVER_SUCCESS",
  INVOICE_SENDTO_RECEIVER_FAILURE: "INVOICE_SENDTO_RECEIVER_FAILURE",

  // StanbicVerifyAccount
  STANBIC_VERIFY_ACCOUNT_REQUEST: "STANBIC_VERIFY_ACCOUNT_REQUEST",
  STANBIC_VERIFY_ACCOUNT_SUCCESS: "STANBIC_VERIFY_ACCOUNT_SUCCESS",
  STANBIC_VERIFY_ACCOUNT_FAILURE: "STANBIC_VERIFY_ACCOUNT_FAILURE",

  // getCountries
  GETCOUNTRIES_REQUEST: "GETCOUNTRIES_REQUEST",
  GETCOUNTRIES_SUCCESS: "GETCOUNTRIES_SUCCESS",
  GETCOUNTRIES_FAILURE: "GETCOUNTRIES_FAILURE",

  GETCOUNTRIES_V2_REQUEST: "GETCOUNTRIES_V2_REQUEST",
  GETCOUNTRIES_V2_SUCCESS: "GETCOUNTRIES_V2_SUCCESS",
  GETCOUNTRIES_V2_FAILURE: "GETCOUNTRIES_V2_FAILURE",
  // getCountriesBalances
  GETCOUNTRIES_BALANCES_REQUEST: "GETCOUNTRIES_BALANCES_REQUEST",
  GETCOUNTRIES_BALANCES_SUCCESS: "GETCOUNTRIES_BALANCES_SUCCESS",
  GETCOUNTRIES_BALANCES_FAILURE: "GETCOUNTRIES_BALANCES_FAILURE",
  // getCountriesDetails
  GETCOUNTRIES_DETAILS_REQUEST: "GETCOUNTRIES_DETAILS_REQUEST",
  GETCOUNTRIES_DETAILS_SUCCESS: "GETCOUNTRIES_DETAILS_SUCCESS",
  GETCOUNTRIES_DETAILS_FAILURE: "GETCOUNTRIES_DETAILS_FAILURE",
  // getCurrencies
  GETCURRENCIES_REQUEST: "GETCURRENCIES_REQUEST",
  GETCURRENCIES_SUCCESS: "GETCURRENCIES_SUCCESS",
  GETCURRENCIES_FAILURE: "GETCURRENCIES_FAILURE",

  GETCURRENCIES_V2_REQUEST: "GETCURRENCIES_V2_REQUEST",
  GETCURRENCIES_V2_SUCCESS: "GETCURRENCIES_V2_SUCCESS",
  GETCURRENCIES_V2_FAILURE: "GETCURRENCIES_V2_FAILURE",
  // getCountriesByCurrency
  GETCOUNTRIES_BY_CURRENCY_REQUEST: "GETCOUNTRIES_BY_CURRENCY_REQUEST",
  GETCOUNTRIES_BY_CURRENCY_SUCCESS: "GETCOUNTRIES_BY_CURRENCY_SUCCESS",
  GETCOUNTRIES_BY_CURRENCY_FAILURE: "GETCOUNTRIES_BY_CURRENCY_FAILURE",

}