export const ChoiceBankConstants = {
  CHOICE_GETTYPES_REQUEST: 'CHOICE_GETTYPES_REQUEST',
  CHOICE_GETTYPES_SUCCESS: 'CHOICE__GETTYPES_SUCCESS',
  CHOICE_GETTYPES_FROM_STORE: 'CHOICE__GETTYPES_FROM_STORE',
  CHOICE_GETTYPES_FAILURE: 'CHOICE__GETTYPES_FAILURE',
  CHOICE_GETOPERATINGMODES_REQUEST: 'CHOICE_GETOPERATINGMODES_REQUEST',
  CHOICE_GETOPERATINGMODES_SUCCESS: 'CHOICE__GETOPERATINGMODES_SUCCESS',
  CHOICE_GETOPERATINGMODES_FROM_STORE: 'CHOICE__GETOPERATINGMODES_FROM_STORE',
  CHOICE_GETOPERATINGMODES_FAILURE: 'CHOICE__GETOPERATINGMODES_FAILURE',
  CHOICE_GETGENDER_REQUEST: 'CHOICE_GETGENDER_REQUEST',
  CHOICE_GETGENDER_SUCCESS: 'CHOICE__GETGENDER_SUCCESS',
  CHOICE_GETGENDER_FROM_STORE: 'CHOICE__GETGENDER_FROM_STORE',
  CHOICE_GETGENDER_FAILURE: 'CHOICE__GETGENDER_FAILURE',
  CHOICE_GETINDUSTRIES_REQUEST: 'CHOICE_GETINDUSTRIES_REQUEST',
  CHOICE_GETINDUSTRIES_SUCCESS: 'CHOICE__GETINDUSTRIES_SUCCESS',
  CHOICE_GETINDUSTRIES_FROM_STORE: 'CHOICE__GETINDUSTRIES_FROM_STORE',
  CHOICE_GETINDUSTRIES_FAILURE: 'CHOICE__GETINDUSTRIES_FAILURE',

  CHOICE_CREATEBUSINESS_REQUEST: 'CHOICE_CREATEBUSINESS_REQUEST',
  CHOICE_CREATEBUSINESS_SUCCESS: 'CHOICE_CREATEBUSINESS_SUCCESS',
  CHOICE_CREATEBUSINESS_FROM_STORE: 'CHOICE_CREATEBUSINESS_FROM_STORE',
  CHOICE_CREATEBUSINESS_FAILURE: 'CHOICE_CREATEBUSINESS_FAILURE',

  CHOICE_SAVE_SHAREHOLDER_REQUEST: 'CHOICE_SAVE_SHAREHOLDER_REQUEST',
  CHOICE_SAVE_SHAREHOLDER_SUCCESS: 'CHOICE_SAVE_SHAREHOLDER_SUCCESS',
  CHOICE_SAVE_SHAREHOLDER_FROM_STORE: 'CHOICE_SAVE_SHAREHOLDER_FROM_STORE',
  CHOICE_SAVE_SHAREHOLDER_FAILURE: 'CHOICE_SAVE_SHAREHOLDER_FAILURE',

  CHOICE_VERIFYOTP_REQUEST: 'CHOICE_VERIFYOTP_REQUEST',
  CHOICE_VERIFYOTP_SUCCESS: 'CHOICE_VERIFYOTP_SUCCESS',
  CHOICE_VERIFYOTP_FROM_STORE: 'CHOICE_VERIFYOTP_FROM_STORE',
  CHOICE_VERIFYOTP_FAILURE: 'CHOICE_VERIFYOTP_FAILURE',

  CHOICE_RESEND_OTP_REQUEST: 'CHOICE_RESEND_OTP_REQUEST',
  CHOICE_RESEND_OTP_SUCCESS: 'CHOICE_RESEND_OTP_SUCCESS',
  CHOICE_RESEND_OTP_FROM_STORE: 'CHOICE_RESEND_OTP_FROM_STORE',
  CHOICE_RESEND_OTP_FAILURE: 'CHOICE_RESEND_OTP_FAILURE',

  CHOICE_UPLOADMEDIA_REQUEST: 'CHOICE_UPLOADMEDIA_REQUEST',
  CHOICE_UPLOADMEDIA_SUCCESS: 'CHOICE_UPLOADMEDIA_SUCCESS',
  CHOICE_UPLOADMEDIA_FROM_STORE: 'CHOICE_UPLOADMEDIA_FROM_STORE',
  CHOICE_UPLOADMEDIA_FAILURE: 'CHOICE_UPLOADMEDIA_FAILURE',

  CHOICE_ONBOARDING_REQUEST: 'CHOICE_ONBOARDING_REQUEST',
  CHOICE_ONBOARDING_SUCCESS: 'CHOICE_ONBOARDING_SUCCESS',
  CHOICE_ONBOARDING_FROM_STORE: 'CHOICE_ONBOARDING_FROM_STORE',
  CHOICE_ONBOARDING_FAILURE: 'CHOICE_ONBOARDING_FAILURE',

  CHOICE_BUSINESS_INFO_REQUEST: 'CHOICE_BUSINESS_INFO_REQUEST',
  CLEAR_BUSINESS_INFO: 'CLEAR_BUSINESS_INFO',
};
