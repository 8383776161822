import React from 'react';
import { Button } from 'react-bootstrap';

const SetupFailed = () => {
  return (
    <div className="document-verified" style={{ margin: '90px auto' }}>
      <div className="document-verified__heading" style={{ color: '#df3a3a' }}>
        Account Setup failed
      </div>

      <div className="document-verified__desc">
        In case of any queries, reach out to us at <span>support@niobi.co</span>
      </div>
      <Button
        className="document-verified__btn"
        onClick={() => window.location.reload()}
      >
        Go back{' '}
      </Button>
    </div>
  );
};

export default SetupFailed;
