import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { Form, InputGroup } from 'react-bootstrap';
import { entityActions } from '../../../../../../../../../_actions';
import * as Yup from 'yup';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import { ErrorMessage, setIn } from 'formik';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import { CircularProgress, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  certificateFile: any;
  handleImage: any;
  certificateLabel: string;
}
const SoleProprietoryStepOne = ({
  formik,
  certificateFile,
  certificateLabel,
  handleRemoveImage,
  loader,
  handleImage,
}: any) => {
  const [render, setRender] = useState(true);
  const industries = useSelector(
    (state: any) => state.getIndustriesReducer.data
  );

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  // const [chack_cox_data, setChack_cox_data] = useState(false);
  // const certificateLabel = 'Photo of the Business Certificate';
  // const [certificateFile, setCertificateFile] = useState(certificateLabel);
  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [submitted, setSubmitted] = useState(false);
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getIndustries() as any);
    dispatch(choiceActions.getOperatingModes() as any);
  }, [dispatch]);

  // const handleLoader = (value: boolean) => {
  //   setLoader(value);
  // };
  // const handleImage = (e: any) => {
  //   if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
  //     setFileSize(e.target.files[0].size);
  //     setCertificateFile(e.target.files[0].name);
  //     getBase64(e.target.files[0], (result: any) => {
  //       setinvoiceImage(result);
  //       const payload = {
  //         title: 'certificateFile',
  //         document_type: '1',
  //         mediaBase64: result,
  //         type: 'business_documents',
  //         mediaType: 'KYCF00010',
  //         contentType: 'image',
  //       };
  //       dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
  //       setSubmitted(true);
  //       formik.setFieldValue('businessCertificate', result);
  //     });
  //   }
  // };

  // const handleRemoveImage = () => {
  //   // setCertificateFile(certificateLabel);
  //   setinvoiceImage('');
  // };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  // const imageUrl =
  //   'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  return (
    <>
      <p className="complete-profile__businesstypo">Business Details</p>
      <div className="calss_accordion_contenar">
        <Form>
          <div className="input-form-wrapper dot">
            <label>Business name</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessName"
                value={formik.values.businessName}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.businessName && formik.errors.businessName
                }
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessName" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Number of the business Certificate</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessCerNum"
                value={formik.values.businessCerNum}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.businessCerNum && formik.errors.businessCerNum
                }
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessCerNum" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Address of the Incorporation</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessAddress"
                value={formik.values.businessAddress}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.businessAddress &&
                  Boolean(formik.errors.businessAddress)
                }
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessAddress" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Industry of the Company</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Select
                name="businessIndustry"
                value={formik.values.businessIndustry}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.businessIndustry &&
                  Boolean(formik.errors.businessIndustry)
                }
              >
                <option>Business Industry</option>
                {Array.isArray(industries)
                  ? industries?.map((element: any) => {
                      return (
                        <option key={element.id} value={element.key}>
                          {element.value}
                        </option>
                      );
                    })
                  : 'data not found'}
              </Form.Select>
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessIndustry" component="div" />
            </p>
          </div>
          <div className="flax_data_list">
            <Tooltip
              className="doc-input-wrapper"
              title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)"
            >
              <label
                style={{ padding: '15px 0px 0px 0px' }}
                className="complete-profile__upload mb-0"
              >
                <div
                  // id='afafafsdaFfsf'
                  className="complete-profile__upload__label"
                  //  style={{ cursor: 'not-allowed' }}
                >
                  <span className="complete-profile__upload__txt">
                    {formik.values?.businessDocs?.data != null &&
                    formik.values?.businessDocs?.data?.data?.businessCertificate
                      ?.file_name != null
                      ? formik.values?.businessDocs?.data?.data
                          ?.businessCertificate?.file_name
                      : certificateFile}
                  </span>
                  {/* {certificateFile === certificateLabel ? (
                    <UploadIcon />
                  ) : (
                    <div>
                      <CloseIcon />
                    </div>
                  )} */}
                </div>
                <input
                  type="file"
                  // disabled={submitted}
                  name="businessCertificate"
                  onChange={(e) => {
                    handleImage(e, formik);
                  }}
                />
                {1024 * 1024 <= fileSize ? (
                  <div className="error-message">
                    File size too large, max file size is 1 Mb
                  </div>
                ) : null}
                <p className="error-message">
                  {' '}
                  {formik.errors.businessCertificate ? (
                    <ErrorMessage name="businessCertificate" component="div" />
                  ) : null}
                </p>
              </label>
            </Tooltip>
            {certificateFile === certificateLabel ? (
              <div className="aDADADaDsfgf">
                {' '}
                <UploadIcon />
              </div>
            ) : (
              <div
                className="aDADADaDsfgf"
                onClick={() => {
                  // remove_data(
                  //   groupOneName[i].KRN_certi_new
                  // );
                  handleRemoveImage('businessCertificate');
                  console.log('remove');
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div>
        </Form>
      </div>

      {loader && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default SoleProprietoryStepOne;
