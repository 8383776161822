import ReactApexChart from "react-apexcharts";

interface Props {
  chart_payment: any;
  name: any;
}
const Donut_chart = (props: Props) => {
  const linedata = [44, 55, 41, 17]
  console.log(props, 'propsprops1234');

  const chartData = {
    series: props.chart_payment,
    options: {
     
      chart: {
        toolbar: {
          show: false
        },
        dropShadow: {
          enabled: true,
          color: '#111',
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2
        }
      },
      // legend: {
      //   position:"top",
      // },
      stroke: {
        width: 0,
      },
      legend: {
        // position: 'bottom',
        width:150,
        fontSize:"10px"
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val:any) {
            return ""
          }
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '35%',
            labels: {
              show: false,
              total: {
                showAlways: false,
                show: false
              },

            },
            
          }
        }
      },
      labels: props.name,
      colors: [
        '#1C4640',
        '#458F7D',
        '#9B7A73',
        "#D1D1D1",
        "#C1E9E1"
      ],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            // width: 200
          },
          legend: {
            // position: 'bottom',
            // width:"200px",
            
          }
        }
      }]
    },
  };


  return (
    <>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height={200}
        width={"100%"}
        // stackType={"100%"}
      />
    </>
  )
}
export default Donut_chart;