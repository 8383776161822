import style from './Integrations.module.scss';
import React from 'react';
import moment from "moment";
import { Button } from 'react-bootstrap';
import { getUser, isUserAuthenticated } from "../../../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";

interface Props {
    onclick: any;
    onCansal: any;
}
const Integrations = (props: Props) => {
    const [data_add, setData_add] = React.useState([])

    const [render, setRender] = React.useState(true);
    const [render_two, setRender_two] = React.useState(true);
    const dispatch = useDispatch();
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            dispatch(paymentJourneyActions.get_connections() as any)
            setRender(false)
        }
    }, [render, isUserAuthenticated]);

    React.useEffect(() => {
        if (render_two && dataList.GET_CONNECTION_SUCCESS !== undefined) {
            setData_add(dataList.GET_CONNECTION_SUCCESS)
            setRender_two(false)
        }
    }, [render_two, dataList]);

    // disable_connection
   
    return (
        <>
            <div className={style.main_dev_contean}>
                <p className={style.heding_tage_listtt}>
                    Integrations
                </p>
                <div className={style.div_main_calssname_this}>
                    {data_add.map((element: any, index: number) => {
                        return (

                            <div className={style.contenar_data} 
                            onClick={() => { props.onclick(element) }} 
                            // key={index}
                            // onClick={() => { onSave(element) }}
                            >
                                <div>
                                    {/* <img src={element.connection_url} /> */}
                                    <p>
                                        {element.platform}
                                    </p>
                                </div>
                                <div>
                                    <div className={style.date_test_lineee}>

                                        <p className={style.heding_test_onn}>
                                            Activated on:
                                        </p>
                                        <p className={style.calss_moment_date}>
                                            {moment(element.created_at).format("DD-MMM-YYYY")}

                                        </p>
                                    </div>
                                    {/* <div className={style.Deactivate_data}>
                                        <Button onClick={() => { ondisable(element.id) }}>
                                            {element.status}
                                        </Button>
                                    </div> */}
                                </div>
                            </div>
                        );
                    })}

                </div>
                <div className={style.conatenar_footer}>
                    <Button className={style.List_footer} onClick={() => { props.onCansal() }}>
                        Add more integrations
                    </Button>
                </div>
            </div>
        </>
    )
}
export default Integrations;