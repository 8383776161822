import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import "../Settings.scss";
import { UserDetail } from '../UserDetails';
import { getUser } from "../../../../../_helpers";
import { Comingsoon } from '../components/Profile/components/Comingsoon';
import { settingActions } from "../../../../../_actions/setting.actions";
import { useDispatch, useSelector } from 'react-redux'
import { settingSelector } from "../../../../../_reducers/setting.reducer";
import { useSearchParams } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

export const Users = () => {
    const [userDetail, setUserDetail] = useState(false);
    const [comingsoon, setcomingsoon] = useState(false);
    const [users, setUsers] = useState<any>(getUser);
    const [renderEntity, setRenderEntity] = useState(true);
    const [render, setRender] = useState(true);
    const [typevalue, setType] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch()

    useEffect(() => {
        const id = searchParams.get('id');
        const Invite_id = searchParams.get('Invite_id');
        if (id == '1') {
            setUserDetail(true)
            // console.log();
            // setType(CounterpartType.ORGANIZATION)
        } else if (Invite_id == '2') {
            setcomingsoon(true)
        }
    }, [searchParams]);
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    // const storeDocSelector: any = useSelector((state) => settingSelector(state));
    // useEffect(() => {
    //     if (getUser) {
    //         setUsers(getUser);
    //     }
    // }, [getUser]);
    // React.useEffect(() => {
    //     if (getUser && render) {
    //         setRender(false);
    //         setUsers(getUser);
    //         dispatch(settingActions.getUserDocuments() as any)
    //     }
    // }, [render, getUser]);

    // React.useEffect(() => {
    //     if (renderEntity && storeDocSelector?.GETENTITYDOCUMENT_SUCCESS !== undefined) {
    //         console.log('dataEntity list', 'dataEntity', storeDocSelector.USERDOCUMENT_SUCCESS.data);

    //                 var GetData = storeDocSelector.USERDOCUMENT_SUCCESS.data;


    //                     setType(GetData);
    //                     setRender(false);
    //                     // setstatus(GetData.status);

    //             }
    //         }, [storeDocSelector, renderEntity]);
    return (
        <>
            <ThemeProvider theme={theme}>
                <div className='accoun-management'>
                    <div className='accoun-management__header' style={{ marginBottom: '35px' }}>
                        Team
                    </div>
                    {userDetail === true ? (
                        <UserDetail />
                    ) : (
                        <Comingsoon
                                    onCancel={() => {

                                        setcomingsoon(false);
                                    }}
                                />
                
                                    // <>

                                    //     <div className='users'>
                                    //         <div className='users__heading'>Users</div>
                                    //         <div className='users__list'>
                                    //             <div className='users__user'>
                                    //                 <img src="/assets/user-icon.png" />
                                    //                 <div className='users__user__name'>{!!users ? !!users.data.name ? users.data.name : '' : ''}</div>
                                    //                 <div className='users__user__desig'>Owner</div>
                                    //             </div>

                                    //             <div className='users__user' onClick={() => setcomingsoon(true)}>
                                    //                 <AddUserIcon />
                                    //                 <div className='users__user__name'>Invite director</div>
                                    //                 {/* <div className='users__user__desig'>Owner</div> */}
                                    //             </div>
                                    //         </div>

                                    //         <div className='users__btn-row'>
                                    //             <Button className='users__save-btn' onClick={() => setUserDetail(!userDetail)}>Choose User</Button>
                                    //         </div>
                                    //     </div>
                                    // </>
                    )}

                </div>
            </ThemeProvider>
        </>
    )
}


const AddUserIcon = () => (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_372_178142)">
            <circle cx="44" cy="42" r="40" fill="white" />
        </g>
        <path d="M55.5334 42.7664H61.5334" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M58.5334 39.7664V45.7664" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M44.0334 45.7664C48.1756 45.7664 51.5334 42.4085 51.5334 38.2664C51.5334 34.1242 48.1756 30.7664 44.0334 30.7664C39.8913 30.7664 36.5334 34.1242 36.5334 38.2664C36.5334 42.4085 39.8913 45.7664 44.0334 45.7664Z" stroke="#025041" stroke-width="2" stroke-miterlimit="10" />
        <path d="M33.3085 50.7663C34.6225 49.2001 36.2637 47.9408 38.1165 47.0767C39.9694 46.2127 41.989 45.7649 44.0335 45.7649C46.0779 45.7649 48.0975 46.2127 49.9504 47.0767C51.8033 47.9408 53.4444 49.2001 54.7585 50.7663" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
            <filter id="filter0_d_372_178142" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.458333 0 0 0 0 0.456424 0 0 0 0 0.456424 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_372_178142" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_372_178142" result="shape" />
            </filter>
        </defs>
    </svg>
);