import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import "../../../Settings.scss";


interface Props {
    onSave: any;
}

export const Model_Reject = (props: Props) => {

    const onSave = () => {
        
        props.onSave();
    }

    return (
        <div className='document-verified'>
            <div className='document-verified__heading' style={{color:"#DF3A3A"}}>Account Setup Failed</div>

            <div className='document-verified__desc'>
                
                <br />
                In case of any queries, reach out to us for <span>support@niobi.co</span>
            </div>
            <Button className='document-verified__btn' onClick={() => onSave()}>Go back </Button>
        </div>
    )
}
