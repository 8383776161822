import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import "./BankAccountDetail.scss";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from "react-redux";
import { userActions } from "../../../../../../_actions";
import { useSearchParams } from "react-router-dom";


interface Props {
    onCancel: any;
    onSave: any;
    bank: any;
    counterpart: any;
    item: any;
    body_data: any;
}

export const BankAccountDetail = (props: Props) => {
    const [entity, setEntity] = useState<any>({
        account_name: '',
        account_number: '',
    })
    const [detailsBank, setDetailsBank] = useState<any>()
    const [itemm, setitem] = useState<any>();
    const [counterpart, setcounterpart] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [datadisplay, setDatadisplay] = useState('')

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        account_name: Yup.string()
            .required('Account Name is required'),
        account_number: Yup.number()
            .typeError('Account Number must contain only numeric value')
            .required('Account Number is required'),
    })
    useEffect(() => {
        const popup = searchParams.get('type');
        if (popup == 'Express Payment KES') {
            setDatadisplay(popup)

        } else {
        }
    }, [searchParams]);
    useEffect(() => {
        setitem(props.item);
        setcounterpart(props.counterpart);
    }, [props]);



    const handleSubmit = (e: any) => {
        if (!!e && !!e.account_name && !!e.account_number && datadisplay == '') {
            const body = {
                bank_name: detailsBank.bank_name,
                bank_code: detailsBank.niobi_bank_code,
                branch_name: detailsBank.brance_name,
                account_name: e.account_name,
                account_number: e.account_number,
                payment_details: itemm,
                counterpart: counterpart,
            }

            console.log(body, 'body');
            dispatch(userActions.getOtp('') as any)
            onSave(body)
        } else if (datadisplay == 'Express Payment KES' && !!e && !!e.account_name && !!e.account_number) {
            const body = {
                bank_name: detailsBank.bank_name,
                bank_code: detailsBank.niobi_bank_code,
                branch_name: detailsBank.brance_name,
                account_name: e.account_name,
                account_number: e.account_number,
                counterpart: props.body_data.counterpart,
                amount: props.body_data.amount * 100,
                tags: props.body_data.tags
            }

            console.log(body, 'body');
            dispatch(userActions.getOtp('') as any)
            onSave(body)
        }
    }
    React.useEffect(() => {
        if (!!props) {
            setDetailsBank(props.bank);
        }
    }, [props]);

    const onSave = (body: any) => {
        props.onSave(body);
    }
    const onCancel = () => {
        props.onCancel();
    }
    console.log(props.bank, 'props.bank')

    return (
        <div className='bank-acc-detail'>
            <div className='bank-acc-detail__uprow' onClick={() => onCancel()}>
                <BackArrow />
                Back
            </div>
            <div className='bank-acc-detail__heading'>
                {!!detailsBank ? detailsBank.bank_name : ""}
            </div>
            <div className='bank-acc-detail__bname'>
                {!!detailsBank ? detailsBank.brance_name : ""}

            </div>
            <div className='bank-acc-detail__desc'>
                Please fill in the recipient’s bank account details
            </div>
            <div>
                <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ errors, touched, isSubmitting, handleChange, values }) => (
                        <Form>
                            <div className="input-form-wrapper mb-4">
                                <label>Account name</label>
                                <Field
                                    name="account_name"
                                    type="text"
                                    placeholder="Account Name"
                                    className={
                                        "text-field field-enable" +
                                        (errors.account_name && touched.account_name
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="account_name"
                                    component="div"
                                    className="invalid-feedback red"
                                />
                            </div>
                            <div className="input-form-wrapper m-10">
                                <label>Account Number</label>
                                <Field
                                    name="account_number"
                                    type="number"
                                    placeholder="Account Number"
                                    className={
                                        "text-field field-enable" +
                                        (errors.account_number && touched.account_number
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="account_number"
                                    component="div"
                                    className="invalid-feedback red"
                                />
                            </div>

                            <div className='bank-acc-detail__btn-row'>
                                <Button type='submit' className='bank-acc-detail__btn-row__grey' >Next</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}


const BackArrow = () => (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9999 6.00019H3.4099L6.7099 2.71019C6.8982 2.52188 7.00399 2.26649 7.00399 2.00019C7.00399 1.73388 6.8982 1.47849 6.7099 1.29019C6.52159 1.10188 6.2662 0.996094 5.9999 0.996094C5.73359 0.996094 5.4782 1.10188 5.2899 1.29019L0.289896 6.29019C0.198856 6.38529 0.127491 6.49743 0.0798963 6.62019C-0.0201217 6.86365 -0.0201217 7.13672 0.0798963 7.38019C0.127491 7.50294 0.198856 7.61508 0.289896 7.71019L5.2899 12.7102C5.38286 12.8039 5.49346 12.8783 5.61532 12.9291C5.73718 12.9798 5.86788 13.006 5.9999 13.006C6.13191 13.006 6.26261 12.9798 6.38447 12.9291C6.50633 12.8783 6.61693 12.8039 6.7099 12.7102C6.80363 12.6172 6.87802 12.5066 6.92879 12.3848C6.97956 12.2629 7.00569 12.1322 7.00569 12.0002C7.00569 11.8682 6.97956 11.7375 6.92879 11.6156C6.87802 11.4937 6.80363 11.3831 6.7099 11.2902L3.4099 8.00019H10.9999C11.2651 8.00019 11.5195 7.89483 11.707 7.70729C11.8945 7.51976 11.9999 7.2654 11.9999 7.00019C11.9999 6.73497 11.8945 6.48062 11.707 6.29308C11.5195 6.10554 11.2651 6.00019 10.9999 6.00019Z" fill="#707070" />
    </svg>
);