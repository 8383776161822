import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import "../RecipientBank/RecipientBank.scss";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isUserAuthenticated } from "../../../../../../_helpers";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { userActions, dashboardActions } from "../../../../../../_actions";
import moment from 'moment';
import { useSearchParams } from "react-router-dom";

const SWIFTPayment = (props: any) => {
    const [entity, setEntity] = useState<any>({
        Beneficiary_bank_name: '',
        account_name: '',
        account_number: '',
        Beneficiary_bank_SWIFT_code: "",
        Beneficiary_bank_address: "",
        Correspondent_bank_name: "",
        Correspondent_bank_SWIFT_code: ""
    })
    const [bankList, setBankList] = useState<any>([{
        "id": 5,
        "bank_name": "Prime Bank",
        "bank_code": "10",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 6,
        "bank_name": "Cooperative Bank",
        "bank_code": "11",
        "created_at": null,
        "updated_at": null
    }]);
    const [bankvalid, setBankvlid] = useState('1')
    const [loading, setLoading] = React.useState(false);
    const [render, setRender] = useState(true);
    const [bankListRender, setBankListRender] = useState<any>(true);
    const [bank_name, setBank_name] = useState('')
    const [apidata, setApidata] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
    })
    const [searchParams, setSearchParams] = useSearchParams();
    const [datadisplay, setDatadisplay] = useState('')

    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const dispatch = useDispatch();

    useEffect(() => {
        const popup = searchParams.get('type');
        if (!!popup) {
          setDatadisplay(popup)
        }else {
          setDatadisplay('')
        }
      }, [searchParams]);
    const onCancel = () => {
        props.onCancel();
        setBankvlid("1")
    }
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(paymentJourneyActions.getPesaBankList() as any)
        }
    }, [render, isUserAuthenticated]);
    useEffect(() => {
        if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
            setBankListRender(false);
            setBankList(dataList.GETPESABANKLIST_SUCCESS);
            if (!!props.counterpart) {
                const object = {
                    bank_name: '',
                    branch_name: props.counterpart.bank_branch,
                    account_name: !!props?.counterpart?.account_name ? props?.counterpart?.account_name : "",
                    account_number: !!props?.counterpart?.bank_account_number ? props.counterpart?.bank_account_number : "",
                }
                const findBankId = dataList.GETPESABANKLIST_SUCCESS.find((o: any) => o.bank_name == props.counterpart.bank_name)
                if (!!findBankId) {
                    object.bank_name = findBankId.id
                }
                setBank_name(findBankId)
                setEntity(object)
            }
        }
    }, [dataList, bankListRender]);
    const validationSchema = Yup.object().shape({
        account_name: Yup.string()
            .required('Please select the account name.'),
        Beneficiary_bank_name: Yup.string()
            .required('Please enter the Beneficiary bank name.'),
        account_number: Yup.string()
            .required('Please enter the account number.'),
        Beneficiary_bank_SWIFT_code: Yup.string()
            .required('Please enter the Beneficiary bank SWIFT code.'),
        Beneficiary_bank_address: Yup.string()
            .required('Please enter the Beneficiary bank address.'),
    })
    const onSave = (bank: any) => {
        props.onSave(bank);
    }
    return (
        <>

            <div className='recipient-bank-container'>
                <p className='heding_typename'>
                    {props?.payOption} payment
                </p>
                <div style={{ textAlign: "left", marginBottom: "6px" }} className='recipient-bank-container__heading'>
                    Select Recipient Bank
                </div>
                <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        var Date_formet = new Date();
                        const body = {
                            "payment_channel": props?.payOption,
                            transaction_date: moment(Date_formet).format("YYYY-MM-DD"),
                            currency:datadisplay == "Express Payment USD" ? "USD" : "KES",
                            payment_method: "bank_transfer",
                            beneficiary_bank_name: values.Beneficiary_bank_name,
                            account_name: values.account_name,
                            account_number: values.account_number,
                            beneficiary_bank_swift_code: values.Beneficiary_bank_SWIFT_code,
                            beneficiary_bank_address: values.Beneficiary_bank_address,
                            correspondent_bank_name: values.Correspondent_bank_name,
                            correspondent_bank_swift_code: values.Correspondent_bank_SWIFT_code,
                            counterpart: props.body_data.counterpart,
                            amount: props.body_data.amount,
                            tags: props.body_data.tags,
                            counterpart_type: props.body_data.counterpart_type,
                            is_tax: props.body_data.is_tax,
                            vat: props?.body_data?.vat,
                            payment_reference:props?.nameRef
                        }
                        props.transactionExist('CMFB')
                        dispatch(userActions.getOtp('') as any)
                        onSave(body)
                    }}
                >
                    {({ errors, touched, isSubmitting, handleChange, values }) => (
                        <div className='company-details-container__wrapper'>
                            <Form>
                                <div className='user-detail-container__wrapper'>
                                    <div className='input-form-wrapper dot'>
                                        <label>Account name</label>
                                        <Field
                                            name="account_name"
                                            type="text"
                                            placeholder="Account Name"
                                            className={
                                                "text-field field-enable" +
                                                (errors.account_number && touched.account_number
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage name="account_name" component="div" className="invalid-feedback red" />
                                    </div>
                                    <div className="input-form-wrapper m-10 dot">
                                        <label>Account number / IBAN</label>
                                        <Field
                                            name="account_number"
                                            type="text"
                                            placeholder="Account Number"
                                            className={
                                                "text-field field-enable" +
                                                (errors.account_number && touched.account_number
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="account_number"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className="input-form-wrapper mb-4 dot">
                                        <label>Beneficiary bank name</label>
                                        <Field
                                            name="Beneficiary_bank_name"
                                            type="text"
                                            placeholder="Beneficiary bank name"
                                            className={
                                                "text-field field-enable" +
                                                (errors.Beneficiary_bank_name && touched.Beneficiary_bank_name
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="Beneficiary_bank_name"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className="input-form-wrapper mb-4 dot">
                                        <label>Beneficiary bank SWIFT code</label>
                                        <Field
                                            name="Beneficiary_bank_SWIFT_code"
                                            type="text"
                                            placeholder="Beneficiary bank SWIFT code"
                                            className={
                                                "text-field field-enable" +
                                                (errors.Beneficiary_bank_SWIFT_code && touched.Beneficiary_bank_SWIFT_code
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="Beneficiary_bank_SWIFT_code"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className="input-form-wrapper mb-4 dot">
                                        <label>Beneficiary bank address</label>
                                        <Field
                                            name="Beneficiary_bank_address"
                                            type="text"
                                            placeholder="Beneficiary bank address"
                                            className={
                                                "text-field field-enable" +
                                                (errors.Beneficiary_bank_address && touched.Beneficiary_bank_address
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="Beneficiary_bank_address"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className="input-form-wrapper mb-4">
                                        <label>Correspondent bank name</label>
                                        <Field
                                            name="Correspondent_bank_name"
                                            type="text"
                                            placeholder="Correspondent bank name"
                                            className={
                                                "text-field field-enable" +
                                                (errors.Correspondent_bank_name && touched.Correspondent_bank_name
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="Correspondent_bank_name"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className="input-form-wrapper mb-4">
                                        <label>Correspondent bank SWIFT code</label>
                                        <Field
                                            name="Correspondent_bank_SWIFT_code"
                                            type="text"
                                            placeholder="Correspondent bank SWIFT code"
                                            className={
                                                "text-field field-enable" +
                                                (errors.Correspondent_bank_SWIFT_code && touched.Correspondent_bank_SWIFT_code
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="Correspondent_bank_SWIFT_code"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className="auth-trans-container__btn-row">
                                        <div>
                                            <Button className='complete-profile__saventmm' variant='custom'
                                                onClick={() => { onCancel(); }}
                                            >   Cancel  </Button>
                                        </div>
                                        <div>
                                            <Button type='submit'
                                                disabled={loading}
                                                style={{ display: "flex", alignItems: "center" }}
                                                className='complete-profile__submitbtnlist' >

                                                <p style={{textTransform:"capitalize"}}>Continue</p>{loading == true && <div style={{ marginLeft: "6px" }} className="spinner-border spinner-border-sm mr-1"></div>}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div >

        </>
    )
}
export default SWIFTPayment;