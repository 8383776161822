import { alertActions } from '.';
import { choiceBankService } from '../_services/choiceBank.service';
import { ChoiceBankConstants } from '../_constants/choiceBank.constants';
import toast, { Toaster } from 'react-hot-toast';

export const choiceActions = {
  getEntityTypes,
  getOperatingModes,
  getIndustries,
  getGender,
  createBusinessAccount,
  verifyOtp,
  resendOtp,
  uploadMedia,
  getStoreOnboardingInfo,
  setBusiness,
  saveShareholder,
};

function getEntityTypes() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getBusinessTypes()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETTYPES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETTYPES_FAILURE };
  }
}

function getOperatingModes() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getOperatingModes()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETOPERATINGMODES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETOPERATINGMODES_FAILURE };
  }
}

function getIndustries() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getIndustries()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETINDUSTRIES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETINDUSTRIES_FAILURE };
  }
}

function getGender() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getGenders()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETGENDER_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETGENDER_FAILURE };
  }
}

function createBusinessAccount(business: any, navigateCallback: () => void) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(business));
    choiceBankService
      .createBusiness(business)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            toast.success('Business Account Created successfully', {
              duration: 8000,
            });
            navigateCallback();
          } else {
            console.log('response Getting in error:', response.data);
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });

            if (response.message === 'Validation errors') {
              var fieldarray = [
                'businessName',
                'businessCerNum',
                'firstName',
                'middleName',
                'lastName',
                'gender',
                'birthday',
                'idNumber',
                'kraPin',
                'businessAddress',
                'kinFullName',
                'kinRelationship',
                'kinCountryCode',
                'kinMobile',
                ' businessIndustry',
                'specifyIndustry',
                'userId',
                'countryCode',
                'businessType',
                'operatingMode',
                'otpType',
                'mobile',
                'email',
                'shareholders',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_FAILURE, error };
  }
}

function verifyOtp(payload: any, navigateCallback: () => void) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .verifyOtp(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            toast.success('Business Account Created successfully');
            navigateCallback();
          } else {
            dispatch(failure(response.message));
            toast.error(
              'Otp verification failed try again or request a new OTP',
              { duration: 8000 }
            );
            if (response.message === 'Validation errors') {
              var fieldarray = ['otpCode', 'businessId', 'id'];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_VERIFYOTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_FAILURE, error };
  }
}

function uploadMediaToChoice(payload: any, navigateCallback: () => void) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .verifyOtp(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            toast.success('Business Account Created successfully');
            navigateCallback();
          } else {
            dispatch(failure(response.message));
            toast.error(
              'Otp verification failed try again or request a new OTP',
              { duration: 8000 }
            );
            if (response.message === 'Validation errors') {
              var fieldarray = ['otpCode', 'businessId', 'id'];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_VERIFYOTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_FAILURE, error };
  }
}

function saveShareholder(payload: any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .saveShareholder(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            // toast.success('Business Account Created successfully');
          } else {
            dispatch(failure(response.message));
            // toast.error(
            //   'Otp verification failed try again or request a new OTP',
            //   { duration: 8000 }
            // );
            if (response.message === 'Validation errors') {
              var fieldarray = [
                'idType',
                'firstName',
                'lastName',
                'idNumber',
                'gender',
                'countryCode',
                'mobile',
                'kraPin',
                'idFrontSideFile',
                'idFrontSideFileType',
                'idBackSideFile',
                'idBackSideFileType',
                'selfieFile',
                'selfieFileType',
                'kraPinFile',
                'kraPinFileType',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_VERIFYOTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_FAILURE, error };
  }
}

function resendOtp(payload: any, navigateCallback: () => void) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .verifyOtp(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            toast.success('Business Account Created successfully');
            navigateCallback();
          } else {
            dispatch(
              failure(
                response.response ? response.response.msg : response.message
              )
            );
            toast.error(response.response.msg, { duration: 8000 });
            if (response.message === 'Validation errors') {
              var fieldarray = ['otpCode', 'businessId', 'id'];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_RESEND_OTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_RESEND_OTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_RESEND_OTP_FAILURE, error };
  }
}

function uploadMedia(payload: any, handleLoader: (value: boolean) => void) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    handleLoader(true);
    choiceBankService
      .uploadMedia(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          handleLoader(false);
          if (response.success === true) {
            dispatch(success(response));
            toast.success('Media Uploaded successfully');
          } else {
            dispatch(failure(response.message));
            toast.error(response.message, { duration: 8000 });
            if (response.message === 'Validation errors') {
              var fieldarray = [
                'title',
                'document_type',
                'mediaBase64',
                'mediaType',
                'contentType',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_UPLOADMEDIA_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_UPLOADMEDIA_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_UPLOADMEDIA_FAILURE, error };
  }
}

function getStoreOnboardingInfo() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getStoreOnboardingInfo()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_ONBOARDING_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_ONBOARDING_FAILURE };
  }
}

function setBusiness(business: any) {
  return {
    type: ChoiceBankConstants.CHOICE_BUSINESS_INFO_REQUEST,
    payload: business,
  };
}
