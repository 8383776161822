import { Box } from '@mui/material';
import Loader from './Settings/components/Profile/components/AccountSetup/Loader';
import { getEnvironment } from '../../../_helpers/environmentProperties';

export default function AppLoadingScreen() {
  const environment = getEnvironment();
  return (
    <Box
      sx={{
        display: 'grid',
        'justify-content': 'center',
        'align-items': 'center',
        gap: "6px",
        height: '100vh',
        width: '100vw',
      }}
    >
      {/* <Loader /> */}
      <div style={{textAlign:"center"}}>
        <img src="/logo 3.svg" />
        <p style={{ fontSize: '1.5rem',padding:"20px 0px" }}>{`${environment == "sandbox" ? "Sandbox" : 'Production'
          } mode is loading`}</p>
        <img
          width={75}
          height={75}
          src="/Niobi Loading Animation-Cross.gif"
        />
      </div>
    </Box>
  );
}
