import React, { useEffect, useState } from 'react';
import "./Settings.scss";
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from "../../../../_helpers";

import { Formik, Field, Form, ErrorMessage } from 'formik'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import * as Yup from 'yup'
import { Avatar, Button, Typography, Box } from '@mui/material';
import { entityActions } from "../../../../_actions/entity.actions";
import { selectAllData } from "../../../../_reducers/entity.reducer";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';


export const CompanyDetails = () => {

   const [users, setUsers] = useState<any>();
   const [country, setCountry] = useState<any>();
   const [region, setRegion] = useState<any>();
   const [render, setRender] = useState(true);
   const [submitted, setSubmitted] = useState(false)
   const [data, setData] = useState<any>();
   const [countryError, setCountryError] = useState(false);
   const [responseRender, setresponseRender] = useState(true);
   const [entity, setEntity] = useState<EntityRequest>({
      country: '',
      state: '',
      street_address: '',
      city: '',
      zip_code: '',
      tax_identification_number: '',
      registration_number: ''
   })
   const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
   const [isInitialActivityRender, setIsInitialActivityRender] = useState(true);
   const [entityType, setEntityType] = useState<any>();
   const [entityActivity, setEntityActivity] = useState<any>();
   const [enableButton, setEnableButton] = useState(false)
   const user = getUser()?.data ?? '';

   const dispatch = useDispatch()
   const entityData = useSelector((state) => selectAllData(state));


   useEffect(() => {
      if (isInitialTypeRender && entityData?.GETENTITYITEMS_SUCCESS !== undefined) {
         setIsInitialTypeRender(false);
         setEntityType(entityData.GETENTITYITEMS_SUCCESS);
      }
   }, [data, isInitialTypeRender]);

   useEffect(() => {
      if (isInitialActivityRender && entityData?.GET_ENTITY_ACTIVITY_SUCCESS !== undefined) {
         setIsInitialActivityRender(false);
         setEntityActivity(entityData.GET_ENTITY_ACTIVITY_SUCCESS);
      }
   }, [data, isInitialActivityRender]);


   useEffect(() => {
      if (getUser && render) {
         setRender(false);
         setUsers(getUser);
         dispatch(entityActions.getEntityTypes() as any)
         dispatch(entityActions.getEntityActivities() as any)
      }
   }, [render, getUser]);
   useEffect(() => {
      if (!!users) {
         if (!!users.data.entity.id) {
            let requestBody = {
               id: users.data.entity.id,
            }
            dispatch(entityActions.getEntities(requestBody) as any)
         }
      }
   }, [users]);

   useEffect(() => {
      if (responseRender && entityData?.GETENTITY_SUCCESS !== undefined) {
         setresponseRender(false);
         var object = {
            tax_identification_number: !!entityData.GETENTITY_SUCCESS.data.tax_identification_number ? entityData.GETENTITY_SUCCESS.data.tax_identification_number : '',
            registration_number: !!entityData.GETENTITY_SUCCESS.data.registration_number ? entityData.GETENTITY_SUCCESS.data.registration_number : '',
            country: !!entityData.GETENTITY_SUCCESS.data.country ? entityData.GETENTITY_SUCCESS.data.country : '',
            state: !!entityData.GETENTITY_SUCCESS.data.state ? entityData.GETENTITY_SUCCESS.data.state : '',
            street_address: !!entityData.GETENTITY_SUCCESS.data.street_address ? entityData.GETENTITY_SUCCESS.data.street_address : '',
            city: !!entityData.GETENTITY_SUCCESS.data.city ? entityData.GETENTITY_SUCCESS.data.city : '',
            zip_code: !!entityData.GETENTITY_SUCCESS.data.zip_code ? entityData.GETENTITY_SUCCESS.data.zip_code : ''
         }
         if (!!object.country) {
            setCountry(entityData.GETENTITY_SUCCESS.data.country)
         }
         if (!!object.zip_code) {
            setRegion(entityData.GETENTITY_SUCCESS.data.state)
         }
         setEntity(object)
         setData(entityData.GETENTITY_SUCCESS)
      }
   }, [entityData, responseRender]);

   const validationSchema = Yup.object().shape({
      tax_identification_number: Yup.string()
         .required('Please enter the Tax Identification Number.'),
      registration_number: Yup.string()
         .required('Please enter the registration number.'),
      country: Yup.string()
         .required('Please select the country where the business is located.'),
      state: Yup.string()
         .required('Please select the country where the business is located.'),
      street_address: Yup.string()
         .required('Please enter the street address.'),
      city: Yup.string()
         .required('Please select the city where the business is located.'),
      zip_code: Yup.string()
         .required('Please enter the postal address.'),

   })
   const handleSubmit = (e: any) => {
      console.log(e, 'adadassddddd');
      setSubmitted(true);
      if (e.city && e.country && e.state && e.street_address && e.zip_code && e.registration_number && e.tax_identification_number && !!users && !!users.data.entity.id) {


         e.entity_id = users.data.entity.id
         dispatch(entityActions.updateEntity(e) as any)
      }
   }
   const theme = createTheme({
      status: {
         danger: orange[500],
      },
   });
   return (
      <ThemeProvider theme={theme}>
         <div className='accoun-management'>
            <div className='accoun-management__header' style={{ marginBottom: '35px' }}>
               Organisation
            </div>
            <div className='company-details-container'>
               <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
                  {({ errors, touched, isSubmitting, handleChange, values, setFieldValue }) => (
                     <div className='company-details-container__wrapper'>
                        {/* <div style={{padding:'20px 0px',textAlign:'center'}} className='company-details-container__heading'>Business Management</div> */}

                        <Form className='form-input'>
                           <div className='input-form-wrapper cursorPoint'>
                              <label>Organisation name</label>
                              <input type="text" className='text-field' value={!!data ? data.data.name : ""} readOnly />
                           </div>
                           <div className='input-form-wrapper cursorPoint'>
                              <label>Organisation type</label>
                              <input type="text" className='text-field' value={!!data && Array.isArray(entityType) ? (entityType.find((o: any) => o.id == data.data.entity_type_id)).name : ""} readOnly />
                           </div>
                           <div className='input-form-wrapper cursorPoint'>
                              <label>Business activity</label>
                              <input type="text" className='text-field' value={!!data && Array.isArray(entityActivity) ? (entityActivity.find((o: any) => o.id == data.data.entity_activity_id)).name : ""} readOnly />
                              {/*  name="business" id="business" >
                           <option value="volvo">Volvo</option>
                           <option value="saab">Saab</option>
                        </select> */}
                           </div>
                           {/* <div className='input-form-wrapper cursorPoint'>
                              <label>Registration number</label>
                              <input type="text" className='text-field'
                               value={!!data ? data.data.registration_number : ""} readOnly />
                           </div> */}
                           <div className='input-form-wrapper'>
                              <label>Registration number</label>
                              <Field name="registration_number"
                                 type="text"
                                 placeholder="Registration number"
                                 onChange={(e: any) => {
                                    handleChange(e)
                                    setEnableButton(true)
                                 }}
                                 className={'text-field border-green' + (errors.registration_number && touched.registration_number ? ' is-invalid' : '')} />
                              <ErrorMessage name="registration_number" component="div" className="invalid-feedback red" />
                           </div>
                           {/* <div className='input-form-wrapper cursorPoint'>
                              <label>Tax identification number</label>
                              <input type="text"  
                              className={'text-field border-green' + (errors.street_address && touched.street_address ? ' is-invalid' : '')}
                              //  value={!!data ? data.data.tax_identification_number : ""}
                               onChange={(e: any) => {
                                 handleChange(e)
                                 setEnableButton(true)
                              }}
                              //   readOnly 
                                />
                           </div> */}
                           <div className='input-form-wrapper'>
                              <label>Tax identification number</label>
                              <Field name="tax_identification_number"
                                 type="text"
                                 placeholder="Tax identification number"
                                 onChange={(e: any) => {
                                    handleChange(e)
                                    setEnableButton(true)
                                 }}
                                 className={'text-field border-green' + (errors.tax_identification_number && touched.tax_identification_number ? ' is-invalid' : '')} />
                              <ErrorMessage name="tax_identification_number" component="div" className="invalid-feedback red" />
                           </div>
                           {/* <div className='input-form-wrapper'>
                        <label>Address</label>
                        <input type="text" className='text-field' value="" readOnly />
                     </div> */}

                           <div className='input-form-wrapper'>
                              <label>Street address</label>
                              <Field name="street_address"
                                 type="text" placeholder="Street address"
                                 onChange={(e: any) => {
                                    handleChange(e)
                                    setEnableButton(true)
                                 }}
                                 className={'text-field border-green' + (errors.street_address && touched.street_address ? ' is-invalid' : '')} />
                              <ErrorMessage name="street_address" component="div" className="invalid-feedback red" />
                           </div>


                           <div className='input-form-wrapper'>
                              <label>City</label>
                              <Field name="city" type="text" placeholder="City" onChange={(e: any) => {
                                 handleChange(e)
                                 setEnableButton(true)
                              }} className={'text-field border-green' + (errors.city && touched.city ? ' is-invalid' : '')} />
                              <ErrorMessage name="city" component="div" className="invalid-feedback red" />
                           </div>

                           <div className='input-form-wrapper'>
                              <label>Postal address</label>
                              <Field name="zip_code" type="text" placeholder="Postal Address"
                                 onChange={(e: any) => {
                                    handleChange(e)
                                    setEnableButton(true)
                                 }} className={'text-field border-green' + (errors.zip_code && touched.zip_code ? ' is-invalid' : '')} />
                              <ErrorMessage name="zip_code" component="div" className="invalid-feedback red" />
                           </div>
                           <div className='input-form-wrapper'>
                              <label>Country</label>
                              <CountryDropdown
                                 value={country}

                                 onChange={
                                    (e, event) => {
                                       console.log(e, event, 'essssss')
                                       setCountryError(!!e ? false : true)
                                       setCountry(e);
                                       setEnableButton(true)
                                       handleChange(event);
                                    }}
                                 name="country"
                                 classes={'text-field border-green' + (errors.country && touched.country ? ' is-invalid' : '')}
                              />
                              <ErrorMessage name="country" component="div" className="invalid-feedback red" />
                           </div>

                           <div className='input-form-wrapper'>
                              <label>County    </label>
                              <RegionDropdown
                                 country={country}
                                 value={region}
                                 name="state"
                                 onChange={(val, e) => {
                                    setRegion(val);
                                    setEnableButton(true)
                                    handleChange(e);
                                 }}
                                 classes={'text-field border-green' + (errors.state && touched.state ? ' is-invalid' : '')}
                              />
                              <ErrorMessage name="state" component="div" className="invalid-feedback red" />

                           </div>
                           {!!user && !!user.role_name && user.role_name == "Developer" ? "" : <>
                              {enableButton ? <Button type="submit" className='mt-3 complete-profile__btn2 text-capitalize' style={{ background: '#025041 !important' }}>Save</Button> :
                                 <Button style={{ cursor: 'not-allowed' }} className='mt-3 complete-profile__btn2 text-capitalize btndisable'>Save</Button>}
                           </>}

                           <Box className="mb-5"></Box>
                        </Form>
                     </div>
                  )}
               </Formik>
               {/* <div className='image-wrapper'>
               <img src="/assets/Company_logo.png" width="116px" />
            </div> */}
               {/* */}
               {/* <div className='input-form-wrapper'>
                <label>Phone number</label>
                <input type="text" className='text-field' value="1245678" readOnly/>
             </div>
             <div className='input-form-wrapper'>
                <label>Email address</label>
                <input type="email" className='text-field' value="loremispum@gmail.com" readOnly/>
             </div> */}
            </div>
         </div>
      </ThemeProvider>
   )
}
interface EntityRequest {
   tax_identification_number: string;
   registration_number: string;
   country: string;
   state: string;
   street_address: string;
   city: string;
   zip_code: string;
}