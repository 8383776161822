import { authHeader, handleResponse } from '../_helpers';
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;

export const counterpartsService = {
  getAllCounterparts,
  getAllTags,
  getUserCounterparts,
  getCounterpartById,
  getPendingPaybles,
  updatePaybles,
  submitPaybles,
  getBudgetTags_services,
  getAllvat_service,
  getbulk_payment_payables,
  updatebulk_payment,
  rejectbulk_payment,
  CeretePaybles,
  getAllCounterparts_list,
  geteditCounterparts,
  edit_Counterparts,
  Counterparts_delete_servicers,
  deletePayee_servicers,
};

// get all counterparts api
function deletePayee_servicers(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(id),
  };
  return fetch(
    `${baseURL}/counterparts/delete-counterparts`,
    requestOptions
  ).then(handleResponse);
}
function getCounterpartById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/counterparts/get-counterpart-by-id/${id}`,
    requestOptions
  ).then(handleResponse);
}

// get all counterparts api
function getUserCounterparts() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/counterparts/get-user-counterparts`,
    requestOptions
  ).then(handleResponse);
}
function getbulk_payment_payables(data, Limetdata) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/get-pending-bulk-payment-payables?page=${data}&limit=${Limetdata}`,
    requestOptions
  ).then(handleResponse);
}
// get all counterparts api
function getAllCounterparts() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/counterparts/get-counterparts`, requestOptions).then(
    handleResponse
  );
}
function geteditCounterparts(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/counterparts/get-counterpart/${req}`,
    requestOptions
  ).then(handleResponse);
}
function getAllCounterparts_list(data, type, page, categ, limet) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/counterparts/get-counterpart-list?search=${data}&type=${type}&page=${page}&${categ}&limit=${limet}`,
    requestOptions
  ).then(handleResponse);
}
function getAllTags() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/counterparts/get-tags`, requestOptions).then(
    handleResponse
  );
}
function getAllvat_service() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/payables/vat-list`, requestOptions).then(
    handleResponse
  );
}
function getBudgetTags_services() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-team-users`, requestOptions).then(
    handleResponse
  );
}
function getPendingPaybles() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/get-pending-payables `,
    requestOptions
  ).then(handleResponse);
}

function updatePaybles(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/payables/update-payables`, requestOptions).then(
    handleResponse
  );
}
function updatebulk_payment(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/update-bulk-payment-payables`, requestOptions).then(
    handleResponse
  );
}
function rejectbulk_payment(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(
    `${baseURL}/update-bulk-payment-payables-reject`,
    requestOptions
  ).then(handleResponse);
}
function submitPaybles(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/payables/submit-payables`, requestOptions).then(
    handleResponse
  );
}
function CeretePaybles(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/counterparts/create`, requestOptions).then(
    handleResponse
  );
}
function edit_Counterparts(bankDetail, id) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${baseURL}/counterparts/update-counterpart-by-id/${id}`,
    requestOptions
  ).then(handleResponse);
}
function Counterparts_delete_servicers(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/counterparts/delete-counterpart/${id}`,
    requestOptions
  ).then(handleResponse);
}
