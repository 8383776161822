import { paymentJourneyConstants } from "../_constants/paymentJourney.constants";

export function getFeesReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETFEES_FROM_STORE:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETFEES_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETFEES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function checkTransactionTypeReducer(state = {}, action: any) {
    console.log(action, 'actionactionaction');

    switch (action.type) {
        case paymentJourneyConstants.CHECK_TRANSACTION_TYPE_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.CHECK_TRANSACTION_TYPE_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.CHECK_TRANSACTION_TYPE_FAILURE:
            return {
                error: action.error

            };
        default:
            return state
    }
}
export function ChackBalanceReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.CHACKBALANCE_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.CHACKBALANCE_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.CHACKBALANCE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function ChackBalancepaymentLinkReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.CHACKBALANCE_PAYMENT_LINK_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.CHACKBALANCE_PAYMENT_LINK_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.CHACKBALANCE_PAYMENT_LINK_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getValidateAccountReducer(state = {}, action: any) {
    console.log(action, 'actionactionaction');

    switch (action.type) {
        case paymentJourneyConstants.GETVALIDATE_ACCOUNT_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETVALIDATE_ACCOUNT_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETVALIDATE_ACCOUNT_FAILURE:
            return {
                error: action.error

            };
        default:
            return state
    }
}
export function getShareablePdfLinkReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETSHAREABLEPDFLINK_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETSHAREABLEPDFLINK_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETSHAREABLEPDFLINK_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getbulk_payments_status_activeReducer(state = {}, action: any) {
    console.log(state, action, 'actionaction');

    switch (action.type) {
        case paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function getInveact(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.INVITE_STORE:
            return {
                loading: true
            };
        case paymentJourneyConstants.INVITE_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.INVITE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getSave_data(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.SAVE_DATA_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.SAVE_DATA_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.SAVE_DATA_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getBulk_payment_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GET_BULKPAYMENT_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GET_BULKPAYMENT_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GET_BULKPAYMENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getFeesNewReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETNEWFEES_FROM_STORE:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETNEWFEES_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETNEWFEES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCountriesReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETCOUNTRIES_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETCOUNTRIES_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETCOUNTRIES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCountriesReducerV2(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETCOUNTRIES_V2_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETCOUNTRIES_V2_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETCOUNTRIES_V2_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCountriesBalancesReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETCOUNTRIES_BALANCES_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETCOUNTRIES_BALANCES_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETCOUNTRIES_BALANCES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCountriesDetailsReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETCOUNTRIES_DETAILS_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETCOUNTRIES_DETAILS_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETCOUNTRIES_DETAILS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCountriesByCurrencyReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCurrenciesReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETCURRENCIES_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETCURRENCIES_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETCURRENCIES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCurrenciesV2Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETCURRENCIES_V2_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETCURRENCIES_V2_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETCURRENCIES_V2_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPesaBankListReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETPESABANKLIST_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETPESABANKLIST_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETPESABANKLIST_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getIntegration_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETINTEGRATION_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETINTEGRATION_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETINTEGRATION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function get_accounting_types_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GET_ACCOUNTING_TYPE_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GET_ACCOUNTING_TYPE_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GET_ACCOUNTING_TYPE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function get_connections_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GET_CONNECTION_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GET_CONNECTION_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GET_CONNECTION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getdurationlist_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETDURATIONLIST_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETDURATIONLIST_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETDURATIONLIST_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getIntegration_Apikey_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETINTEGRATION_APIKEY_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GETINTEGRATION_APIKEY_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GETINTEGRATION_APIKEY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function delete_credentials_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.DELETE_CREDENTIALS_REQUEST:
            return {
                data: undefined,
            };
        case paymentJourneyConstants.DELETE_CREDENTIALS_SUCCESS:
            return {
                data: action.data,
                loading: true,
            };
        case paymentJourneyConstants.DELETE_CREDENTIALS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function delete_budget__Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.DELETE_BUDGET_REQUEST:
            return {
                data: undefined,
            };
        case paymentJourneyConstants.DELETE_BUDGET_SUCCESS:
            return {
                data: action.data,
                loading: true,
            };
        case paymentJourneyConstants.DELETE_BUDGET_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function PaymentLinkApiDeleteReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.DELETE_PAYMENT_LINK_REQUEST:
            return {
                data: undefined,
            };
        case paymentJourneyConstants.DELETE_PAYMENT_LINK_SUCCESS:
            return {
                data: action.data,
                loading: true,
            };
        case paymentJourneyConstants.DELETE_PAYMENT_LINK_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function budget_list_data_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.LIST_BUDGET_REQUEST:
            return {
                data: undefined,
                loading: true
            };
        case paymentJourneyConstants.LIST_BUDGET_SUCCESS:
            return {
                data: action.data,
                loading: false,
            };
        case paymentJourneyConstants.LIST_BUDGET_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}
export function budget_viwe_list_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.GETBUDGET_LIST_REQUEST:
            return {
                data: undefined,
            };
        case paymentJourneyConstants.GETBUDGET_LIST_SUCCESS:
            return {
                data: action.data,
                loading: true,
            };
        case paymentJourneyConstants.GETBUDGET_LIST_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function AddBankDisbursementReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.ADDBANK_DESBURSEMENT_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.ADDBANK_DESBURSEMENT_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.ADDBANK_DESBURSEMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}

export function getPaybillNewReducer(state = {}, action: any) {
    console.log(action, 'actionactionaction');

    switch (action.type) {
        case paymentJourneyConstants.GET_PAYBILL_NEW_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.GET_PAYBILL_NEW_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.GET_PAYBILL_NEW_FAILURE:
            return {
                error: action.error

            };
        default:
            return state
    }
}
export function StanbicVerifyAccountReducer(state = {}, action: any) {
    console.log(action, 'actionactionaction');

    switch (action.type) {
        case paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_REQUEST:
            return {
                loading: true
            };
        case paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_SUCCESS:
            return {
                data: action.data
            };
        case paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_FAILURE:
            return {
                error: action.error

            };
        default:
            return state
    }
}
export function create_connectionReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.CREATE_CONNECTION_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.CREATE_CONNECTION_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.CREATE_CONNECTION_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function disable_connectionReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.DISABLE_CONNECTION_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.DISABLE_CONNECTION_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.DISABLE_CONNECTION_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined

            };
        default:
            return state
    }
}
export function Create_budget_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.CREATE_BUDGET_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.CREATE_BUDGET_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.CREATE_BUDGET_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function edit_budget_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.EDIT_BUDGET_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.EDIT_BUDGET_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.EDIT_BUDGET_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function PaymentLinkApiUpdateReducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.PAYMENTLINKUPDATE_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.PAYMENTLINKUPDATE_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.PAYMENTLINKUPDATE_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function AddBankDisbursementReducer_Exapes(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function AddBankUSDReducer(state = {}, action: any) {
    console.log(action, "actionaction456");

    switch (action.type) {
        case paymentJourneyConstants.ADDBANK_EXPERES_USD_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.ADDBANK_EXPERES_USD_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.ADDBANK_EXPERES_USD_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function Create_integration_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.CREATE_INTEGRATION_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.CREATE_INTEGRATION_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.CREATE_INTEGRATION_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function Create_credentials_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.CREATE_CREDENTIALS_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.CREATE_CREDENTIALS_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case paymentJourneyConstants.CREATE_CREDENTIALS_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function Express_Payment_Reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.EXPRESSPAYMENT_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.EXPRESSPAYMENT_SUCCESS:
            console.log(state, action, 'reducer')
            return {
                data: action.otp.data,
                loading: false
            };
        case paymentJourneyConstants.EXPRESSPAYMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function NiobiSignature_Reducer(state = {}, action: any) {
    console.log(action, "actionaction456");

    switch (action.type) {
        case paymentJourneyConstants.NIOBI_SIGNATURE_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.NIOBI_SIGNATURE_SUCCESS:
            console.log(action,action.data,"saFsAFFFFFFFSFf");
            
            return {
                data: action.data.data,
                loading: false
            };
        case paymentJourneyConstants.NIOBI_SIGNATURE_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function getPaymentLinkByShortcode_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.PAYMENTLINKSHORTCODE_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.PAYMENTLINKSHORTCODE_SUCCESS:
            console.log(state, action, 'reducer')
            return {
                data: action.otp.data,
                loading: false
            };
        case paymentJourneyConstants.PAYMENTLINKSHORTCODE_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}

export function paylink_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.PAYLINK_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.PAYLINK_SUCCESS:
            return {
                data: action.otp.data,
                loading: false
            };
        case paymentJourneyConstants.PAYLINK_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function invoicesend_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.INVOICE_SENDTOME_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.INVOICE_SENDTOME_SUCCESS:
            return {
                data: action.otp,
                loading: false
            };
        case paymentJourneyConstants.INVOICE_SENDTOME_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function paymentLinkApiCreate_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.PAYMENT_LINK_API_CREATE_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.PAYMENT_LINK_API_CREATE_SUCCESS:
            return {
                data: action.otp,
                loading: false
            };
        case paymentJourneyConstants.PAYMENT_LINK_API_CREATE_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function invoicesendreceiver_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_SUCCESS:
            return {
                data: action.otp,
                loading: false
            };
        case paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function transpaylink_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.TRANSPAYLINK_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.TRANSPAYLINK_SUCCESS:
            return {
                data: action.otp.data,
                loading: false
            };
        case paymentJourneyConstants.TRANSPAYLINK_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function PaymentLinkApiListreducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.PAYMENTLINKAPILIST_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.PAYMENTLINKAPILIST_SUCCESS:
            return {
                data: action.otp.data,
                loading: false
            };
        case paymentJourneyConstants.PAYMENTLINKAPILIST_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function transpaylinkPDF_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.TRANSPAYLINK_PDF_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.TRANSPAYLINK_PDF_SUCCESS:
            return {
                data: action.otp,
                loading: false
            };
        case paymentJourneyConstants.TRANSPAYLINK_PDF_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export function Bankdetails_reducer(state = {}, action: any) {
    switch (action.type) {
        case paymentJourneyConstants.BANK_DETELS_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case paymentJourneyConstants.BANK_DETELS_SUCCESS:
            return {
                data: action.otp,
                loading: false
            };
        case paymentJourneyConstants.BANK_DETELS_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export const selectPaymentJourneyData = (state: any) => {
    console.log(state,state.NiobiSignature_Reducer,state.NiobiSignature_Reducer.data,"state.NiobiSignature_Reducer.data");
    
    return {
        'GETFEES_SUCCESS': state.getFeesReducer.data,
        'CHACKBALANCE_SUCCESS': state.ChackBalanceReducer.data,
        "CHACKBALANCE_PAYMENT_LINK_SUCCESS":state.ChackBalancepaymentLinkReducer.data,
        'GETSHAREABLEPDFLINK_SUCCESS': state.getShareablePdfLinkReducer.data,
        'GETNEWFEES_SUCCESS': state.getFeesNewReducer.data,
        'GETCOUNTRIES_SUCCESS': state.getCountriesReducer.data,
        'GETCOUNTRIES_V2_SUCCESS': state.getCountriesReducerV2.data,
        'GETCOUNTRIES_BALANCES_SUCCESS': state.getCountriesBalancesReducer.data,
        'GET_PAYBILL_NEW_SUCCESS': state.getPaybillNewReducer.data,
        'STANBIC_VERIFY_ACCOUNT_SUCCESS': state.StanbicVerifyAccountReducer.data,
        'GET_PAYBILL_NEW_FAILURE': state.getPaybillNewReducer.error,
        'INVITE_SUCCESS': state.getInveact.data,
        'SAVE_DATA_SUCCESS': state.getSave_data.data,
        "GET_BULKPAYMENT_SUCCESS": state.getBulk_payment_reducer.data,
        'GETPESABANKLIST_SUCCESS': state.getPesaBankListReducer.data,
        'GETINTEGRATION_SUCCESS': state.getIntegration_Reducer.data,
        'GET_ACCOUNTING_TYPE_SUCCESS': state.get_accounting_types_Reducer.data,
        'GET_CONNECTION_SUCCESS': state.get_connections_Reducer.data,

        'GETDURATIONLIST_SUCCESS': state.getdurationlist_Reducer.data,

        'GETINTEGRATION_APIKEY_SUCCESS': state.getIntegration_Apikey_Reducer.data,

        "DELETE_CREDENTIALS_REQUEST": state.delete_credentials_Reducer.loading,
        'DELETE_CREDENTIALS_SUCCESS': state.delete_credentials_Reducer.data,
        "DELETE_CREDENTIALS_FAILURE": state.delete_credentials_Reducer.error,
        "GETCOUNTRIES_DETAILS_SUCCESS": state.getCountriesDetailsReducer.data,

        "GETCOUNTRIES_DETAILS_FAILURE": state.getCountriesDetailsReducer.error,
        "GETCURRENCIES_SUCCESS": state.getCurrenciesReducer.data,
        "GETCURRENCIES_V2_SUCCESS": state.getCurrenciesV2Reducer.data,
        "GETCOUNTRIES_BY_CURRENCY_SUCCESS": state.getCountriesByCurrencyReducer.data,

        "DELETE_BUDGET_REQUEST": state.delete_budget__Reducer.loading,
        'DELETE_BUDGET_SUCCESS': state.delete_budget__Reducer.data,
        "DELETE_BUDGET_FAILURE": state.delete_budget__Reducer.error,

        "DELETE_PAYMENT_LINK_REQUEST": state.PaymentLinkApiDeleteReducer.loading,
        'DELETE_PAYMENT_LINK_SUCCESS': state.PaymentLinkApiDeleteReducer.data,
        "DELETE_PAYMENT_LINK_FAILURE": state.PaymentLinkApiDeleteReducer.error,
        'CHECK_TRANSACTION_TYPE_SUCCESS': state.checkTransactionTypeReducer.data,
        "GET_BULK_PAYMENTS_STATUS_REQUEST": state.getbulk_payments_status_activeReducer.loading,
        'GET_BULK_PAYMENTS_STATUS_SUCCESS': state.getbulk_payments_status_activeReducer.data,
        "GET_BULK_PAYMENTS_STATUS_FAILURE": state.getbulk_payments_status_activeReducer.error,

        "LIST_BUDGET_REQUEST": state.budget_list_data_Reducer.loading,
        'LIST_BUDGET_SUCCESS': state.budget_list_data_Reducer.data,
        "LIST_BUDGET_FAILURE": state.budget_list_data_Reducer.error,
        'GETVALIDATE_ACCOUNT_FAILURE': state.getValidateAccountReducer.error,
        'GETVALIDATE_ACCOUNT_SUCCESS': state.getValidateAccountReducer.data,
        "GETBUDGET_LIST_REQUEST": state.budget_viwe_list_Reducer.loading,
        'GETBUDGET_LIST_SUCCESS': state.budget_viwe_list_Reducer.data,
        "GETBUDGET_LIST_FAILURE": state.budget_viwe_list_Reducer.error,

        'ADDBANK_DESBURSEMENT_REQUEST': state.AddBankDisbursementReducer.loading,
        'ADDBANK_DESBURSEMENT_SUCCESS': state.AddBankDisbursementReducer.data,
        'ADDBANK_DESBURSEMENT_FAILURE': state.AddBankDisbursementReducer.error,
        'CREATE_CONNECTION_REQUEST': state.create_connectionReducer.loading,
        'CREATE_CONNECTION_SUCCESS': state.create_connectionReducer.data,
        'CREATE_CONNECTION_FAILURE': state.create_connectionReducer.error,
        'DISABLE_CONNECTION_REQUEST': state.disable_connectionReducer.loading,
        'DISABLE_CONNECTION_SUCCESS': state.disable_connectionReducer.data,
        'DISABLE_CONNECTION_FAILURE': state.disable_connectionReducer.error,
        'CREATE_INTEGRATION_REQUEST': state.Create_integration_Reducer.loading,
        'CREATE_INTEGRATION_SUCCESS': state.Create_integration_Reducer.data,
        'CREATE_INTEGRATION_FAILURE': state.Create_integration_Reducer.error,


        'EDIT_BUDGET_REQUEST': state.edit_budget_Reducer.loading,
        'EDIT_BUDGET_SUCCESS': state.edit_budget_Reducer.data,
        'EDIT_BUDGET_FAILURE': state.edit_budget_Reducer.error,

        'PAYMENTLINKUPDATE_REQUEST': state.PaymentLinkApiUpdateReducer.loading,
        'PAYMENTLINKUPDATE_SUCCESS': state.PaymentLinkApiUpdateReducer.data,
        'PAYMENTLINKUPDATE_FAILURE': state.PaymentLinkApiUpdateReducer.error,

        'CREATE_CREDENTIALS_REQUEST': state.Create_credentials_Reducer.loading,
        'CREATE_CREDENTIALS_SUCCESS': state.Create_credentials_Reducer.data,
        'CREATE_CREDENTIALS_FAILURE': state.Create_credentials_Reducer.error,

        'ADDBANK_DESBURSEMENT_EXPERES_REQUEST': state.AddBankDisbursementReducer_Exapes.loading,
        'ADDBANK_DESBURSEMENT_EXPERES_SUCCESS': state.AddBankDisbursementReducer_Exapes.data,
        'ADDBANK_DESBURSEMENT_EXPERES_FAILURE': state.AddBankDisbursementReducer_Exapes.error,
        'ADDBANK_EXPERES_USD_REQUEST': state.AddBankUSDReducer.loading,
        'ADDBANK_EXPERES_USD_SUCCESS': state.AddBankUSDReducer.data,
        'ADDBANK_EXPERES_USD_FAILURE': state.AddBankUSDReducer.error,

        'EXPRESSPAYMENT_REQUEST': state.Express_Payment_Reducer.loading,
        'EXPRESSPAYMENT_SUCCESS': state.Express_Payment_Reducer.data,
        'EXPRESSPAYMENT_FAILURE': state.Express_Payment_Reducer.error,

        'NIOBI_SIGNATURE_REQUEST': state.NiobiSignature_Reducer.loading,
        'NIOBI_SIGNATURE_SUCCESS': state.NiobiSignature_Reducer.data,
        'NIOBI_SIGNATURE_FAILURE': state.NiobiSignature_Reducer.error,

        'PAYMENTLINKSHORTCODE_REQUEST': state.getPaymentLinkByShortcode_reducer.loading,
        'PAYMENTLINKSHORTCODE_SUCCESS': state.getPaymentLinkByShortcode_reducer.data,
        'PAYMENTLINKSHORTCODE_FAILURE': state.getPaymentLinkByShortcode_reducer.error,

        'PAYLINK_REQUEST': state.paylink_reducer.loading,
        'PAYLINK_SUCCESS': state.paylink_reducer.data,
        'PAYLINK_FAILURE': state.paylink_reducer.error,

        'INVOICE_SENDTOME_REQUEST': state.invoicesend_reducer.loading,
        'INVOICE_SENDTOME_SUCCESS': state.invoicesend_reducer.data,
        'INVOICE_SENDTOME_FAILURE': state.invoicesend_reducer.error,

        'PAYMENT_LINK_API_CREATE_REQUEST': state.paymentLinkApiCreate_reducer.loading,
        'PAYMENT_LINK_API_CREATE_SUCCESS': state.paymentLinkApiCreate_reducer.data,
        'PAYMENT_LINK_API_CREATE_FAILURE': state.paymentLinkApiCreate_reducer.error,

        'INVOICE_SENDTO_RECEIVER_REQUEST': state.invoicesendreceiver_reducer.loading,
        'INVOICE_SENDTO_RECEIVER_SUCCESS': state.invoicesendreceiver_reducer.data,
        'INVOICE_SENDTO_RECEIVER_FAILURE': state.invoicesendreceiver_reducer.error,

        'TRANSPAYLINK_REQUEST': state.transpaylink_reducer.loading,
        'TRANSPAYLINK_SUCCESS': state.transpaylink_reducer.data,
        'TRANSPAYLINK_FAILURE': state.transpaylink_reducer.error,

        'PAYMENTLINKAPILIST_REQUEST': state.PaymentLinkApiListreducer.loading,
        'PAYMENTLINKAPILIST_SUCCESS': state.PaymentLinkApiListreducer.data,
        'PAYMENTLINKAPILIST_FAILURE': state.PaymentLinkApiListreducer.error,

        'TRANSPAYLINK_PDF_REQUEST': state.transpaylinkPDF_reducer.loading,
        'TRANSPAYLINK_PDF_SUCCESS': state.transpaylinkPDF_reducer.data,
        'TRANSPAYLINK_PDF_FAILURE': state.transpaylinkPDF_reducer.error,
        'BANK_DETELS_REQUEST': state.Bankdetails_reducer.loading,
        'BANK_DETELS_SUCCESS': state.Bankdetails_reducer.data,
        'BANK_DETELS_FAILURE': state.Bankdetails_reducer.error,
    };
}