import { Box, Grid } from "@mui/material";
import styles from "./Pay.module.scss";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../../../_reducers/paymentJourney.reducer";
interface Props {
  country: any;
  contryCode: any;
  loading:any;
}
const Setp2 = ({ country, contryCode ,loading}: Props) => {
  const [tabdata, setTabdata] = useState("MPesa");
  const [pohon_data, setPhone] = useState<any>();
  const [data_stastes, setStast_data] = useState(false);
  const [startgetapi, setStartgetapi] = useState(true);
  const [render, setRender] = useState(true);
  const [countryList_api, setCountrtList_api] = useState<any>([]);
  const [country_sortname, setCountry_sortname] = useState<any>("");
//   const[loading,setLoading]=useState("")
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const dispatch = useDispatch();
  console.log(contryCode, "contryCodecontryCode");

  const handsubmit = () => {
    //
    // console.log(pohon_data, 'pohon_data');
    // let payloadData = payload;
    // payloadData.mobile= pohon_data;
    // setpayload(payloadData);
    // nextpage()
  };
  useEffect(() => {
    if (!!country) {
      setRender(false);
      dispatch(paymentJourneyActions.getCountriesDetails(country) as any);
      setTimeout(() => {
        setStartgetapi(true);
      }, 4000);
    }
  }, [country]);

  // GETCOUNTRIES_SUCCESS

  useEffect(() => {
    if (startgetapi && dataList?.GETCOUNTRIES_DETAILS_SUCCESS !== undefined) {
      setCountrtList_api(dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS?.data);
      console.log(dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS?.data);

      setTabdata(dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS?.data.id);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  return (
    <>
      <div style={{ height: "90vh" }}>
        {/* <Box className={styles.tab_contean_button}>
                    <Button className={tabdata == "MPesa" ? styles.mpesa_button : styles.difolt_button} onClick={() => { setTabdata('MPesa') }}>
                    <div className={styles.mpsaimg}><img width={'100%'} height={'100%'} src="/Ellipse 107.svg" /></div>
                        M-Pesa</Button>
                    <Button className={tabdata == "Card" ? styles.mpesa_button : styles.difolt_button} disabled onClick={() => { setTabdata('Card') }}>Card Coming soon</Button>
                </Box> */}
        <Box className={styles.tab_contean_button}>
          <Grid container>
            {countryList_api?.map(
              (arrayElement: any, index: number) => {
                return (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={12}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Button
                      className={
                        tabdata == arrayElement.id
                          ? styles.mpesa_button
                          : styles.difolt_button
                      }
                      onClick={() => {
                        setTabdata(arrayElement.country_payment_method_id);
                      }}
                    >
                      <div className={styles.mpsaimg}>
                        <img
                          width={"100%"}
                          height={"100%"}
                          src={arrayElement.logo}
                        />
                      </div>
                      {arrayElement.title}
                    </Button>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Box>
        <div style={{ paddingTop: "20px" }}>
          <p className={styles.heding_data_call_sjjshgss}>
            Use Express Payment
          </p>
          <p style={{ fontWeight: "400" }} className="discrip_the_ahhahas">
            Hello,
          </p>
          <p style={{ fontWeight: "400" }} className="discrip_the_ahhahas">
          You will shortly receive a prompt on your phone to enter your mobile money PIN to complete the payment. Please ensure your phone is on and unlocked to complete the process.
          </p>

          <div className="company-details-container__wrapper">
            {/* <div style={{padding:'20px 0px',textAlign:'center'}} className='company-details-container__heading'>Business Management</div> */}

            <div className="input-form-wrapper">
              <label>Mobile</label>
              <PhoneInput
                // inputProps={{
                //     name: 'phone',
                //     required: true,
                //     autoFocus: true.valueOf,
                //     readOnly: true

                // }}
                disabled
                country={contryCode}
                onlyCountries={[contryCode]}
                localization={{ de: contryCode }}
                value={pohon_data}
                onChange={(value, country, e, formattedValue) => {
                  console.log(value, e, "aahauyaahaha");
                  setPhone(value);
                }}
                inputClass={"inputBx-mobile readonly"}
                // autoFormat={false}
                // disabled
                // onChange={(phone) => setPhone({phone})}
              />
              {data_stastes == true && pohon_data == undefined ? (
                <p className="err_calss_display">Mobile is required</p>
              ) : (
                ""
              )}
              <div className={styles.button_bottomcontean}>
                {!!pohon_data ? (
                  <Button
                    id="fast_go_button"
                    disabled
                    className="balance__save-btn"
                    onClick={() => {
                      // handleClose()
                      handsubmit();
                      setStast_data(true);
                    }}
                  >
                    {/* {loading == true && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )} */}
                    Pay
                  </Button>
                ) : (
                  <Button
                    id="fast_go_button"
                    disabled
                    className="balance__save-btn"
                    onClick={() => {
                      // handleClose()
                      setStast_data(true);
                    }}
                  >
                   /
                    Pay
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Setp2;
