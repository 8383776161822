export const dashboardConstants = {
    GETDASHBOARD_REQUEST: 'GETDASHBOARD_REQUEST',
    GETDASHBOARD_SUCCESS: 'GETDASHBOARD_SUCCESS',
    GETDASHBOARD_FROM_STORE: 'GETDASHBOARD_FROM_STORE',
    GETDASHBOARD_FAILURE: 'GETDASHBOARD_FAILURE',

    GET_DASHBOARD_BALANCE_SUCCESS: 'GET_DASHBOARD_BALANCE_SUCCESS',
    GET_DASHBOARD_BILL_SUCCESS: 'GET_DASHBOARD_BILL_SUCCESS',
    
    GET_DASHBOARD_RECENT_REQUEST: 'GET_DASHBOARD_RECENT_REQUEST',
    GET_DASHBOARD_RECENT_SUCCESS: 'GET_DASHBOARD_RECENT_SUCCESS',
    GET_DASHBOARD_RECENT_FAILURE: "GET_DASHBOARD_RECENT_FAILURE",
    
    GET_DASHBOARD_BUDGETDETAILS_REQUEST: 'GET_DASHBOARD_BUDGETDETAILS_REQUEST',
    GET_DASHBOARD_BUDGETDETAILS_SUCCESS: 'GET_DASHBOARD_BUDGETDETAILS_SUCCESS',
    GET_DASHBOARD_BUDGETDETAILS_FAILURE: "GET_DASHBOARD_BUDGETDETAILS_FAILURE",
   
    GET_PAYABLE_BY_ID_REQUEST: 'GET_PAYABLE_BY_ID_REQUEST',
    GET_PAYABLE_BY_ID_SUCCESS: 'GET_PAYABLE_BY_ID_SUCCESS',
    GET_PAYABLE_BY_ID_FAILURE: "GET_PAYABLE_BY_ID_FAILURE",
// update-unified-payments
    GET_UPDATE_UNIFIED_PAYMENTS_REQUEST: 'GET_UPDATE_UNIFIED_PAYMENTS_REQUEST',
    GET_UPDATE_UNIFIED_PAYMENTS_SUCCESS: 'GET_UPDATE_UNIFIED_PAYMENTS_SUCCESS',
    GET_UPDATE_UNIFIED_PAYMENTS_FAILURE: "GET_UPDATE_UNIFIED_PAYMENTS_FAILURE",

    GET_DASHBOARD_UPDATEDETAILS_REQUEST: 'GET_DASHBOARD_UPDATEDETAILS_REQUEST',
    GET_DASHBOARD_UPDATEDETAILS_SUCCESS: 'GET_DASHBOARD_UPDATEDETAILS_SUCCESS',
    GET_DASHBOARD_UPDATEDETAILS_FAILURE: "GET_DASHBOARD_UPDATEDETAILS_FAILURE",
    
    GET_DASHBOARD_CATEGRORY_EXPENSES_REQUEST: 'GET_DASHBOARD_CATEGRORY_EXPENSES_REQUEST',
    GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS: 'GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS',
    GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE: "GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE",
   
    GET_DASHBOARD_PAYEE_EXPENSES_REQUEST: 'GET_DASHBOARD_PAYEE_EXPENSES_REQUEST',
    GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS: 'GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS',
    GET_DASHBOARD_PAYEE_EXPENSES_FAILURE: "GET_DASHBOARD_PAYEE_EXPENSES_FAILURE",
    
    GET_DASHBOARD_PAYMENT_EXPENSES_REQUEST: 'GET_DASHBOARD_PAYMENT_EXPENSES_REQUEST',
    GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS: 'GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS',
    GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE: "GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE",
    
    DOCUMENT_VERIFICATION_REQUEST: "DOCUMENT_VERIFICATION_REQUEST",
    DOCUMENT_VERIFICATION_SUCCESS: "DOCUMENT_VERIFICATION_SUCCESS",
    DOCUMENT_VERIFICATION_FAILURE: "DOCUMENT_VERIFICATION_FAILURE",
    
    GET_CURRENCY_RATE_REQUEST: 'GET_CURRENCY_RATE_REQUEST',
    GET_CURRENCY_RATE_SUCCESS: 'GET_CURRENCY_RATE_SUCCESS',
    GET_CURRENCY_RATE_FAILURE: 'GET_CURRENCY_RATE_FAILURE',
   
    GET_PAYABLE_TO_APPROVE_REQUEST: 'GET_PAYABLE_TO_APPROVE_REQUEST',
    GET_PAYABLE_TO_APPROVE_SUCCESS: 'GET_PAYABLE_TO_APPROVE_SUCCESS',
    GET_PAYABLE_TO_APPROVE_FAILURE: 'GET_PAYABLE_TO_APPROVE_FAILURE',
    
    GET_PAYABLE_TO_REJECT_REQUEST: 'GET_PAYABLE_TO_REJECT_REQUEST',
    GET_PAYABLE_TO_REJECT_SUCCESS: 'GET_PAYABLE_TO_REJECT_SUCCESS',
    GET_PAYABLE_TO_REJECT_FAILURE: 'GET_PAYABLE_TO_REJECT_FAILURE',
    
    GET_PAYABLE_TO_PENDING_REQUEST: 'GET_PAYABLE_TO_PENDING_REQUEST',
    GET_PAYABLE_TO_PENDING_SUCCESS: 'GET_PAYABLE_TO_PENDING_SUCCESS',
    GET_PAYABLE_TO_PENDING_FAILURE: 'GET_PAYABLE_TO_PENDING_FAILURE',
// get-unified-payment-channels
    GET_UNIFIED_PAYMENT_CHANNELS_REQUEST: 'GET_UNIFIED_PAYMENT_CHANNELS_REQUEST',
    GET_UNIFIED_PAYMENT_CHANNELS_SUCCESS: 'GET_UNIFIED_PAYMENT_CHANNELS_SUCCESS',
    GET_UNIFIED_PAYMENT_CHANNELS_FAILURE: 'GET_UNIFIED_PAYMENT_CHANNELS_FAILURE',

    GET_PAYABLE_TO_UPDATE_REQUEST: 'GET_PAYABLE_TO_UPDATE_REQUEST',
    GET_PAYABLE_TO_UPDATE_SUCCESS: 'GET_PAYABLE_TO_UPDATE_SUCCESS',
    GET_PAYABLE_TO_UPDATE_FAILURE: 'GET_PAYABLE_TO_UPDATE_FAILURE',

    GET_PAYABLE_TO_CANCEL_REQUEST: 'GET_PAYABLE_TO_CANCEL_REQUEST',
    GET_PAYABLE_TO_CANCEL_SUCCESS: 'GET_PAYABLE_TO_CANCEL_SUCCESS',
    GET_PAYABLE_TO_CANCEL_FAILURE: 'GET_PAYABLE_TO_CANCEL_FAILURE',

    GET_PAYABLE_TO_PAY_REQUEST: 'GET_PAYABLE_TO_PAY_REQUEST',
    GET_PAYABLE_TO_PAY_SUCCESS: 'GET_PAYABLE_TO_PAY_SUCCESS',
    GET_PAYABLE_TO_PAY_FAILURE: 'GET_PAYABLE_TO_PAY_FAILURE',

    GET_DASHBOARD_CHART_REQUEST: 'GET_DASHBOARD_CHART_REQUEST',
    GET_DASHBOARD_CHART_SUCCESS: 'GET_DASHBOARD_CHART_SUCCESS',
    GET_DASHBOARD_CHART_FAILURE: 'GET_DASHBOARD_CHART_FAILURE',

    GET_PAYINS_REQUEST:"GET_PAYINS_REQUEST",
    GET_PAYINS_SUCCESS:"GET_PAYINS_SUCCESS",
    GET_PAYINS_FAILURE:"GET_PAYINS_FAILURE",
    
    GET_PAYMENT_METHOD_PAYINS_REQUEST:"GET_PAYMENT_METHOD_PAYINS_REQUEST",
    GET_PAYMENT_METHOD_PAYINS_SUCCESS:"GET_PAYMENT_METHOD_PAYINS_SUCCESS",
    GET_PAYMENT_METHOD_PAYINS_FAILURE:"GET_PAYMENT_METHOD_PAYINS_FAILURE"


}