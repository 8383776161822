

const ApiLink = () => {

    return (
        <>
            <iframe src="https://docs.niobi.co/introduction" width="100%" height="640" allowfullscreen frameborder="0"></iframe>
        </>
    )
}
export default ApiLink;