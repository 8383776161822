import { Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './Balance.scss';
import { InfoIcon } from './Balance';
import { Tooltip } from '@mui/material';

const BalanceCard = ({
  select_amount,
  balance,
  handleClick,
  title,
  subtitle,
  amount,
  count,
  cardTypeselect,
  onClickCard
}: any) => {
  return (
    <div
    onClick={() => {
      if (title == "Unsettled Balance") {
        onClickCard("Unsettled Balance")
      } else {
        onClickCard("Pending Balance")
      }
    }}
      style={{ cursor: 'pointer' }}
      className={cardTypeselect == title ? 'balance__card_TWO' : "balance__card"}
    >
      <div onClick={() => {
    
      }} className={cardTypeselect == title ? 'balance__card_TWO__row' : "balance__card__row"}>
        <span className={cardTypeselect == title ? 'balance__card_TWO__unit' : "balance__card__unit"}>{select_amount}</span>
        <span className={cardTypeselect == title ? 'balance__card_TWO__amount' : "balance__card__amount"}>{amount}</span>
      </div>
      <div
    
        className="d-flex"
        style={{
          justifyContent: 'space-between',
          marginTop: '0px',
          paddingTop: '4px',
        }}
      >
        <div className="left" style={{ fontSize: '13px' }}>
          {title}
        </div>
      </div>
      <div

        className="d-flex"
        style={{
          justifyContent: 'space-between',
          paddingTop: '1px',
        }}
      >
        <div  
        // onClick={(e) => 
        // { handleClick(e, subtitle) }} 
        className="left" style={{ fontSize: '13px' }}>
          {subtitle}

          <Tooltip title={title == "Unsettled Balance"?"Click here to view the successful pay-ins that are yet to be settled.":"Click here to view the pay-outs that are currently being processed and whose status is yet to either be successful or failed."}>
          <Button
            className="button_iconpanding"
            style={
              select_amount === 'KES'
                ? { backgroundColor: '#fff' }
                : { backgroundColor: '#F2F8F7' }
            }
            // onClick={(e) => { handleClick(e, subtitle) }}
          >
            <InfoIcon />
          </Button>
          </Tooltip>
        </div>
        <div onClick={() => {
        if (title == "Unsettled Balance") {
          onClickCard("Unsettled Balance")
        } else {
          onClickCard("Pending Balance")
        }
      }} className="right" style={{ fontSize: '13px' }}>
          {count}
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;
