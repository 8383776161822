import { OrderEnum } from "@team-monite/sdk-api";
import { Dropdown, DropdownMenuItem } from "@team-monite/ui-kit-react";
import { PayablesTable, PayableDetails } from '@team-monite/ui-widgets-react';
import React, { useCallback, useState, useEffect } from "react";
import { Button, Col, Collapse, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
// import { TopupModal } from "../Balance/components/topup-modal/Topup";
import PageHeader from "../PageHeader";
import "./Bill.scss";
import { DocumentForm } from "./components/document/Document";
import { PayDetailsModal } from "./components/PayDetails/PayDetailsModal";
import { useNavigate } from "react-router-dom";
import { order } from "../../../../_reducers/cart.reducer";
import Csvdata from './components/csvfile/csvlist';
import EnhancedTable from "./components/csvfile/addnewtebal";
import { Create_Bill } from "./Create_Bill";
import { dashboardActions } from "../../../../_actions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { entityActions } from "../../../../_actions/entity.actions";
import { getProperties, getUser, isUserAuthenticated } from "../../../../_helpers";
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { payablesActions } from "../../../../_actions/payables.actions";
import { selectAllPayablesListData } from "../../../../_reducers/payables.reducer";
import { TopupModal_two } from './components/popup/Topup_two';
import amplitude from 'amplitude-js';
import Bill_Detail from "./components/billtebal/Bill_Detail";
export class HttpClient {
  static get() {
    return Promise.resolve(`buyPrice,buy_qty,sellPrice,sell_qty,enter,
          3.37,1,3.41,1,0
          3.33,1,3.37,1,0
          3.55,1,3.7,1,1
          3.23,1,3.27,1,0
          3.21,1,3.25,1,0`);
  }
}
export const Bill = () => {
  const [isOpen, setIsOpen] = useState(false);
  var localUser: any = localStorage.getItem("user");
  const properties = getProperties();

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/payment-journey`;
    navigate(path);
  };
  // console.log(props.data, 'props.data');

  const OptionalFields = {
    invoiceDate: true,
    suggestedPaymentDate: false,
    tags: true,
    iban: false,
    bic: false,
  }

  // const monite = new MoniteApp({
  //   apiUrl: "https://api.sandbox.monite.com/v1", // Or 'https://api.monite.com/v1' to use Production
  //   entityId: JSON.parse(localUser).data.hasEntity
  //     ? JSON.parse(localUser).data.entity.monite_id
  //     : "c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1", // Entity whose data you want to access
  //   token:
  //   JSON.parse(localUser).data.monite_access_token, // Access token (of an entity user or a partner)
  // });

  const PAYABLE_ID = "id";
  const datenew = new Date();
  // var datemitadd = (datenew + (30 * 60 * 1000))
  const [searchParams, setSearchParams] = useSearchParams();
  const [isApiData, setIsAPIData] = useState<any>(false);
  const [isPayApiData, setIsPayAPIData] = useState<any>(false);
  const [dateset, setDateset] = useState(new Date)
  const [payableId, setId] = useState<string | undefined>(undefined);
  const [listpopup, setPopup] = useState<any>(false)
  const [payableType, setType] = useState<any | undefined>(
    undefined
  );
  const [cereat_teabal, setCereat_Teabal] = useState(false)
  const [popup_swoa, setPopup_swoa] = useState(false)
  const [dataInCSV, setDataInCSV] = React.useState("");
  const [listcvdata, setUserJSON] = React.useState([])
  const [csvname, setCsvnamedata] = React.useState('')
  console.log(csvname, 'csvnamecsvname');
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  }

  // const theme = createTheme({
  //     status: {
  //         danger: orange[500],
  //     },
  // });

  // const handleCSVUpload = (file) => {
  //     setCsvnamedata(file.name)
  //     Papa.parse(file, {
  //         header: true,
  //         complete: (results) => {
  //             console.log(results.data, 'dfgfgfdg');
  //             // var userJSON = userJSON.find(item => item.buyPrice === '');

  //             // console.log(result,'dfrtfdtftfd');
  //             // element.buyPrice
  //             // if (results.data === '') {
  //             //     break;
  //             //   }
  //             setUserJSON(results.data)
  //             // setListcsvdsts(results.data)
  //         },
  //     });
  // };
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // React.useEffect(() => {
  //     HttpClient.get().then((res) => setDataInCSV(res));
  // }, []);
  const [render, setRender] = useState(true);
  const [payablesList, setPayablesList] = useState<any>();

  // const [payableType, setType] = useState<any | undefined>(
  //   undefined
  // );

  console.log(payablesList, 'payablesList');

  const dispatch = useDispatch();
  const payablesListData = useSelector((state) =>
    selectAllPayablesListData(state)
  );
  console.log(payablesListData, 'payablesListData');

  const changeTime = () => {
    const tokentwo: any = localStorage.getItem('tokentwo')
    console.log(new Date().getTime(), new Date(JSON.parse(tokentwo)).getTime(), new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime())
    if (new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime() >= 600000) {
      console.log("here 30 minutes upper")
      setRender(false)
      dispatch(entityActions.getBilldata() as any)
    } else {
      console.log("here 30 minutes lower")
    }
    setTimeout(() => changeTime(), 630000)
  }

  useEffect(() => {
    var localdata = localStorage.getItem('user')
    console.log(localdata, 'localdata');

    var dateset = new Date()
    const tokentwo: any = localStorage.getItem('tokentwo')
    changeTime()

  }, [render, isUserAuthenticated]);
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  useEffect(() => {
    const popup = searchParams.get('popup');
    const BILLSBULK: any = searchParams.get('BILLSBULK')
    console.log(BILLSBULK, 'BILLSBULK');
    setCsvnamedata(BILLSBULK)

    console.log(popup, 'popup');



    if (popup == '1') {
      // console.log();
      setIsOpen(true)
      // setType(CounterpartType.ORGANIZATION)
    } else if (popup == '1') {

      // setType(CounterpartType.INDIVIDUAL)
    }
  }, [searchParams]);
  const id = searchParams.get(PAYABLE_ID);

  const onRowClick = (id: string) => {
    searchParams.set(PAYABLE_ID, id);
    setSearchParams(searchParams);
  };

  // const counterpart: any = counterpartsListData?.GETUSERCOUNTERPARTS_SUCCESS?.find(
  //   (ele: any) => ele.monite_counterpart_id === id
  // );
  // console.log(counterpart, 'counterpartcounterpart');
  console.log(counterpartsListData, 'counterpartsListData');



  const closeModal = () => {
    searchParams.delete(PAYABLE_ID);
    setSearchParams(searchParams);
  };

  // monite.api.payable
  //   .getList(OrderEnum.DESC) // Returns Promise<Response>
  //   .then((res) => {
  //     console.log(res,'resres');

  //     console.log(`Number of payables: ${res}`);
  //   });

  return (
    <>
      {!!localUser && <div className="bill-container">
        {/* {!isOpen && ( */}
        <div className="bill-container__payables">
          {!!id ? "" :
            <PageHeader
              title="Bills"

              extra={
                JSON.parse(localUser).data.role_name != 'Viewer' ?
                  <>
                    <Dropdown
                      button={

                        <Button color="#025041" className="bill-container__save-btn"
                          onClick={() =>
                            //  setIsOpen(true)
                            setPopup_swoa(true)
                          }
                        >
                          Create New
                        </Button>
                      }
                    >
                      {/* <DropdownMenuItem onClick={() => setIsOpen(true)}>
                    Add Manually
                  </DropdownMenuItem>
                  <DropdownMenuItem disabled={true}  >
                    Upload Document
                  </DropdownMenuItem> */}
                    </Dropdown>
                  </> :
                  <Dropdown
                    button={

                      <Button color="#025041" style={{ cursor: 'not-allowed' }} className="bill-container__save-btn">
                        Create New
                      </Button>
                    }>
                  </Dropdown>
              }
              extratwo={
                JSON.parse(localUser).data.role_name != 'Viewer' ?
                  <Dropdown
                    button={

                      <Button color="#025041" className="bill-container__white-btn" onClick={() => { setPopup(true); setCsvnamedata('') }}>
                        Import Bills
                      </Button>

                    }
                  >
                  </Dropdown> :
                  <Dropdown
                    button={
                      <Button color="#025041" style={{ cursor: 'not-allowed' }} className="bill-container__white-btn" >
                        Import Bills
                      </Button>
                    }
                  >
                  </Dropdown>
              }
              extrathere={
                JSON.parse(localUser).data.role_name == 'Director' || JSON.parse(localUser).data.role_name == 'Approver' || JSON.parse(localUser).data.role_name == 'Admin' ?
                  <Dropdown
                    button={
                      <Button color="#025041"
                        className="bill-container__white-btn"
                        onClick={() => { window.location.href = '/bills-approval' }}
                      >
                        Approvals
                      </Button>
                    }
                  >
                  </Dropdown>
                  :
                  <Dropdown
                    button={
                      <Button color="#025041"
                        style={{ cursor: 'not-allowed' }}
                        className="bill-container__white-btn"

                      >
                        Approvals
                      </Button>
                    }
                  >
                  </Dropdown>
              }

            />}
          {listpopup && (
            <>
              <Csvdata
                show={listpopup}
                onClose={() => {
                  setPopup(false)
                  setCsvnamedata('')
                  // setShowTopup(false);
                  // setShowBalanceGrid(true);
                }}
                onSave={(el: boolean) => {
                  setCsvnamedata('bulkadd')
                  setPopup(false)
                  // setShowTopup(false);
                  // setTopupMethod(el);
                  // setTopup(true);
                  // setShowBalanceGrid(false);
                }} />
            </>)}
          {isOpen ? (
            <>
              {/* <div className="bill-container__content"></div> */}
              {/* <div className=""> */}
              <Collapse in={isOpen} dimension="width">
                <PayDetailsModal
                  show={true}
                  setIsAPIData={setIsAPIData}
                  setIsPayAPIData={setIsPayAPIData}
                  setIsOpen={setIsOpen}
                  isApiData={isApiData}
                  isPayApiData={isPayApiData}
                />
              </Collapse>
              {/* </div> */}
            </>)
            :
            (<>
              {csvname == 'bulkadd' ?

                <div className={'height'}><EnhancedTable /></div>
                : cereat_teabal == true ?
                  <div className={'height'} style={{height:"100%"}}>
                    <Create_Bill onClos={() => { setCereat_Teabal(false); }} />
                  </div>
                  :!!id ? <Bill_Detail id={id} onClose={() => {
                    searchParams.delete(PAYABLE_ID);
                    setSearchParams(searchParams);
                  }} /> : <>
                    <div className="scroll_remove">
                      <div className={'height'} style={{
                        height: "auto"
                      }}>
                        
                        {/* <Create_Bill /> */}
                        <PayablesTable onRowClick={onRowClick} onPay={(id: any) => {
                          if (JSON.parse(localUser).data.role_name == 'Director' || JSON.parse(localUser).data.role_name == 'Accountant' || JSON.parse(localUser).data.role_name == 'Admin') {
                            navigate("/balance?popup=" + id, { replace: true, state: { popup: id } });
                          }
                        }} />


                      </div>
                    </div>
                  </>}
            </>)
          }
{/* 
          {id && <PayableDetails
            id={id}
            optionalFields={OptionalFields}
            onApprove={() => {
              const event = "Bill Approved";
              amplitude.getInstance().logEvent(event, eventProperties);
            }
            }
            onReject={() => {
              const event = "Bill Rejected";
              amplitude.getInstance().logEvent(event, eventProperties);
            }}

            onPay={() => {
              // setItem()
              if (JSON.parse(localUser).data.role_name == 'Director' || JSON.parse(localUser).data.role_name == 'Accountant' || JSON.parse(localUser).data.role_name == 'Admin') {
                navigate("/balance?popup=" + id, { replace: true, state: { popup: id } });
              }
              // window.location.href = '/balance'
            }}
            onClose={closeModal} />}  */}
          {/* </>} */}
        </div>
        {popup_swoa && (
          <TopupModal_two
            show={popup_swoa}
            onClose={() => {
              setPopup_swoa(false);
              // setShowBalanceGrid(true);
            }}
            onSave={(el: boolean) => {
              setPopup_swoa(false);
              setCereat_Teabal(true)
            }}
            onconfiam={(el: boolean) => {

              setPopup_swoa(false);
              setIsOpen(true)
            }}
          />)}
        {/* )} */}

        {/* className={`drawer_container w3-animate-right ${transitionExit ? "exit" : ""}`} */}

      </div>}
    </>
  );
};

const selectTab = (key: any) => { };