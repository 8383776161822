import style from './from_bill.module.scss';
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Button, InputGroup, Nav, Row, Tab, Tabs } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { selectAllCounterpartsListData } from "../../../../../../../../../../_reducers/counterparts.reducer";
import { getProperties, getUser, isUserAuthenticated } from "../../../../../../../../../../_helpers";
import { getBase64, getKESFormate } from "../../../../../../../../../../_helpers/common";
import styles from '../../../../../../Create_Bill.module.scss';
import { counterpartsActions } from "../../../../../../../../../../_actions/counterparts.actions";
import { useDispatch, useSelector } from "react-redux";
import { PayablesRequest } from "../../../../../../../../../../interfaces/interfaces";
import * as Yup from "yup";
import { Select } from "@team-monite/ui-kit-react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage, useField, useFormikContext } from "formik";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { selectAllPayablesListData } from "../../../../../../../../../../_reducers/payables.reducer";
import { settingActions } from "../../../../../../../../../../_actions/setting.actions";
import { settingSelector } from "../../../../../../../../../../_reducers/setting.reducer";
import moment from "moment";
import amplitude from 'amplitude-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { orange } from '@mui/material/colors';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CountryDropdown } from 'react-country-region-selector';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { handleFileUpload } from '../../../../../../../../../utils';

const From_bill = (props: any) => {
    const directorStatic = {
        tax: 0,
        Unit_Price: "0",
        name: "",
        quantity: "0",
        description: "",
        total: 0
    }
    const directorStatic_TWO = {
        tax: 0,
        name: "",
        quantity: 0,
        description: "",
        total: 0
    }
    const [userDetail, setUserDetail] = useState<any>();
    const [userRender, setUserRender] = useState(true);
    const userData = useSelector((state) => settingSelector(state))
    const [counterpartsListRender, setCounterpartsListRender] = useState(true);
    const [counterpartsList, setCounterpartsList] = useState<any>();
    const [invoiceImage, setinvoiceImage] = useState<any>('');
    const [invoicefile, setinvoicefile] = useState('Upload supporting documents for record');
    const [fileSize, setFileSize] = useState<any>('');
    const [newPayee, setNewPayee] = useState(false);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [categoriesError, setCategoriesError] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    const [requestRender, setrequestRender] = useState(false);
    const [responseRender, setresponseRender] = useState(false);
    const [btnType, setBtnType] = useState('submit')
    const [selectedValue, setSelectedValue] = useState([]);
    const [tagsList, setTagsList] = useState<any>([]);
    const [tagsListRender, setTagsListRender] = useState(true);
    const [Reduser, setReduyasar] = useState(true)
    const [render, setRender] = useState(true);
    const [bodyDATA, setBobydata] = useState<any>()
    const [new_select, setNew_select] = useState("Weekly")
    const [select_flow, setSelect_flow] = useState([
        { name: "Weekly" },
        { name: "Monthly" },
        { name: "Yearly" },
    ])
    const [dayname, setDayname] = React.useState([
        { name: "Sunday" },
        { name: "Monday" },
        { name: "Tuesday" },
        { name: "Wednesday" },
        { name: "Thursday" },
        { name: "Friday" },
        { name: "Saturday" }
    ])
    const [date_text, setDate_text] = React.useState([
        { name: "1" },
        { name: "2" },
        { name: "3" },
        { name: "4" },
        { name: "5" },
        { name: "6" },
        { name: "7" },
        { name: "8" },
        { name: "9" },
        { name: "10" },
        { name: "11" },
        { name: "12" },
        { name: "13" },
        { name: "14" },
        { name: "15" },
        { name: "16" },
        { name: "17" },
        { name: "18" },
        { name: "19" },
        { name: "20" },
        { name: "21" },
        { name: "22" },
        { name: "23" },
        { name: "24" },
        { name: "25" },
        { name: "26" },
        { name: "27" },
        { name: "28" },
        { name: "29" },
        { name: "30" },
        { name: "31" },
    ])
    const [month_name, setMonth_name] = useState([
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" }
    ])
    const [directors, setDirectors] = useState([directorStatic]);
    const [directors_two, setDirectors_two] = useState([directorStatic_TWO]);
    const [enddate, setEnddate] = React.useState()
    const [month_name_add, setMonth_name_add] = useState("")
    const [date_number_add, setDate_number_add] = useState("")
    const [counterpartsListRender_two, setCounterpartsListRender_two] = useState(true);
    const [dataadd, setDataAdd] = useState(false)
    const [day_name_add, setDay_name_add] = useState("")
    const [vatall, setVatall] = useState([])
    const [chack_hideadata, setChack_hideadata] = useState(false)

    console.log(selectedValue, "selectedValue");

    const numberMask = createNumberMask({
        prefix: '',
        suffix: '' // This will put the dollar sign at the end, with a space.
    })
    const dispatch = useDispatch()

    let navigate = useNavigate();

    const balancelistData = useSelector((state) =>
        selectAllPayablesListData(state)
    );
    const userD = getUser()?.data ?? null;

    const counterpartsListData = useSelector((state) => selectAllCounterpartsListData(state))

    const dateformet = new Date();
    useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(counterpartsActions.getAllCounterparts() as any)
            dispatch(counterpartsActions.getAllTags() as any)
            dispatch(counterpartsActions.getAllvat() as any)

        }
    }, [render, isUserAuthenticated])
    const handleImage = async (e: any) => {
        if (e.target.files.length > 0) {
            setFileSize(e.target.files[0].size)
            setinvoicefile(e.target.files[0].name);
            // getBase64(e.target.files[0], (result: any) => {
            //     setinvoiceImage(result);
            // });
            const fileBase64 = await handleFileUpload(e)
            setinvoiceImage(fileBase64)
        }
    }
    useEffect(() => {
        if (requestRender && balancelistData?.PAYABLES_REQUEST == true) {
            setLoading(true);
            setrequestRender(false)
            setresponseRender(true);
        }
    }, [balancelistData, requestRender]);
    useEffect(() => {
        if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
            let options: any = [];
            if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
                counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
                    options.push({
                        id: item.id,
                        name: item.name
                    })
                })
            }
            setTagsListRender(false);
            setTagsList(options);
        }
    }, [counterpartsListData, tagsListRender]);
    console.log(balancelistData?.PAYABLES_REQUEST, 'balancelistData?.PAYABLES_REQUEST');
    useEffect(() => {
        if (responseRender && counterpartsListData?.SUBMIT_PAYABLES_REQUEST == false) {
            setLoading(false);
            setresponseRender(false);
        }
    }, [counterpartsListData, responseRender]);
    React.useEffect(() => {
        if (userRender && userData?.GETUSERDETAIL_SUCCESS !== undefined) {
            setUserRender(false);
            setUserDetail(userData?.GETUSERDETAIL_SUCCESS);
        }
    }, [userData, userRender]);
    const changeTohandle = (e: any) => {
        navigate("/Counterparts?popup=" + e.target.value, { replace: true, state: { popup: e.target.value } });
        // if(e.target.value == 0){
        //   setType(CounterpartType.ORGANIZATION)
        // }elsee.target.value
        //   setType(CounterpartType.INDIVIDUAL)
        // }

    }
    React.useEffect(() => {
        if (userRender && userData?.GETUSERDETAIL_SUCCESS !== undefined) {
            setUserRender(false);
            setUserDetail(userData?.GETUSERDETAIL_SUCCESS);
        }
    }, [userData, userRender]);
    useEffect(() => {
        if (counterpartsListRender && counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined) {
            setCounterpartsListRender(false);
            setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
        }
    }, [counterpartsListData, counterpartsListRender]);

    const [submittedData, setSubmittedData] = useState<any>({
        invoice_record: '',
        document_id: '',
        payment_date: '',
        amount: '0',
        supplier_id: '',
        submitted_by: '',
    });
    const [datachange, setDataChange] = useState(false)
    const [Totelamounyt, setTotelamount] = useState(0)
    const [formInitailData, setFormInitailData] = useState<any>({
        payment_date: '',
        due_date: "",
        amount: '0',
        submitted_by: '',
        supplier_id: "",
        new_supplier_id: "",
        invoice_record: "",
        invoice_date: moment(dateformet).format('YYYY-MM-DD'),
        document_id: "",
        description: "",
        categories: []
    });
    const [fild_data, setFild_data] = useState({
        First_name: "",
        Last_name: "",
        Type: "individual",
        Phone_number: "",
        Email: "",
        Street_address: "",
        Country: "KE",

    })
    const validationSchema = Yup.object().shape({
        supplier_id: Yup.string()
            // .min(2, "*Names must have at least 2 characters")
            // .max(100, "*Names can't be longer than 100 characters")
            .required("Please enter the payee's name."),
        document_id: Yup.string().required("Please enter the invoice number."),
        invoice_date: Yup.string().required("Please enter the invoice date."),
        due_date: Yup.string().required("Please enter the due date."),
        amount: Yup.string().required("Please enter the amount."),
        description: Yup.string().max(500, 'Description should be maximum 500 characters'),
        categories: Yup.string().required("Please select the category."),
        invoice_record: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
                "file Size",
                "File size too large, max file size is 1 Mb",
                () => {
                    let valid = true;
                    if (!!fileSize) {
                        if (1024 * 1024 <= fileSize) {
                            valid = false;
                        }
                    }
                    return valid;
                }
            )
        // blog: Yup.string()
        // .url("*Must enter URL in http://www.example.com format")
        // .required("*URL required")
    });
    console.log(formInitailData, "formInitailData");

    useEffect(() => {

        let Totel: number = 0;
        for (let index = 0; index < directors.length; index++) {
            const element = directors[index];
            console.log(element, 'elementelementhhh');
            Totel += (+element.Unit_Price * +element.quantity)

        }
        setTotelamount(Totel)
    }, [directors])
    console.log(datachange, "datachangedatachange");
    useEffect(() => {
        if (counterpartsListRender_two && counterpartsListData?.GETALLVAT_SUCCESS !== undefined) {
            setCounterpartsListRender_two(false);
            setVatall(counterpartsListData?.GETALLVAT_SUCCESS);
        }
    }, [counterpartsListData, counterpartsListRender_two]);
    // /props.data
    console.log(props?.data?.data, formInitailData, "DDFZGDGprops", vatall);

    useEffect(() => {
        if (!!props && Reduser) {
            console.log(props?.data?.data, "props?.data?.data?.tags");
            var selected: any = {}
            if (!!formInitailData?.supplier_id && !!counterpartsList) {
                selected = counterpartsList.find((item: any) => {
                    return item.monite_counterpart_id == formInitailData.supplier_id
                })
            }
            var categories_array: any = []
            var categories_array_name: any = []
            for (let index = 0; index < props?.data?.payable?.categories?.length; index++) {
                const element = props?.data?.payable?.categories[index];

                categories_array.push(element)
                categories_array_name.push(element)
                console.log(element.name, "element.name");


            }
            console.log(props?.data?.data?.tags, categories_array, "categories_array");

            setFormInitailData({
                payment_date: props?.data?.data?.issued_at,
                due_date: moment(props?.data?.data?.due_date).format('YYYY-MM-DD'),
                amount: JSON.stringify(props?.data?.data?.amount / 100),
                submitted_by: '',
                supplier_id: props?.data?.data?.counterpart_id,
                new_supplier_id: selected?.name,
                invoice_record: props?.data?.data?.file,
                invoice_date: moment(props?.data?.data?.issued_at).format('YYYY-MM-DD'),
                document_id: props?.data?.data?.document_id,
                description: props?.data?.data?.description,
                categories: categories_array
            })
            console.log(JSON.stringify(props?.data?.data?.amount / 100), "JSON.stringify(props?.data?.data?.amount / 100)");

            var array: any = []
            var array_two: any = []
            let Totel = 0
            for (let index = 0; index < props?.data?.payable?.line_items?.length; index++) {
                const element = props?.data?.payable?.line_items[index];
                // Totel +=((element.total/100) * (element.quantity))
                //  element.total* element.quantity

                var object = {
                    tax: element.tax == 'Exempt' ? "Exempt" : JSON.stringify(element.tax / 100),
                    Unit_Price: (element.total / 100),
                    name: element.name,
                    quantity: element.quantity,
                    description: element.description,
                    total: element.total * element.quantity

                }
                var object_two = {
                    tax: element.tax == 'Exempt' ? "Exempt" : JSON.stringify(element.tax / 100),
                    name: element.name,
                    quantity: element.quantity,
                    description: element.description,
                    total: element.total
                }
                array_two.push(object_two)
                array.push(object)
            }
            console.log(Totel, "TotelTotel");

            setNew_select(props?.data?.payable?.recurring_job?.type)
            if (props?.data?.payable?.recurring_job?.type == "Weekly") {
                setDay_name_add(props?.data?.payable?.recurring_job?.day)
            } else if (props?.data?.payable?.recurring_job?.type == "Monthly") {
                setDate_number_add(props?.data?.payable?.recurring_job?.every)
            } else if (props?.data?.payable?.recurring_job?.type == "Yearly") {
                setMonth_name_add(props?.data?.payable?.recurring_job?.month)
                setDate_number_add(props?.data?.payable?.recurring_job?.every)
            }
            var enddate: any = moment(props?.data?.payable?.recurring_job?.end_date).format('YYYY-MM-DD')
            setEnddate(enddate)
            setDirectors_two(array_two)
            setDirectors(array)
            // setTotelamount(Totel)
            setSelectedValue(categories_array_name)
            setReduyasar(false)
        }
    }, [props, Reduser])
    const handleAddClick2 = () => {
        // setInputList([...inputList, { firstName: "", lastName: "" }]);
        // setShareholdertwo([...shareholdertwo, shareHolderStatic])

        setDirectors([...directors, directorStatic])
        setDirectors_two([...directors_two, directorStatic_TWO])
        // setShareHoldersDocs([...ShareHolderdoc, directorDocStaticTwo])
        // setGroupTwoName([...groupTwoName, commonStatiTwo])

    };
    const handleRemoveClick = (index: any) => {
        console.log(index);
        // const list = [...inputList];
        // list.splice(index, 1);
        // setInputList(list);
        /* for name */

        const listName = [...directors];
        listName.splice(index, 1);
        const listName_two = [...directors_two];
        listName_two.splice(index, 1);
        setDirectors_two(listName_two)
        setDirectors(listName)
    }
    useEffect(() => {
        const findItem_name = directors.find((data: any) => data.name == '')
        const findQuantity = directors.find((data: any) => data.quantity == '')
        const findUnit_Price = directors.find((data: any) => data.Unit_Price == '')
        const findVat = directors.find((data: any) => data.tax == '')
        var selected: any = {}
        if (!!formInitailData?.supplier_id && !!counterpartsList) {
            selected = counterpartsList.find((item: any) => {
                return item.monite_counterpart_id == formInitailData.supplier_id
            })
        }
        // const payeeName = selected.counterpart_type == 'individual' ? selected.contact_first_name + ' ' + selected.contact_last_name : selected.name
        var array = []
        for (let index = 0; index < formInitailData?.categories?.length; index++) {
            const element: any = formInitailData?.categories[index];
            array.push(element.id)
        }

        var body = {}
        console.log(array, body, formInitailData?.categories, "arrayarrayarray");

        var counterpart = {}
        if (newPayee == true) {
            if (fild_data.Type == "individual") {
                counterpart = {
                    first_name: fild_data.First_name,
                    last_name: fild_data.Last_name,
                    counterpart_type: fild_data.Type,
                    country: !!fild_data.Country ? fild_data.Country : "KE",
                    city: 'City',
                    postal_code: 'Postal Address',
                    line1: !!fild_data.Street_address ? fild_data.Street_address : "123 Main Street",
                    phone: !!fild_data.Phone_number ? fild_data.Phone_number : "254123456789",
                    email: !!fild_data.Email ? fild_data.Email : "hello@niobi.co",
                }
            } else {
                counterpart = {
                    legal_name: fild_data.First_name,
                    counterpart_type: fild_data.Type,
                    country: !!fild_data.Country ? fild_data.Country : "KE",
                    city: 'City',
                    postal_code: 'Postal Address',
                    "line1": !!fild_data.Street_address ? fild_data.Street_address : "123 Main Street",
                    phone: !!fild_data.Phone_number ? fild_data.Phone_number : '254123456789',
                    email: !!fild_data.Email ? fild_data.Email : "hello@niobi.co",
                }
            }
        } else {
            counterpart = {
                counterpart_id: formInitailData.supplier_id,
                counterpart_name: selected?.counterpart_name,
                counterpart_type: fild_data.Type,
            }
        }
        console.log(selected, formInitailData, "selectedselected");

        body = {
            counterpart: counterpart,
            submitted_by: userDetail?.email,
            amount: parseInt(formInitailData.amount) * 100,
            document_id: formInitailData.document_id,
            due_date: formInitailData.due_date,
            invoice_date: formInitailData.invoice_date,
            invoice_record: invoiceImage,
            description: formInitailData.description,
            tags: array,
            newPayee: newPayee,
            line_items: directors_two,
            "type": new_select,
            "day": day_name_add,
            "end_date": enddate,
            every: date_number_add,
            month: month_name_add
        }

        var amountles = props?.data?.data?.amount
        if (props?.data?.data?.counterpart_id != formInitailData.supplier_id ||
            formInitailData.document_id != props?.data?.data?.document_id ||
            amountles != parseInt(formInitailData.amount) * 100 ||
            props?.data?.data?.issued_at != formInitailData.invoice_date ||
            props?.data?.data?.due_date != formInitailData.due_date ||
            formInitailData.description != props?.data?.data?.description ||
            array.length != props?.data?.data?.tags.length
        ) {
            setDataChange(true)
        } else {
            setDataChange(false)
        }

        props.setData(body)

    }, [formInitailData, new_select, day_name_add, date_number_add, month_name_add, enddate, directors, newPayee, fild_data])
    console.log(props, "asdsadfsad");

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <div className="document" id={style.id_document_style} style={{}}>
                <div className="document__wrapper" id={style.div_wrapper_doyoument}>
                    <h1 style={{ paddingLeft: "0px", padding: "15px 0px 0px 0px", margin: "0px" }}>
                        Document details {/* <InfoIcon /> */}
                    </h1>

                    <div className="input-form-wrapper dot">
                        <label>Payee's name</label>
                        {newPayee == true ?
                            <Form.Select name="supplier_id"

                                //onChange={handleSubmit}
                                onChange={(e: any) => {
                                    setFormInitailData({
                                        payment_date: formInitailData.payment_date,
                                        due_date: formInitailData.due_date,
                                        amount: formInitailData.amount,
                                        submitted_by: formInitailData.submitted_by,
                                        supplier_id: e.target.value,
                                        new_supplier_id: formInitailData.new_supplier_id,
                                        invoice_record: formInitailData.invoice_record,
                                        invoice_date: formInitailData.invoice_date,
                                        document_id: formInitailData.document_id,
                                        description: formInitailData.description,
                                        categories: formInitailData.categories
                                    })
                                }}
                                disabled
                                // value={formInitailData.supplier_id}
                                className={'doc-form-input'}
                            >
                                <option selected value="">Select payee</option>
                                {counterpartsList?.map((arrayElement: any, index: number) => {
                                    return (
                                        <option value={arrayElement.monite_counterpart_id} key={index + 1}>{arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name}</option>
                                    )
                                })}
                            </Form.Select> : <Form.Select name="supplier_id"
                                // onClick={() => setNewPayee(false)}
                                //onChange={handleSubmit}
                                onChange={(e: any) => {
                                    setFormInitailData({
                                        payment_date: formInitailData.payment_date,
                                        due_date: formInitailData.due_date,
                                        amount: formInitailData.amount,
                                        submitted_by: formInitailData.submitted_by,
                                        supplier_id: e.target.value,
                                        new_supplier_id: formInitailData.new_supplier_id,
                                        invoice_record: formInitailData.invoice_record,
                                        invoice_date: formInitailData.invoice_date,
                                        document_id: formInitailData.document_id,
                                        description: formInitailData.description,
                                        categories: formInitailData.categories
                                    })
                                }}
                                value={formInitailData.supplier_id}
                                className={'doc-form-input'}
                            >
                                <option selected value="">Select payee</option>
                                {counterpartsList?.map((arrayElement: any, index: number) => {
                                    return (
                                        <option value={arrayElement.monite_counterpart_id} key={index + 1}>{arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name}</option>
                                    )
                                })}
                            </Form.Select>}
                        {!formInitailData.supplier_id ? (
                            <div className="error-message">
                                Please enter the payee's name.
                            </div>
                        ) : null}
                    </div>
                    {/* {newPayee == true ?
                        <Button className='complete-profile__ADDBTNHHA'
                            onClick={() => setNewPayee(false)}
                        >
                            + Add new Payee
                        </Button> :
                        <Button className='complete-profile__ADDBTNHHA'
                            onClick={() => setNewPayee(true)}
                        >
                            + Add new Payee
                        </Button>} */}
                    {newPayee == true ? <>
                        <div className='main_fast_and_last'>
                            <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                                <label>{fild_data.Type == "individual" ? "First name" : "Organization name"}</label>
                                <InputGroup className="doc-input-wrapper">
                                    <Form.Control
                                        className={
                                            'doc-form-input test valid'}
                                        placeholder={fild_data.Type == "individual" ? "First name" : "Organization name"}
                                        value={fild_data.First_name}
                                        name='name'
                                        type="text"
                                        onChange={(e: any) => {
                                            setFild_data({
                                                First_name: e.target.value,
                                                Last_name: fild_data.Last_name,
                                                Type: fild_data.Type,
                                                Email: fild_data.Email,
                                                Street_address: fild_data.Street_address,
                                                Phone_number: fild_data.Phone_number,
                                                Country: fild_data.Country,

                                            })
                                        }}
                                    />
                                </InputGroup>
                                {!fild_data.First_name ? <p className="error-message">Please enter the first name</p> : ""}
                            </div>
                            <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                                <label>Last name</label>
                                <InputGroup className="doc-input-wrapper">
                                    <Form.Control
                                        disabled={fild_data.Type == "individual" ? false : true}
                                        className={

                                            'doc-form-input test valid'}
                                        placeholder="Last name"
                                        value={fild_data.Type == "individual" ? fild_data.Last_name : ""}
                                        name='name'
                                        type="text"
                                        onChange={(e: any) => {
                                            setFild_data({
                                                First_name: fild_data.First_name,
                                                Last_name: e.target.value,
                                                Type: fild_data.Type,
                                                Email: fild_data.Email,
                                                Street_address: fild_data.Street_address,
                                                Phone_number: fild_data.Phone_number,
                                                Country: fild_data.Country,

                                            })
                                        }}
                                    />
                                </InputGroup>
                                {!fild_data.Last_name && fild_data.Type == "individual" ? <p className="error-message">Please enter the last name</p> : ""}
                            </div>
                        </div>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            className='maib_redio_button_contenar'
                            value={fild_data.Type}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFild_data({

                                    Type: (event.target as HTMLInputElement).value,
                                    First_name: fild_data.First_name,
                                    Last_name: fild_data.Last_name,

                                    Email: fild_data.Email,
                                    Street_address: fild_data.Street_address,
                                    Phone_number: fild_data.Phone_number,
                                    Country: fild_data.Country,
                                })
                            }}
                        >
                            <FormControlLabel className='main_redio_buttton' value="individual" control={<Radio icon={<img src="/Checkbox.svg" />}
                                checkedIcon={<img src="/Checkbox_two.svg" />} />} label="Individual" />
                            <FormControlLabel className='main_redio_buttton' value="organization" control={<Radio icon={<img src="/Checkbox.svg" />}
                                checkedIcon={<img src="/Checkbox_two.svg" />} />} label="Organization" />
                        </RadioGroup>
                        <div style={{ paddingBottom: "20px", paddingTop: "10px" }} className="amont_text_p_chackbox">
                            <ThemeProvider theme={theme}>
                                <Checkbox
                                    style={{ padding: "0px 10px 0px 0px" }}
                                    icon={<img src="/Checkbox.svg" />}
                                    checkedIcon={<img src="/Checkbox_two.svg" />}
                                    color="primary"
                                    value={chack_hideadata}
                                    onClick={() => {
                                        if (chack_hideadata == true) {
                                            setChack_hideadata(false)
                                        } else {
                                            setChack_hideadata(true)

                                        }
                                    }}
                                    // indeterminate={selected.length > 0 && selected.length < patternDataArray.length}
                                    // checked={patternDataArray.length > 0 && selected.length === patternDataArray.length}
                                    // onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all desserts',
                                    }}
                                />
                            </ThemeProvider>
                            <p>
                                I have the Beneficiary contact details
                            </p>
                        </div>
                        {chack_hideadata == true ?
                            <>
                                <div className="input-form-wrapper dot">
                                    <label>Email</label>
                                    <InputGroup className="doc-input-wrapper">
                                        <Form.Control
                                            className={

                                                'doc-form-input test valid'}
                                            placeholder="hello@niobi.co"
                                            value={fild_data.Email}
                                            name='name'
                                            type="text"
                                            onChange={(e: any) => {
                                                setFild_data({
                                                    First_name: fild_data.First_name,
                                                    Last_name: fild_data.Last_name,
                                                    Type: fild_data.Type,
                                                    Email: e.target.value,
                                                    Street_address: fild_data.Street_address,
                                                    Phone_number: fild_data.Phone_number,
                                                    Country: fild_data.Country,

                                                })
                                            }}
                                        />
                                    </InputGroup>
                                    {/* {!fild_data.Email && display_datamode == true ? <p className="error-message">Email in the required field</p> : ""} */}
                                </div>
                                <div className="input-form-wrapper dot">
                                    <label>Phone number</label>
                                    <InputGroup className="doc-input-wrapper">
                                        <Form.Control
                                            className={

                                                'doc-form-input test valid'}
                                            placeholder="254123456789"
                                            value={fild_data.Phone_number}
                                            name='name'
                                            type="text"
                                            onChange={(e: any) => {
                                                setFild_data({
                                                    First_name: fild_data.First_name,
                                                    Last_name: fild_data.Last_name,
                                                    Type: fild_data.Type,
                                                    Email: fild_data.Email,
                                                    Phone_number: e.target.value,
                                                    Street_address: fild_data.Street_address,
                                                    Country: fild_data.Country,

                                                })
                                            }}
                                        />
                                    </InputGroup>
                                    {/* {!fild_data.Phone_number && display_datamode == true ? <p className="error-message">Phone number in the required field</p> : ""} */}
                                </div>
                                <div className="input-form-wrapper dot">
                                    <label>Street address</label>
                                    <InputGroup className="doc-input-wrapper">
                                        <Form.Control
                                            className={

                                                'doc-form-input test valid'}
                                            placeholder="123 Main Street"
                                            value={fild_data.Street_address}
                                            name='name'
                                            type="text"
                                            onChange={(e: any) => {
                                                setFild_data({
                                                    First_name: fild_data.First_name,
                                                    Last_name: fild_data.Last_name,
                                                    Type: fild_data.Type,
                                                    Email: fild_data.Email,
                                                    Phone_number: fild_data.Phone_number,
                                                    Street_address: e.target.value,
                                                    Country: fild_data.Country,
                                                })
                                            }}
                                        />
                                    </InputGroup>
                                    {/* {!fild_data.Street_address && display_datamode == true ? <p className="error-message">Street address in the required field</p> : ""} */}
                                </div>


                                <div className="input-form-wrapper dot">
                                    <label>Country</label>
                                    <CountryDropdown
                                        value={fild_data.Country}
                                        valueType="short"
                                        // whitelist={{ CountryCode: [] }}
                                        onChange={(e: any, val) => {
                                            setFild_data({
                                                First_name: fild_data.First_name,
                                                Last_name: fild_data.Last_name,
                                                Type: fild_data.Type,
                                                Email: fild_data.Email,
                                                Phone_number: fild_data.Phone_number,
                                                Street_address: fild_data.Street_address,
                                                Country: e,

                                            })
                                        }}

                                        name="country"
                                        classes={'doc-form-input test valid'
                                        }
                                    />
                                    {/* {!fild_data.Country && display_datamode == true ? <p className="error-message">Country in the required field</p> : ""} */}
                                </div></> : ""}
                    </> : ""}
                    {props?.data?.payable?.recurring_job != null ?
                        <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)">
                            <label className='complete-profile__upload mb-0'>
                                <div id='afafafsdaFfsf' className='complete-profile__upload__label'
                                // style={{ cursor: 'not-allowed' }}
                                >
                                    <span className='complete-profile__upload__txt'>{invoicefile}</span>
                                    <UploadIcon />
                                </div>
                                <input type="file" name='invoice_record'
                                    // disabled
                                    onChange={handleImage}
                                // value={invoicefile} 
                                />
                                {!invoicefile ? (
                                    <div className="error-message">File size too large, max file size is 1 Mb</div>
                                ) : null}
                            </label>
                        </Tooltip> : ""}
                    <div className="input-form-wrapper dot">
                        <label>Invoice number</label>
                        <InputGroup className="doc-input-wrapper">
                            <Form.Control
                                className={'doc-form-input test valid'}
                                placeholder="Invoice number"
                                value={formInitailData.document_id}
                                name='document_id'
                                type="text"
                                onChange={(e: any) => {
                                    setFormInitailData({
                                        payment_date: formInitailData.payment_date,
                                        due_date: formInitailData.due_date,
                                        amount: formInitailData.amount,
                                        submitted_by: formInitailData.submitted_by,
                                        supplier_id: formInitailData.supplier_id,
                                        new_supplier_id: formInitailData.new_supplier_id,
                                        invoice_record: formInitailData.invoice_record,
                                        invoice_date: formInitailData.invoice_date,
                                        document_id: e.target.value,
                                        description: formInitailData.description,
                                        categories: formInitailData.categories
                                    })
                                }}
                            // onBlur={handleBlur}
                            // value="24.06.22"

                            />
                        </InputGroup>
                        {!formInitailData.document_id ? (
                            <div className="error-message">Please enter the invoice number.</div>
                        ) : null}
                    </div>
                    <div className={style.dateContean}>
                        <div className="input-form-wrapper dot" style={{ width: "48%", margin: "10px 0px" }}>
                            <label>Invoice date</label>
                            <input type='date'
                                className="doc-date doc-form-input form-control"
                                value={formInitailData.invoice_date}
                                name='invoice_date'
                                onChange={(e: any) => {
                                    setFormInitailData({
                                        payment_date: formInitailData.payment_date,
                                        due_date: formInitailData.due_date,
                                        amount: formInitailData.amount,
                                        submitted_by: formInitailData.submitted_by,
                                        supplier_id: formInitailData.supplier_id,
                                        new_supplier_id: formInitailData.new_supplier_id,
                                        invoice_record: formInitailData.invoice_record,
                                        invoice_date: e.target.value,
                                        document_id: formInitailData.document_id,
                                        description: formInitailData.description,
                                        categories: formInitailData.categories
                                    })
                                }}
                            // onBlur={handleBlur}
                            />
                            {!formInitailData?.invoice_date ? (
                                <div className="error-message">Please enter the invoice date.</div>
                            ) : null}
                        </div>
                        <div className="input-form-wrapper dot " style={{ width: "48%", margin: "10px 0px" }}>
                            <label>Due date</label>
                            <input type="date"
                                className="doc-date doc-form-input form-control"
                                value={formInitailData.due_date}
                                name='due_date'
                                onChange={(e: any) => {
                                    setFormInitailData({
                                        payment_date: formInitailData.payment_date,
                                        due_date: e.target.value,
                                        amount: formInitailData.amount,
                                        submitted_by: formInitailData.submitted_by,
                                        supplier_id: formInitailData.supplier_id,
                                        new_supplier_id: formInitailData.new_supplier_id,
                                        invoice_record: formInitailData.invoice_record,
                                        invoice_date: formInitailData.invoice_date,
                                        document_id: formInitailData.document_id,
                                        description: formInitailData.description,
                                        categories: formInitailData.categories
                                    })
                                }}
                            // onBlur={handleBlur}
                            />
                            {!formInitailData.due_date ? (
                                <div className="error-message">Please enter the due date.</div>
                            ) : null}
                        </div>
                    </div>
                    {props?.data?.payable?.line_items?.length == 0 ?
                        <div className="input-form-wrapper dot" style={{ padding: 0 }}>
                            <label>Amount</label>
                            <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>
                                <MaskedInput
                                    mask={numberMask}
                                    style={{ height: '100%' }}
                                    disabled={props?.data?.payable?.recurring_job == null ? false : true}
                                    className="doc-form-input form-control"
                                    placeholder="Total"
                                    value={(formInitailData?.amount)}
                                    name='amount'
                                    onChange={(e: any) => {
                                        const kesValue = getKESFormate(e.target.value);
                                        const obj = e;

                                        console.log(e, kesValue, 'kesValue');
                                        setFormInitailData({
                                            payment_date: formInitailData.payment_date,
                                            due_date: formInitailData.due_date,
                                            amount: e.target.value,
                                            submitted_by: formInitailData.submitted_by,
                                            supplier_id: formInitailData.supplier_id,
                                            new_supplier_id: formInitailData.new_supplier_id,
                                            invoice_record: formInitailData.invoice_record,
                                            invoice_date: formInitailData.invoice_date,
                                            document_id: formInitailData.document_id,
                                            description: formInitailData.description,
                                            categories: formInitailData.categories
                                        })
                                    }}
                                    // onBlur={handleBlur}
                                    // value="5593.00"
                                    aria-describedby="basic-addon3"

                                />
                                <InputGroup.Text className="input-mask" id="basic-addon3">
                                    KES
                                </InputGroup.Text>
                            </InputGroup>
                            {!formInitailData?.amount ? (
                                <div className="error-message">Please enter the amount.</div>
                            ) : null}
                        </div> : ""}
                    <div className="dot">
                        <label className="Category_label">Category</label>
                        <ThemeProvider theme={theme}>
                            <Autocomplete
                                sx={{
                                    "& .MuiInputBase-root": {
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#FFFFFF',
                                        border: '1px solid #DDDDDD',
                                        outline: 'none',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        color: '#111111',
                                        borderRadius: '8px',
                                        padding: '6px 9px'
                                    }
                                }}
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={tagsList}
                                value={formInitailData?.categories}
                                getOptionLabel={(option: any) => option.name}
                                //   defaultValue={selectedValue}
                                onChange={(e: any, newValue: any) => {
                                    console.log(e.target.value, newValue, "eee123");

                                    // setCategoriesError(false)
                                    setFormInitailData({
                                        payment_date: formInitailData.payment_date,
                                        due_date: formInitailData.due_date,
                                        amount: formInitailData.amount,
                                        submitted_by: formInitailData.submitted_by,
                                        supplier_id: formInitailData.supplier_id,
                                        new_supplier_id: formInitailData.new_supplier_id,
                                        invoice_record: formInitailData.invoice_record,
                                        invoice_date: formInitailData.invoice_date,
                                        document_id: formInitailData.document_id,
                                        description: formInitailData.description,
                                        categories: newValue
                                    })
                                    // setSelectedList(newValue)
                                }}
                                renderInput={(params: any) => (
                                    <TextField {...params} />
                                )}
                            // sx={{ width: '500px' }}
                            />
                        </ThemeProvider>
                        {/* <Multiselect

                            options={tagsList} // Options to display in the dropdown
                            selectedValues={selectedValue} // Preselected value to persist in dropdown
                            onSelect={(selectedList: any) => {
                                // values.categories = 'values'
                                // errors.categories = undefined
                                setCategoriesError(false)
                                setSelectedList(selectedList)

                                setFormInitailData({
                                    payment_date: formInitailData.payment_date,
                                    due_date: formInitailData.due_date,
                                    amount: formInitailData.amount,
                                    submitted_by: formInitailData.submitted_by,
                                    supplier_id: formInitailData.supplier_id,
                                    new_supplier_id: formInitailData.new_supplier_id,
                                    invoice_record: formInitailData.invoice_record,
                                    invoice_date: formInitailData.invoice_date,
                                    document_id: formInitailData.document_id,
                                    description: formInitailData.description,
                                    categories: selectedList
                                })

                            }} // Function will trigger on select event
                            onRemove={(selectedList: any) => {
                                if (selectedList.length == 0) {
                                    // values.categories = ''
                                    setCategoriesError(true)
                                    setFormInitailData({
                                        payment_date: formInitailData.payment_date,
                                        due_date: formInitailData.due_date,
                                        amount: formInitailData.amount,
                                        submitted_by: formInitailData.submitted_by,
                                        supplier_id: formInitailData.supplier_id,
                                        new_supplier_id: formInitailData.new_supplier_id,
                                        invoice_record: formInitailData.invoice_record,
                                        invoice_date: formInitailData.invoice_date,
                                        document_id: formInitailData.document_id,
                                        description: formInitailData.description,
                                        categories: selectedList
                                    })
                                }
                            }}
                            displayValue="name" // Property name to display in the dropdown options
                            closeOnSelect={true}
                        /> */}
                        {formInitailData?.categories?.length == 0 ? (
                            <div className="error-message">Please select a category</div>
                        ) : null}
                    </div>
                    {props?.data?.payable?.line_items?.length == 0 ?
                        <div className="input-form-wrapper">
                            <label>Description</label>
                            <InputGroup className="doc-input-wrapper">
                                <Form.Control
                                    className={'doc-form-input test valid'}
                                    placeholder="Description"
                                    value={formInitailData.description}
                                    name='description'
                                    type="text"
                                    onChange={(e: any) => {
                                        setFormInitailData({
                                            payment_date: formInitailData.payment_date,
                                            due_date: formInitailData.due_date,
                                            amount: formInitailData.amount,
                                            submitted_by: formInitailData.submitted_by,
                                            supplier_id: formInitailData.supplier_id,
                                            new_supplier_id: formInitailData.new_supplier_id,
                                            invoice_record: formInitailData.invoice_record,
                                            invoice_date: formInitailData.invoice_date,
                                            document_id: formInitailData.document_id,
                                            description: e.target.value,
                                            categories: formInitailData.categories
                                        })
                                    }}

                                />
                            </InputGroup>

                        </div> : ""}
                    <div className="input-form-wrapper">
                        <label>Submitted by</label>
                        <InputGroup className="doc-input-wrapper input">{userDetail?.name}</InputGroup>
                    </div>
                </div>
                {props?.data?.payable?.recurring_job == null ? "" :
                    <div className={style.main_div_RecurringIntervals}>

                        <div className={style.manin_loop_collam}>
                            <label className={style.Recurringlabel}>Recurring Intervals</label>
                            <div className={style.displya_data_fastdiv}>
                                <div style={{ width: "70%", paddingTop: "4px" }} className={style.main_contean_data_inthis}>
                                    <div style={{ width: "31%" }}>
                                        <label></label>
                                        <Form.Select
                                            style={{ fontSize: '12px' }}
                                            name="select_flow"
                                            onChange={(e) => {
                                                // handleChange(e)
                                                setNew_select(e.target.value)
                                            }}
                                            // onBlur={handleBlur}
                                            value={new_select}
                                            className={'doc-form-input'}
                                        >
                                            <option selected value="">Select Monthly</option>
                                            {select_flow?.map((arrayElement: any, index: number) => {
                                                return (
                                                    <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        {!new_select ? (
                                            <div className="error-message">Please select the frequency interval.</div>
                                        ) : <div className="error-message"></div>}


                                    </div>
                                    <p className={style.main_div_no_text}>on</p>
                                    {new_select == 'Weekly' ?
                                        <div style={{ width: "60%" }}>
                                            <label></label>

                                            <Form.Select
                                                style={{ fontSize: '12px' }}
                                                onChange={(e) => {
                                                    setDay_name_add(e.target.value)
                                                }}
                                                // onBlur={handleBlur}
                                                value={day_name_add}
                                                className={'doc-form-input'}
                                            >
                                                <option selected value="">Day</option>
                                                {dayname?.map((arrayElement: any, index: number) => {
                                                    return (
                                                        <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                            {!day_name_add ? (
                                                <div className="error-message">Please select a day.</div>
                                            ) : <div className="error-message"></div>}

                                        </div> : ""}
                                    {new_select == 'Monthly' ?
                                        <div style={{ width: "60%" }}>
                                            <label></label>

                                            <Form.Select
                                                style={{ fontSize: '12px' }}
                                                onChange={(e) => {
                                                    setDate_number_add(e.target.value)
                                                }}
                                                // onBlur={handleBlur}
                                                value={date_number_add}
                                                className={'doc-form-input'}
                                            >
                                                <option selected value="">Select Date</option>
                                                {date_text?.map((arrayElement: any, index: number) => {
                                                    return (
                                                        <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {!date_number_add ? (
                                                <div className="error-message">Date in the required field</div>
                                            ) : <div className="error-message"></div>}
                                        </div> : ""}
                                    {new_select == 'Yearly' ?
                                        <>
                                            <div style={{ width: "31%" }}>
                                                <label></label>
                                                <Form.Select
                                                    style={{ fontSize: '12px' }}
                                                    name="month"
                                                    onChange={(e: any) => {
                                                        setMonth_name_add(e.target.value)
                                                    }}
                                                    // onBlur={handleBlur}
                                                    value={month_name_add}
                                                    className={'doc-form-input'}
                                                >
                                                    <option selected value="">Monthly</option>
                                                    {month_name?.map((arrayElement: any, index: number) => {
                                                        return (
                                                            <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {!month_name_add ? (
                                                    <div className="error-message">Month in the required field </div>
                                                ) : null}


                                            </div>
                                            <div style={{ paddingLeft: "5px", width: "31%" }}>
                                                <label></label>

                                                <Form.Select
                                                    style={{ fontSize: '12px' }}
                                                    name="numbar_date"
                                                    onChange={(e) => {
                                                        setDate_number_add(e.target.value)
                                                    }}
                                                    // onBlur={handleBlur}
                                                    value={date_number_add}
                                                    className={'doc-form-input'}
                                                >
                                                    <option selected value="">Select Date</option>
                                                    {date_text?.map((arrayElement: any, index: number) => {
                                                        return (
                                                            <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {!date_number_add ? (
                                                    <div className="error-message">Date in the required field</div>
                                                ) : null}
                                            </div>
                                        </> : ""}
                                </div>
                                <div style={{ width: "30%" }} className={style.main_contean_data_inthis}>
                                    <div style={{ margin: "0px 0px", width: "100%" }} className="input-form-wrapper">
                                        <label>End date</label>
                                        <input type="date"
                                            className="doc-date doc-form-input form-control"
                                            // onChange={setInvoiceDate}
                                            value={enddate}
                                            // format="yyyy-MM-dd"
                                            name='end_date'
                                            onChange={(e: any) => {
                                                setEnddate(e.target.value)
                                            }}
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        />
                                        {!enddate ? (
                                            <div className="error-message">Please select an end date.</div>
                                        ) : <div className="error-message"></div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>}
                {props?.data?.payable?.line_items == null || props?.data?.payable?.line_items?.length == 0 ? "" :
                    <div className={style.main_div_classss}>
                        <div className={style.line_items_main_div}>
                            <label className={style.heading_label}>Line Item</label>
                            <div style={{ paddingTop: "10px" }}>
                                {directors.map((example, i: any) => {
                                    console.log(example, 'exampleexampleexample')
                                    return (
                                        <>
                                            <div className={styles.main_contenar_lineeee} style={{ display: "flex", justifyContent: "end", alignItems: "center", paddingTop: "15px" }}>
                                                {i > 0 ? <Button style={{ padding: "0px" }} className='complete-profile__removebtn' onClick={() => { handleRemoveClick(i) }}>Remove</Button> : ''}
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }} className={styles.manin_loop_collam}>
                                                <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "32%" }} className="input-form-wrapper">
                                                    <label>Item name</label>
                                                    <InputGroup className="doc-input-wrapper">
                                                        <Form.Control
                                                            className={'doc-form-input test valid'}
                                                            placeholder="Item name"
                                                            value={example.name}
                                                            //onChange={handleSubmit}
                                                            name='Item_name'
                                                            type="text"
                                                            onChange={(e: any) => {
                                                                console.log(e.target.value, 'eeeeee');
                                                                const arrrr: any = []
                                                                const newarr: any = []
                                                                for (let index = 0; index < directors.length; index++) {
                                                                    const element = directors[index];

                                                                    if (i == index) {
                                                                        element.name = e.target.value
                                                                        arrrr.push(element)
                                                                    } else {
                                                                        arrrr.push(element)
                                                                    }
                                                                }
                                                                for (let index = 0; index < directors_two.length; index++) {
                                                                    const element = directors_two[index];

                                                                    if (i == index) {
                                                                        element.name = e.target.value
                                                                        newarr.push(element)
                                                                    } else {
                                                                        newarr.push(element)
                                                                    }
                                                                }
                                                                setDirectors_two(newarr)
                                                                setDirectors(arrrr)

                                                            }}
                                                        />
                                                    </InputGroup>
                                                    {!example.name && dataadd == true ?
                                                        <div className="error-message">Please enter an item name.</div>
                                                        : null}
                                                </div>
                                                <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "32%", paddingLeft: "5px" }} className="input-form-wrapper">
                                                    <label>Unit Price</label>
                                                    <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>
                                                        <MaskedInput
                                                            mask={numberMask}
                                                            style={{ height: '100%' }}
                                                            className="doc-form-input form-control"
                                                            placeholder="Unit Price"
                                                            value={(example.Unit_Price)}
                                                            //onChange={handleSubmit}
                                                            name='Unit_Price'
                                                            onChange={(e: any) => {
                                                                const kesValue = getKESFormate(e.target.value);

                                                                const arrrr: any = []
                                                                const newarr: any = []
                                                                let amount_data: any = 0;
                                                                for (let index = 0; index < directors.length; index++) {
                                                                    const element = directors[index];
                                                                    // const newdata = directors;

                                                                    if (i == index) {
                                                                        element.Unit_Price = e.target.value.replaceAll(',', '')
                                                                        element.total = (+e.target.value.replaceAll(',', '') * +example.quantity)
                                                                        // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                                                        arrrr.push(element)
                                                                    } else {
                                                                        arrrr.push(element)
                                                                    }
                                                                }
                                                                for (let index = 0; index < directors_two.length; index++) {
                                                                    const element = directors_two[index];

                                                                    if (i == index) {
                                                                        // element.Unit_Price = e.target.value.replaceAll(',', '')*100
                                                                        element.total = (+e.target.value.replaceAll(',', '')) * 100
                                                                        newarr.push(element)
                                                                    } else {
                                                                        newarr.push(element)
                                                                    }
                                                                }
                                                                setDirectors_two(newarr)
                                                                // setDataTotal(amount_data)
                                                                // setDirectors_two(arrrr)
                                                                setDirectors(arrrr)
                                                            }}

                                                            aria-describedby="basic-addon3"
                                                        />
                                                        <InputGroup.Text className="input-mask" id="basic-addon3">
                                                            KES
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    {!example.Unit_Price && dataadd == true ? (
                                                        <div className="error-message">Please select the unit price</div>
                                                    ) : null}
                                                </div>
                                                <div id={styles.main_input_datatatata} style={{ width: "32%", margin: "0px 0px", paddingLeft: "5px" }} className="input-form-wrapper">
                                                    <label>VAT</label>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Form.Select name="Vat"

                                                            onChange={(e: any) => {
                                                                const arrrr: any = []
                                                                const newarr: any = []
                                                                for (let index = 0; index < directors.length; index++) {
                                                                    const element = directors[index];
                                                                    if (i == index) {
                                                                        element.tax = e.target.value

                                                                        arrrr.push(element)
                                                                    } else {
                                                                        arrrr.push(element)
                                                                    }
                                                                    // const newdata = directors;
                                                                    console.log(element, 'elementelement');


                                                                }
                                                                for (let index = 0; index < directors_two.length; index++) {
                                                                    const element = directors_two[index];

                                                                    if (i == index) {
                                                                        element.tax = e.target.value
                                                                        newarr.push(element)
                                                                    } else {
                                                                        newarr.push(element)
                                                                    }
                                                                }
                                                                setDirectors_two(newarr)
                                                                setDirectors(arrrr)
                                                            }}
                                                            value={example.tax}
                                                            className={'doc-form-input'}
                                                        >
                                                            <option selected value="">VAT %</option>
                                                            {vatall?.map((arrayElement: any, index: number) => {
                                                                return (
                                                                    <option value={arrayElement.vat} key={index + 1}>{arrayElement.vat} {arrayElement.vat == "Exempt" ? "" : "%"}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </div>
                                                    {!example.tax && dataadd == true ? (
                                                        <div className="error-message">VAT % in the required field</div>
                                                    ) : null}

                                                </div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }} className={styles.manin_loop_collam}>
                                                <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "32%", paddingLeft: "5px" }} className="input-form-wrapper">
                                                    <label>Quantity </label>
                                                    <InputGroup className="doc-input-wrapper">
                                                        <Form.Control
                                                            className={'doc-form-input test valid'}
                                                            placeholder="Quantity"
                                                            value={example.quantity}
                                                            type="text"
                                                            onChange={(e: any) => {
                                                                const arrrr: any = []
                                                                const newarr: any = []
                                                                let amount_data: any = 0;
                                                                for (let index = 0; index < directors.length; index++) {
                                                                    const element = directors[index];

                                                                    if (i == index) {
                                                                        element.quantity = e.target.value
                                                                        element.total = +(+example.Unit_Price * +e.target.value)

                                                                        console.log(element.total, 'element.total');
                                                                        // amount_data += +(+example.Unit_Price * +e.target.value).toString()
                                                                        // element.amount_total=amount_data
                                                                        arrrr.push(element)
                                                                    } else {
                                                                        arrrr.push(element)
                                                                    }

                                                                }
                                                                for (let index = 0; index < directors_two.length; index++) {
                                                                    const element = directors_two[index];

                                                                    if (i == index) {
                                                                        element.quantity = +e.target.value
                                                                        // element.total =  +(+example.Unit_Price) * 100
                                                                        newarr.push(element)
                                                                    } else {
                                                                        newarr.push(element)
                                                                    }
                                                                }
                                                                setDirectors_two(newarr)
                                                                setDirectors(arrrr)
                                                            }}
                                                        />
                                                    </InputGroup>
                                                    {!example.quantity && dataadd == true ? (
                                                        <div className="error-message">Quantity in the required field</div>
                                                    ) : null}
                                                </div>
                                                <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "32%", paddingLeft: "5px" }} className="input-form-wrapper">
                                                    <label>Description</label>
                                                    <InputGroup className="doc-input-wrapper">
                                                        <Form.Control
                                                            className={'doc-form-input test valid'}
                                                            placeholder="Description"
                                                            value={example.description}
                                                            type="text"
                                                            onChange={(e: any) => {

                                                                const arrrr: any = []
                                                                const newarr: any = []

                                                                for (let index = 0; index < directors.length; index++) {
                                                                    const element = directors[index];
                                                                    if (i == index) {
                                                                        element.description = e.target.value
                                                                        arrrr.push(element)
                                                                    } else {
                                                                        arrrr.push(element)
                                                                    }
                                                                }
                                                                for (let index = 0; index < directors_two.length; index++) {
                                                                    const element = directors_two[index];

                                                                    if (i == index) {
                                                                        element.description = e.target.value
                                                                        newarr.push(element)
                                                                    } else {
                                                                        newarr.push(element)
                                                                    }
                                                                }
                                                                setDirectors_two(newarr)

                                                                setDirectors(arrrr)
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </div>
                                                <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "32%", paddingLeft: "5px" }} className="input-form-wrapper">
                                                    <label>Amount</label>
                                                    <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>

                                                        <MaskedInput
                                                            mask={numberMask}
                                                            style={{ height: '100%' }}
                                                            className="doc-form-input form-control"
                                                            disabled
                                                            placeholder="Amount"
                                                            value={((+example.Unit_Price) * (+example.quantity))}
                                                            onChange={(e: any) => {

                                                            }}

                                                            aria-describedby="basic-addon3"

                                                        />
                                                        <InputGroup.Text className="input-mask" id="basic-addon3">
                                                            KES
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    {!example.total && dataadd == true ? (
                                                        <div className="error-message">Amount in the required field</div>
                                                    ) : null}
                                                </div>
                                            </div>


                                            {directors.length - 1 === i && <Button className='complete-profile__ADDBTNHHA' onClick={handleAddClick2}>+ Add Line item</Button>}

                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>}
                <div className={style.main_div_classss}>
                    <div className={style.line_items_main_div}>
                        <label className={style.heading_label}>Total</label>
                        <div style={{ paddingTop: "10px" }}>
                            <div id={styles.main_input_datatatata} style={{ margin: "10px 0px" }} className="input-form-wrapper">
                                <label></label>
                                <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>

                                    <MaskedInput
                                        mask={numberMask}
                                        style={{ height: '100%' }}
                                        className="doc-form-input form-control"
                                        disabled
                                        placeholder="Amount"
                                        value={props?.data?.payable?.line_items?.length == 0 ? formInitailData?.amount : Totelamounyt}
                                        onChange={(e: any) => {

                                        }}

                                        aria-describedby="basic-addon3"

                                    />
                                    <InputGroup.Text className="input-mask" id="basic-addon3">
                                        KES
                                    </InputGroup.Text>
                                </InputGroup>

                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default From_bill;
const AddICon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
            fill="#025041"
        />
    </svg>
);
const UploadIcon = () => (
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.67773 14V16C1.67773 16.5304 1.88845 17.0391 2.26352 17.4142C2.63859 17.7893 3.1473 18 3.67773 18H15.6777C16.2082 18 16.7169 17.7893 17.0919 17.4142C17.467 17.0391 17.6777 16.5304 17.6777 16V14M4.67773 6L9.67773 1L14.6777 6M9.67773 1V13"
            stroke="#B8B8B8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);