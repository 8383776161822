import { getUser, authHeader, handleResponse } from '../_helpers';
// import { getUser, isUserAuthenticated, authHeader } from "../../../../_helpers";
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL

const user = getUser()?.data ?? null;
export const dashboardService = {
  getBalance,
  getBills,
  getChartData,
  documentverification,
  getBalance_currency_rate,
  getRecent,
  getBudgetdetails,
  getUpdatedetails,
  getCategoryExpenses,
  getPayeeExpenses,
  getPaymentExpenses,
  getBilldetels,
  getPayableToApprove,
  getPayableToReject,
  getPayableToPending,
  getPayableToCancel,
  getPayableToUpdate,
  getPayableToPay,
  getPayins,
  getPaymentMethodPayins,
  getunifiedPaymentChannels,
  UpdateUnifiedPayments
};
function getBalance(Link) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}${Link}`, requestOptions).then(
    handleResponse
  );
}
function getunifiedPaymentChannels(Link) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-unified-payment-channels/${Link}`, requestOptions).then(
    handleResponse
  );
}
function getBalance_currency_rate(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/get-current-currency-rate?currency=${data}`,
    requestOptions
  ).then(handleResponse);
}
// get dashboard activities api
function getBills(data, currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}${'/wallet/get-unified-payouts'
    }?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function UpdateUnifiedPayments(currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/update-unified-payments/${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getRecent(currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-unified-recent-transactions?currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getBudgetdetails(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-budget-details`,
    requestOptions
  ).then(handleResponse);
}
function getUpdatedetails() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-update-details`,
    requestOptions
  ).then(handleResponse);
}
function getBilldetels(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/get-payable-by-id/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToApprove(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/update-payable-to-approve/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToReject(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/update-payable-to-reject/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToPending(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/update-payable-to-pending/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToPay(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/update-payable-to-paid/${id}`,
    requestOptions
  ).then(handleResponse);
}
// getPayableToCancel
function getPayableToCancel(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/update-payable-to-cancel/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToUpdate(id, body) {
  const requestOptions = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/payables/update/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getCategoryExpenses(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-category-expenses?type=${data}`,
    requestOptions
  ).then(handleResponse);
}
function getPayins(data,currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-unified-payins?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPayeeExpenses(data,currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-payee-expenses?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPaymentMethodPayins(data,currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-unified-payment-method-payins?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPaymentExpenses(data,currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-unified-payment-method-expenses?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
// get dashboard charts api
function getChartData(startdate, enddate,currency) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/wallet/get-chart-data-for-payments-portal/${startdate}/${enddate}/${currency}`,
    requestOptions
  ).then(handleResponse);
}
function documentverification() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    // body: JSON.stringify(req)
  };
  return fetch(
    `${baseURL}/user-document-verification`,
    requestOptions
  ).then(handleResponse);
}
