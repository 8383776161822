import './Tebal_Detels.scss';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, IconButton, Typography } from '@mui/material';
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useNavigate, useLocation } from "react-router-dom";

// import '../../../../Balance/Balance.scss';
const Tebal_Detels = (props: any) => {
    // const rows = props.data ? props.data : [];
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('transaction_date');
    const [selected, setSelected] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = useState(props.data)
    const navigate = useNavigate();
    console.log(rows, "rowsrows");

    useEffect(() => {
        if (props?.data) {
            var array: any = []
            for (let index = 0; index < props.data.length; index++) {
                const element = props.data[index];
                console.log(element, "elementelement");
                var object = {
                    type: element.type == "Pay in" ? "pay_in" : element.type == "Pay Out" ? "pay_out" : element.type == "Fees" ? "fees" : "",
                    reference: element.ref,
                    transaction_amount: element.credit,
                    payee: element.payee,
                    invoice_no: element.invoice_no,
                    created_at: element.created_at,
                    transaction_detail: element.transaction_detail,
                    balance: element.balance
                }
                array.push(object)
            }
            setRows(props.data)
        }
    }, [props])
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <Box className='box_contenar_tebal'>
                <TableContainer>
                    <Table
                        className='tebal_class_contean'
                    >
                        <thead>
                            <tr>
                                <TableCell className='LinksTableCell'>
                                    Date
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Payment Reference
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Type
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Customer Name
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Customer Email
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                Customer Phone
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Description
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                Transaction Amount
                                </TableCell>
                                {/* <TableCell className='LinksTableCell'>
                                    Balance
                                </TableCell> */}
                                <TableCell className='LinksTableCell'>
                                    More info
                                </TableCell>
                            </tr>
                        </thead>
                        <tbody>

                            {!!rows &&
                                rows
                                    .map((element: any, index: any) => {
                                        var TAmount: any = element.transaction_amount;

                                        var tagclass = '';
                                        var textclass = '';
                                        var tagname = element.Type;
                                        if (element.Type == 'pay_in') {
                                            tagclass = 'tag_payin-tag'
                                            textclass = 'text_payin-tag'
                                            tagname = 'Pay In'
                                        } else if (element.Type == 'pay_out') {
                                            tagclass = 'tag_payout-tag'
                                            textclass = 'text_payout-tag'
                                            tagname = 'Pay Out'
                                            TAmount = -element.transaction_amount;
                                        } else if (element.Type == 'fees') {
                                            tagclass = 'tag_fees-tag'
                                            textclass = 'text_fees-tag'
                                            tagname = 'Fees'
                                            TAmount = -element.transaction_amount;
                                        }
                                        return (
                                            <TableRow  >
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {moment(element.created_at).format("DD-MMM-YYYY")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='TableCell_roww' >
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.reference}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='TableCell_roww' >
                                                    <span className={`tag_color ${tagclass}`}>
                                                        {tagname}
                                                    </span>
                                                </TableCell>
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {`${element?.payment?.first_name}${" "}${element?.payment?.last_name}`}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='TableCell_roww' >
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {!!element?.payment?.email ? element?.payment?.email: "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.mobile}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.description}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell id="amountColam" className='text-color'>
                                                    <span className={`text_color ${textclass}`} >
                                                        {(parseFloat(TAmount) > 0) ? "+" : "-"}{element.currency} {!!TAmount ? parseFloat(TAmount) > 0 ?

                                                            Number(parseFloat(TAmount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) :
                                                            `${Number(parseFloat(TAmount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }).replace("-", "")}`
                                                            : '0'}
                                                    </span></TableCell>
                                                {/* <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.currency} {!!element.balance ? Number((parseFloat(element.balance)).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : element.balance}
                                                    </Typography>
                                                </TableCell> */}
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='viwe_buttonTypography' onClick={() => props.onSelect(element)}>
                                                        View details
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            {emptyRows > 0 && (
                                <TableRow
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}

                        </tbody>
                    </Table>
                </TableContainer>
            </Box>
            {/* <div className='div_Buttondiv'>
                <Button id='id_gobackbutton' className='complete-profile__saventmm' onClick={()=>{navigate("/settings/Payment_Link");}}>
                    Go Back
                </Button>
            </div> */}
        </>
    )
}
export default Tebal_Detels;