import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import "../../../Settings.scss";


interface Props {
    onSave: any;
    page:string;
}

 const VerifiedPopup = (props: Props) => {

    const onSave = () => {
        props.onSave();
    }

    return (
        <div className='document-verified'>
            <div className='document-verified__heading'>Account Setup Complete</div>

            <div className='document-verified__desc'>
                <span>Congratulations!</span>
                <br />
                Your account setup is complete and has been successfully verified.
                 You now have access to all features on our platform.

                <br />
                <br />
                In case of any queries, reach out to us at <span>support@niobi.co</span>
            </div>
            <Button className='document-verified__btn' onClick={() =>{
                if(props?.page=="fastpage"){
                    onSave()
                }else{
                window.location.reload()
                }
            }}>Go Back </Button>
        </div>
    )
}
export default VerifiedPopup;