import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../../Settings.scss';
import { getBase64 } from '../../../../../../../_helpers/common';
import { getUser } from '../../../../../../../_helpers';
import Form from 'react-bootstrap/Form';
import { settingActions } from '../../../../../../../_actions/setting.actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  getEntityActivitiesReducer,
  selectAllData,
} from '../../../../../../../_reducers/entity.reducer';
import { entityActions } from '../../../../../../../_actions/entity.actions';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { settingSelector } from '../../../../../../../_reducers/setting.reducer';
// import {DocumentVerfied} from './DocumentVerfied';
import { ModelumentVerified } from './modelsoon';
import VerifiedPopup from './VerifiedPopup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Model_Reject } from './Model_Reject';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  onSave: any;
}
interface RequestProps {
  representative: any;
  document_type: string;
  business_documents: any;
  directors: any;
  shareholders: any;
  status: string;
}
// interface EntityRequest{
//     business_name: string;
//     registration_number: string;
//     company_type:string;
//     business_activity: string;
//     country: string;
//     state: string;
//     street_address: string;
//     tax_identification_number: string;
//     city: string;
//     zip_code: string;
// }
export const CompleteProfile = (props: Props) => {
  const [fileBRC, setFileBRC] = useState('Certificate of Incorporation');
  const [fileBRCBase, setFileBRCBase] = useState('');
  const [fileTax, setFileTax] = useState('CR 12');
  const [fileTaxBase, setFileTaxBase] = useState('Upload Tax Document');
  const [fileTax1, setFileTax1] = useState('Certificate of Incorporation');
  const [fileTax1Base, setFileTax1Base] = useState('Upload Document 3');
  const [fileTax2, setFileTax2] = useState('Upload Document 4');
  const [fileTax2Base, setFileTax2Base] = useState('Upload Document 4');
  const [userDetail, setUserDetail] = useState(false);
  const [typevalue, setType] = useState('');
  const [users, setUsers] = useState<any>();
  const [render, setRender] = useState(true);
  const [entityType, setEntityType] = useState<any>();
  const [requestbody, setrequestbody] = useState<RequestProps>();
  const [loader, setLoader] = useState(false);
  // const user = getUser()?.data ?? '';

  const directorDocStatic: any = {
    frontID: '',
    backID: '',
    KRN_certi: '',
    Passport: '',
  };
  const directorDocStaticTwo = {
    frontID: '',
    backID: '',
    KRN_certi: '',
    Passport: '',
    id: null,
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };

  const directorStatictwo2 = {
    datainput1: '',
    datainput2: '',
    datainput3: '',
  };
  const databusinessdoc = {
    certi_of_incorpo: '',
    CR: '',
    KRA: '',
  };
  const [businessdoc, setBusinessDoc] = useState<any>({});

  const [Directordoc, setDirectorDoc] = useState<any>([directorDocStatic]);
  const [Directordoc_two, setDirectorDoc_two] = useState<any>([
    directorDocStatic,
  ]);
  const [ShareHolderdoc, setShareHoldersDocs] = useState<any>([
    directorDocStaticTwo,
  ]);
  const [ShareHolderdoctwo, setShareHoldersDocstwo] = useState<any>([
    directorStatictwo2,
  ]);

  const [OfficialDoc, setOfficialDoc] = useState<any>([directorDocStatic]);
  const [TrusteeDoc, setTrusteeDoc] = useState<any>([directorDocStatic]);
  const [BeneficiaryDoc, setBeneficiaryDoc] = useState<any>([
    directorDocStatic,
  ]);
  const [ProprietorDoc, setProprietorDoc] = useState<any>([directorDocStatic]);
  const [RepresentativeDoc, setRepresentativeDoc] = useState<any>([
    directorDocStatic,
  ]);
  const [partnersDoc, setPartnersDoc] = useState<any>([directorDocStatic]);
  const [renderEntity, setRenderEntity] = useState(true);
  const [renderEntity_two, setRenderEntity_two] = useState(true);

  const [renderSubmit, setRenderSubmit] = useState(true);
  const [status, setstatus] = useState('waiting_for_submission');
  const [data_go_back, setData_Go_back] = useState<any>(false);
  const [data1, setdata1] = useState({
    datainput1: '',
    datainput2: '',
    datainput3: '',
  });
  const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
  console.log(businessdoc, 'businessdocbusinessdoc');

  const dispatch = useDispatch();
  const data = useSelector((state) => selectAllData(state));
  const dataEntity: any = useSelector((state) => settingSelector(state));

  const storeDocSelector: any = useSelector((state) => settingSelector(state));

  React.useEffect(() => {
    setUserDetail(false);
  }, []);

  React.useEffect(() => {
    if (getUser && render) {
      setRender(false);
      setUsers(getUser);
      dispatch(settingActions.getEntityDocuments() as any);
    }
  }, [render, getUser]);

  console.log(dataEntity, 'dataEntitydataEntity');
  const commonStaticFASTTT = {
    certi_of_incorpo: '{type}',
    certi_of_compliance: '{type}',
    CR: '{type}',
    KRA: '{type}',
    Passport: '{type}',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  const directorStatic: any = {
    frontID: '{type} {i} National ID Front Picture',
    backID: '{type} {i} National ID Back Picture',
    KRN_certi: '{type} {i} KRA PIN Certificate',
    Passport: '{type} {i} Passport Copy',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  const directorStatictwo = {
    datainput1: "Representative's National ID Front Picture",
    datainput2: "Representative's National ID Back Picture",
    datainput3: "Representative's KRA PIN Certificate",
    datainput4: "Director's Passport Copy",
  };

  const commonStatic: any = {
    frontID: '{type} National ID Front Picture',
    backID: '{type} National ID Back Picture',
    KRN_certi: '{type} KRA PIN Certificate',
    Passport: '{type} Passport Copy',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };

  const commonStatiTwo: any = {
    frontID: '{type} National ID Front Picture',
    backID: '{type} National ID Back Picture',
    KRN_certi: '{type} KRA PIN Certificate',

    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  const shareHolderStatic: any = {
    frontID: 'Shareholder  {i} - National ID Front Picture',
    backID: 'Shareholder  {i} - National ID Back Picture',
    KRN_certi: 'Shareholder  {i} - KRA PIN Certificates',
    Passport: "Shareholder's Passport Copy",
    id: null,
    fast_data: '{Shareholder  {i} - National ID Front Picture}',
    secend_data: '{Shareholder  {i} - National ID Back Picture}',
    thred_data: '{Shareholder  {i} - KRA PIN Certificates}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  React.useEffect(() => {
    if (
      renderEntity_two &&
      dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'dataEntity list',
        'dataEntity',
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data
      );
      if (
        !!dataEntity.GETENTITYDOCUMENT_SUCCESS.data &&
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data.length !== 0
      ) {
        if (
          typeof dataEntity.GETENTITYDOCUMENT_SUCCESS.data == 'object' &&
          dataEntity.GETENTITYDOCUMENT_SUCCESS?.message !==
            'Documents were not found.'
        ) {
          var GetData = dataEntity.GETENTITYDOCUMENT_SUCCESS.data;
          console.log(
            'DataEntity Message',
            dataEntity.GETENTITYDOCUMENT_SUCCESS?.message
          );
          setType(GetData.document_type);
          setUserDetail(true);
          setstatus(GetData.status);
        }
      }
    }
  }, [renderEntity_two, renderEntity]);

  React.useEffect(() => {
    if (renderEntity && dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined) {
      console.log(
        'dataEntity list',
        'dataEntity',
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data
      );
      setData_Go_back(false);
      if (
        !!dataEntity.GETENTITYDOCUMENT_SUCCESS.data &&
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data.length !== 0
      ) {
        if (typeof dataEntity.GETENTITYDOCUMENT_SUCCESS.data == 'object') {
          var GetData = dataEntity.GETENTITYDOCUMENT_SUCCESS.data;

          // if (!!GetData.document_type) {
          //     setType(GetData.document_type);
          //     setUserDetail(true);
          //     setstatus(GetData.status);
          // }
          console.log(
            dataEntity.GETENTITYDOCUMENT_SUCCESS,
            'dataEntity.GETENTITYDOCUMENT_SUCCESS1'
          );

          if (!!GetData.business_documents) {
            console.log(
              dataEntity.GETENTITYDOCUMENT_SUCCESS,
              'dataEntity.GETENTITYDOCUMENT_SUCCESS2'
            );

            setBusinessDoc({
              certi_of_incorpo: !!GetData.business_documents.certi_of_incorpo
                ? GetData.business_documents.certi_of_incorpo
                : commonStaticFASTTT.certi_of_incorpo,
              certi_of_compliance: !!GetData.business_documents
                .certi_of_compliance
                ? GetData.business_documents.certi_of_compliance
                : commonStaticFASTTT.certi_of_compliance,
              CR: !!GetData.business_documents.CR
                ? GetData.business_documents.CR
                : commonStaticFASTTT.CR,
              KRA: !!GetData.business_documents.KRA
                ? GetData.business_documents.KRA
                : commonStaticFASTTT.KRA,
              director: [directorStatic],
              id: !!GetData.business_documents.id
                ? GetData.business_documents.id
                : commonStaticFASTTT.id,
            });
            setInputListfast({
              certi_of_incorpo: !!GetData.business_documents.certi_of_incorpo
                ? GetData.business_documents.certi_of_incorpo
                : commonStaticFASTTT.certi_of_incorpo,
              certi_of_compliance: !!GetData.business_documents
                .certi_of_compliance
                ? GetData.business_documents.certi_of_compliance
                : commonStaticFASTTT.certi_of_compliance,
              CR: !!GetData.business_documents.CR
                ? GetData.business_documents.CR
                : commonStaticFASTTT.CR,
              KRA: !!GetData.business_documents.KRA
                ? GetData.business_documents.KRA
                : commonStaticFASTTT.KRA,
              director: [directorStatic],
              fast_data: !!GetData.business_documents.data.certi_of_incorpo
                ? GetData.business_documents.data.certi_of_incorpo.file_name
                : null,
              secend_data: !!GetData.business_documents.data.certi_of_compliance
                ? GetData.business_documents.data.certi_of_compliance.file_name
                : null,
              thred_data: !!GetData.business_documents.data.CR
                ? GetData.business_documents.data.CR.file_name
                : null,
              frore_data: !!GetData.business_documents.data.KRA
                ? GetData.business_documents.data.KRA.file_name
                : null,
              certi_of_incorpo_new: !!GetData.business_documents.data
                .certi_of_incorpo
                ? GetData.business_documents.data.certi_of_incorpo.id
                : null,
              certi_of_compliance_new: !!GetData.business_documents.data
                .certi_of_compliance
                ? GetData.business_documents.data.certi_of_compliance.id
                : null,
              CR_new: !!GetData.business_documents.data.CR
                ? GetData.business_documents.data.CR.id
                : null,
              KRA_new: !!GetData.business_documents.data.KRA
                ? GetData.business_documents.data.KRA.id
                : null,
            });
          }
          const directsData = {};

          if (
            !!GetData.directors ||
            !!GetData.partners ||
            !!GetData.proprietor ||
            !!GetData.trustee ||
            !!GetData.official
          ) {
            console.log('directors');
            var DirecArray: any = [];
            var DirecArrayStatic: any = [];

            const Directors: any = !!GetData.directors
              ? Object.values(GetData.directors)
              : !!GetData.partners
              ? Object.values(GetData.partners)
              : !!GetData.proprietor
              ? Object.values(GetData.proprietor)
              : !!GetData.trustee
              ? Object.values(GetData.trustee)
              : !!GetData.official
              ? Object.values(GetData.official)
              : [];

            console.log('Directors APi', Directors);
            Directors.map((x: any, i: any) => {
              const Data = {
                frontID: !!Directors[i].frontID
                  ? Directors[i].frontID
                  : '{type} {i} - National ID Front Picture',
                backID: !!Directors[i].backID
                  ? Directors[i].backID
                  : '{type} {i} - National ID Back Picture',
                KRN_certi: !!Directors[i].KRN_certi
                  ? Directors[i].KRN_certi
                  : '{type} {i} - KRA PIN Certificates',
                Passport: !!Directors[i].Passport
                  ? Directors[i].Passport
                  : '{type} Passport Copy',
                id: !!Directors[i].id ? Directors[i].id : null,
                frontID_new: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.id
                  : null,
                backID_new: !!Directors[i].data.backID
                  ? Directors[i].data.backID.id
                  : null,
                KRN_certi_new: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.id
                  : null,
                Passport_new: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.id
                  : null,
                fast_data: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.file_name
                  : null,
                secend_data: !!Directors[i].data.backID
                  ? Directors[i].data.backID.file_name
                  : null,
                thred_data: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.file_name
                  : null,
              };
              const DataStatic = {
                frontID: !!Directors[i].frontID ? Directors[i].frontID : '',
                backID: !!Directors[i].backID ? Directors[i].backID : '',
                KRN_certi: !!Directors[i].KRN_certi
                  ? Directors[i].KRN_certi
                  : '',
                Passport: !!Directors[i].Passport ? Directors[i].Passport : '',
                id: !!Directors[i].id ? Directors[i].id : null,
                frontID_new: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.id
                  : null,
                backID_new: !!Directors[i].data.backID
                  ? Directors[i].data.backID.id
                  : null,
                KRN_certi_new: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.id
                  : null,
                Passport_new: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.id
                  : null,
                fast_data: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.file_name
                  : null,
                secend_data: !!Directors[i].data.backID
                  ? Directors[i].data.backID.file_name
                  : null,
                thred_data: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.file_name
                  : null,
              };
              DirecArray.push(Data);
              DirecArrayStatic.push(DataStatic);
            });

            setDirectors(DirecArray);
            setDirectorDoc(DirecArrayStatic);
            setGroupOneName(DirecArray);
          }
          if (!!GetData.representative) {
            var DirecArray: any = [];
            var DirecArrayStatic: any = [];
            const Directors: any = !!GetData.representative
              ? Object.values(GetData.representative)
              : [];
            Directors.map((x: any, i: any) => {
              const Data = {
                frontID: !!Directors[i].frontID
                  ? Directors[i].frontID
                  : '{type} {i} - National ID Front Picture',
                backID: !!Directors[i].backID
                  ? Directors[i].backID
                  : '{type} {i} - National ID Back Picture',
                KRN_certi: !!Directors[i].KRN_certi
                  ? Directors[i].KRN_certi
                  : '{type} {i} - KRA PIN Certificates',
                Passport: !!Directors[i].Passport
                  ? Directors[i].Passport
                  : '{type} Passport Copy',
                id: !!Directors[i].id ? Directors[i].id : null,
                frontID_new: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.id
                  : null,
                backID_new: !!Directors[i].data.backID
                  ? Directors[i].data.backID.id
                  : null,
                KRN_certi_new: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.id
                  : null,
                Passport_new: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.id
                  : null,
                fast_data: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.file_name
                  : null,
                secend_data: !!Directors[i].data.backID
                  ? Directors[i].data.backID.file_name
                  : null,
                thred_data: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.file_name
                  : null,
              };
              DirecArray.push(Data);
              DirecArrayStatic.push(Data);
            });
            setDirectors_two(DirecArray);
            setDirectorDoc_two(DirecArrayStatic);
          }
          // else{
          //     setDirectors(directorStatic);
          //     // setDirectorDoc(directorDocStatic)
          //     setGroupOneName(commonStatic)
          // }Data
          if (!!GetData.shareholders || !!GetData.beneficiary) {
            var shareArray: any = [];
            var shareArrayStatic: any = [];
            const ShareHolders: any = !!GetData.shareholders
              ? Object.values(GetData.shareholders)
              : !!GetData.beneficiary
              ? Object.values(GetData.beneficiary)
              : [];
            ShareHolders.map((x: any, i: any) => {
              const Data = {
                frontID: !!ShareHolders[i].frontID
                  ? ShareHolders[i].frontID
                  : 'ShareHolders {i} - National ID Front Picture',
                backID: !!ShareHolders[i].backID
                  ? ShareHolders[i].backID
                  : 'ShareHolders {i} - National ID Back Picture',
                KRN_certi: !!ShareHolders[i].KRN_certi
                  ? ShareHolders[i].KRN_certi
                  : 'ShareHolders {i} - KRA PIN Certificates',
                id: !!ShareHolders[i].id ? ShareHolders[i].id : null,
                Passport: !!ShareHolders[i].Passport
                  ? ShareHolders[i].Passport
                  : "Shareholder's Passport Copy",
                frontID_new: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.id
                  : null,
                backID_new: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.id
                  : null,
                KRN_certi_new: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.id
                  : null,
                Passport_new: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.id
                  : null,
                fast_data: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.file_name
                  : null,
                secend_data: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.file_name
                  : null,
                thred_data: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.file_name
                  : null,
              };
              const DataStatic = {
                frontID: !!ShareHolders[i].frontID
                  ? ShareHolders[i].frontID
                  : '',
                backID: !!ShareHolders[i].backID ? ShareHolders[i].backID : '',
                KRN_certi: !!ShareHolders[i].KRN_certi
                  ? ShareHolders[i].KRN_certi
                  : '',
                id: !!ShareHolders[i].id ? ShareHolders[i].id : null,
                frontID_new: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.id
                  : null,
                Passport: !!ShareHolders[i].Passport
                  ? ShareHolders[i].Passport
                  : "Director's Passport Copy",

                backID_new: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.id
                  : null,
                KRN_certi_new: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.id
                  : null,
                Passport_new: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.id
                  : null,

                fast_data: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.file_name
                  : null,
                secend_data: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.file_name
                  : null,
                thred_data: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.file_name
                  : null,
              };
              shareArray.push(Data);
              shareArrayStatic.push(DataStatic);
            });
            setShareholder(shareArray);
            setShareHoldersDocs(shareArrayStatic);
            setGroupTwoName(shareArray);
          }
          // else{
          //     setShareholder(shareHolderStatic);
          //     // setShareHoldersDocs(directorDocStaticTwo)
          //     setGroupTwoName(commonStatiTwo);
          // }
        }
        //setDirectorDoc(DirecArray)
        //setShareHoldersDocs(shareArray)
      } else {
        setInputListfast(commonStaticFASTTT);
        setShareholder([shareHolderStatic]);
        setGroupTwoName([commonStatiTwo]);
        setShareHoldersDocs([directorDocStaticTwo]);
        setDirectors([directorStatic]);
        setDirectorDoc([directorDocStatic]);
        setGroupOneName([commonStatic]);
        setBusinessDoc({});
        setData_Go_back(true);
        setBusinessDoc({});
      }
      setRenderEntity(false);
    }
  }, [dataEntity, renderEntity]);

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  // React.useEffect(() => {
  // if (!!users) {
  //     if(!!users.data.entity.id){
  //         let requestBody = {
  //             id: users.data.entity.id,
  //         }
  //         dispatch(settingActions.getDocuments(requestBody) as any)
  //     }
  // }
  // }, [users]);

  const [submitted, setSubmitted] = useState(false);

  // const directorStatictwo = {
  //     datainput1: "Representative's National ID Front Picture",
  //     datainput2: "Representative's National ID Back Picture",
  //     datainput3: "Representative's KRA PIN Certificate",
  //     datainput4: "Director's Passport Copy"
  // }

  // const commonStatic = {
  //     frontID: "{type} National ID Front Picture",
  //     backID: "{type} National ID Back Picture",
  //     KRN_certi: "{type} KRA PIN Certificate",
  //     id: null,
  //     frontID_new: null,
  //     backID_new: null,
  //     KRN_certi_new: null
  // }

  // const commonStatiTwo = {
  //     frontID: "{type} National ID Front Picture",
  //     backID: "{type} National ID Back Picture",
  //     KRN_certi: "{type} KRA PIN Certificate",
  //     id: null,
  //     frontID_new: null,
  //     backID_new: null,
  //     KRN_certi_new: null
  // }
  // const shareHolderStatic = {
  //     frontID: "Shareholder  {i} - National ID Front Picture",
  //     backID: 'Shareholder  {i} - National ID Back Picture',
  //     KRN_certi: "Shareholder  {i} - KRA PIN Certificates",
  //     id: null,
  //     frontID_new: null,
  //     backID_new: null,
  //     KRN_certi_new: null
  // }
  const [directors, setDirectors] = useState([directorStatic]);
  const [directors_two, setDirectors_two] = useState([directorStatic]);

  const [shareholder, setShareholder] = useState([shareHolderStatic]);
  const [directorstwo, setDirectorstwo] = useState([directorStatictwo]);
  const [shareholdertwo, setShareholdertwo] = useState([shareHolderStatic]);
  const [newdata, setNewdata] = useState(false);
  const [groupOneName, setGroupOneName] = useState([commonStatic]);
  const [groupTwoName, setGroupTwoName] = useState([commonStatiTwo]);
  console.log(groupOneName, groupTwoName, 'groupTwoName');
  console.log(directors, 'directors');

  const [datadetels, setdatadetels] = useState<any>({
    director: [directorStatictwo],
  });
  console.log(datadetels, 'datadetels');

  const [inputlfast, setInputListfast] = useState<any>(
    commonStaticFASTTT
    // {

    //     Certificate: "Certificate of Incorporation",
    //     ofiicial_fast: "Certificate of Registration or Exemption",
    //     ofiicial_two: "Introduction letter from the NGOs Co-ordination Board",
    //     ofiicial_three: "Constitution of the organization",
    //     ofiicial_foarh: "KRA Pin Certificate or Exemption for the NGO",
    //     fast_trustee: "Certificate of registration",
    //     fast_trustee_two: "Trust deed or Exemption",
    //     fast_trustee_three: "Trust's KRA PIN Certificate or Tax Exemption Certificate",
    //     Certificate_two: "Business Registration Certificate",
    //     Certificate_two_three: "Partnership Mandate or Deed",
    //     CR: "CR 12",
    //     KRA: "Company’s KRA PIN Certificate",
    //     certi_of_compliance: "Certificate of Compliance",
    //     // director: [
    //     //     directorStatic
    //     // ]

    // }
  );
  console.log(
    inputlfast,
    directors,
    businessdoc,
    groupOneName,
    shareholder,
    shareholdertwo,
    'busfxhhhinessdoc'
  );

  console.log(inputlfast, 'inputlfast');

  const [inputlsecond, setInputListsecond] = useState({
    Certificate: 'Certificate of Incorporation',
    CR: 'CR 12',
    Compliance: 'Certificate of Compliance',
    KRA: 'Company’s KRA PIN Certificate',
    director: [directorStatictwo],
  });
  const [inputlthird, setInputListthird] = useState({
    Certificate: 'Business Registration Certificate',
    CR: 'Partnership Mandate or Deed',
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  const [inputlfourth, setInputListfourth] = useState({
    Certificate: 'Business Registration Certificate',
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  const [inputlfiveth, setInputListfiveth] = useState({
    Certificate: 'Certificate of registration',
    Trust: 'Trust deed or Exemption',
    PIN: "Trust's KRA PIN Certificate or Tax Exemption Certificate",
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  const [inputlsix, setInputListsix] = useState({
    Certificate: 'Certificate of Registration or Exemption',
    Trust: 'Introduction letter from the NGOs Co-ordination Board',
    PIN: 'Constitution of the organisation',
    KRA: 'KRA Pin Certificate or Exemption for the NGO',
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  console.log(datadetels, Directordoc, 'datSFffffadetels');

  const [inputList, setInputList] = useState([{ firstName: '', lastName: '' }]);
  const [inputList2, setInputList2] = useState([
    { firstName: '', lastName: '' },
  ]);
  const [inputListtwo, setInputListtwo] = useState([
    { firstName: '', lastName: '' },
  ]);
  const [datalistsize, setDatalistsize] = useState(true);
  const onSave = () => {
    props.onSave();
  };
  console.log(submitted, 'submitted');

  const [entity, setEntity] = useState<any>({
    certi_of_incorpo: '',
    CR12: '',
    KRN_certi: '',
  });
  console.log(fileBRCBase);
  const handleAddClick_two = () => {
    // setInputList([...inputList, { firstName: "", lastName: "" }]);
    setDirectors_two([...directors_two, directorStatic]);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { firstName: '', lastName: '' }]);
    // inputlfast.director.push(directorStatic);
    // setInputListfast(inputlfast);
    // inputlsecond.director.push(directorStatictwo);
    // setInputListsecond(inputlsecond);
    setDirectorstwo([...directorstwo, directorStatictwo]);
    setDirectors([...directors, directorStatic]);
    setDirectorDoc([...Directordoc, directorDocStatic]);
    // setRepresentativeDoc([...RepresentativeDoc, directorDocStatic])
    // setPartnersDoc([...partnersDoc, directorDocStatic])
    // setProprietorDoc([...ProprietorDoc, directorDocStatic])
    // setTrusteeDoc([...TrusteeDoc, directorDocStatic])
    // setDirectorstwo([...directorstwo, directorStatictwo])
    setShareHoldersDocstwo([...ShareHolderdoctwo, directorStatictwo2]);
    console.log(inputlfast, 'inputlfast');
    setGroupOneName([...groupOneName, commonStatic]);
  };
  // const handleAddClicktwo = () => {
  //     setInputList2([...inputList2, { firstName: "", lastName: "" }]);
  //     inputlsecond.director.push(directorStatictwo);
  //     setInputListsecond(inputlsecond);

  //     setDirectorstwo([...directorstwo, directorStatictwo])

  //     console.log(inputlfast, 'inputlfast');
  // };
  console.log(inputList, 'inputListinputList');

  const handleRemoveClicktwo = (index: any) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
    /* for name */

    const listName = [...directorstwo];
    listName.splice(index, 1);
    setDirectorstwo(listName);

    const listNamedoc = [...Directordoc];
    listNamedoc.splice(index, 1);
    console.log(listNamedoc, 'listNamedoc');
    setDirectorDoc(listNamedoc);
  };

  const handleRemoveClick_two = (index: any, id: any) => {
    const listName = [...directors_two];
    listName.splice(index, 1);
    setDirectors_two(listName);

    if (!!id) {
      var reqBody = {
        id: id,
      };
      dispatch(settingActions.deleteEntityDocuments(reqBody) as any);
    }
  };
  const handleRemoveClick = (index: any, id: any) => {
    console.log(index);
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    /* for name */

    const listName = [...directors];
    listName.splice(index, 1);
    setDirectors(listName);

    const listNamedoc = [...Directordoc];
    listNamedoc.splice(index, 1);
    console.log(listNamedoc, 'listNamedoc');
    setDirectorDoc(listNamedoc);

    const groupOneNameState = [...groupOneName];
    groupOneNameState.splice(index, 1);
    console.log(groupOneNameState, 'listNamedoc');
    setGroupOneName(groupOneNameState);
    if (!!id) {
      var reqBody = {
        id: id,
      };
      dispatch(settingActions.deleteEntityDocuments(reqBody) as any);
    }
  };
  const handleAddClick2 = () => {
    setInputList2([...inputList2, { firstName: '', lastName: '' }]);
    setShareholdertwo([...shareholdertwo, shareHolderStatic]);

    setShareholder([...shareholder, shareHolderStatic]);
    setShareHoldersDocs([...ShareHolderdoc, directorDocStaticTwo]);
    setGroupTwoName([...groupTwoName, commonStatiTwo]);
  };
  const handleAddClicktwo2 = () => {
    setInputListtwo([...inputList2, { firstName: '', lastName: '' }]);

    setShareholdertwo([...shareholdertwo, shareHolderStatic]);
    // setShareHoldersDocs([...ShareHolderdoc, directorDocStatic])
  };
  const handleRemoveClicktwo2 = (index: any) => {
    const list = [...inputListtwo];
    list.splice(index, 1);
    setInputListtwo(list);
    /* for name */
    const listName = [...shareholder];
    listName.splice(index, 1);
    setShareholdertwo(listName);

    const listNameSharedoc = [...ShareHolderdoc];
    listNameSharedoc.splice(index, 1);
    console.log(listNameSharedoc, 'listNamedoc');
    setShareHoldersDocs(listNameSharedoc);
  };
  const handleRemoveClick2 = (index: any, id: any) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
    /* for name */
    const listName = [...shareholder];
    listName.splice(index, 1);
    setShareholder(listName);

    const listNameSharedoc = [...ShareHolderdoc];
    listNameSharedoc.splice(index, 1);
    console.log(listNameSharedoc, 'listNamedoc');
    setShareHoldersDocs(listNameSharedoc);

    const groupTwoNameState = [...groupTwoName];
    groupTwoNameState.splice(index, 1);
    console.log(groupTwoNameState, 'listNamedoc');
    setGroupTwoName(groupTwoNameState);
    if (!!id) {
      var reqBody = {
        id: id,
      };
      dispatch(settingActions.deleteEntityDocuments(reqBody) as any);
    }
  };
  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  useEffect(() => {
    if (isInitialTypeRender && data?.GETENTITYITEMS_SUCCESS !== undefined) {
      setIsInitialTypeRender(false);
      setEntityType(data.GETENTITYITEMS_SUCCESS);
    }
  }, [data, isInitialTypeRender]);
  const saveButton = () => {
    if (!!users.data.entity.id) {
      //dispatch(settingActions.storeDocuments(e) as any)
    }
  };
  const validationSchema = Yup.object().shape({
    // certi_of_incorpo: Yup.string()
    //     .required('Business Name is required'),
    // CR12: Yup.string()
    //     .required('Registration Number is required'),
    // KRN_certi: Yup.string()
    //     .required('Business Type is required'),
  });
  console.log('Docs businessDoc', businessdoc);
  console.log('Docs Directordoc', Directordoc);
  console.log('Docs ShareHolderdoc', ShareHolderdoc);
  console.log('Docs partner', partnersDoc);
  console.log('Docs RepresentativeDoc', RepresentativeDoc);
  console.log('Docs OfficialDoc   ', OfficialDoc);

  const handleSubmit = (e: any) => {
    console.log('dataadd');

    // if(e.business_name && e.registration_number && e.company_type && e.business_activity && !!country && !!region){
    // e.country = country
    // e.state = region
    // console.log(e,'Submit')
    // dispatch(entityActions.createEntity(e) as any)
    // }
  };
  // useEffect(() => {
  //     const findfast = Directordoc.find((o: any) => o.KRN_certi == '')
  //     const findtwo = Directordoc.find((o: any) => o.backID == '')
  //     const findthdee = Directordoc.find((o: any) => o.frontID == '')
  //     const findfastss = ShareHolderdoc.find((o: any) => o.KRN_certi == '')
  //     const findtwoss = ShareHolderdoc.find((o: any) => o.backID == '')
  //     const findthdeess = ShareHolderdoc.find((o: any) => o.frontID == '')
  //     console.log(findfast, findtwo, findthdee, findfastss, findtwoss, findthdeess, 'findfastfindfast');

  //     if (!!businessdoc.certi_of_incorpo || !!businessdoc.CR || !!businessdoc.KRA || findfast == undefined || findtwo == undefined || findthdee == undefined || findfastss == undefined || findtwoss == undefined || findthdeess == undefined) {
  //         setData_Go_back(false)
  //     } else {
  //         setData_Go_back(true)
  //     }
  // }, [Directordoc, ShareHolderdoc]);
  console.log(directors, 'directorsdirectors');

  const handleChangeFile = (
    id: any,
    type: any,
    docType: any,
    title: any,
    file: any,
    name: any
  ) => {
    var reqBody = {
      id: !!id ? id : null,
      document_type: typevalue,
      type: type,
      title: title,
      file: file,
      status: 'waiting_for_submission',
      file_name: name,
    };
    console.log(reqBody, 'reqBodyreqBody22');
    setLoader(true);
    dispatch(settingActions.storeDocuments(reqBody) as any);
    //
    setTimeout(() => {
      console.log('called aftersometime');
      dispatch(settingActions.getEntityDocuments() as any);
      setTimeout(() => {
        setRenderEntity(true);
        setLoader(false);
      }, 3000);
    }, 5000);
  };
  const remove_data = (id: any) => {
    console.log(id, 'adaddddddd');

    dispatch(settingActions.deleteDocuments(id) as any);
    setTimeout(() => {
      console.log('called aftersometime');
      dispatch(settingActions.getEntityDocuments() as any);
      setTimeout(() => {
        setRenderEntity(true);
      }, 2000);
    }, 4500);
    // setRender(true)
  };
  console.log(Directordoc_two, 'Directordoc_two');

  // const validateSelectedFile = () => {
  //     const MIN_FILE_SIZE = 1024 // 1MB
  //     const MAX_FILE_SIZE = 5120 // 5MB

  //     if (!selectedFile) {
  //       setErrorMsg("Please choose a file");
  //       setIsSuccess(false)
  //       return
  //     }

  //     const fileSizeKiloBytes = selectedFile.size / 1024

  //     if(fileSizeKiloBytes < MIN_FILE_SIZE){
  //       setErrorMsg("File size is less than minimum limit");
  //       setIsSuccess(false)
  //       return
  //     }
  //     if(fileSizeKiloBytes > MAX_FILE_SIZE){
  //       setErrorMsg("File size is greater than maximum limit");
  //       setIsSuccess(false)
  //       return
  //     }

  //     setErrorMsg("")
  //     setIsSuccess(true)
  //   };
  const handleSave = () => {
    console.log('dataadd');
    var reqBody = {};
    if (typevalue == '1') {
      reqBody = {
        document_type: typevalue,
        business_documents: businessdoc,
        status: 'waiting_for_submission',
        directors: Directordoc,
        shareholders: ShareHolderdoc,
      };
    } else if (typevalue == '2') {
      reqBody = {
        document_type: typevalue,
        business_documents: businessdoc,
        status: 'waiting_for_submission',
        representative: Directordoc,
        shareholders: ShareHolderdoc,
      };
    } else if (typevalue == '3') {
      reqBody = {
        document_type: typevalue,
        business_documents: businessdoc,
        status: 'waiting_for_submission',
        partners: Directordoc,
        shareholders: ShareHolderdoc,
      };
    } else if (typevalue == '4') {
      reqBody = {
        document_type: typevalue,
        business_documents: businessdoc,
        status: 'waiting_for_submission',
        proprietor: Directordoc,
      };
    } else if (typevalue == '5') {
      reqBody = {
        document_type: typevalue,
        business_documents: businessdoc,
        status: 'waiting_for_submission',
        trustee: Directordoc,
        shareholders: ShareHolderdoc,
      };
    } else if (typevalue == '6') {
      reqBody = {
        document_type: typevalue,
        business_documents: businessdoc,
        status: 'waiting_for_submission',
        official: Directordoc,
        shareholders: ShareHolderdoc,
      };
    } else {
      reqBody = {
        document_type: typevalue,
        business_documents: businessdoc,
        status: 'waiting_for_submission',
        directors: Directordoc,
      };
    }

    dispatch(settingActions.storeDocuments(reqBody) as any);
    console.log(reqBody, 'reqBody');
  };

  console.log(businessdoc, 'ShareHolderdoc');
  console.log(Directordoc, 'representative');
  // console.log(businessdoc.certi_of_incorpo, 'businessdoc.certi_of_incorpo');
  console.log(ShareHolderdoc, businessdoc, Directordoc, 'ShareHolderdoc');

  console.log(data1, 'data1data1');
  console.log(Directordoc, 'Directordoc');
  const validateSelectedFile = (e: any) => {
    const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 2048; // 5MB
    if (e.size > 1024) {
      setDatalistsize(false);
    } else {
      setDatalistsize(true);
    }
  };
  console.log(businessdoc, 'businessdocbusinessdoc');
  console.log(groupOneName, 'groupOneName');
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const imageUrl =
    'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  // const downloadImage = (imageUrl: string) => {
  //     const a = window.document.createElement("a");
  //     const blob = new Blob([imageUrl], { type: "octet/stream" });
  //     console.log("blob", blob);
  //     const x = window.URL.createObjectURL(blob);
  //     console.log("x", x);
  //     a.href = window.URL.createObjectURL(blob);
  //     a.download = "image.png";
  //     document.body.appendChild(a);
  //     a.click();
  //   };

  //   const downloadImage = () => {
  //     saveAs('image_url', 'image.jpg') // Put your image URL here.
  //   }

  // const downloadImage = async (data: any) => {
  //     console.log(data,'datadata');

  //     try {
  //         const response = await fetch(imageUrl);
  //         const blob = await response.blob();
  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'image.png'); // Change the filename if needed
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();
  //         console.log(link,'linklink');

  //     } catch (error) {
  //         console.error('Error downloading the image:', error);
  //     }
  // }
  // console.log(inputlfast, ' console.log(inputlfast);');
  return (
    <>
      {status == 'waiting_for_submission' ? (
        <div className="complete-profile">
          <Formik
            initialValues={entity}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log('handleSubmit', values, businessdoc);
              const ReqBody = {
                document_type: typevalue,
                business_documents: businessdoc,
                directors: Directordoc,
                shareholders: ShareHolderdoc,
                status: 'submitted',
              };
              const findfast = Directordoc.find((o: any) => o.KRN_certi == '');
              const findtwo = Directordoc.find((o: any) => o.backID == '');
              const findthdee = Directordoc.find((o: any) => o.frontID == '');
              const findfore = Directordoc.find((o: any) => o.Passport == '');

              const findfast_two = Directordoc_two.find(
                (o: any) => o.KRN_certi == ''
              );
              const findtwo_two = Directordoc_two.find(
                (o: any) => o.backID == ''
              );
              const findthdee_two = Directordoc_two.find(
                (o: any) => o.frontID == ''
              );
              const findfore_two = Directordoc_two.find(
                (o: any) => o.Passport == ''
              );

              const findfastss = ShareHolderdoc.find(
                (o: any) => o.KRN_certi == ''
              );
              const findtwoss = ShareHolderdoc.find((o: any) => o.backID == '');
              const findthdeess = ShareHolderdoc.find(
                (o: any) => o.frontID == ''
              );
              const findforess = ShareHolderdoc.find(
                (o: any) => o.Passport == ''
              );
              // const findfastssbb = businessdoc.find((o: any) => o.CR == '')
              // const findtwossbb = businessdoc.find((o: any) => o.KRA == '')
              // const findthdeessbb = businessdoc.find((o: any) => o.certi_of_incorpo == '')
              // const findthdeessbbtwo = businessdoc.find((o: any) => o.certi_of_incorpo == undefined)
              console.log(findthdee, findtwo, findfast, 'findthdee');
              console.log(
                findfast,
                findtwo,
                findthdee,
                findfore,
                findfastss,
                findtwoss,
                findthdeess,
                findforess,
                findfast_two,
                findtwo_two,
                findthdee_two,
                findfore_two,
                typevalue,
                'certi_of_incorpo22'
              );

              if (
                !!businessdoc.certi_of_incorpo &&
                !!businessdoc.CR &&
                !!businessdoc.KRA &&
                findfast == undefined &&
                findtwo == undefined &&
                findthdee == undefined &&
                findfore == undefined &&
                findfastss == undefined &&
                findtwoss == undefined &&
                findthdeess == undefined &&
                findforess == undefined &&
                findfast_two == undefined &&
                findtwo_two == undefined &&
                findthdee_two == undefined &&
                // && findfore_two == undefined
                typevalue == '6'
              ) {
                console.log('data_submit');
                dispatch(settingActions.submitEntityDocuments() as any);
                setstatus('waiting_for_approval');
              } else if (
                !!businessdoc.certi_of_incorpo &&
                !!businessdoc.CR &&
                !!businessdoc.KRA &&
                findfast == undefined &&
                findtwo == undefined &&
                findthdee == undefined &&
                findfastss == undefined &&
                findtwoss == undefined &&
                findthdeess == undefined &&
                typevalue == '3'
              ) {
                console.log('addsuseacdata');
                dispatch(settingActions.submitEntityDocuments() as any);
                setstatus('waiting_for_approval');
              } else if (
                !!businessdoc.certi_of_incorpo &&
                !!businessdoc.CR &&
                !!businessdoc.KRA &&
                findfast == null &&
                findtwo == null &&
                findthdee == null &&
                (typevalue == '2' || typevalue == '4' || typevalue == '5')
              ) {
                console.log('addsuseacdata2');
                setstatus('waiting_for_approval');
                dispatch(settingActions.submitEntityDocuments() as any);
              } else if (
                !!businessdoc.certi_of_incorpo &&
                findfast == null &&
                findtwo == null &&
                findthdee == null &&
                typevalue == '1'
              ) {
                console.log('addsuseacdata3');
                dispatch(settingActions.submitEntityDocuments() as any);
                setstatus('waiting_for_approval');
              }
              // setRenderSubmit(true)
              console.log(ReqBody, 'ReqBody');
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <ThemeProvider theme={theme}>
                    {userDetail === true &&
                      !!typevalue &&
                      typevalue != '1' &&
                      typevalue != '2' &&
                      typevalue != '5' &&
                      typevalue != '4' && (
                        <>
                          <div className="complete-profile__heading">
                            Please provide the following documents
                          </div>
                          <p className="complete-profile__businesstypo">
                            Business Documents
                          </p>
                          <div className="flax_data_list">
                            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                              <label className="complete-profile__upload">
                                <div className="complete-profile__upload__label">
                                  {inputlfast.certi_of_incorpo_new == null ? (
                                    <span className="complete-profile__upload__txt">
                                      {inputlfast.certi_of_incorpo
                                        ? inputlfast.certi_of_incorpo.replace(
                                            '{type}',
                                            'Certificate of Incorporation'
                                          )
                                        : ''}
                                      {/* {inputlfast.Certificate} */}
                                    </span>
                                  ) : (
                                    <a
                                      href={`${inputlfast.certi_of_incorpo}`}
                                      className="complete-profile__upload__txt"
                                      download
                                    >
                                      {inputlfast.fast_data
                                        ? inputlfast.fast_data.replace(
                                            '{type}',
                                            'Certificate of Incorporation'
                                          )
                                        : ''}
                                    </a>
                                  )}
                                </div>

                                <input
                                  // name="certi_of_incorpo"
                                  type="file"
                                  // value={values.certi_of_incorpo}
                                  onChange={(event: any) => {
                                    var idOfIndex = !!businessdoc.id
                                      ? businessdoc.id
                                      : null;
                                    const newdata = inputlfast;
                                    newdata.certi_of_incorpo =
                                      event.target.files[0].name;
                                    setInputListfast(newdata);
                                    getBase64(
                                      event.target.files[0],
                                      (result: any) => {
                                        var business = businessdoc;
                                        business.certi_of_incorpo = result;
                                        setBusinessDoc(business);
                                        handleChangeFile(
                                          idOfIndex,
                                          'business_documents',
                                          typevalue,
                                          'certi_of_incorpo',
                                          result,
                                          event.target.files[0].name
                                        );
                                      }
                                    );
                                  }}
                                />

                                {businessdoc.certi_of_incorpo == undefined &&
                                submitted == true ? (
                                  <p className="addnewpere">
                                    This field is required
                                  </p>
                                ) : datalistsize == false &&
                                  submitted == true ? (
                                  'maxdata enter'
                                ) : (
                                  ''
                                )}
                                {/* <ErrorMessage name="business_name" component="div" className="invalid-feedback red" /> */}
                              </label>
                            </Tooltip>
                            {inputlfast.certi_of_incorpo_new == null ? (
                              <div className="aDADADaDsfgf">
                                <UploadIcon />
                              </div>
                            ) : (
                              <div
                                className="aDADADaDsfgf"
                                onClick={() => {
                                  remove_data(inputlfast.certi_of_incorpo_new);
                                }}
                              >
                                <CloseIcon />
                              </div>
                            )}
                          </div>
                          {typevalue == '6' && (
                            <div className="flax_data_list">
                              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                <label className="complete-profile__upload">
                                  <div
                                    id={!!businessdoc.id ? '' : 'data_disebal'}
                                    className="complete-profile__upload__label"
                                  >
                                    {inputlfast.certi_of_compliance_new ==
                                    null ? (
                                      <span className="complete-profile__upload__txt">
                                        {inputlfast.certi_of_compliance
                                          ? inputlfast.certi_of_compliance.replace(
                                              '{type}',
                                              'Certificate of Compliance'
                                            )
                                          : ''}
                                      </span>
                                    ) : (
                                      <a
                                        href={`${inputlfast.certi_of_compliance}`}
                                        className="complete-profile__upload__txt"
                                        download
                                      >
                                        {inputlfast.secend_data
                                          ? inputlfast.secend_data.replace(
                                              '{type}',
                                              'Certificate of Compliance'
                                            )
                                          : ''}
                                      </a>
                                    )}
                                  </div>
                                  <input
                                    // name="certi_of_compliance"
                                    // value={values.certi_of_compliance}
                                    disabled={!!businessdoc.id ? false : true}
                                    type="file"
                                    onChange={(event: any) => {
                                      var idOfIndex = !!businessdoc.id
                                        ? businessdoc.id
                                        : null;
                                      const newdata = inputlfast;
                                      newdata.certi_of_compliance =
                                        event.target.files[0].name;
                                      setInputListfast(newdata);
                                      getBase64(
                                        event.target.files[0],
                                        (result: any) => {
                                          var business = businessdoc;
                                          business.certi_of_compliance = result;
                                          setBusinessDoc(business);
                                          handleChangeFile(
                                            idOfIndex,
                                            'business_documents',
                                            typevalue,
                                            'certi_of_compliance',
                                            result,
                                            event.target.files[0].name
                                          );
                                        }
                                      );
                                    }}
                                  />
                                  {businessdoc.certi_of_compliance ==
                                    undefined && submitted == true ? (
                                    <p className="addnewpere">
                                      This field is required
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                </label>
                              </Tooltip>
                              {inputlfast.certi_of_compliance_new == null ? (
                                <div className="aDADADaDsfgf">
                                  <UploadIcon />
                                </div>
                              ) : (
                                <div
                                  className="aDADADaDsfgf"
                                  onClick={() => {
                                    remove_data(
                                      inputlfast.certi_of_compliance_new
                                    );
                                  }}
                                >
                                  <CloseIcon />
                                </div>
                              )}
                            </div>
                          )}
                          <div className="flax_data_list">
                            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                              <label className="complete-profile__upload">
                                <div
                                  id={!!businessdoc.id ? '' : 'data_disebal'}
                                  className="complete-profile__upload__label"
                                >
                                  {inputlfast.CR_new == null ? (
                                    <span className="complete-profile__upload__txt">
                                      {inputlfast.CR
                                        ? inputlfast.CR.replace(
                                            '{type}',
                                            'CR 12'
                                          )
                                        : ''}
                                    </span>
                                  ) : (
                                    <a
                                      href={`${inputlfast.CR}`}
                                      className="complete-profile__upload__txt"
                                      download
                                    >
                                      {inputlfast.thred_data
                                        ? inputlfast.thred_data.replace(
                                            '{type}',
                                            'CR 12'
                                          )
                                        : ''}
                                    </a>
                                  )}
                                </div>
                                <input
                                  // name="CR12"
                                  // value={values.CR}
                                  disabled={!!businessdoc.id ? false : true}
                                  type="file"
                                  onChange={(event: any) => {
                                    var idOfIndex = !!businessdoc.id
                                      ? businessdoc.id
                                      : null;
                                    const newdata = inputlfast;
                                    newdata.CR = event.target.files[0].name;
                                    setInputListfast(newdata);
                                    getBase64(
                                      event.target.files[0],
                                      (result: any) => {
                                        var business = businessdoc;
                                        business.CR = result;
                                        setBusinessDoc(business);
                                        handleChangeFile(
                                          idOfIndex,
                                          'business_documents',
                                          typevalue,
                                          'CR',
                                          result,
                                          event.target.files[0].name
                                        );
                                      }
                                    );
                                  }}
                                />
                                {businessdoc.CR == undefined &&
                                submitted == true ? (
                                  <p className="addnewpere">
                                    This field is required
                                  </p>
                                ) : (
                                  ''
                                )}
                              </label>
                            </Tooltip>
                            {inputlfast.CR_new == null ? (
                              <div className="aDADADaDsfgf">
                                <UploadIcon />
                              </div>
                            ) : (
                              <div
                                className="aDADADaDsfgf"
                                onClick={() => {
                                  remove_data(inputlfast.CR_new);
                                }}
                              >
                                <CloseIcon />
                              </div>
                            )}
                          </div>
                          <div className="flax_data_list">
                            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                              <label className="complete-profile__upload">
                                <div
                                  id={!!businessdoc.id ? '' : 'data_disebal'}
                                  className="complete-profile__upload__label"
                                >
                                  {inputlfast.KRA_new == null ? (
                                    <span className="complete-profile__upload__txt">
                                      {inputlfast.KRA
                                        ? inputlfast.KRA.replace(
                                            '{type}',
                                            'Company’s KRA PIN Certificate'
                                          )
                                        : ''}
                                    </span>
                                  ) : (
                                    <a
                                      href={inputlfast.KRA}
                                      className="complete-profile__upload__txt"
                                      download
                                    >
                                      {inputlfast.frore_data
                                        ? inputlfast.frore_data.replace(
                                            '{type}',
                                            'Company’s KRA PIN Certificate'
                                          )
                                        : ''}
                                    </a>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  // name="KRA"
                                  // value={values.KRA}
                                  disabled={!!businessdoc.id ? false : true}
                                  onChange={(event: any) => {
                                    var idOfIndex = !!businessdoc.id
                                      ? businessdoc.id
                                      : null;
                                    const newdata = inputlfast;
                                    newdata.KRA = event.target.files[0].name;
                                    setInputListfast(newdata);
                                    getBase64(
                                      event.target.files[0],
                                      (result: any) => {
                                        var business = businessdoc;
                                        business.KRA = result;
                                        setBusinessDoc(business);
                                        handleChangeFile(
                                          idOfIndex,
                                          'business_documents',
                                          typevalue,
                                          'KRA',
                                          result,
                                          event.target.files[0].name
                                        );
                                      }
                                    );
                                  }}
                                />
                                {businessdoc.KRA == undefined &&
                                submitted == true ? (
                                  <p className="addnewpere">
                                    This field is required
                                  </p>
                                ) : (
                                  ''
                                )}
                              </label>
                            </Tooltip>
                            {inputlfast.KRA_new == null ? (
                              <div className="aDADADaDsfgf">
                                <UploadIcon />
                              </div>
                            ) : (
                              <div
                                className="aDADADaDsfgf"
                                onClick={() => {
                                  remove_data(inputlfast.KRA_new);
                                }}
                              >
                                <CloseIcon />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    {userDetail === true && typevalue == '3' ? (
                      <>
                        <p className="complete-profile__businesstypo">
                          Director’s Details
                        </p>
                        {directors.map((x, i: any) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Director {i + 1}
                                </p>
                                {i > 0 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {directors[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].frontID
                                            ? directors[i].frontID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].fast_data
                                            ? directors[i].fast_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      // maxFileSize={4000000}
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput1 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            console.log('result', Directordoc);
                                            // var directorDc = Directordoc;
                                            // directorDc[i].frontID = result;
                                            // setDirectorDoc(directorDc)
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '1',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].frontID_new ==
                                      `{type} {i} National ID Front Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!directors[i].id ? '' : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].backID
                                            ? directors[i].backID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].secend_data
                                            ? directors[i].secend_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!directors[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        console.log('clicked', directors);
                                        const newdata = directors;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput2 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            // var directorDc = Directordoc;
                                            // directorDc[i].backID = result;
                                            // setDirectorDoc(directorDc)
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '1',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                        console.log('updated', directors);
                                      }}
                                    />
                                    {directors[i].backID ==
                                      `{type} {i} National ID Back Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!directors[i].id ? '' : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].KRN_certi
                                            ? directors[i].KRN_certi.replace(
                                                '{type}',
                                                "Director's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].thred_data
                                            ? directors[i].thred_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!directors[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput3 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            // var directorDc = Directordoc;
                                            // directorDc[i].KRN_certi = result;
                                            // setDirectorDoc(directorDc)
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '1',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />

                                    {directors[i].KRN_certi ==
                                      `{type} {i} KRA PIN Certificate` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].KRN_certi_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>

                              <div className="complete-profile__btn_box">
                                {directors.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another director{' '}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <p className="complete-profile__businesstypo">
                          Shareholders’ Details
                        </p>
                        {shareholder.map((x, i) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Shareholder {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick2(
                                        i,
                                        !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {shareholder[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].frontID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].fast_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);

                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            // var shareHolderDc = ShareHolderdoc;
                                            // shareHolderDc[i].frontID = result;
                                            // setShareHoldersDocs(shareHolderDc)
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '1',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].frontID ==
                                      `Shareholder  {i} - National ID Front Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                       This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!shareholder[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].backID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].secend_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!shareholder[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            // var shareHolderDc = ShareHolderdoc;
                                            // shareHolderDc[i].backID = result;
                                            // setShareHoldersDocs(shareHolderDc)
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '1',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].backID ==
                                      `Shareholder  {i} - National ID Back Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!shareholder[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].KRN_certi.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].thred_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!shareholder[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            // var shareHolderDc = ShareHolderdoc;
                                            // shareHolderDc[i].KRN_certi = result;
                                            // setShareHoldersDocs(shareHolderDc)
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '1',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].KRN_certi ==
                                      `Shareholder  {i} - KRA PIN Certificates` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                       This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].KRN_certi_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {shareholder.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick2}
                                  >
                                    + Add another shareholder{' '}
                                  </Button>
                                )}
                                {/* {inputList2.length !== 1 && <Button className='complete-profile__removebtn' onClick={() => {handleRemoveClick2(i) }}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '6' ? (
                      <>
                        <p className="complete-profile__businesstypo">
                          Representative's Details
                        </p>

                        {directors_two.map((x, i: any) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Representative {i + 1}
                                </p>
                                {i > 0 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick_two(
                                        i,
                                        !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {directors_two[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors_two[i].frontID
                                            ? directors_two[i].frontID
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors_two[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors_two[i].fast_data
                                            ? directors_two[i].fast_data
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      // maxFileSize={4000000}
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null;
                                        const newdata = directors_two;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setDirectors_two(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput1 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            console.log('result', Directordoc);
                                            var directorDc = Directordoc_two;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc_two(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'representative',
                                              '1',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {directors_two[i].frontID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors_two[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors_two[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!directors_two[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors_two[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors_two[i].backID
                                            ? directors_two[i].backID
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors_two[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors_two[i].secend_data
                                            ? directors_two[i].secend_data
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!directors_two[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null;
                                        console.log('clicked', directors_two);
                                        const newdata = directors_two;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setDirectors_two(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput2 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc_two;
                                            directorDc[i].backID = result;
                                            setDirectorDoc_two(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'representative',
                                              '1',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {directors_two[i].backID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors_two[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors_two[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!directors_two[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors_two[i].KRN_certi_new ==
                                      null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors_two[i].KRN_certi
                                            ? directors_two[
                                                i
                                              ].KRN_certi.replace(
                                                '{type}',
                                                "Representative's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors_two[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors_two[i].thred_data
                                            ? directors_two[i].thred_data
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!directors_two[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null;
                                        const newdata = directors_two;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setDirectors_two(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput3 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc_two;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc_two(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'representative',
                                              '1',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />

                                    {directors_two[i].KRN_certi_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors_two[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        directors_two[i].KRN_certi_new
                                      );
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {directors_two[i].Passport_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(directors_two[i].Passport) ? directors_two[i].Passport.replace('{type}', 'Representative\'s').replace('{i}', i + 1) : ""}
                                                                        </span> : <a href={directors_two[i].Passport} className='complete-profile__upload__txt' download>
                                                                            {(directors_two[i].frore_data) ? directors_two[i].frore_data.replace('{type}', 'Representative\'s').replace('{i}', i + 1) : ""}
                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!directors_two[i].id ? directors_two[i].id : null
                                                                    const newdata = directors_two;
                                                                    newdata[i].Passport = event.target.files[0].name
                                                                    setDirectors_two(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        var directorDc = Directordoc_two;
                                                                        directorDc[i].Passport = result;
                                                                        setDirectorDoc_two(directorDc)
                                                                        setFileTax2Base(result);
                                                                        handleChangeFile(idOfIndex, 'representative', '2', 'Passport', result, event.target.files[0].name)
                                                                    });
                                                                }} />
                                                                {directors_two[i].Passport_new == null && submitted == true ? <p className='addnewpere'>This is field required</p> : ''}
                                                            </label>
                                                            {directors_two[i].Passport_new == null ? <div className='aDADADaDsfgf'><UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(directors_two[i].Passport_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                              <div className="complete-profile__btn_box">
                                {directors_two.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick_two}
                                  >
                                    + Add another representative{' '}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <p className="complete-profile__businesstypo">
                          Director’s Details{' '}
                        </p>
                        {directors.map((x, i: any) => {
                          return (
                            <div className="box">
                              {/* <p className='complete-profile__director'>Representative {i + 1}</p> */}
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Director {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {directors[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].frontID
                                            ? directors[i].frontID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].fast_data
                                            ? directors[i].fast_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].frontID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!directors[i].id ? '' : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].backID
                                            ? directors[i].backID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].secend_data
                                            ? directors[i].secend_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!directors[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        console.log('clicked', directors);
                                        const newdata = directors;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax2Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].backID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!directors[i].id ? '' : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].KRN_certi
                                            ? directors[i].KRN_certi.replace(
                                                '{type}',
                                                "Director's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].thred_data
                                            ? directors[i].thred_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!directors[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax2Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].KRN_certi_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].KRN_certi_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!directors[i].id ? '' : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].Passport_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].Passport
                                            ? directors[i].Passport.replace(
                                                '{type}',
                                                "Director's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].Passport}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].frore_data
                                            ? directors[i].frore_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      disabled={
                                        !!directors[i].id ? false : true
                                      }
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].Passport =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].Passport = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax2Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'Passport',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].Passport_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].Passport_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].Passport_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {directors.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another director
                                  </Button>
                                )}
                                {/* {inputList.length !== 1 && <Button className='complete-profile__removebtn' onClick={handleRemoveClick}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}

                        {/* {shareholder.map((x, i) => {
                                                return (
                                                    <div className="box">
                                                        <p className='complete-profile__businesstypo'>Shareholders’ Details</p>
                                                        <div className="complete-profile__btn_box">
                                                            <p className='complete-profile__director'>Shareholder {i + 1}</p>
                                                            {i >= 1 ? <Button className='complete-profile__removebtn' onClick={() => { handleRemoveClick2(i, !!shareholder[i].id ? shareholder[i].id : null) }}>Remove</Button> : ''}
                                                        </div>
                                                        <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].Passport_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].Passport).replace('{i}', (i + 1).toString())}
                                                                        </span> : <a href={shareholder[i].Passport} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].frore_data).replace('{i}', (i + 1).toString())}

                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null

                                                                    const newdata = shareholder;
                                                                    newdata[i].Passport = event.target.files[0].name
                                                                    setShareholder(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        setFileTax1Base(result);
                                                                        var shareHolderDc = ShareHolderdoc;
                                                                        shareHolderDc[i].Passport = result;
                                                                        setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '2', 'Passport', result, event.target.files[0].name)
                                                                    });
                                                                }} />

                                                                {shareholder[i].Passport == `Director's Passport Copy` && submitted == true ? <p className='addnewpere'>Director's Passport Copy Company’s is required</p> : ''}

                                                            </label>
                                                            {shareholder[i].Passport_new == null ? <div className='aDADADaDsfgf'><UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].Passport_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div>

                                                        {shareholder.length - 1 === i && <Button className='complete-profile__ADDBTNHHA' onClick={handleAddClick2}>+ Add another Shareholder </Button>}

                                                    </div>
                                                );
                                            })} */}
                        {shareholder.map((x, i) => {
                          return (
                            <div className="box">
                              <p className="complete-profile__businesstypo">
                                Shareholders’ Details
                              </p>
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Shareholder {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick2(
                                        i,
                                        !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {shareholder[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].frontID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].fast_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;

                                        const newdata = shareholder;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax1Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].frontID = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />

                                    {shareholder[i].frontID ==
                                      `Shareholder  {i} - National ID Front Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!shareholder[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].backID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].secend_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      disabled={
                                        !!shareholder[i].id ? false : true
                                      }
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;

                                        setFileTax2(event.target.files[0].name);
                                        const newdata = shareholder;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax2Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].backID = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].backID ==
                                      `Shareholder  {i} - National ID Back Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!shareholder[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].KRN_certi.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].thred_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!shareholder[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        setFileTax2(event.target.files[0].name);
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax2Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].KRN_certi = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].KRN_certi ==
                                      `Shareholder  {i} - KRA PIN Certificates` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].KRN_certi_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {shareholder[i].Passport_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].Passport.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].Passport}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].frore_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;

                                        const newdata = shareholder;
                                        newdata[i].Passport =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax1Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].Passport = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'Passport',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />

                                    {shareholder[i].Passport ==
                                      `Shareholder's Passport Copy` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].Passport_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].Passport_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {shareholder.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick2}
                                  >
                                    + Add another shareholder{' '}
                                  </Button>
                                )}
                                {/* {inputList2.length !== 1 && <Button className='complete-profile__removebtn' onClick={() => {handleRemoveClick2(i) }}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '2' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={`${inputlfast.certi_of_incorpo}`}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_incorpo == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div
                                id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.certi_of_compliance_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_compliance
                                      ? inputlfast.certi_of_compliance.replace(
                                          '{type}',
                                          'Partnership Mandate or Deed'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={`${inputlfast.certi_of_compliance}`}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.secend_data
                                      ? inputlfast.secend_data.replace(
                                          '{type}',
                                          'Partnership Mandate or Deed'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                disabled={!!businessdoc.id ? false : true}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_compliance =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_compliance = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_compliance',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_compliance == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_compliance_new == null ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_compliance_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Partner's Details
                        </p>
                        {groupOneName.map((x, i: any) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Partner {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {groupOneName[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].frontID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax1(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'partners',
                                              '3',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label
                                    aria-disabled={
                                      !!groupOneName[i].id ? true : false
                                    }
                                    className="complete-profile__upload"
                                  >
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].backID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[i].secend_data
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'partners',
                                              '3',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Partner's"
                                              ).replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].KRN_certi}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[i].thred_data
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax2Base(result);
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'partners',
                                              '3',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another partner{' '}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        {/* <p className='complete-profile__businesstypo'>Shareholders’ Details</p>
                                            {shareholder.map((x, i) => {
                                                return (
                                                    <div className="box">
                                                        <div className="complete-profile__btn_box">
                                                            <p className='complete-profile__director'>Shareholder {i + 1}</p>
                                                            {i >= 1 ? <Button className='complete-profile__removebtn' onClick={() => { handleRemoveClick2(i, !!shareholder[i].id ? shareholder[i].id : null) }}>Remove</Button> : ''}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].frontID_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].frontID).replace('{i}', (i + 1).toString())}</span> :
                                                                        <a href={`${shareholder[i].frontID}`} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].frontID).replace('{i}', (i + 1).toString())}</a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].frontID = event.target.files[0].name
                                                                    setShareholder(newdata);

                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].frontID = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'frontID', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].frontID == `Shareholder  {i} - National ID Front Picture` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}
                                                            </label>
                                                            {shareholder[i].frontID_new == null ? <div className='aDADADaDsfgf'> <UploadIcon /></div> :

                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].frontID_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].backID_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].backID).replace('{i}', (i + 1).toString())}</span> :
                                                                        <a href={`${shareholder[i].backID}`} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].backID).replace('{i}', (i + 1).toString())}</a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].backID = event.target.files[0].name
                                                                    setShareholder(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].backID = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'backID', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].backID == `Shareholder  {i} - National ID Back Picture` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}

                                                            </label>
                                                            {shareholder[i].backID_new == null ? <div className='aDADADaDsfgf'> <UploadIcon /></div> :

                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].backID_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].KRN_certi_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].KRN_certi).replace('{i}', (i + 1).toString())}
                                                                        </span> : <a href={`${shareholder[i].KRN_certi}`} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].KRN_certi).replace('{i}', (i + 1).toString())}
                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].KRN_certi = event.target.files[0].name
                                                                    setShareholder(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].KRN_certi = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'KRN_certi', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].KRN_certi == `Shareholder  {i} - KRA PIN Certificates` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}

                                                            </label>
                                                            {shareholder[i].KRN_certi_new == null ? <div className='aDADADaDsfgf'> <UploadIcon /></div> :

                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].KRN_certi_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className="complete-profile__btn_box">
                                                            {shareholder.length - 1 === i && <Button className='complete-profile__ADDBTNHHA' onClick={handleAddClick2}>+ Add another Shareholder </Button>} */}
                        {/* {inputList2.length !== 1 && <Button className='complete-profile__removebtn' onClick={() => {handleRemoveClick2(i) }}>Remove</Button>} */}

                        {/* </div>
                                                    </div>
                                                );
                                            })} */}
                      </>
                    ) : userDetail === true && typevalue == '1' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={`${inputlfast.certi_of_incorpo}`}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_incorpo == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Proprietor's Details
                        </p>
                        {groupOneName.map((x, i) => {
                          console.log(groupOneName, 'groupOneName');

                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Proprietor {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              {/* <p className='complete-profile__director'>Proprietor {i + 1}</p> */}
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    {/* <div > */}
                                    {/* <a download={`${groupOneName[i].frontID}`} href="/path/to/image" title="ImageName"> */}
                                    {/* ZSDADddddADd
                                                                </a> */}
                                    <div
                                      className="complete-profile__upload__label"

                                      // href={`${groupOneName[i].frontID}`}
                                      // download target="_blank"
                                    >
                                      {groupOneName[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].frontID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax1Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'proprietor',
                                              '4',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />

                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />{' '}
                                  </div>
                                ) : (
                                  <div className="aDADADaDsfgf">
                                    <CloseIcon
                                      onClick={() => {
                                        remove_data(
                                          groupOneName[i].frontID_new
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].backID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[
                                                i
                                              ].secend_data.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'proprietor',
                                              '4',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].KRN_certi}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[
                                                i
                                              ].thred_data.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'proprietor',
                                              '4',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />{' '}
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another proprietor
                                  </Button>
                                )}
                                {/* {inputList.length !== 1 && <Button className='complete-profile__removebtn' onClick={handleRemoveClick}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '5' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Certificate of registration'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_incorpo}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Certificate of registration'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      console.log(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_incorpo == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div
                                id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.certi_of_compliance_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_compliance
                                      ? inputlfast.certi_of_compliance.replace(
                                          '{type}',
                                          'Trust deed or Exemption'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_compliance}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.secend_data
                                      ? inputlfast.secend_data.replace(
                                          '{type}',
                                          'Trust deed or Exemption'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                disabled={!!businessdoc.id ? false : true}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_compliance =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_compliance = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_compliance',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_compliance == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_compliance_new == null ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_compliance_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>

                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div
                                id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.CR_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.CR
                                      ? inputlfast.CR.replace(
                                          '{type}',
                                          "Trust's KRA PIN Certificate or Tax Exemption Certificate"
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.CR}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.thred_data
                                      ? inputlfast.thred_data.replace(
                                          '{type}',
                                          "Trust's KRA PIN Certificate or Tax Exemption Certificate"
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                disabled={!!businessdoc.id ? false : true}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.fast_trustee_three =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.CR = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'CR',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.CR == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.CR_new == null ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.CR_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Trustee's Details
                        </p>
                        {groupOneName.map((x, i) => {
                          return (
                            <div className="box">
                              {/* <p className='complete-profile__director'>Trustee {i + 1}</p> */}
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Trustee {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {groupOneName[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        console.log(
                                          'clicked 1 before',
                                          groupOneName,
                                          groupTwoName
                                        );
                                        const newdataGroup = groupOneName;
                                        newdataGroup[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdataGroup);
                                        console.log(
                                          'clicked 1 after',
                                          groupOneName,
                                          groupTwoName
                                        );

                                        setFileTax1(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'trustee',
                                              '5',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[i].secend_data
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'trustee',
                                              '5',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Trustee's"
                                              ).replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[i].thred_data
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'trustee',
                                              '5',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another trustee
                                  </Button>
                                )}
                                {/* {inputList.length !== 1 && <Button className='complete-profile__removebtn' onClick={handleRemoveClick}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}

                        {/* <p className='complete-profile__businesstypo'>Shareholders’ Details</p>
                                            {shareholder.map((x, i) => {
                                                return (
                                                    <div className="box"> */}
                        {/* <div className="complete-profile__btn_box">
                                                            <p className='complete-profile__director'>Shareholder {i + 1}</p>
                                                            {i >= 1 ? <Button className='complete-profile__removebtn' onClick={() => { handleRemoveClick2(i, !!shareholder[i].id ? shareholder[i].id : null) }}>Remove</Button> : ''}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].frontID_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].frontID).replace('{i}', (i + 1).toString())}
                                                                        </span> :
                                                                        <a href={shareholder[i].frontID} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].frontID).replace('{i}', (i + 1).toString())}


                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].frontID = event.target.files[0].name
                                                                    setShareholder(newdata);

                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].frontID = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'frontID', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].frontID == `Shareholder  {i} - National ID Front Picture` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}
                                                            </label>
                                                            {shareholder[i].frontID_new == null ? <div className='aDADADaDsfgf'><UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].frontID_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].backID_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].backID).replace('{i}', (i + 1).toString())}
                                                                        </span> :
                                                                        <a href={shareholder[i].backID} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].backID).replace('{i}', (i + 1).toString())}

                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].backID = event.target.files[0].name
                                                                    setShareholder(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].backID = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'backID', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].backID == `Shareholder  {i} - National ID Back Picture` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}

                                                            </label>
                                                            {shareholder[i].backID_new == null ? <div className='aDADADaDsfgf'><UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].backID_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].KRN_certi_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].KRN_certi).replace('{i}', (i + 1).toString())}
                                                                        </span> :
                                                                        <a href={shareholder[i].KRN_certi} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].KRN_certi).replace('{i}', (i + 1).toString())}

                                                                        </a>}


                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].KRN_certi = event.target.files[0].name
                                                                    setShareholder(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].KRN_certi = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'KRN_certi', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].KRN_certi == `Shareholder  {i} - KRA PIN Certificates` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}

                                                            </label>
                                                            {shareholder[i].KRN_certi_new == null ? <div className='aDADADaDsfgf'><UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].KRN_certi_new) }}>
                                                                    <CloseIcon /></div>}

                                                        </div> */}
                        {/* <div className="complete-profile__btn_box">
                                                            {shareholder.length - 1 === i && <Button className='complete-profile__ADDBTNHHA' onClick={handleAddClick2}>+ Add another Shareholder </Button>} */}
                        {/* {inputList2.length !== 1 && <Button className='complete-profile__removebtn' onClick={() => {handleRemoveClick2(i) }}>Remove</Button>} */}

                        {/* 
                                                        </div>
                                                    </div>
                                                );
                                            })} */}
                      </>
                    ) : userDetail === true && typevalue == '4' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Certificate of Registration or Exemption'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_incorpo}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Certificate of Registration or Exemption'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_incorpo == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div
                                id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.certi_of_compliance_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_compliance
                                      ? inputlfast.certi_of_compliance.replace(
                                          '{type}',
                                          'Introduction letter from the NGOs Co-ordination Board'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_compliance}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.secend_data
                                      ? inputlfast.secend_data.replace(
                                          '{type}',
                                          'Introduction letter from the NGOs Co-ordination Board'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                disabled={!!businessdoc.id ? false : true}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_compliance =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_compliance = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_compliance',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_compliance == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_compliance_new == null ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_compliance_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div
                                id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.CR_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.CR
                                      ? inputlfast.CR.replace(
                                          '{type}',
                                          'Constitution of the organization'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.CR}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.thred_data
                                      ? inputlfast.thred_data.replace(
                                          '{type}',
                                          'Constitution of the organization'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                disabled={!!businessdoc.id ? false : true}
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.CR = event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.CR = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'CR',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.CR == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.CR_new == null ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.CR_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label className="complete-profile__upload">
                              <div
                                id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.KRA_new == null ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.KRA
                                      ? inputlfast.KRA.replace(
                                          '{type}',
                                          'KRA Pin Certificate or Exemption for the NGO'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.KRA}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.frore_data
                                      ? inputlfast.frore_data.replace(
                                          '{type}',
                                          'KRA Pin Certificate or Exemption for the NGO'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                disabled={!!businessdoc.id ? false : true}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.KRA = event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.KRA = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'KRA',
                                        result,
                                        event.target.files[0].name
                                      );
                                    }
                                  );
                                }}
                              />
                              {businessdoc.KRA == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.KRA_new == null ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.KRA_new);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Official's Details
                        </p>
                        {groupOneName.map((x, i) => {
                          console.log(groupOneName, 'groupOneNameSAdDD');

                          return (
                            <div className="box">
                              {/* <p className='complete-profile__director'>Official {i + 1}</p> */}
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Official {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {groupOneName[i].frontID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        setFileTax1(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'official',
                                              '6',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].frontID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[i].secend_data
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'official',
                                              '6',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                                  <label className="complete-profile__upload">
                                    <div
                                      id={
                                        !!groupOneName[i].id
                                          ? ''
                                          : 'data_disebal'
                                      }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Official's"
                                              ).replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[i].thred_data
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      disabled={
                                        !!groupOneName[i].id ? false : true
                                      }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'official',
                                              '6',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another official
                                  </Button>
                                )}
                                {/* {inputList.length !== 1 && <Button className='complete-profile__removebtn' onClick={handleRemoveClick}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}

                        {/* <p className='complete-profile__businesstypo'>Shareholders’ Details</p>
                                            {shareholder.map((x, i) => {
                                                return (
                                                    <div className="box">
                                                        <div className="complete-profile__btn_box">
                                                            <p className='complete-profile__director'>Shareholder {i + 1}</p>
                                                            {i >= 1 ? <Button className='complete-profile__removebtn' onClick={() => { handleRemoveClick2(i, !!shareholder[i].id ? shareholder[i].id : null) }}>Remove</Button> : ''}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].frontID_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].frontID).replace('{i}', (i + 1).toString())}
                                                                        </span> :
                                                                        <a href={shareholder[i].frontID} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].frontID).replace('{i}', (i + 1).toString())}

                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].frontID = event.target.files[0].name
                                                                    setShareholder(newdata);

                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].frontID = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'frontID', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].frontID == `Shareholder  {i} - National ID Front Picture` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}
                                                            </label>
                                                            {shareholder[i].frontID_new == null ? <div className='aDADADaDsfgf'><UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].frontID_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].backID_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].backID).replace('{i}', (i + 1).toString())}
                                                                        </span> :
                                                                        <a href={shareholder[i].backID} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].backID).replace('{i}', (i + 1).toString())}
                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].backID = event.target.files[0].name
                                                                    setShareholder(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].backID = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'backID', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].backID == `Shareholder  {i} - National ID Back Picture` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}

                                                            </label>
                                                            {shareholder[i].backID_new == null ? <div className='aDADADaDsfgf'><UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].backID_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className='flax_data_list'>
                                                            <label className='complete-profile__upload'>
                                                                <div className='complete-profile__upload__label'>
                                                                    {shareholder[i].KRN_certi_new == null ?
                                                                        <span className='complete-profile__upload__txt'>
                                                                            {(shareholder[i].KRN_certi).replace('{i}', (i + 1).toString())}
                                                                        </span> :
                                                                        <a href={shareholder[i].KRN_certi} className='complete-profile__upload__txt' download>
                                                                            {(shareholder[i].KRN_certi).replace('{i}', (i + 1).toString())}

                                                                        </a>}

                                                                </div>
                                                                <input type="file" onChange={(event: any) => {
                                                                    var idOfIndex = !!shareholder[i].id ? shareholder[i].id : null
                                                                    const newdata = shareholder;
                                                                    newdata[i].KRN_certi = event.target.files[0].name
                                                                    setShareholder(newdata);
                                                                    getBase64(event.target.files[0], (result: any) => {
                                                                        // var shareHolderDc = ShareHolderdoc;
                                                                        // shareHolderDc[i].KRN_certi = result;
                                                                        // setShareHoldersDocs(shareHolderDc)
                                                                        handleChangeFile(idOfIndex, 'shareholders', '1', 'KRN_certi', result)
                                                                    });
                                                                }} />
                                                                {shareholder[i].KRN_certi == `Shareholder  {i} - KRA PIN Certificates` && submitted == true ? <p className='addnewpere'>Company’s KRA PIN Certificate is required</p> : ''}

                                                            </label>
                                                            {shareholder[i].KRN_certi_new == null ? <div className='aDADADaDsfgf'> <UploadIcon /></div> :
                                                                <div className='aDADADaDsfgf'
                                                                    onClick={() => { remove_data(shareholder[i].KRN_certi_new) }}>
                                                                    <CloseIcon /></div>}
                                                        </div> */}
                        {/* <div className="complete-profile__btn_box">
                                                            {shareholder.length - 1 === i && <Button className='complete-profile__ADDBTNHHA' onClick={handleAddClick2}>+ Add another Shareholder </Button>} */}
                        {/* {inputList2.length !== 1 && <Button className='complete-profile__removebtn' onClick={() => {handleRemoveClick2(i) }}>Remove</Button>} */}

                        {/* 
                                                        </div>
                                                    </div>
                                                );
                                            })} */}
                      </>
                    ) : (
                      <>
                        <div className="complete-profile__heading">
                          Please complete your account setup{' '}
                        </div>
                        <Form.Select
                          style={{ fontSize: '13px' }}
                          className="complete-profile__SELECT"
                          aria-label="Default select example"
                          onChange={(e) => {
                            console.log('e.target.value', e.target.value);
                            setType(e.target.value);
                            setGroupOneName([commonStatic]);
                            setGroupTwoName([commonStatiTwo]);
                            setDirectorDoc([directorDocStatic]);
                            setShareHoldersDocs([directorDocStaticTwo]);
                          }}
                        >
                          <option>Type of Organisation</option>
                          {Array.isArray(entityType)
                            ? entityType?.map((element: any) => {
                                return (
                                  <option key={element.id} value={element.id}>
                                    {element.name}
                                  </option>
                                );
                              })
                            : 'data not found'}
                        </Form.Select>
                        {userDetail == true && typevalue == '' ? (
                          <p className="listtexterrr">This field is required</p>
                        ) : (
                          ''
                        )}
                        <Button
                          className="complete-profile__btn2"
                          onClick={() => setUserDetail(true)}
                        >
                          Next
                        </Button>
                      </>
                    )}

                    {userDetail === true && typevalue != '' && (
                      <Box className="complete-profile__boxbtndisplay">
                        {data_go_back == true ? (
                          <Button
                            className="complete-profile__saventmm"
                            // onClick={() => handleSave()}
                            onClick={() => {
                              setType('0');
                              setUserDetail(false);
                            }}
                          >
                            Go Back
                          </Button>
                        ) : (
                          ''
                        )}
                        <Button
                          type="submit"
                          className="complete-profile__submitbtnlist"
                          onClick={() => {
                            setSubmitted(true);
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    )}
                  </ThemeProvider>
                </Form>
              </>
            )}
          </Formik>
        </div>
      ) : status == 'approved' && users?.data?.entity?.status == 'VERIFIED' ? (
        <VerifiedPopup page="lastpage" onSave={true} />
      ) : status == 'rejected' ? (
        <Model_Reject onSave={true} />
      ) : (
        <ModelumentVerified page="lastpage" onSave={true} />
      )}
      {loader && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export const ShareholderCom = (props: Props) => {};
const UploadIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13"
      stroke="#025041"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
