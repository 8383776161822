import React, { useState, useEffect } from 'react';
import "../Settings.scss";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useSearchParams } from "react-router-dom";
import { getUser,isUserAuthenticated } from "../../../../../_helpers";
import PhoneInput from 'react-phone-input-2'
import { Button, InputGroup, Table } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {entityActions} from "../../../../../_actions/entity.actions";
import {useDispatch, useSelector } from 'react-redux'
import {settingSelector} from "../../../../../_reducers/setting.reducer";
import {settingActions} from "../../../../../_actions/setting.actions";
import { PasswordUpdate } from '../components/Profile/PasswordUpdate';

export const Profile = () => {
    const [users, setUsers] = useState<any>("");
    const [userDetails, setUserDetails] = useState<any>();
    const [showPassword, setShowPass] = useState(false);
    const [entity, setEntity] = useState<any>({
      first_name: '',
      last_name: '',
    })
    const [render, setRender] = useState(true);
    const [entityType, setEntityType] = useState<any>();
    const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userRender, setUserRender] = useState(true);
    const [userDetailsRender, setUserDetailsRender] = useState(true);
    let navigate = useNavigate();
    let location = useLocation();
    const userData = useSelector((state) => settingSelector(state))
    // const onCancel = () => {
    //   props.onCancel();
    // }
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (isUserAuthenticated() && render ) {
          setRender(false);
          dispatch(settingActions.getRolsDocuments() as any)
        }
   }, [render, isUserAuthenticated]);

  React.useEffect(() => {
    if (getUser && userDetailsRender) {
      setUserDetailsRender(false);
      setUserDetails(getUser);
    }
  }, [userDetailsRender,getUser]);

  
   React.useEffect(() => {
      if(!!userDetails){
        const bodyReq = {
          id : userDetails.data.user_id
        }
        dispatch(settingActions.getUserDetail(bodyReq,"no") as any)
      }
  }, [userDetails]);
  
  React.useEffect(() => {
    if (isInitialTypeRender && userData?.ROLSDATA_SUCCESS !== undefined) {
        setIsInitialTypeRender(false);
        setEntityType(userData.ROLSDATA_SUCCESS);
    }
  }, [userData, isInitialTypeRender]);
  
  React.useEffect(() => {
    if (userRender && userData?.GETUSERDETAIL_SUCCESS !== undefined) {
      setUserRender(false);
      setUsers(userData?.GETUSERDETAIL_SUCCESS)
      var object = {
        first_name: !!userData?.GETUSERDETAIL_SUCCESS.first_name ? userData?.GETUSERDETAIL_SUCCESS.first_name : '',
        last_name: !!userData?.GETUSERDETAIL_SUCCESS.last_name ? userData?.GETUSERDETAIL_SUCCESS.last_name : '',
        company_type: !!userData?.GETUSERDETAIL_SUCCESS.role_id ? userData?.GETUSERDETAIL_SUCCESS.role_id : ''
      }
      setEntity(object)
    }
  }, [userData, userRender]);
   const validationSchema = Yup.object().shape({
    first_name: Yup.string()
       .required('Please enter your first name.'),
    last_name: Yup.string()
       .required('Please enter your last name.'),
    company_type: Yup.string()
       .required('Please enter the role.')
  })
  const handleSubmit = (e: any) => {
    if(!!userDetails.data.user_id && !!e && e.first_name && e.last_name && e.company_type){
      const reqBody = {
        user_id : userDetails.data.user_id ,
        first_name : e.first_name,
        last_name : e.last_name,
        role_id :e.company_type
      }
      dispatch(settingActions.updateUser(reqBody) as any)
    }
  }
  

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        // <ThemeProvider theme={theme}>
            <div className='accoun-management'>
                <div className='accoun-management__header' style={{marginBottom: '35px'}}>
                    Profile
                </div>
                {
        showPassword == false && (
          <div className='user-detail-container' style={{marginTop: '20px'}}>
            <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting, handleChange, values }) => (
                  <div className='company-details-container__wrapper'>
                              {/* <div style={{padding:'20px 0px',textAlign:'center'}} className='company-details-container__heading'>Business Management</div> */}

               <Form>
               <div className='user-detail-container__wrapper'>
              
              {/* <img src="/assets/user-icon.png" width="116px" /> */}
              <div className="first-last-name">
                <div className="input-form-wrapper m-10">
                  <label>First name</label>
                  <Field
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    className={
                      "text-field field-enable" +
                      (errors.first_name && touched.first_name
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="invalid-feedback red"
                  />
                </div>
                <div className="input-form-wrapper m-10">
                  <label>Last name</label>
                  <Field
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    className={
                      "text-field field-enable" +
                      (errors.last_name && touched.last_name
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="invalid-feedback red"
                  />
                </div>
              </div>
              <div className='input-form-wrapper'>
                <label>Email</label>
                <input type="email" id='disebal' disabled className='text-field readonly' value={!!users ? users.email  : ''} />
              </div>
              <div className='input-form-wrapper'>
                <label>Mobile</label>
                <PhoneInput

                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true.valueOf,
                    readOnly: true

                  }}
                  country={'ke'}
                  prefix='+'
                  value={!!users ? users.mobile : ''}

                  onChange={(value, country, e, formattedValue) => {

                  }}

                  inputClass={"inputBx-mobile readonly"}
                  autoFormat={false}
                  disabled
                //onChange={(e) => setPhone(e)}
                />
              </div>
              <div className='input-form-wrapper'>
                <label>User role</label>
                <Field name="company_type" disabled as="select" style={{cursor: "no-drop"}} className={'text-field select-box' + (errors.company_type && touched.company_type ? ' is-invalid' : '')} >
                    <option value="">--Select--</option>
                    {Array.isArray(entityType)
                        ? entityType?.map((element: any) => {
                            return <option key={element.id} value={element.id}>{element.name}</option>
                        })
                        : "data not found"}
                </Field>
                <ErrorMessage name="company_type" component="div" className="invalid-feedback red" />
              </div>
              {/* <div className='input-form-wrapper'>
                <label>User role</label>
                <input type="text" id='disebal' disabled className='text-field readonly' value="Owner" />
              </div> */}

              <div className="setting-modal__btn-row d-flex flex-center justify-content-center align-items-center gap-10">
                <div>
                  <Button type = 'submit' className='complete-profile__submitbtnlist' > Save
                  </Button>
                </div>
                 <div>
                  <Button style={{textTransform:"capitalize"}} className='complete-profile__submitbtnlist' onClick = {() => setShowPass(true)} >Update Password
                  </Button>
                </div> 
                
              </div>
            </div>
               </Form>
               </div>
            )}
         </Formik>
            
          </div>
        )}
      {
        showPassword && (
          <ThemeProvider theme={theme}>
        <PasswordUpdate onCancel={() => {
          setShowPass(false);
        }} />
        </ThemeProvider>
        )

      }
            </div>
        // </ThemeProvider>
    )
}


