import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import "../RecipientBank/RecipientBank.scss";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isUserAuthenticated } from "../../../../../../_helpers";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { userActions, dashboardActions } from "../../../../../../_actions";
import moment from 'moment';
import { useSearchParams } from "react-router-dom";

const DigitalCurrency = (props: any) => {
    const [entity, setEntity] = useState<any>({
        Digital_currency: '',
        Amountpurchase: '',
        Wallet_address: '',
        Wallet_network: ""
    })
    const [Digital_currencyList, setDigital_currencyList] = useState<any>([{
        name: "USDT"
    },
    {
        name: "USDC "
    }]);
    const [Wallet_networkList, setWallet_networkList] = useState<any>([{
        name: "ERC20"
    },
    {
        name: "TRC20 "
    }]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [bankvalid, setBankvlid] = useState('1')
    const [loading, setLoading] = React.useState(false);
    const [render, setRender] = useState(true);
    const [bankListRender, setBankListRender] = useState<any>(true);
    const [bank_name, setBank_name] = useState('')
    const [apidata, setApidata] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
    })
    const [datadisplay, setDatadisplay] = useState('')

    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const dispatch = useDispatch();

    useEffect(() => {
        const popup = searchParams.get('type');
        if (popup == 'Express Payment KES') {
            setDatadisplay(popup)
        } else if (popup == 'Express Payment USD') {
            setDatadisplay(popup)
        } else {
            setDatadisplay('')
        }
    }, [searchParams]);
    const onCancel = () => {
        props.onCancel();
        setBankvlid("1")
    }


    const validationSchema = Yup.object().shape({
        Digital_currency: Yup.string()
            .required('Please select the Digital currency.'),
        Amountpurchase: Yup.string()
            .required('Please enter the Amount you want to purchase.'),
        Wallet_address: Yup.string()
            .required('Please enter the Wallet address.'),
        Wallet_network: Yup.string()
            .required('Please select the Wallet network.'),
    })
    const onSave = (bank: any) => {
        props.onSave(bank);
    }
    return (
        <>

            <div className='recipient-bank-container'>
                <div style={{ textAlign: "left", marginBottom: "6px",padding:"0px 0px 0px 27px" }} className='recipient-bank-container__heading'>
                Digital currency conversion
                </div>
                <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        var Date_formet = new Date();
                        const body = {
                            "digital_currency": values.Digital_currency,
                            "amount_to_purchase": values.Amountpurchase,
                            "wallet_address": values.Wallet_address,
                            "wallet_network": values.Wallet_network,
                            payment_reference: props?.nameRef,
                            "payment_channel": props?.payOption,
                            transaction_date: moment(Date_formet).format("YYYY-MM-DD"),
                            currency: datadisplay == "Express Payment USD" ? "USD" : "KES",
                            payment_method: "digital_currency",
                            counterpart: props.body_data.counterpart,
                            amount: props.body_data.amount,
                            tags: props.body_data.tags,
                            counterpart_type: props.body_data.counterpart_type,
                            is_tax: props.body_data.is_tax,
                            vat: props?.body_data?.vat
                        }
                        dispatch(userActions.getOtp('') as any)
                        onSave(body)

                    }}
                >
                    {({ errors, touched, isSubmitting, handleChange, values }) => (
                        <div className='company-details-container__wrapper'>
                            <Form>
                                <div className='user-detail-container__wrapper'>
                                    <div className='input-form-wrapper dot'>

                                        <label>Digital currency</label>
                                        <Field name="Digital_currency" as="select"
                                            className={'text-field select-box' + (errors.Digital_currency && touched.Digital_currency ? ' is-invalid' : '')} >
                                            <option value="">--Select Digital currency--</option>
                                            {Array.isArray(Digital_currencyList)
                                                ? Digital_currencyList?.map((element: any) => {
                                                    return <option key={element.name} value={element.name}>{element.name}</option>
                                                })
                                                : "data not found"}
                                        </Field>
                                        <ErrorMessage name="Digital_currency" component="div" className="invalid-feedback red" />
                                    </div>

                                    <div className="input-form-wrapper mb-4 dot">
                                        <label>Amount you want to purchase</label>
                                        <Field
                                            name="Amountpurchase"
                                            type="text"
                                            placeholder="Amount you want to purchase"
                                            className={
                                                "text-field field-enable" +
                                                (errors.Amountpurchase && touched.Amountpurchase
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="Amountpurchase"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className="input-form-wrapper m-10 dot">
                                        <label>Wallet address</label>
                                        <Field
                                            name="Wallet_address"
                                            type="text"
                                            placeholder="Wallet address"
                                            className={
                                                "text-field field-enable" +
                                                (errors.Wallet_address && touched.Wallet_address
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="Wallet_address"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div>
                                    <div className='input-form-wrapper dot'>

                                        <label>Wallet network</label>
                                        <Field name="Wallet_network" as="select"
                                            // onChange={(e:any)=>{setBank_name(e.target.value)}}
                                            className={'text-field select-box' + (errors.Wallet_network && touched.Wallet_network ? ' is-invalid' : '')} >
                                            <option value="">--Select Wallet network--</option>
                                            {Array.isArray(Wallet_networkList)
                                                ? Wallet_networkList?.map((element: any) => {
                                                    return <option key={element.id} value={element.name}>{element.name}</option>
                                                })
                                                : "data not found"}
                                        </Field>
                                        <ErrorMessage name="Wallet_network" component="div" className="invalid-feedback red" />
                                    </div>
                                    <div className="auth-trans-container__btn-row">
                                        <div>
                                            <Button className='complete-profile__saventmm' variant='custom'
                                                onClick={() => { onCancel(); }}
                                            >   Cancel  </Button>
                                        </div>
                                        <div>
                                            <Button type='submit'
                                                disabled={loading}
                                                style={{ display: "flex", alignItems: "center", }}
                                                className='complete-profile__submitbtnlist' >

                                                <p style={{ textTransform: "capitalize" }}>Continue</p>{loading == true && <div style={{ marginLeft: "6px" }} className="spinner-border spinner-border-sm mr-1"></div>}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div >

        </>
    )
}
export default DigitalCurrency;