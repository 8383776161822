import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './Invite_users.scss';
import * as Yup from 'yup';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import { Box } from '@mui/material';
import Invited_Successfully from './Invited_Successfully';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../../../../../../_actions/setting.actions';
import { settingSelector } from '../../../../../../../_reducers/setting.reducer';
import axios from 'axios';
import {
  getUser,
  isUserAuthenticated,
  authHeader,
  getProperties,
} from '../../../../../../../_helpers';
import amplitude from 'amplitude-js';
import posthog from 'posthog-js';
import { getEnvironment } from '../../../../../../../_helpers/environmentProperties';

interface Props {
  onCancel: any;
}

export const Comingsoon = (props: Props) => {
  const [inputList, setInputList] = useState([
    { firstName: '', lastName: '', entityid: '', team: '' },
  ]);
  const [openpopup, setOpenpopup] = useState(false);
  console.log(inputList, 'inputList');
  const [addnewteab, setAddnewteb] = useState(false);
  const [render, setRender] = useState(true);
  const [renderSubmit, setRenderSubmit] = useState(true);
  const [datalist, setDatalist] = useState<any>([]);
  const [renderUser, setRenderUser] = React.useState(true);
  const [userData, setUserData] = React.useState<any>([]);
  console.log(addnewteab, 'addnewteab');
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const dispatch = useDispatch();
  console.log(datalist, 'datalistdatalist');
  const baseURL =
    getEnvironment() === 'production'
      ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_STAGING_API_URL;
  const properties = getProperties();
  // useEffect(() => {
  //     var localUser: any = localStorage.getItem('user');
  //     const newdata = inputList;
  //     newdata.entityid = localUser.data.entity.id
  //     setInputList(newdata);
  //     // setInputList(localUser.data.entity.id)
  // }, [])
  // useEffect(() => {
  //     if (render) {
  //         setRender(false)

  //         // dispatch(entityActions.getEntityActivities() as any)
  //     }
  // }, [render])
  React.useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      // var data = `page=${1}`;
      dispatch(settingActions.getTeamDocuments() as any);
      dispatch(settingActions.getRolsDocuments() as any);
    }
  }, [render, isUserAuthenticated]);
  React.useEffect(() => {
    if (renderUser && dataEntity?.TEAMDOCUMENT_SUCCESS !== undefined) {
      setUserData(dataEntity.TEAMDOCUMENT_SUCCESS);
      // setTotalCount(dataUser.USERDOCUMENT_SUCCESS.data.total)
      setRenderUser(false);
    }
  }, [dataEntity, renderUser]);
  React.useEffect(() => {
    if (renderSubmit && dataEntity?.ROLSDATA_SUCCESS !== undefined) {
      console.log(
        'Succesfully Sumbit Entity list two',
        dataEntity?.ROLSDATA_SUCCESS
      );
      var Array = [];
      for (
        let index = 0;
        index < dataEntity?.ROLSDATA_SUCCESS.length;
        index++
      ) {
        const element = dataEntity?.ROLSDATA_SUCCESS[index];
        if (element.name == 'Director' || element.name == 'Developer')
          Array.push(element);
      }
      setDatalist(Array);
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);
  const onCancel = () => {
    props.onCancel();
  };
  const formInitailData = {
    users: inputList,
  };
  // React.useEffect(() => {
  //     setInputList(users)
  // }, [users])
  const validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string()
          .email('Invalid Email Address')

          // .min(2, "*Names must have at least 2 characters")
          // .max(100, "*Names can't be longer than 100 characters")
          .required('*Please enter the email address')
          .test(
            'Unique Email',
            'Email already in use', // <- key, message
            function (value) {
              return new Promise((resolve, reject) => {
                console.log('method Callled');
                const requestOptions = {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    ...authHeader(),
                  },
                };
                axios
                  .get(`${baseURL}/check-email/${value}`, requestOptions)
                  .then((res) => {
                    console.log(res, 'res');
                    resolve(true);
                  })
                  .catch((error) => {
                    resolve(false);
                    // if (error.response.data.content === "The email has already been taken.") {
                    //     resolve(false);
                    // }
                  });
              });
            }
          ),
        // team: Yup.string().required("*Team is required"),
        lastName: Yup.string().required("Please enter the user's role"),
      })
    ),

    // blog: Yup.string()
    // .url("*Must enter URL in http://www.example.com format")
    // .required("*URL required")
  });
  const [age, setAge] = React.useState('');
  console.log(age, 'ageage');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  const ADDLISY = () => {
    setInputList([
      ...inputList,
      { firstName: '', lastName: '', entityid: '', team: '' },
    ]);
  };
  const handleRemoveClicktwo = (index: any) => {
    console.log(index);
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  return (
    <>
      {openpopup == true ? (
        <Invited_Successfully />
      ) : (
        <div className="addinvitelisttwo">
          <p className="addinvitetext">Invite Users</p>
          <p className="addtestdiscer">
            Add your teammates to current account by entering emails and
            selecting roles for each of them.
          </p>
          <Formik
            enableReinitialize
            initialValues={formInitailData}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log('submit');
              console.log(values, 'valuesvalues');
              setOpenpopup(true);
              // console.log(values, patternDataArray, 'submitted');
              const ReqBody: any = { data: [] };
              // var localUser: any = localStorage.getItem('user');
              inputList?.map((values: any) => {
                const body = {
                  email: values.firstName,
                  role_id: values.lastName,
                  team_id: Number(values.team),
                  // entity_id: 26
                };
                ReqBody.data.push(body);
              });

              console.log(ReqBody, 'ReqBody');
              // var datalog = [];
              // setLoading(true);
              dispatch(settingActions.getInviteDocuments(ReqBody) as any);
              // localStorage.setItem('Bulkdata', JSON.stringify([]))
              const event = 'New User Invited';
              const eventProperties = {
                userId:
                  !!properties && !!properties.user_id
                    ? properties.user_id
                    : null,
                userName:
                  !!properties && !!properties.name ? properties.name : null,
                email:
                  !!properties && !!properties.email ? properties.email : null,
                pageUrl: window.location.href,
              };

              amplitude.getInstance().logEvent(event, eventProperties);
              posthog.capture(event, eventProperties);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <FieldArray
                    name="users"
                    render={(arrayHelpers) => {
                      const users = inputList;
                      return (
                        <>
                          <Box className="box_list_maintwo">
                            <p className="emaillebal">Email address</p>
                            <p className="emaillebal">Team</p>
                            <p className="emaillebal">User’s role</p>
                          </Box>
                          {users
                            ? users.map((user: any, index: any) => {
                                // var value = user;
                                console.log(user, 'useruser');

                                return (
                                  <div className="mainform">
                                    <Box className="box_list_main">
                                      <Box className="box_list">
                                        <input
                                          type="text"
                                          className="text-field"
                                          // value={user.firstName}
                                          // defaultValue={user.firstName}
                                          name={`users.${index}.firstName`}
                                          onChange={(e: any) => {
                                            const newdata = users;
                                            // newdata[index].entityid = 26';
                                            newdata[index].firstName =
                                              e.target.value;
                                            setInputList(newdata);
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`users.${index}.firstName`}
                                          component="div"
                                          className={'addnewdaddaa'}
                                        />
                                      </Box>
                                      <Box className="box_list">
                                        <Form.Select
                                          style={{ fontSize: '11px' }}
                                          // name={`users.${index}.team`}
                                          onChange={(e: any) => {
                                            const newdata = users;
                                            // newdata[index].entityid = 26';
                                            newdata[index].team =
                                              e.target.value;
                                            setInputList(newdata);
                                          }}
                                          className={'doc-form-input'}
                                        >
                                          <option selected value="">
                                            Select team
                                          </option>
                                          {userData?.map(
                                            (
                                              arrayElement: any,
                                              index: number
                                            ) => {
                                              return (
                                                <option
                                                  value={arrayElement.id}
                                                  key={index + 1}
                                                >
                                                  {arrayElement.name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </Form.Select>
                                        {/* <ErrorMessage name={`users.${ipxndex}.team`} component="div"
                                                                                className={'addnewdaddaa'}
                                                                            /> */}
                                      </Box>
                                      <Box className="box_list">
                                        <Autocomplete
                                          autoHighlight
                                          className="addautosert"
                                          // name={`users.${index}.lastName`}
                                          options={datalist}
                                          onChange={(
                                            event,
                                            listid,
                                            reason,
                                            details
                                          ) => {
                                            // for (let index = 0; index < inputList.length; index++) {
                                            //     const element = inputList[index];

                                            // }
                                            const newdata = users;
                                            newdata[index].lastName = listid.id;
                                            setInputList(newdata);
                                          }}
                                          // style={{width:'300px'}}
                                          getOptionLabel={(option: any) =>
                                            option.name
                                          }
                                          renderOption={(props, option) => (
                                            <Accordion>
                                              <AccordionSummary
                                                className="addmenuitem"
                                                expandIcon={<ExpandMoreIcon />}
                                              >
                                                <Box
                                                  className="addnewowenwr"
                                                  component="li"
                                                  style={{ display: 'block' }}
                                                  {...props}
                                                >
                                                  <div className="newonewtext">
                                                    {option.name}
                                                  </div>
                                                </Box>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <ul>
                                                  {option?.permissions?.map(
                                                    (x: any, i: any) => {
                                                      return (
                                                        <li className="list_li_data_logg">
                                                          {x}
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </AccordionDetails>
                                            </Accordion>
                                            // </MenuItem>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              //   label="Choose a country"
                                              //   inputProps={{
                                              //     // ...params.inputProps,
                                              //     autoComplete: 'new-password', // disable autocomplete and autofill
                                              //   }}
                                            />
                                          )}
                                        />

                                        <ErrorMessage
                                          name={`users.${index}.lastName`}
                                          component="div"
                                          className={'addnewdaddaa'}
                                        />
                                      </Box>
                                    </Box>
                                    <div className="addbtninput">
                                      {users.length - 1 === index && (
                                        <Button
                                          id={'adduserbtn'}
                                          className="complete-profile__ADDBTNHHA"
                                          onClick={ADDLISY}
                                        >
                                          + Add Another User
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      );
                    }}
                  />
                  <div className="seve_and_create_button">
                    <Button
                      variant="primary"
                      id="two_list_go_bank"
                      onClick={() => {
                        window.location.href = '/settings/team';
                      }}
                      className="payDetails-modal__btn-row__white"
                    >
                      Go Back
                    </Button>
                    {/* {create_budget==} */}
                    <Button
                      variant="primary"
                      //    onClick={() => { setSave_data('create') }}
                      type="submit"
                      id={'addinvitebtn'}
                      className="payDetails-modal__btn-row__green "
                    >
                      Invite Users
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
interface CountryType {
  label: string;
  Name: string;
  labeltwo: string;
  labeltew: string;
  namedata2: string;
  namedata1: string;
  namedata3: string;
  namedata4: string;
  namedata5: string;
  namedata6: string;
  namedata7: string;
  namedata8: string;
  namedata9: string;
  namedata10: string;
}
const countries = [
  {
    label: 'View permissions for this role',
    labeltwo: '',
    labeltew: '',
    Name: 'Owner',
    namedata1: 'Can create & update payees',

    namedata2: 'Can view payees',
    namedata3: 'Can create & update tags',
    namedata4: 'Can use tags',
    namedata5: 'Can create & update bills',
    namedata6: 'Can view bills Name',
    namedata7: 'Can delete bills',
    namedata8: 'can pay bills',
    namedata9: 'Can approve bills',
    namedata10: 'Can view balance',
  },
  {
    label: 'View permissions for this role ',
    labeltwo: '',
    labeltew: '',
    Name: 'Auditor',
    namedata1: 'Can view payees',
    namedata2: 'Can use tags',
    namedata3: 'Can view bills',
    namedata4: 'Can view balance',
  },
  {
    label: 'View permissions for this role',
    labeltwo: '',
    labeltew: '',
    Name: 'Associate',
    namedata1: 'Can create & update payees',
    namedata2: 'Can view payees',
    namedata3: 'Can create & update tags',
    namedata4: 'Can use tags',
    namedata5: 'Can create & update bills',
    namedata6: 'Can view bills',
  },
  {
    label: 'View permissions for this role ',
    labeltwo: '',
    labeltew: '',
    Name: 'Approver',
    namedata1: 'Can create & update payees',
    namedata2: 'Can view payees',
    namedata3: 'Can create & update tags',
    namedata4: 'Can use tags',
    namedata5: 'Can create & update bills',
    namedata6: 'Can view bills',
    namedata7: 'Can approve bills',
    namedata8: 'Can view balance',
  },
  {
    label: 'View permissions for this role',
    labeltwo: '',
    labeltew: '',
    Name: 'Accountant',
    namedata1: 'Can create & update payees',
    namedata2: 'Can view payees',
    namedata3: 'Can create & update tags',
    namedata4: 'Can use tags',
    namedata5: 'Can create & update bills',
    namedata6: 'Can view bills',
    namedata7: 'can pay bills',
    namedata8: 'Can view balance',
  },
  {
    label: 'View permissions for this role',
    labeltwo: '',
    labeltew: '',
    Name: 'Manager',
    namedata1: 'Can create & update payees',
    namedata2: 'Can view payees',
    namedata3: 'Can create & update tags',
    namedata4: 'Can use tags',
    namedata5: 'Can create & update bills',
    namedata6: 'Can view bills',
    namedata7: 'can pay bills',
    namedata8: 'Can approve bills',
    namedata9: 'Can view balance',
  },
];
