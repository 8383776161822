import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { entityActions } from '../../../../../../../../../_actions';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import { ErrorMessage, FieldArray, getIn } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader';
import { on } from 'events';

const PartnershipStepTwo = ({
  formik,
  idBackFile,
  idBackLabel,
  idFrontFile,
  idFrontLabel,
  kraFile,
  kraLabel,
  handleRemoveImage,
  selfieFile,
  selfieLabel,
  handleImage,
  shareHolderLabels,
  handleAddNewPartner,
  loader,
}: any) => {
  const [render, setRender] = useState(true);
  // owner details

  const defaultPaterneship = {
    idType: '101',
    firstName: '',
    lastName: '',
    idNumber: '',
    gender: '',
    countryCode: '',
    mobile: '',
    kraPin: '',
    idFrontSideFile: '',
    idFrontSideFileType: '',
    idBackSideFile: '',
    idBackSideFileType: '',
    selfieFile: '',
    selfieFileType: '',
    kraPinFile: '',
    kraPinFileType: '',
  };

  const [partners, setPartners] = useState([defaultPaterneship]);
  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean[]>([false]);
  const [kraAccordion, setKraAccordion] = useState<boolean[]>([false]);
  const [idAccordion, setIdAccordion] = useState<boolean[]>([false]);

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));

  const genders = useSelector((state: any) => state.getGenderReducer.data);

  console.log('$$$$$$formik shareholders', formik.values.shareholders);

  console.log('$$$$$$formik errors', formik.errors);
  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getGender() as any);
  }, [dispatch]);

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  // const [chack_cox_data, setChack_cox_data] = useState(false);
  const [invoicefile, setinvoicefile] = useState('Upload supporting documents');
  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  // const selfieLabel = 'Selfie Photo';
  // const idFront = 'Photo of the frontside of the ID or passport';
  // const idBackLabel = 'Photo of the backside of the ID or passport';
  // const kraLabel = 'Photo of the KRA PIN Certificate of the business owner';
  // const [selfieFile, setSelfieFile] = useState(selfieLabel);
  // const [idFrontFile, setIdFrontFile] = useState(idFront);
  // const [idBackFile, setIdBackFile] = useState(idBackLabel);
  // const [kraFile, setKraFile] = useState(kraLabel);
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  // const handleLoader = (value: boolean) => {
  //   setLoader(value);
  // };

  // const handleImage = (e: any) => {
  //   let field = e.target.name;
  //   if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
  //     setFileSize(e.target.files[0].size);
  //     let title: any = null;
  //     switch (e.target.name) {
  //       case 'selfie':
  //         console.log('selfie');
  //         setSelfieFile(e.target.files[0].name);
  //         title = field;
  //         break;
  //       case 'idFront':
  //         console.log('idFront');
  //         setIdFrontFile(e.target.files[0].name);
  //         title = field;
  //         break;
  //       case 'idBack':
  //         console.log('idBack');
  //         setIdBackFile(e.target.files[0].name);
  //         title = field;
  //         break;
  //       case 'kra':
  //         console.log('idBack');
  //         setKraFile(e.target.files[0].name);
  //         title = field;
  //         break;
  //       default:
  //         break;
  //     }
  //     getBase64(e.target.files[0], (result: any) => {
  //       setinvoiceImage(result);
  //       formik.setFieldValue(field, result);
  //       const payload = {
  //         title: title,
  //         document_type: '3',
  //         mediaBase64: result,
  //         type: 'business_documents',
  //         mediaType: 'KYCF00029',
  //         contentType: 'image',
  //       };
  //       dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
  //     });
  //   }
  // };

  // const handleRemoveImage = () => {
  //   // setCertificateFile(certificateLabel);
  //   // setinvoiceImage('');
  // };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const handleAddPartner = () => {
    setPartners([...partners, defaultPaterneship]);
    setPaymentacodiyan([...paymentacodiyan, false]);
    setIdAccordion([...idAccordion, false]);
    setKraAccordion([...kraAccordion, false]);
  };

  const handleRemoveClick = (index: any, id: any) => {
    console.log(index);
    setPartners((partners) => partners.filter((_, i) => i !== index));
    // const list = [...inputList];
    // list.splice(index, 1);
    // setInputList(list);
    // /* for name */

    // const listName = [...directors];
    // listName.splice(index, 1);
    // setDirectors(listName);

    // const listNamedoc = [...Directordoc];
    // listNamedoc.splice(index, 1);
    // console.log(listNamedoc, 'listNamedoc');
    // setDirectorDoc(listNamedoc);

    // const groupOneNameState = [...groupOneName];
    // groupOneNameState.splice(index, 1);
    // console.log(groupOneNameState, 'listNamedoc');
    // setGroupOneName(groupOneNameState);
    // if (!!id) {
    //   var reqBody = {
    //     id: id,
    //   };
    //   dispatch(settingActions.deleteEntityDocuments(reqBody) as any);
  };

  const toggleAccordion = (index: number, accordionType: string) => {
    if (accordionType === 'payment') {
      const updatedAccordion = [...paymentacodiyan];
      updatedAccordion[index] = !updatedAccordion[index];
      setPaymentacodiyan(updatedAccordion);
    } else if (accordionType === 'id') {
      const updatedAccordion = [...idAccordion];
      updatedAccordion[index] = !updatedAccordion[index];
      setIdAccordion(updatedAccordion);
    } else if (accordionType === 'kra') {
      const updatedAccordion = [...kraAccordion];
      updatedAccordion[index] = !updatedAccordion[index];
      setKraAccordion(updatedAccordion);
    }
  };
  return (
    <>
      <p className="complete-profile__businesstypo">Partner's details</p>

      <FieldArray name="shareholders">
        {({ push, remove }) => (
          <>
            {formik.values.shareholders.map((shareholder: any, i: number) => {
              const firstName = `shareholders[${i}].firstName`;
              const touchedFirstName = getIn(formik.touched, firstName);
              const errorFirstName = getIn(formik.errors, firstName);

              const gender = `shareholders[${i}].gender`;
              const touchedGender = getIn(formik.touched, gender);
              const errorGender = getIn(formik.errors, gender);

              const idNumber = `shareholders[${i}].idNumber`;
              const touchedIdNumber = getIn(formik.touched, idNumber);
              const errorIdNumber = getIn(formik.errors, idNumber);

              const kraPin = `shareholders[${i}].kraPin`;
              const touchedKraPin = getIn(formik.touched, kraPin);
              const errorKraPin = getIn(formik.errors, kraPin);

              const mobile = `shareholders[${i}].mobile`;
              const touchedMobile = getIn(formik.touched, mobile);
              const errorMobile = getIn(formik.errors, mobile);

              const selfiePhoto = `shareholders[${i}].selfieFile`;
              const touchedSelfie = getIn(formik.touched, selfiePhoto);
              const errorSelfie = getIn(formik.errors, selfiePhoto);

              const idBackPhoto = `shareholders[${i}].idBackSideFile`;
              const touchedIdback = getIn(formik.touched, idBackPhoto);
              const errorIdBack = getIn(formik.errors, idBackPhoto);

              const idFrontPhoto = `shareholders[${i}].idFrontSideFile`;
              const touchedIdFront = getIn(formik.touched, idFrontPhoto);
              const errorIdFront = getIn(formik.errors, idFrontPhoto);

              const kraPinPhoto = `shareholders[${i}].kraPinFile`;
              const touchedKraPinFile = getIn(formik.touched, kraPinPhoto);
              const errorKraPinFile = getIn(formik.errors, kraPinPhoto);
              return (
                <div key={i}>
                  <div
                    className="complete-profile__btn_box"
                    style={{ marginTop: '20px' }}
                  >
                    {i !== 0 ? (
                      <p className="complete-profile__director">
                        Partner {i + 1}
                      </p>
                    ) : (
                      ''
                    )}
                    {i > 0 ? (
                      <Button
                        className="complete-profile__removebtn"
                        onClick={() => remove(i)}
                      >
                        Remove
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="calss_accordion_contenar">
                    <div
                      className="maian_adaat_listtts"
                      onClick={() => toggleAccordion(i, 'payment')}
                    >
                      <ThemeProvider theme={theme}>
                        <div className="avayar_text">
                          {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
                          <p className="accordion_p_data_contean">
                            Personal details
                          </p>
                        </div>
                        {!paymentacodiyan[i] ? (
                          <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                        ) : (
                          <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                        )}
                      </ThemeProvider>
                    </div>

                    {paymentacodiyan[i] && (
                      <Form>
                        <div className="input-form-wrapper dot">
                          <label>Full Names </label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={firstName}
                              value={formik.values.shareholders[i].firstName}
                              onChange={formik.handleChange}
                              type="text"
                              isInvalid={Boolean(
                                touchedFirstName && errorFirstName
                              )}
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={firstName} component="div" />
                          </p>
                        </div>
                        <div className="input-form-wrapper dot">
                          <label>Gender</label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Select
                              name={gender}
                              value={formik.values.shareholders[i].gender}
                              isInvalid={Boolean(touchedGender && errorGender)}
                              onChange={formik.handleChange}
                            >
                              <option>Gender</option>
                              {genders?.map((gender: any) => (
                                <option value={gender.key} key={gender.id}>
                                  {gender.value}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={gender} component="div" />
                          </p>
                        </div>
                        <div className="input-form-wrapper dot">
                          <label>Mobile number</label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={mobile}
                              value={formik.values.shareholders[i].mobile}
                              onChange={formik.handleChange}
                              type="text"
                              isInvalid={Boolean(touchedMobile && errorMobile)}
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={mobile} component="div" />
                          </p>
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data
                                    ?.selfie?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.selfie?.file_name
                                    : shareHolderLabels[i].selfieLabel}
                                </span>
                                {/* {selfieFile === selfieLabel ? (
                          <UploadIcon />
                        ) : (
                          <CloseIcon />
                        )} */}
                              </div>
                              <input
                                type="file"
                                name={selfiePhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {formik.errors.selfie ? (
                                  <ErrorMessage name="selfie" component="div" />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].selfieLabel === selfieLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => handleRemoveImage('selfie', i)}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </div>

                  <div className="calss_accordion_contenar">
                    <div
                      className="maian_adaat_listtts"
                      onClick={() => toggleAccordion(i, 'id')}
                    >
                      <ThemeProvider theme={theme}>
                        <div className="avayar_text">
                          {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
                          <p className="accordion_p_data_contean">ID details</p>
                        </div>
                        {!idAccordion[i] ? (
                          <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                        ) : (
                          <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                        )}
                      </ThemeProvider>
                    </div>

                    {idAccordion[i] && (
                      <Form>
                        <div className="input-form-wrapper dot">
                          <label>ID or Passport number </label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={idNumber}
                              value={formik.values.shareholders[i].idNumber}
                              onChange={formik.handleChange}
                              type="text"
                              isInvalid={Boolean(
                                touchedIdNumber && errorIdNumber
                              )}
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage name={idNumber} component="div" />
                          </p>
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data
                                    ?.idFront?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.idFront?.file_name
                                    : shareHolderLabels[i].idFrontLabel}
                                </span>
                                {/* {idFrontFile === idFront ? (
                          <UploadIcon />
                        ) : (
                          <CloseIcon />
                        )} */}
                              </div>
                              <input
                                type="file"
                                name={idFrontPhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {Boolean(touchedIdFront && errorIdFront) ? (
                                  <ErrorMessage
                                    name={idFrontPhoto}
                                    component="div"
                                  />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].idFrontLabel ===
                          idFrontLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => handleRemoveImage('idFront', i)}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div style={{ height: '25px' }}></div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data
                                    ?.idBack?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.idBack?.file_name
                                    : shareHolderLabels[i].idBackLabel}
                                </span>
                                {/* {idBackFile === idBackLabel ? (
                          <UploadIcon />
                        ) : (
                          <CloseIcon />
                        )} */}
                              </div>
                              <input
                                type="file"
                                name={idBackPhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {Boolean(touchedIdback && errorIdBack) ? (
                                  <ErrorMessage
                                    name={idBackPhoto}
                                    component="div"
                                  />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].idBackLabel === idBackLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => handleRemoveImage('idBack', i)}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </div>

                  <div className="calss_accordion_contenar">
                    <div
                      className="maian_adaat_listtts"
                      onClick={() => toggleAccordion(i, 'kra')}
                    >
                      <ThemeProvider theme={theme}>
                        <div className="avayar_text">
                          {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
                          <p className="accordion_p_data_contean">
                            KRA Details
                          </p>
                        </div>
                        {!kraAccordion[i] ? (
                          <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                        ) : (
                          <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                        )}
                      </ThemeProvider>
                    </div>

                    {kraAccordion[i] && (
                      <Form>
                        <div className="input-form-wrapper dot">
                          <label>Personal KRA PIN Number</label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              name={`shareholders[${i}].kraPin`}
                              value={formik.values.shareholders[i].kraPin}
                              onChange={formik.handleChange}
                              type="text"
                              isInvalid={Boolean(touchedKraPin && errorKraPin)}
                            />
                          </InputGroup>
                          <p className="error-message">
                            {' '}
                            <ErrorMessage
                              name={`shareholders[${i}].kraPin`}
                              component="div"
                            />
                          </p>
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                            <label
                              style={{ padding: '15px 0px 0px 0px' }}
                              className="complete-profile__upload mb-0"
                            >
                              <div
                                // id='afafafsdaFfsf'
                                className="complete-profile__upload__label"
                                //  style={{ cursor: 'not-allowed' }}
                              >
                                <span className="complete-profile__upload__txt">
                                  {formik.values?.businessDocs?.data != null &&
                                  formik.values?.businessDocs?.data?.data?.kra
                                    ?.file_name != null
                                    ? formik.values?.businessDocs?.data?.data
                                        ?.kra?.file_name
                                    : shareHolderLabels[i].kraPinLabel}
                                </span>
                              </div>
                              <input
                                type="file"
                                name={kraPinPhoto}
                                onChange={(e) => {
                                  handleImage(e, formik, i);
                                }}
                              />
                              {1024 * 1024 <= fileSize ? (
                                <div className="error-message">
                                  File size too large, max file size is 1 Mb
                                </div>
                              ) : null}
                              <p className="error-message">
                                {' '}
                                {Boolean(
                                  touchedKraPinFile && errorKraPinFile
                                ) ? (
                                  <ErrorMessage
                                    name={kraPinPhoto}
                                    component="div"
                                  />
                                ) : null}
                              </p>
                            </label>
                          </Tooltip>
                          {shareHolderLabels[i].kraPinLabel === kraLabel ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                // remove_data(
                                //   groupOneName[i].KRN_certi_new
                                // );
                                handleRemoveImage('kra', i);
                                console.log('remove');
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </div>
                </div>
              );
            })}
            <div
              className="complete-profile__btn_box"
              style={{ marginTop: '20px' }}
            >
              <Button
                className="complete-profile__ADDBTNHHA"
                onClick={() => {
                  push({ ...defaultPaterneship });
                  handleAddNewPartner();
                }}
              >
                + Add another partner
              </Button>
            </div>
          </>
        )}
      </FieldArray>
      {formik.submitted && formik.errors && formik.errors.shareholders && (
        <p className="error-message" style={{ margin: '2rem 0' }}>
          Kindly make sure that all the fields are filled correctly and you have
          added at least two partners
        </p>
      )}
      {loader && <Loader />}
    </>
  );
};

export default PartnershipStepTwo;
