import { Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import '../../auth/Auth.css';
import React from 'react';

type DefaultLayoutProps = {
    children?: React.ReactNode;
  };
const Layout = ({ children }:DefaultLayoutProps) => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container className="lohin_container_class">
                    <Grid item md={7} className='grid_img_container'>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}
export default Layout;