interface IEnvironment {
  name: string;
  env: string;
  apiHost: string;
  apiBase: string;
}

enum ENVS {
  SANDBOX = 'sandbox',
  PROD = 'production',
}

const Environments: { [key: string]: IEnvironment } = {
  Sandbox: {
    name: 'Sandbox',
    env: 'sandbox',
    apiHost: `${process.env.REACT_APP_STAGING_API_URL}`,
    apiBase: '/api/',
  },
  Prod: {
    name: 'Production',
    env: 'prod',
    apiHost: `${process.env.REACT_APP_API_URL}`,
    apiBase: '/api',
  },
};

const Environment = {
  current: Environments.Prod,
};

const ENV_MATCHERS: {
  [key: string]: string[];
} = {
  [ENVS.PROD]: ['prod', 'users.niobi.co'],
  [ENVS.SANDBOX]: ['sandbox', 'localhost', '127.0.0.1', 'dev.users.niobi.co'],
};

const isEnv = (hostUrl: string, environment: ENVS): boolean => {
  const matchers = ENV_MATCHERS[environment];

  if (!matchers) {
    throw new Error('Critical: Error initializing environment');
  }

  return matchers.find((match) => hostUrl.includes(match)) !== undefined;
};

const initEnv = (host: string) => {
  if (isEnv(host, ENVS.SANDBOX)) {
    Environment.current = Environments.Sandbox;
    return ENVS.SANDBOX;
  }

  Environment.current = Environments.Prod;
  return ENVS.PROD;
};

const reloadEnvironmentConfig = (env?: string) =>
  initEnv(env || window.location.host);

reloadEnvironmentConfig();

export { Environment, reloadEnvironmentConfig };
