import { authHeader, handleResponse } from '../_helpers';
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;

const getIndustries = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/choice/get-industries`, requestOptions).then(
    handleResponse
  );
};

const getGenders = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/choice/get-gender`, requestOptions).then(
    handleResponse
  );
};

const getOperatingModes = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/choice/get-operating-modes`, requestOptions).then(
    handleResponse
  );
};

const getBusinessTypes = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/choice/get-types`, requestOptions).then(
    handleResponse
  );
};

const createBusiness = (BusinessRequest) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(BusinessRequest),
  };
  return fetch(
    `${baseURL}/choice/create-business-account`,
    requestOptions
  ).then(handleResponse);
};

const verifyOtp = (otpData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(otpData),
  };
  return fetch(`${baseURL}/choice/verify-otp`, requestOptions).then(
    handleResponse
  );
};

const resendOtp = (otpData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(otpData),
  };
  return fetch(`${baseURL}/choice/resend-otp`, requestOptions).then(
    handleResponse
  );
};

const saveShareholder = (otpData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(otpData),
  };
  return fetch(`${baseURL}/choice/create-shareholder`, requestOptions).then(
    handleResponse
  );
};

const getStoreOnboardingInfo = (businessId) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${baseURL}/choice/get-store-onboarding-info`,
    requestOptions
  ).then(handleResponse);
};

const uploadMedia = (mediaData) => {
  console.log('media data in choice service', mediaData);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(mediaData),
  };
  return fetch(`${baseURL}/choice/upload-document`, requestOptions).then(
    handleResponse
  );
};

export const choiceBankService = {
  getIndustries,
  getBusinessTypes,
  getOperatingModes,
  getGenders,
  createBusiness,
  verifyOtp,
  resendOtp,
  getStoreOnboardingInfo,
  uploadMedia,
  saveShareholder,
};
