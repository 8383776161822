import React, { useCallback, useState, useEffect } from 'react';
// import { CounterpartsTable, CounterpartDetails } from '@team-monite/ui-widgets-react';
// import {
//   CounterpartsTable,
//   CounterpartDetails,
// } from "@team-monite/ui-widgets-react";
import CounterpartsTable from './components/CounterpartsTable/CounterpartsTable';
import { MoniteSDK } from '@team-monite/sdk-api';
import { GrantType, ObtainTokenPayload } from '@team-monite/sdk-api';
import { useSearchParams } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { Dropdown, DropdownMenuItem } from '@team-monite/ui-kit-react';
//import { CounterpartType } from '@team-monite/ui-widgets-react';
import { CounterpartType } from '@team-monite/sdk-api';
import './Counterparts.scss';
import amplitude from 'amplitude-js';
// import Csvdata from "../Bill/components/csvfile/csvlist";
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import '../../../i18n/index';
import Bulktebal from '../Bill/components/csvfile/Bulktebal';
import {
  getProperties,
  getUser,
  isUserAuthenticated,
} from '../../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../../_actions/entity.actions';
import Csvdata from '../Counterparts/components/csvlist';
import EnhancedTable from '../Counterparts/components/addnewtebal';
import { ThemeProvider, createTheme } from '@mui/material';
import posthog from 'posthog-js';

export const Counterparts = () => {
  const [counterpartId, setId] = useState<any>(undefined);
  const [counterpartType, setType] = useState<any>(undefined);
  const [csvname, setCsvnamedata] = React.useState('');
  console.log(counterpartId, 'counterpartType');

  const dispatch = useDispatch();

  const [counterpartData, setCounterpartData] = useState<any>();

  var localUser: any = localStorage.getItem('user');
  const properties = getProperties();

  const closeModal = useCallback(() => {
    counterpartId && setId(undefined);
    counterpartType && setType(undefined);
  }, [counterpartId, counterpartType]);
  var monite_id = 'c6d40989-f8df-4c9d-a72c-8a4297ebe1d9';
  var user_monite_id = '24487962-1852-4ef4-8d33-08d2acf59c14';
  if (localUser != null) {
    monite_id = JSON.parse(localUser).data.hasEntity
      ? JSON.parse(localUser).data.entity.monite_id
      : 'c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1';
    user_monite_id = JSON.parse(localUser).data.monite_user
      ? JSON.parse(localUser).data.monite_user.monite_id
      : '24487962-1852-4ef4-8d33-08d2acf59c14';
  }
  var client_id = 'c6d40989-f8df-4c9d-a72c-8a4297ebe1d9';
  const monite: any = new MoniteSDK({
    apiUrl: 'https://api.monite.com/v1',
    entityId: monite_id,
    fetchToken: async () => {
      const request: ObtainTokenPayload = {
        grant_type: GrantType.ENTITY_USER,
        client_id: client_id,
        client_secret: '55f99047-3686-4fa6-afdf-7b21305ad8ff',
        entity_user_id: user_monite_id,
      };

      const response = await fetch('https://api.monite.com/v1/auth/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-monite-version': '2023-03-14', //Monite API version
        },
        body: JSON.stringify(request),
      });

      return response.json();
    },
    //currencyLocale: 'en-US'
  });
  // const monite = new MoniteApp({
  //   apiUrl: "https://api.monite.com/v1", // Or 'https://api.monite.com/v1' to use Production
  //   entityId: JSON.parse(localUser).data.hasEntity
  //     ? JSON.parse(localUser).data.entity.monite_id
  //     : "c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1", // X-Monite-Entity-Id
  //   token:
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXlsb2FkIjp7ImNsaWVudF9pZCI6ImQ4NjJlYjg3LTc1ZDEtNDEyOS05MWI1LTVjNzk2ZmJjNjVhZiIsImNyZWF0ZWRfYXQiOiIyMDIzLTAxLTA0VDExOjU3OjM1LjQyMjg0MiJ9LCJleHAiOjE2NzI4MzUyNTV9.FFJ2kdIOaPjF-HZmxQ7KK_pvPThb5xA_WY1Bq8BqIDw", // Access token (of an entity user or a partner)
  // });

  const PAYABLE_ID = 'id';

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get(PAYABLE_ID);
  const [listpopup, setPopup] = useState<any>(false);

  const [render, setRender] = useState(true);

  const onRowClick = (id: string) => {
    searchParams.set(PAYABLE_ID, id);
    setSearchParams(searchParams);
  };
  const MINUTE_MS = 120000;
  const changeTime = () => {
    var dateset = new Date();
    const tokentwo: any = localStorage.getItem('tokentwo');
    if (
      new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime() >=
      1800000
    ) {
      console.log('here 30 minutes upper');
      setRender(false);
      dispatch(entityActions.getBilldata() as any);
    } else {
      console.log('here 30 minutes lower');
    }
    setTimeout(() => changeTime(), 1830000);
  };
  useEffect(() => {
    changeTime();
  }, [render, isUserAuthenticated]);

  useEffect(() => {
    const popup = searchParams.get('popup');
    const BILLSBULK: any = searchParams.get('BILLSBULK');
    console.log(BILLSBULK, 'BILLSBULK');
    setCsvnamedata(BILLSBULK);
    // const popup = searchParams.get('popup');
    if (popup == '0') {
      setType(CounterpartType.ORGANIZATION);
    } else if (popup == '1') {
      setType(CounterpartType.INDIVIDUAL);
    }
  }, [searchParams]);

  // const closeModal = () => {
  //   searchParams.delete(PAYABLE_ID);
  //   setSearchParams(searchParams);
  // };

  // monite.api.counterparts
  //   .getList() // Returns Promise<Response>
  //   .then((res) => {
  //     // setCounterpartData(res.data);
  //     console.log(`Number of counterparts: ${res.data.length}`);
  //   });

  const submitData = () => {
    const event = 'Payee Created';
    const eventProperties = {
      userId: !!properties && !!properties.user_id ? properties.user_id : null,
      userName: !!properties && !!properties.name ? properties.name : null,
      email: !!properties && !!properties.email ? properties.email : null,
      pageUrl: window.location.href,
    };
    amplitude.getInstance().logEvent(event, eventProperties);
    posthog.capture(event, eventProperties);
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: '#000' } },
  });
  return (
    <>
      {!!localUser && (
        <div className="counterparty">
          <PageHeader
            title="Beneficiaries"
            extra={
              JSON.parse(localUser).data.role_name != 'Viewer' ? (
                <>
                  <Dropdown
                    button={
                      <Button
                        color="#025041"
                        onClick={() => {
                          window.location.href = '/CereteCounterparts';
                        }}
                        className="counterparty__save-btn"
                      >
                        Create New
                      </Button>
                    }
                  >
                    {/* <DropdownMenuItem
                      onClick={() => setType(CounterpartType.ORGANIZATION)}
                    >
                      Organisation
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => setType(CounterpartType.INDIVIDUAL)}
                    >
                      Individual
                    </DropdownMenuItem> */}
                  </Dropdown>
                </>
              ) : (
                <Dropdown
                  button={
                    <Button
                      color="#025041"
                      style={{ cursor: 'not-allowed' }}
                      className="counterparty__save-btn"
                    >
                      Create New
                    </Button>
                  }
                  children={undefined}
                ></Dropdown>
              )
            }
            extratwo={
              JSON.parse(localUser).data.role_name != 'Viewer' ? (
                <Dropdown
                  button={
                    <Button
                      color="#025041"
                      className="counterparty__white-btn"
                      onClick={() => {
                        setPopup(true);
                        setCsvnamedata('');
                      }}
                    >
                      Import Payees
                    </Button>
                  }
                >
                  {/* <DropdownMenuItem>
              Upload File
            </DropdownMenuItem> */}
                </Dropdown>
              ) : (
                <Dropdown
                  button={
                    <Button
                      color="#025041"
                      style={{ cursor: 'not-allowed' }}
                      className="counterparty__white-btn"
                    >
                      Import Payees
                    </Button>
                  }
                  children={undefined}
                ></Dropdown>
              )
            }
          />
          {/* <MoniteProvider monite={monite}> */}
          {listpopup && (
            <>
              <Csvdata
                show={listpopup}
                onClose={() => {
                  setPopup(false);
                  setCsvnamedata('');
                  // setShowTopup(false);
                  // setShowBalanceGrid(true);
                }}
                onSave={(el: boolean) => {
                  setPopup(false);
                  setCsvnamedata('bulkadd');
                  // setShowTopup(false);
                  // setTopupMethod(el);
                  // setTopup(true);
                  // setShowBalanceGrid(false);
                }}
              />
            </>
          )}
          {csvname == 'bulkadd' ? (
            <div className={'height'}>
              <EnhancedTable />{' '}
            </div>
          ) : (
            <div className={'scroll_remove'}>
              <div
                className={'height'}
                style={{
                  height: 'auto',
                  display: 'block',
                }}
              >
                {' '}
                <ThemeProvider theme={theme}>
                  <CounterpartsTable
                  //  onRowClick={setId} onEdit={setId}
                  />
                </ThemeProvider>
              </div>
            </div>
          )}
          {/* </MoniteProvider> */}

          {/*<CounterpartsTable onRowClick={setId} onEdit={setId} />*/}
          {/*<CounterpartsTable onRowClick={onRowClick}  onEdit={setId}/>*/}
          {/* 
          {(!!counterpartId || !!counterpartType) && (
            <CounterpartDetails
              id={counterpartId}
              type={counterpartType}
              showBankAccounts={false}
              onCreate={submitData}
              // on

              onClose={closeModal}
              onDelete={closeModal}
            />
          )} */}
        </div>
      )}
    </>
  );
};
