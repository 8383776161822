
import {config} from '../api/config'
import { authHeader, handleResponse } from '../_helpers'
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL:process.env.REACT_APP_STAGING_API_URL
     ;
export const cartService = {
    addToCart,
    getCartItems,
    createOrder,
    getCartItemsCount,
    updateCart,
    removeCartItem,
    getEntities,
}

function addToCart(CartRequest){
    const requestOptions = {
        method:'POST',
        headers:{'Content-Type':'application/json',...authHeader()}, 
        body: JSON.stringify(CartRequest)
    }
    return fetch(`${baseURL}/Cart/AddToCart`,requestOptions).then(handleResponse)
}

function updateCart(CartRequest){
    const requestOptions = {
        method:'POST',
        headers:{'Content-Type':'application/json',...authHeader()}, 
        body: JSON.stringify(CartRequest)
    }
    return fetch(`${baseURL}/Cart/UpdateCart`,requestOptions).then(handleResponse)
}

function removeCartItem(productId){
    const requestOptions = {
        method:'POST',
        headers:{'Content-Type':'application/json',...authHeader()}, 
        body: JSON.stringify(productId)
    }
    return fetch(`${baseURL}/Cart/RemoveFromCart`,requestOptions).then(handleResponse)
}

function getCartItems(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()}, 
    }
    return fetch(`${baseURL}/Cart/GetCartItems`,requestOptions).then(handleResponse)
}

function getCartItemsCount(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()}, 
    }
    return fetch(`${baseURL}/Cart/GetCartItemsCount`,requestOptions).then(handleResponse)
}

function createOrder(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()}, 
    }
    return fetch(`${baseURL}/Order/Order`,requestOptions).then(handleResponse)
}



// entity types api
function getEntities(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`http://127.0.0.1:8000/api/get-entity-types`,requestOptions).then(handleResponse)
}

