import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { getUser } from '../../../../../../../../../_helpers';
import { Form, InputGroup } from 'react-bootstrap';
import { entityActions } from '../../../../../../../../../_actions';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ErrorMessage } from 'formik';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Box } from '@mui/material';

const SoleProprietory = ({
  formik,
  idFrontLabel,
  idFrontFile,
  idBackLabel,
  idBackFile,
  selfieLabel,
  selfieFile,
  handleRemoveImage,
  loader,
  kraFile,
  kraLabel,
  handleImage,
}: any) => {
  const [render, setRender] = useState(true);
  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const [kraAccordion, setKraAccordion] = useState<boolean>(false);
  const [idAccordion, setIdAccordion] = useState<boolean>(false);
  const [nextKinAccordion, setNextKinAccordion] = useState<boolean>(false);

  console.log('formik values now', formik.values);

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const genders = useSelector((state: any) => state.getGenderReducer.data);

  useEffect(() => {
    dispatch(choiceActions.getGender() as any);
  }, [dispatch]);

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  // const selfieLabel = 'Selfie Photo of the business owner';
  // const idFrontLabel =
  //   'Photo of the frontside of the ID Card of the business owner';
  // const idBackLabel =
  //   'Photo of the backside of the ID Card of the business owner';
  // const kraLabel = 'Photo of the KRA PIN Certificate of the business owner';
  // const [selfieFile, setSelfieFile] = useState(selfieLabel);
  // const [idFrontFile, setIdFrontFile] = useState(idFrontLabel);
  // const [idBackFile, setIdBackFile] = useState(idBackLabel);
  // const [kraFile, setKraFile] = useState(kraLabel);

  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  // const handleLoader = (value: boolean) => {
  //   setLoader(value);
  // };
  // const handleImage = (e: any) => {
  //   let field = e.target.name;
  //   if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
  //     setFileSize(e.target.files[0].size);
  //     let title: any = null;
  //     let mediaType: any = null;
  //     switch (e.target.name) {
  //       case 'selfie':
  //         setSelfieFile(e.target.files[0].name);
  //         title = field;
  //         mediaType = 'KYCF00006';
  //         break;
  //       case 'idFront':
  //         setIdFrontFile(e.target.files[0].name);
  //         title = field;
  //         mediaType = 'KYCF00001';
  //         break;
  //       case 'idBack':
  //         setIdBackFile(e.target.files[0].name);
  //         title = field;
  //         mediaType = 'KYCF00002';
  //         break;
  //       case 'kra':
  //         setKraFile(e.target.files[0].name);
  //         title = field;
  //         mediaType = 'KYCF00009';
  //         break;
  //       default:
  //         break;
  //     }
  //     getBase64(e.target.files[0], (result: any) => {
  //       setinvoiceImage(result);
  //       formik.setFieldValue(field, result);
  //       const payload = {
  //         title: title,
  //         document_type: '1',
  //         mediaBase64: result,
  //         type: 'business_documents',
  //         mediaType,
  //         contentType: 'image',
  //       };
  //       dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
  //     });
  //   }
  // };

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  // const imageUrl =
  //   'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  return (
    <>
      <p className="complete-profile__businesstypo">Business owner's details</p>
      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (paymentacodiyan == false) {
              setPaymentacodiyan(true);
              setDataccodiyan(false);
            } else {
              setPaymentacodiyan(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div className="avayar_text">
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">Personal details</p>
            </div>
            {paymentacodiyan === false ? (
              <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
            ) : (
              <ExpandLessIcon style={{ color: '#1D1D1D' }} />
            )}
          </ThemeProvider>
        </div>

        {paymentacodiyan === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Business owner's full name(same as on ID) </label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="firstName" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Date of birth of the business owner </label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="birthday"
                  value={formik.values.birthday}
                  onChange={formik.handleChange}
                  type="date"
                  isInvalid={
                    formik.touched.birthday && Boolean(formik.errors.birthday)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="birthday" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>
                Business owner's kenyan mobile number ( important,used for
                receiving OTP){' '}
              </label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.mobile && Boolean(formik.errors.mobile)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="mobile" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Gender</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Select
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                >
                  <option>Gender</option>
                  {genders?.map((gender: any) => (
                    <option value={gender.key} key={gender.id}>
                      {gender.value}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="gender" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.selfie
                        ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data?.selfie
                            ?.file_name
                        : selfieFile}
                    </span>
                    {/* {selfieFile === selfieLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                    {/* <UploadIcon /> */}
                  </div>
                  <input
                    type="file"
                    name="selfie"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.selfie ? (
                      <ErrorMessage name="selfie" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {selfieFile == selfieLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('selfie');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (idAccordion === false) {
              setIdAccordion(true);
              setDataccodiyan(false);
            } else {
              setIdAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div className="avayar_text">
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">ID details</p>
            </div>
            {idAccordion === false ? (
              <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
            ) : (
              <ExpandLessIcon style={{ color: '#1D1D1D' }} />
            )}
          </ThemeProvider>
        </div>

        {idAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Business owner's ID number </label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="idNumber"
                  value={formik.values.idNumber}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.idNumber && Boolean(formik.errors.idNumber)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="idNumber" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.idFront
                        ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data?.idFront
                            ?.file_name
                        : idFrontFile}
                    </span>
                  </div>
                  <input
                    type="file"
                    name="idFront"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.idFront ? (
                      <ErrorMessage name="idFront" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {idFrontFile === idFrontLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('idFront');
                    console.log('remove');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ marginTop: '5px', height: '12px' }}></div>
            <div className="flax_data_list">
              <Tooltip
                style={{ marginTop: '20px' }}
                title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)"
              >
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.idBack
                        ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data?.idBack
                            ?.file_name
                        : idBackFile}
                    </span>
                  </div>
                  <input
                    type="file"
                    name="idBack"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.idBack ? (
                      <ErrorMessage name="idBack" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {idBackFile === idBackLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('idBack');
                    console.log('remove');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (kraAccordion === false) {
              setKraAccordion(true);
              setDataccodiyan(false);
            } else {
              setKraAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div className="avayar_text">
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">KRA Details</p>
            </div>
            {kraAccordion == false ? (
              <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
            ) : (
              <ExpandLessIcon style={{ color: '#1D1D1D' }} />
            )}
          </ThemeProvider>
        </div>

        {kraAccordion == true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>KRA PIN Number</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kraPin"
                  value={formik.values.kraPin}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kraPin && Boolean(formik.errors.kraPin)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kraPin" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)">
                <label
                  style={{ padding: '15px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.kra?.file_name !=
                        null
                        ? formik.values?.businessDocs?.data?.data?.kra
                            ?.file_name
                        : kraFile}
                    </span>
                    {/* {kraFile === kraLabel ? <UploadIcon /> : <CloseIcon />} */}
                  </div>
                  <input
                    type="file"
                    name="kra"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.kra ? (
                      <ErrorMessage name="kra" component="div" />
                    ) : null}
                  </p>
                </label>
              </Tooltip>
              {kraFile === kraLabel ? (
                <div className="aDADADaDsfgf">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="aDADADaDsfgf"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('kra');
                    console.log('remove');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (nextKinAccordion === false) {
              setNextKinAccordion(true);
              setDataccodiyan(false);
            } else {
              setNextKinAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div className="avayar_text">
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">Next of kin details</p>
            </div>
            {nextKinAccordion === false ? (
              <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
            ) : (
              <ExpandLessIcon style={{ color: '#1D1D1D' }} />
            )}
          </ThemeProvider>
        </div>

        {nextKinAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Full Names</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kinFullName"
                  value={formik.values.kinFullName}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kinFullName &&
                    Boolean(formik.errors.kinFullName)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kinFullName" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Mobile number </label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kinMobile"
                  value={formik.values.kinMobile}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kinMobile && Boolean(formik.errors.kinMobile)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kinMobile" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Relationship</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kinRelationship"
                  value={formik.values.kinRelationship}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kinRelationship &&
                    Boolean(formik.errors.kinRelationship)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kinRelationship" component="div" />
              </p>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {loader && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default SoleProprietory;
