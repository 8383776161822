import ReactDOM from 'react-dom/client';
import './index.css';
import { datadogRum } from '@datadog/browser-rum';
import Root from './Root';

datadogRum.init({
  applicationId: '97faf56f-dea9-41db-b78b-66b10d4030c7',
  clientToken: 'pubdf47915a5e8ddcfc308f7686c02676d4',
  site: 'us5.datadoghq.com',
  service: 'niobi-app',
  env: 'dev',
  sessionSampleRate: 45,
  premiumSampleRate: 10,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});
datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Root />);
