import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'react-bootstrap';
import './Settings.scss';
import { getBase64 } from '../../../../../../../../_helpers/common';
import { getUser } from '../../../../../../../../_helpers';
import Form from 'react-bootstrap/Form';
import { settingActions } from '../../../../../../../../_actions/setting.actions';
import toast from 'react-hot-toast';

import { selectAllData } from '../../../../../../../../_reducers/entity.reducer';
import {
  choiceActions,
  entityActions,
  userActions,
} from '../../../../../../../../_actions';
import * as Yup from 'yup';
import { settingSelector } from '../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import RegistrationForm from './SoleProprietoryShip/RegistrationForm';
import LimitedLiabilityForm, {
  FormStep,
} from './LimitedLiability/LimitedLiabilityForm';
import PartnershipForm from './Paternership/PartnershipForm';
import PartnershipStepOne from './Paternership/PartnershipStepOne';
import PartnershipStepTwo from './Paternership/PartnershipStepTwo';
import { useLocation } from 'react-router-dom';
import LimitedLiability from './LimitedLiability/LimitedLiability';
import LimitedLiabilityStepTwo from './LimitedLiability/LimitedLiabilityStepTwo';
import { useNavigate } from 'react-router-dom';
import SoleProprietoryStepOne from './SoleProprietoryShip/SoleProprietoryStepOne';
import SoleProprietory from './SoleProprietoryShip/SoleProprietory';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DocumentsSubmitted } from '../DocumentsSubmitted';
import { DocumentVerified } from '../DocumentVerfied';
import SetupFailed from './Setupfailed';
import Loader from './Loader';
import { use } from 'i18next';
import { on } from 'events';

interface Props {
  onSave: any;
}
interface RequestProps {
  representative: any;
  document_type: string;
  business_documents: any;
  directors: any;
  shareholders: any;
  status: string;
}

const DocumentUpload = (props: Props) => {
  const [userDetail, setUserDetail] = useState(false);
  const [typevalue, setType] = useState('');
  const [users, setUsers] = useState<any>();
  const [render, setRender] = useState(true);
  const [entityType, setEntityType] = useState<any>();
  const [businessdoc, setBusinessDoc] = useState<any>({});
  const user = getUser()?.data ?? '';
  console.log('user from Document upload:', user);

  const location = useLocation();
  let organizationType = location.state?.selectedType;
  let selectedCurrency = location.state?.selectedCurrency;
  let fromAccountSetup = location.state?.fromAccountSetup;
  const isComingFromOTPWithCancellation = location.state?.fromCancel;

  console.log('check is from otp', isComingFromOTPWithCancellation);

  console.log('organization Type:', organizationType);
  console.log('selectedCurrency:', selectedCurrency);
  const [renderEntity, setRenderEntity] = useState(true);
  const [renderEntity_two, setRenderEntity_two] = useState(true);
  const [status, setstatus] = useState('waiting_for_submission');
  const [formValues, setFormValues] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const onboardingInfo: any = useSelector(
    (state: any) => state.getOnboardingInfoReducer.data
  );
  const [businessDocs, setBusinessDocs] = useState<any>([]);
  const bussiness = useSelector(
    (state: any) => state.getBusinessInfoReducer.data
  );

  console.log('business in redux', bussiness);

  const defaultShareholder = {
    idType: '101',
    firstName: '',
    lastName: '',
    idNumber: '',
    gender: '',
    countryCode: '',
    mobile: '',
    kraPin: '',
    idFrontSideFile: '',
    idFrontSideFileType: '',
    idBackSideFile: '',
    idBackSideFileType: '',
    selfieFile: '',
    selfieFileType: '',
    kraPinFile: '',
    kraPinFileType: '',
  };

  const commonStaticFASTTT = {
    certi_of_incorpo: '{type}',
    certi_of_compliance: '{type}',
    CR: '{type}',
    KRA: '{type}',
    Passport: '{type}',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };

  useEffect(() => {
    // Check if navigation came from the cancel action
    console.log('***running this check***');
    console.log('input businees', bussiness);
    console.log('onboardingInfo', onboardingInfo);
    console.log('location', location.state?.fromCancel);
    if (isComingFromOTPWithCancellation && !fromAccountSetup) {
      console.log('inside the if statement');
      // Fetch data from the Redux store's person state
      if (onboardingInfo?.code === '12004') {
        setFormValues({ ...bussiness, businessDocs });
        setEntityType(bussiness?.businessType);
      } else if (onboardingInfo?.data) {
        setFormValues({ ...onboardingInfo?.data, businessDocs });
        setEntityType(onboardingInfo?.data?.businessType);
      } else {
        setFormValues({ ...onboardingInfo, businessDocs });
        setEntityType(onboardingInfo?.businessType);
      }
    }

    if (fromAccountSetup) {
      setEntityType(organizationType);
    }
  }, [isComingFromOTPWithCancellation, onboardingInfo, fromAccountSetup]);

  const [inputlfast, setInputListfast] = useState<any>(
    commonStaticFASTTT
    // {

    //     Certificate: "Certificate of Incorporation",
    //     ofiicial_fast: "Certificate of Registration or Exemption",
    //     ofiicial_two: "Introduction letter from the NGOs Co-ordination Board",
    //     ofiicial_three: "Constitution of the organization",
    //     ofiicial_foarh: "KRA Pin Certificate or Exemption for the NGO",
    //     fast_trustee: "Certificate of registration",
    //     fast_trustee_two: "Trust deed or Exemption",
    //     fast_trustee_three: "Trust's KRA PIN Certificate or Tax Exemption Certificate",
    //     Certificate_two: "Business Registration Certificate",
    //     Certificate_two_three: "Partnership Mandate or Deed",
    //     CR: "CR 12",
    //     KRA: "Company’s KRA PIN Certificate",
    //     certi_of_compliance: "Certificate of Compliance",
    //     // director: [
    //     //     directorStatic
    //     // ]

    // }
  );

  React.useEffect(() => {
    setUserDetail(false);
  }, []);

  React.useEffect(() => {
    console.log('thiis is running');
    if (getUser && render) {
      setIsLoading(true);
      setRender(false);
      setUsers(getUser);
      dispatch(settingActions.getEntityDocuments() as any);
      // dispatch(choiceActions.getStoreOnboardingInfo() as any);
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  }, []);

  console.log(dataEntity, 'dataEntitydataEntity');

  const navigate = useNavigate();

  useEffect(() => {
    if (render) {
      setIsLoading(true);
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      dispatch(choiceActions.getStoreOnboardingInfo() as any);
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render, dispatch]);

  React.useEffect(() => {
    console.log('Getting here');
    setIsLoading(true);
    if (
      renderEntity_two &&
      dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'dataEntity list',
        'dataEntity',
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data
      );
      if (
        !!dataEntity.GETENTITYDOCUMENT_SUCCESS.data &&
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data.length !== 0
      ) {
        if (typeof dataEntity.GETENTITYDOCUMENT_SUCCESS.data == 'object') {
          var GetData = dataEntity.GETENTITYDOCUMENT_SUCCESS.data;

          setType(GetData.document_type);
          setUserDetail(true);
          setstatus(GetData.status);
          // console.log(onboardingInfo, '************onboardingInfo************');
          // if (onboardingInfo) {
          //   setFormValues(onboardingInfo);
          // }
          setBusinessDocs({
            data: !!GetData?.business_documents
              ? GetData?.business_documents
              : '',
          });
        }
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, [renderEntity_two, renderEntity, dataEntity.GETENTITYDOCUMENT_SUCCESS]);

  const validationSchema = Yup.object().shape({});

  const shareholderSchema = Yup.object().shape({
    // idType: Yup.string().required(),
    firstName: Yup.string().required('Full name is Required'),
    // lastName: Yup.string().required(),
    idNumber: Yup.string().required('Id number is Required'),
    gender: Yup.string().required(),
    // countryCode: Yup.string().required(),
    mobile: Yup.string()
      .required('Mobile Number is Required')
      .test(
        'is-valid-phone',
        'Phone number is not valid kindly check the length',
        (value = '') => {
          const trimmedPhone = trimPhoneNumber(value);
          return /^\d{9}$/.test(trimmedPhone); // Check if the trimmed phone number is exactly 9 digits long
        }
      ),
    kraPin: Yup.string().required('Kra Pin is required'),
    // idFrontSideFile: Yup.string().required(),
    // idFrontSideFileType: Yup.string().required(),
    // idBackSideFile: Yup.string().required(),
    // idBackSideFileType: Yup.string().required(),
    // selfieFile: Yup.string().required(),
    // selfieFileType: Yup.string().required(),
    // kraPinFile: Yup.string().required(),
    // kraPinFileType: Yup.string().required(),
  });

  const limitedLiabilityValidationSchemaStep1 = Yup.object().shape({
    businessName: Yup.string().required('Business name isRequired'),
    businessCerNum: Yup.string().required('Certificate Number is Required'),
    businessAddress: Yup.string().required(' Business Address isRequired'),
    businessIndustry: Yup.string().required(' Business Industry is Required'),
    // specifyIndustry: Yup.string().required('Required'),
    operatingMode: Yup.string().required('Business Operation Mode Required'),
    kraPin: Yup.string().required('KRA Pin is Required'),
    businessCertificate: Yup.string().required(
      'Certificate of Incorporation document is Required'
    ),
    cr: Yup.string().required('Company CR12 document is Required'),
    memorandum: Yup.string().required(
      'Memorandum of Association document is Required'
    ),
    resolution: Yup.string().required(
      'original board resolution document is Required'
    ),
    kraPinCertificate: Yup.string().required(
      'Business KRA Pin certificate is Required'
    ),
    articleOfAssociation: Yup.string().required(
      'Article of Association document is Required'
    ),
    ultimateBeneficiary: Yup.string().required(
      'Ultimatre Beneficiary document is Required'
    ),
  });

  const limitedLiabilityValidationSchemaStep2 = Yup.object().shape({
    shareholders: Yup.array()
      .of(shareholderSchema)
      .required('At least 1 directors are required'),
    // email: Yup.string().required('Email is Required'),
    // selfie: Yup.string().required('Owner Selfie photo is Required'),
    // idFront: Yup.string().required('Owner Id front photo is Required'),
    // idBack: Yup.string().required('Owner Id back photo is Required'),
    // kra: Yup.string().required('Owner Kra Pin photo is Required'),
  });

  const soleProprietorshipValidationSchemaStep1 = Yup.object().shape({
    businessName: Yup.string().required('Business name is Required'),
    businessCerNum: Yup.string().required('Certificate Number is Required'),
    businessAddress: Yup.string().required(' Business Address is Required'),
    businessIndustry: Yup.string().required(' Business Industry is Required'),
    businessCertificate: Yup.string().required(
      'Business Certificate is Required'
    ),
  });

  const soleProprietorshipValidationSchemaStep2 = Yup.object().shape({
    firstName: Yup.string().required('Full Name is Required'),
    gender: Yup.number().required('Gender is Required'),
    mobile: Yup.string()
      .required('Mobile Number is Required')
      .test(
        'is-valid-phone',
        'Phone number is not valid kindly check the length',
        (value = '') => {
          const trimmedPhone = trimPhoneNumber(value);
          return /^\d{9}$/.test(trimmedPhone); // Check if the trimmed phone number is exactly 9 digits long
        }
      ),
    idNumber: Yup.string().required('ID Number is Required'),
    kinFullName: Yup.string().required('Kin Full name is Required'),
    kinRelationship: Yup.string().required('Kin Relationship is Required'),
    kinCountryCode: Yup.string().required('Kin Country Code is Required'),
    kinMobile: Yup.string().required('Kin mobile is Required'),
    kraPin: Yup.string().required('KRA Pin is Required'),
    birthday: Yup.string().required('Birthday is Required'),
    selfie: Yup.string().required('Selfie Photo is Required'),
    idFront: Yup.string().required('Id front side photo is Required'),
    idBack: Yup.string().required('Id Back side is Required'),
    kra: Yup.string().required('Owner KRA is Required'),
  });

  const partnershipValidationSchemaStep1 = Yup.object().shape({
    businessName: Yup.string().required('Business name is Required'),
    businessCerNum: Yup.string().required('Certificate Number is Required'),
    businessAddress: Yup.string().required(' Business Address is Required'),
    businessIndustry: Yup.string().required(' Business Industry is Required'),
    operatingMode: Yup.string().required('Business Operation Mode Required'),
    // businessCertificate: Yup.string().required(
    //   'Business Certificate is Required'
    // ),
    // partnershipDeed: Yup.string().required(
    //   'Partnership Deed document is Required'
    // ),
  });

  const partnershipValidationSchemaStep2 = Yup.object().shape({
    shareholders: Yup.array()
      .of(shareholderSchema)
      .min(2, 'At least 2 partners are required'),
  });

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const trimPhoneNumber = (phoneNumber: string) => {
    const countryCodeWithPlus = '+254';
    const countryCodeWithoutPlus = '254';
    if (phoneNumber.startsWith(countryCodeWithPlus)) {
      return phoneNumber.slice(countryCodeWithPlus.length);
    } else if (phoneNumber.startsWith(countryCodeWithoutPlus)) {
      return phoneNumber.slice(countryCodeWithoutPlus.length);
    } else if (phoneNumber.startsWith('0')) {
      return phoneNumber.slice(1);
    }
    return phoneNumber;
  };

  const handleLimitedLiabilitySubmit = (values: any) => {
    console.log(values);
    if (values.shareholders && values.shareholders.length > 0) {
      values.shareholders.forEach((shareholder: any) => {
        // Split the first name by spaces
        const names = shareholder.firstName.split(' ');

        if (names.length === 1) {
          shareholder.firstName = names[0];
          shareholder.lastName = ''; // Clear lastName
          shareholder.middleName = ''; // Clear middleName
        } else if (names.length === 2) {
          shareholder.firstName = names[0];
          shareholder.middleName = ''; // Clear middleName
          shareholder.lastName = names[1];
        } else {
          shareholder.firstName = names[0];
          shareholder.middleName = names[1];
          shareholder.lastName = names.slice(2).join(' '); // Concatenate remaining names as the last name
        }
      });
    }
    const names = values.firstName.split(' ');
    const firstName = names[0];
    const lastName = names[1];
    const mobile = trimPhoneNumber(
      values?.mobile !== '' ? values?.mobile : values.shareholders[0]?.mobile
    );
    dispatch(
      choiceActions.setBusiness({
        ...values,
        firstName,
        lastName,
        mobile,
      }) as any
    );
    dispatch(
      choiceActions.createBusinessAccount(
        {
          ...values,
          firstName,
          lastName,
          mobile,
        },
        () => {
          if (selectedCurrency === 'KES') {
            dispatch(userActions.getOtp('') as any);
          } else {
            toast.success('An OTP from choice bank was sent to your email.', {
              duration: 10000,
            });
          }
          navigate('/settings/verify-identity', {
            state: {
              entityTYpe: organizationType,
              selectedCurrency,
            },
          });
        }
      ) as any
    );
  };

  const initialValuesLimitedLiability = {
    businessName: '',
    businessCerNum: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    birthday: '',
    idNumber: '',
    kraPin: '',
    businessAddress: '',
    kinFullName: '',
    kinRelationship: '',
    kinCountryCode: '254',
    kinMobile: '',
    businessIndustry: '',
    specifyIndustry: '',
    userId: user?.user_id,
    countryCode: '254',
    businessType: `${organizationType}`,
    currency: `${selectedCurrency}`,
    operatingMode: '',
    otpType: 'email',
    mobile: '',
    email: user?.email,
    entity_id: `${user?.entity?.id}`,
    businessCertificate: '',
    cr: '',
    memorandum: '',
    resolution: '',
    kraPinCertificate: '',
    ultimateBeneficiary: '',
    articlesOfAssociation: '',
    shareholders: [defaultShareholder],
  };

  const initialValuesSoleProprietorship = {
    businessName: '',
    businessCerNum: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    birthday: '',
    idNumber: '',
    kraPin: '',
    businessAddress: '',
    kinFullName: '',
    kinRelationship: '',
    kinCountryCode: '254',
    kinMobile: '',
    businessIndustry: '',
    specifyIndustry: '',
    userId: user?.user_id,
    countryCode: '254',
    businessType: `${organizationType}`,
    currency: `${selectedCurrency}`,
    operatingMode: '',
    otpType: 'email',
    mobile: '',
    email: user?.email,
    entity_id: `${user?.entity?.id}`,
    selfie: '',
    idFront: '',
    idBack: '',
    kra: '',
    businessCertificate: '',
  };

  console.log('organizationType*****************:', organizationType);
  console.log('typeValue****************aha*:', typevalue);

  useEffect(() => {
    console.log('  formValues:', formValues);
  }, [formValues]);
  if (isLoading || !dataEntity) {
    return <Loader />;
  }

  return (
    <>
      {status !== 'waiting_for_submission' && (
        <div className="profile">
          {status === 'waiting_for_approval' && (
            <DocumentsSubmitted
              onSave={() => {
                navigate('/');
              }}
            />
          )}
          {status === 'approved' &&
            users?.data?.entity?.status === 'VERIFIED' && (
              <DocumentVerified
                onSave={() => {
                  navigate('/');
                }}
              />
            )}
          {status === 'rejected' && <SetupFailed />}
        </div>
      )}
      {status === 'waiting_for_submission' && (
        <ThemeProvider theme={theme}>
          <div className="accoun-management">
            <div
              className="accoun-management__header"
              style={{ marginBottom: '35px' }}
            >
              Account Setup
            </div>
            <Button
              id="button_back"
              style={{ color: '#025141', marginLeft: '48px' }}
              onClick={() => navigate('/settings/account-setup')}
              className="payment-journey-container__detailbox__white-btn"
            >
              <ThemeProvider theme={theme}>
                <ArrowBackIcon
                  style={{ fontSize: '19px', marginRight: '10px' }}
                />
              </ThemeProvider>
              Go Back
            </Button>
            <div className="complete-profile">
              {entityType == '1' && (
                <>
                  <RegistrationForm
                    initialValues={
                      formValues || initialValuesSoleProprietorship
                    }
                    onSubmit={(values) => handleLimitedLiabilitySubmit(values)}
                  >
                    <FormStep
                      stepName=""
                      onSumbit={() => console.log('Step1 submit')}
                      validationSchema={soleProprietorshipValidationSchemaStep1}
                    >
                      <SoleProprietoryStepOne />
                    </FormStep>
                    <FormStep
                      stepName=""
                      onSumbit={() => console.log('Step1 submit')}
                      validationSchema={soleProprietorshipValidationSchemaStep2}
                    >
                      <SoleProprietory />
                    </FormStep>
                  </RegistrationForm>
                </>
              )}
              {entityType == '2' && (
                <>
                  <LimitedLiabilityForm
                    initialValues={formValues || initialValuesLimitedLiability}
                    onSubmit={(values) => handleLimitedLiabilitySubmit(values)}
                    stepOneInfo={limitedLiabilityValidationSchemaStep1}
                    stepTwoInfo={limitedLiabilityValidationSchemaStep2}
                  >
                    <FormStep
                      stepName=""
                      onSumbit={() => console.log('Step1 submit')}
                      validationSchema={limitedLiabilityValidationSchemaStep1}
                    >
                      <LimitedLiability />
                    </FormStep>
                    <FormStep
                      stepName=""
                      onSumbit={() => console.log('Step1 submit')}
                      validationSchema={limitedLiabilityValidationSchemaStep2}
                    >
                      <LimitedLiabilityStepTwo />
                    </FormStep>
                  </LimitedLiabilityForm>
                </>
              )}
              {entityType == '3' && (
                <>
                  <PartnershipForm
                    initialValues={formValues || initialValuesLimitedLiability}
                    stepOneInfo={partnershipValidationSchemaStep1}
                    stepTwoInfo={partnershipValidationSchemaStep2}
                    onSubmit={(values) => handleLimitedLiabilitySubmit(values)}
                  >
                    <FormStep
                      stepName=""
                      onSumbit={() => console.log('Step1 submit')}
                      validationSchema={partnershipValidationSchemaStep1}
                    >
                      <PartnershipStepOne />
                    </FormStep>
                    <FormStep
                      stepName=""
                      onSumbit={() => console.log('Step1 submit')}
                      validationSchema={partnershipValidationSchemaStep2}
                    >
                      <PartnershipStepTwo />
                    </FormStep>
                  </PartnershipForm>
                </>
              )}
            </div>
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default DocumentUpload;
