import { Grid, Typography, TableCell, Menu, MenuItem, TableContainer, Table, TableHead, TableRow, Button, Box } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState } from "react";
import { orange } from '@mui/material/colors';
import styles from './user_management.module.css';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useNavigate } from "react-router-dom";
import { isUserAuthenticated, getUser } from "../../../../../../_helpers";
import { settingActions } from "../../../../../../_actions/setting.actions";
import { useDispatch, useSelector } from 'react-redux'
import { settingSelector } from "../../../../../../_reducers/setting.reducer";
import Dialog from '@mui/material/Dialog';
import Modal from 'react-bootstrap/Modal';
import "../../Settings.scss";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CssBaseline, CircularProgress } from '@mui/material';
// import Model from "./model";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
} declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

interface Data {
    email: string,
    role: string,
    status: string,
    id: string,
}

function createData(
    email: string,
    role: string,
    status: string,
    id: string,
): Data {
    return {
        email,
        role,
        status,
        id
    };
}

const rows = [
    createData('Frozen yoghurt', 'Owner', 'active', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6'),
    createData('Frozen yoghurt', 'Owner', 'invited', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c7'),
    createData('Frozen yoghurt', 'Owner', 'removed', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c8'),
    createData('Frozen yoghurt', 'Owner', 'active', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c9')
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}


interface EnhancedTableProps {
    numSelected: number;
    //   onRequestSort: (event: React.MouseEvent<unknown>, fhfxhdxhxrhdrhdfhdfxhrfxhdfgrghhfdhdfhxdhhtdhhrhhproperty: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}



const All_user = () => {

    let navigate = useNavigate();
    const userDetails = localStorage.getItem('user')
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [render, setRender] = React.useState(true)
    const [renderUser, setRenderUser] = React.useState(true)
    const [userData, setUserData] = React.useState<any>([]);
    const [users, setUsers] = React.useState<any>();
    const [isUsers, isSetUsers] = React.useState(true)
    const [idDelete, setIdDelete] = React.useState<any>('')
    const [idEmail, setIdEmail] = React.useState<any>('')
    const [checkstatus, setCheckstatus] = React.useState<any>('')
    const open = Boolean(anchorEl);
    const [opendelog, setOpendelog] = React.useState(false);
    const [datatrue, setDatatrue] = React.useState(false)
    const [show, setShowlist] = React.useState(false);
    const [showModal, setShow] = React.useState(false);
    const [showModal_cerete, setShow_cerete] = React.useState(false);
    const [pageneshn, setPagelist] = React.useState(1)
    const [remove, setRemovedata] = React.useState(10)
    const [totalCount, setTotalCount] = React.useState(10);
    const [team_id, setTeam_id] = useState(0)

    const handleClickOpendelog = () => {
        setOpendelog(true);
    };
    const handleShow = () => {
        setShow(true);
        setShowlist(true);
    }
    const handleShow_cerete = () => {
        setShow_cerete(true)
    }
    const onClose_cerete = () => {
        setShow_cerete(false);
    };
    const onClose = () => {
        setShow(false);
    };
    const handleClosedelog = () => {
        setOpendelog(false);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    React.useEffect(() => {
        if (!!userDetails && JSON.parse(userDetails).data.role_name != 'Director') {
            navigate('/settings/profile')
        }
    }, [userDetails]);
    const dispatch = useDispatch()
    const dataUser = useSelector((state) => settingSelector(state));
    console.log(dataUser, 'userDatauserData');

    console.log(renderUser, userData, dataUser.USERDOCUMENT_SUCCESS, 'jsaDiadjdd');

    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            var data = `page=${1}`;
            dispatch(settingActions.getUserDocuments(data) as any)
        }
    }, [render, isUserAuthenticated]);

    React.useEffect(() => {
        if (renderUser && dataUser?.USERDOCUMENT_SUCCESS !== undefined && dataUser?.USERDOCUMENT_SUCCESS.success == true) {
            setRenderUser(false);
            setUserData(dataUser.USERDOCUMENT_SUCCESS.data.users);
            setTotalCount(dataUser.USERDOCUMENT_SUCCESS.data.total)
        } else if (renderUser && dataUser?.USERDOCUMENT_SUCCESS !== undefined && dataUser?.USERDOCUMENT_SUCCESS.success == false) {
            setRenderUser(false);
        }
    }, [dataUser, renderUser]);

    React.useEffect(() => {
        if (isUsers && getUser) {
            isSetUsers(false);
            setUsers(getUser()?.data);
        }
    });

    const deleteData = () => {
        if (checkstatus == "inactive" || checkstatus == "invited") {
            if (!!idEmail) {
                // var reqBody = {
                //     "id": idEmail
                // }
                var data = `page=${1}`;
                var reqBody = `${process.env.REACT_APP_API_URL}/delete-invited-user/${idEmail}/${team_id}`

                console.log(idEmail, "successfully api Delete call")
                dispatch(settingActions.deleteInactiveUser(reqBody) as any)
                dispatch(settingActions.getUserDocuments(data) as any)
                setRenderUser(true);
            }
        } else {
            if (!!idDelete) {
                // var reqBody = {
                //     "id": idDelete
                // }
                var reqBody = `${process.env.REACT_APP_API_URL}/delete-user/${idDelete}/${team_id}`

                var data = `page=${1}`;

                console.log(idDelete, "successfully api Delete call")
                dispatch(settingActions.deleteUser(reqBody) as any)
                dispatch(settingActions.getUserDocuments(data) as any)
                setRenderUser(true);
            }
        }
    }
    console.log(userData, users, 'userData')
    return (
        <ThemeProvider theme={theme}>
            {renderUser && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <CircularProgress color="success" />
            </Box>}
            <div className='accoun-management'>
                <div className='accoun-management__header' style={{ marginBottom: '35px' }}>
                    Team
                </div>
                <>
                    <Modal
                        show={showModal}
                        onHide={onClose}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby='ModalHeader'
                        // className='topup-modal'
                        centered
                        size="sm"
                    ><Modal.Body><Box className={styles.box_dialog_main}>
                        <Typography className={styles.typo_text}>Are you sure you want to delete this user?</Typography>
                        <Box className={styles.box_btn_cencal}>
                            <Button className={styles.delete_btn} onClick={() => {
                                deleteData()
                                onClose()
                            }}>Yes, Delete</Button>
                            <Button className={styles.cancel_btn} onClick={onClose}>Cancel</Button>
                        </Box>
                    </Box></Modal.Body></Modal>
                    {/* <Model show={showModal_cerete} onClose={onClose_cerete}  /> */}
                    {/* {datatrue == false ? */}
                    <Grid container className={styles.erwrwr_contenar_datatat}>
                        <Grid item md={1} xs={0} sm={0}>
                        </Grid>
                        <Grid item md={10} sm={12} xs={0}>
                            <ThemeProvider theme={theme}>
                                <TableContainer className={styles.addtebalcontenar}>
                                    <Table className={styles.tebalheder}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Role</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Team</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <tbody>
                                            {userData.map((element: any, index: number) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell><p className={'tebaldateformette'}>{!!element.email ? element.email : ""}</p></TableCell>
                                                        <TableCell>{!!element.role ? element.role : ""}</TableCell>
                                                        <TableCell><p className={element.status == 'active' ? styles.Active : element.status == 'invited' ? styles.Invited : styles.Inactive}>{!!element.status ? element.status : "inactive"}</p></TableCell>
                                                        <TableCell>{!!element.team_name ? element.team_name : "-"}</TableCell>

                                                        <TableCell style={{ textAlign: 'end' }}>
                                                            {element.status != 'removed' ?
                                                                <>
                                                                    <Button onClick={(event: any) => {
                                                                        handleClick(event)
                                                                        setIdDelete(element.id)
                                                                        setIdEmail(element.email)
                                                                        setCheckstatus(element.status)
                                                                        setTeam_id(!!element.team_id ? element.team_id : 0)
                                                                    }}>
                                                                        <img src="/Vector (5).svg" /></Button>
                                                                </> : ""}
                                                        </TableCell>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            {!!idDelete && !!users && idDelete == users.user_id ?
                                                                <MenuItem onClick={() => { navigate("/settings/UserDetail?id=1&user_id=" + idDelete, { replace: true, state: { id: '1' } }); }} className={styles.menuitem}>Update</MenuItem> :
                                                                <>
                                                                    <MenuItem onClick={() => { navigate("/settings/UserDetail?id=1&user_id=" + idDelete, { replace: true, state: { id: '1' } }); }} className={styles.menuitem}>Update</MenuItem>
                                                                    <MenuItem onClick={() => {
                                                                        handleClose()
                                                                        // handleClickOpendelog()
                                                                        handleShow()
                                                                        // deleteData()
                                                                    }} className={styles.menuitem} style={{ color: "#F87161" }}>Delete
                                                                    </MenuItem>
                                                                </>
                                                            }
                                                        </Menu>

                                                    </TableRow>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className="addnewbtnclass">

                                        <div className="inpagenum">
                                            {/* <p>{remove - 9}-{remove}</p> */}
                                        </div>
                                        <div className="btnmaindivin" style={{ padding: "15px 0px 0px 0px" }}>
                                            {pageneshn == 1 ?
                                                <button disabled className="nextntmistish" ><ArrowBackIosNewIcon style={{ fontSize: '16px' }} /></button> :
                                                <button className="nextntmistish" onClick={() => {
                                                    setPagelist(pageneshn - 1)
                                                    setRemovedata(remove - 10)
                                                    var data = `page=${pageneshn - 1}`;
                                                    dispatch(settingActions.getUserDocuments(data) as any)
                                                    setTimeout(() => { setRenderUser(true) }, 3000)
                                                }}><ArrowBackIosNewIcon style={{ fontSize: '16px' }} /></button>}
                                            {remove >= totalCount ? <button disabled className="nextntmistish" >
                                                <ArrowForwardIosIcon style={{ fontSize: '16px' }} /></button>
                                                : <button className="nextntmistish" onClick={() => {
                                                    setPagelist(pageneshn + 1)
                                                    setRemovedata(remove + 10)
                                                    var data = `page=${pageneshn + 1}`;
                                                    dispatch(settingActions.getUserDocuments(data) as any)
                                                    setTimeout(() => { setRenderUser(true) }, 3000)

                                                }}><ArrowForwardIosIcon style={{ fontSize: '16px' }} /></button>}
                                        </div>
                                    </div>
                                    <Box className={styles.addnewdatalog} sx={{ justifyContent: "space-between", display: "flex" }}>
                                        <Button className={styles.cerete_user} onClick={() => { navigate("/settings/team") }}>Go back</Button>
                                        <Button className={styles.savebtn} onClick={() => { navigate("/settings/UserDetail?Invite_id=" + '2', { replace: true, state: { Invite_id: '2' } }); }} ><PersonAddAltIcon className={styles.addinviebtn} />Invite Users</Button>
                                    </Box>
                                </TableContainer>
                            </ThemeProvider>
                        </Grid>
                        <Grid item md={1} xs={0} sm={0}>
                        </Grid>
                    </Grid>
                    {/* // : <Grid container className={styles.container_popup}>
                //     <Grid item md={4.5}></Grid>
                //     <Grid item md={3} className={styles.grid_senter}><Box className={styles.box_dialog_main}>
                //         <Typography className={styles.typo_text}>Are you sure you want to delete this user?</Typography>
                //         <Box className={styles.box_btn_cencal}>
                //             <Button className={styles.delete_btn}>Yes, Delete</Button>
                //             <Button className={styles.cancel_btn}>Cancel</Button>
                //         </Box>
                //     </Box></Grid>
                //     <Grid item md={4.5}></Grid>
                // </Grid>} */}
                </>
            </div>
        </ThemeProvider>
    );
}

export default All_user;