import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Props {
  transactions: any;
  fileName: string;
}
const Recent_Transactions = (props: Props) => {
  return (
    <>
      <div>
        <div
          style={
            props.fileName == 'DashboardV3'
              ? { marginBottom: '30px', paddingTop: '24px' }
              : { marginBottom: '30px' }
          }
        >
          <div className="main_dev_three">
            <div className="fast_dev" style={{ fontWeight: '600' }}>
              Date
            </div>
            <div
              className="secend_dev"
              style={{ width: '45%', fontWeight: '600' }}
            >
              From/To
            </div>
            <div className="thred_dev" style={{ fontWeight: '600' }}>
              Amount
            </div>
          </div>
          {!!props &&
            !!props.transactions &&
            props.transactions.map((data: any, index: any) => {
              return (
                <div
                  style={
                    props.fileName == 'DashboardV3'
                      ? { padding: '5px 0px' }
                      : {}
                  }
                  className="main_dev_three"
                >
                  <div className="fast_dev">
                    {moment(data.transaction_date).format('DD-MMM-YYYY')}
                  </div>
                  <div className="secend_dev" style={{ width: '45%' }}>
                    {data.payee}
                  </div>
                  <div className="thred_dev">
                    <span
                      style={{
                        color: data.Type === 'pay_out' ? '#21144c' : '#009E7E',
                      }}
                    >
                      {data.Type === 'pay_out' ? '-' : '+'} {data.currency}
                      {!!data && !!data.transaction_amount
                        ? data.transaction_amount.toLocaleString('en', {
                            minimumFractionDigits: 2,
                          })
                        : '0.00'}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{ position: 'absolute', bottom: '10px', right: '12px' }}
          className="footer"
        >
          <div className="left"></div>
          <div className="right" id="Total_Bills">
            <Link className="link" to="/balance">
              View all
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.19058 0.595215L5.51607 4.9207L1.19058 9.24618"
                  stroke="#257D6B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Recent_Transactions;
