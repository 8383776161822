import "./Gethelp.scss";
import React, { useEffect, useState } from "react";
import Faq_data from "./faq_data";
export const Contact_Us = () => {
  const [showProfile, setshowProfile] = useState(true);
  const [documentSubmitted, setDocumentSubmitted] = useState(false);
  const [documentVerified, setDocumentVerified] = useState(false);

  useEffect(() => {
    // const script = document.createElement('script');

    // script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    // script.async = true;

    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);


  return (
    <div className='gethelp'>
      <div className="balance__header">
        <div className="balance__header__name">FAQs </div>

      </div>
      <div className="dashboard-container">
        <div className="box_add_new_data">
       
        </div>

      </div>
    </div>
  )
}

