import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
// import { Button } from '@mui/material';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';

import { choiceActions } from '../../../../../../../../_actions';
import { useLocation, useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import CurrencySelectionModal from './CurrencySelectionModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageHeader from '../../../../../PageHeader';

const AccountSetup = () => {
  const entities = useSelector(
    (state: any) => state.getChoiceTypesReducer.data
  );
  // Check if the entities array is not empty
  const hasEntities = entities && entities.length > 0;
  const [selectedType, setSelectedType] = useState('');
  const [showCurrency, setShowCurrency] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCurrency = location.state?.selectedMethod;

  // Use the useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Use the useEffect hook to dispatch the action when the component mounts
  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getEntityTypes() as any);
  }, [dispatch]);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const handleNext = (e: any) => {
    e.preventDefault();
    console.log('working');
    navigate('/settings/document-upload', {
      state: {
        selectedType,
        selectedCurrency,
        fromAccountSetup: true,
      },
    });
  };

  const handleBack = (e: any) => {
    e.preventDefault();
    setShowCurrency(true);
  };
  return (
    <div className="tags">
      <PageHeader title="Account Setup" />
      <Button
        id="button_back"
        style={{ color: '#025141', marginLeft: '48px' }}
        onClick={handleBack}
        className="payment-journey-container__detailbox__white-btn"
      >
        <ThemeProvider theme={theme}>
          <ArrowBackIcon style={{ fontSize: '19px', marginRight: '10px' }} />
        </ThemeProvider>
        Go Back
      </Button>
      <div className="create_api_box">
        <div className="inside_sub_box">
          <p className="heading_create_api">
            Please complete your account setup{' '}
          </p>
          <div className={'input_box'}>
            <Form.Select
              style={{ fontSize: '13px' }}
              className="complete-profile__SELECT"
              aria-label="Default select example"
              onChange={(e) => {
                console.log('e.target.value', e.target.value);
                setSelectedType(e.target.value);
              }}
            >
              <option>Type of Organisation</option>
              {hasEntities
                ? entities.map((element: any) => {
                    return (
                      <option key={element.id} value={element.key}>
                        {element.value}
                      </option>
                    );
                  })
                : 'data not found'}
            </Form.Select>
          </div>
          <Button className="complete-profile__btn2" onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
      {showCurrency && (
        <CurrencySelectionModal
          show={showCurrency}
          onClose={() => {
            setShowCurrency(false);
            // setShowBalanceGrid(true);
          }}
          onSave={(el: boolean) => {
            setShowCurrency(false);
            // setCereat_Teabal(true)
          }}
          onconfirm={(el: boolean) => {
            setShowCurrency(false);
            // setIsOpen(true)
          }}
        />
      )}
    </div>
  );
};

export default AccountSetup;
