import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { Form, InputGroup } from 'react-bootstrap';
import { entityActions } from '../../../../../../../../../_actions';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import { ErrorMessage } from 'formik';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader';

const PartnershipStepOne = ({
  formik,
  certificateFile,
  certificateLabel,
  mandateFile,
  mandateLabel,
  handleRemoveImage,
  partnershipFile,
  partnershipLabel,
  handleImage,
  loader,
}: any) => {
  const [render, setRender] = useState(true);
  const industries = useSelector(
    (state: any) => state.getIndustriesReducer.data
  );

  const operationModes = useSelector(
    (state: any) => state.getBusinessOperationModesReducer.data
  );

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getIndustries() as any);
    dispatch(choiceActions.getOperatingModes() as any);
  }, [dispatch]);

  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
    }
  }, [render]);

  // const imageUrl =
  //   'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  return (
    <>
      <p className="complete-profile__businesstypo">Business Details</p>
      <div className="calss_accordion_contenar">
        <Form>
          <div className="input-form-wrapper dot">
            <label>Partnership name</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessName"
                value={formik.values.businessName}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.businessName && formik.errors.businessName
                }
                type="text"
              />
            </InputGroup>
            <p>
              {' '}
              <ErrorMessage name="businessName" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Number of the company Certificate</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessCerNum"
                value={formik.values.businessCerNum}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.businessCerNum && formik.errors.businessCerNum
                }
              />
            </InputGroup>
            <p>
              {' '}
              <ErrorMessage name="businessCerNum" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Address of the Incorporation</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessAddress"
                value={formik.values.businessAddress}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.businessAddress &&
                  Boolean(formik.errors.businessAddress)
                }
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessAddress" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Industry of the Company</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Select
                name="businessIndustry"
                value={formik.values.businessIndustry}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.businessIndustry &&
                  Boolean(formik.errors.businessIndustry)
                }
              >
                <option>Business Industry</option>
                {Array.isArray(industries)
                  ? industries?.map((element: any) => {
                      return (
                        <option key={element.id} value={element.key}>
                          {element.value}
                        </option>
                      );
                    })
                  : 'data not found'}
              </Form.Select>
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessIndustry" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Business Operating Mode</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Select
                name="operatingMode"
                value={formik.values.operatingMode}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.operatingMode &&
                  Boolean(formik.errors.operatingMode)
                }
              >
                <option>Business Operating Modes</option>
                {Array.isArray(operationModes)
                  ? operationModes?.map((element: any) => {
                      return (
                        <option key={element.id} value={element.key}>
                          {element.value}
                        </option>
                      );
                    })
                  : 'data not found'}
              </Form.Select>
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="operatingMode" component="div" />
            </p>
          </div>
          <div className="flax_data_list">
            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
              <label
                style={{ padding: '15px 0px 0px 0px' }}
                className="complete-profile__upload mb-0"
              >
                <div
                  // id='afafafsdaFfsf'
                  className="complete-profile__upload__label"
                  //  style={{ cursor: 'not-allowed' }}
                >
                  <span className="complete-profile__upload__txt">
                    {formik.values?.businessDocs?.data != null &&
                    formik.values?.businessDocs?.data?.data?.businessCertificate
                      ?.file_name != null
                      ? formik.values?.businessDocs?.data?.data
                          ?.businessCertificate?.file_name
                      : certificateFile}
                  </span>
                  {/* {certificateFile === certificateLabel ? (
                    <UploadIcon />
                  ) : (
                    <CloseIcon />
                  )} */}
                </div>
                <input
                  type="file"
                  name="businessCertificate"
                  onChange={handleImage}
                />
                {1024 * 1024 <= fileSize ? (
                  <div className="error-message">
                    File size too large, max file size is 1 Mb
                  </div>
                ) : null}
                <p className="error-message">
                  {' '}
                  {formik.errors.businessCertificate ? (
                    <ErrorMessage name="businessCertificate" component="div" />
                  ) : null}
                </p>
              </label>
            </Tooltip>
            {certificateFile === certificateLabel ? (
              <div className="aDADADaDsfgf">
                {' '}
                <UploadIcon />
              </div>
            ) : (
              <div
                className="aDADADaDsfgf"
                onClick={() => {
                  // remove_data(
                  //   groupOneName[i].KRN_certi_new
                  // );
                  handleRemoveImage('businessCertificate');
                  console.log('remove');
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div>
          <div style={{ height: '20px' }}></div>
          <div className="flax_data_list">
            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf)">
              <label
                style={{ padding: '15px 0px 0px 0px' }}
                className="complete-profile__upload mb-0"
              >
                <div
                  // id='afafafsdaFfsf'
                  className="complete-profile__upload__label"
                  //  style={{ cursor: 'not-allowed' }}
                >
                  <span className="complete-profile__upload__txt">
                    {formik.values?.businessDocs?.data != null &&
                    formik.values?.businessDocs?.data?.data?.partnershipDeed
                      ?.file_name != null
                      ? formik.values?.businessDocs?.data?.data?.partnershipDeed
                          ?.file_name
                      : partnershipFile}
                  </span>
                  {/* {partnershipFile === partnershipLabel ? (
                    <UploadIcon />
                  ) : (
                    <CloseIcon />
                  )} */}
                </div>
                <input
                  type="file"
                  name="partnershipDeed"
                  onChange={handleImage}
                />
                {1024 * 1024 <= fileSize ? (
                  <div className="error-message">
                    File size too large, max file size is 1 Mb
                  </div>
                ) : null}
                <p className="error-message">
                  {' '}
                  {formik.errors.partnershipDeed ? (
                    <ErrorMessage name="partnershipDeed" component="div" />
                  ) : null}
                </p>
              </label>
            </Tooltip>
            {partnershipFile === partnershipLabel ? (
              <div className="aDADADaDsfgf">
                {' '}
                <UploadIcon />
              </div>
            ) : (
              <div
                className="aDADADaDsfgf"
                onClick={() => {
                  // remove_data(
                  //   groupOneName[i].KRN_certi_new
                  // );
                  handleRemoveImage('partnershipDeed');
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div>
        </Form>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default PartnershipStepOne;
