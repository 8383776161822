import styles from './csvlist.module.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { Grid, Typography } from '@mui/material';
import * as XLSX from 'xlsx';

const MobileIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
const VisaCardIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
export class HttpClient {
    static get() {
        return Promise.resolve(`Type,Name*,Email*,Phone Number,Street address*,Country*,Account Number - Send Money,Pay Bill Number,Account Number - PayBill,Account Number - Till Number,Bank Name,Account Number - Bank,Bank Branch,Bank Account Name
individual,Inspekta Mwala,inspektamwala@gmail.com,254711223344,Tom Mboya Street,Kenya,10101010101,123456,987654321,456123,Kenya Commercial Bank,123456789,Westgate,act...
organization,Mzee Ojwang,mzee@ojwang.enterprises,254700998877,Bondeni Road,Kenya,10101010101,123456,987654321,456123,Kenya Commercial Bank,123456789,Westgate,act...`);

    }
}





const Csvdata = (props) => {
    const [dataInCSV, setDataInCSV] = React.useState("");
    const [listcvdata, setUserJSON] = React.useState([])
    const [csvname, setCsvnamedata] = React.useState("")
    const [showModal, setShow] = useState(props.show);
    const [selectedMethod, setSelectedMethod] = useState('');
    const [filllist, setFile] = useState('');
    const [show, setShowlist] = useState(false);
    const [page, setPage] = React.useState(false);
    const [excelData, setExcelData] = useState([]);

    console.log(listcvdata, 'listcvdatalistcvdata');
    console.log(filllist, 'filllist');
    let navigate = useNavigate();

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const handleClose = () => {
        setShowlist(false)
        props.onClose(false);
    }
    const handleShow = () => {
        // window.location.href = '/Bulktebal'
        setShow(false);
        setShowlist(true);
        setPage(true)
    }


    const onClose = () => {
        setShow(false);
        props.onClose(false);
    };
    const onSave = () => {
        setShow(false);
        props.onSave(false);
    };
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setExcelData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };
    // const handleCSVUpload = (file) => {
    //     setCsvnamedata(file.name)
    //     Papa.parse(file, {
    //         header: true,
    //         complete: (results) => {
    //             console.log(results.data, 'dfgfgfdg');
    //             // var userJSON = userJSON.find(item => item.buyPrice === '');

    //             // console.log(result,'dfrtfdtftfd');
    //             // element.buyPrice
    //             // if (results.data === '') {
    //             //     break;
    //             //   }
    //             // if (page == true) {
    //             //localStorage.setItem('Bulkdatatwo', JSON.stringify(results.data))
    //             // }
    //             setUserJSON(results.data)
    //             // setListcsvdsts(results.data)
    //         },
    //     });
    // };

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        setPage(false)
        HttpClient.get().then((res) => setDataInCSV(res));
    }, []);


    return (
        <>
            <Modal
                show={showModal}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby='ModalHeader'
                className='topup-modal'
                centered
                size="lg"
            >
                <Modal.Header className='tophead' closeButton>
                    <Modal.Title>Import payees</Modal.Title>
                    <ThemeProvider theme={theme}>
                        <Typography className={styles.sfsffsffshhsjs} style={{ marginTop: '8px' }}>
                            Follow the steps below to import payees  </Typography>
                    </ThemeProvider>
                </Modal.Header>

                <Modal.Body className='modalbbody'>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={0}>
                            <Grid xs={12} sm={12} md={6} style={{ paddingRight: '10px' }}>
                                <Typography className='headingCus'>Step 1</Typography>
                                <Typography id={styles.fonst_size_err} className='subheading mb-3'>Fill in and save the details in the file then proceed to step 2</Typography>
                                <div
                                    className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}
                                // onClick={() => setSelectedMethod('mobile')}

                                >
                                    <a style={{ textDecoration: 'none' }}
                                        // className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}
                                        // onClick={() => setSelectedMethod('mobile')}
                                       download="payees.xlsx"
                                       href='/payees.xlsx'
                                    //    download="beneficiaries.xlsx"
                                    //    href='/beneficiaries.xlsx'
                                        // href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                                    >
                                        {/* <div>
                                    
                                    <span className='type-card__name'>via M-Pesa</span>
                                </div> */}
                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>
                                                Download Template File
                                            </div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div class="m-2">Download</div>
                                                <div class={styles.download + " "}><MobileIcon /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} style={{ paddingLeft: '10px' }}>
                                <Typography className='headingCus'>Step 2</Typography>
                                <Typography className='subheading' id={styles.fonst_size_err} style={{ paddingBottom: '26px' }}>Please upload the saved file</Typography>

                                <div>
                                    <label for="inputTag" style={{ width: '100%',marginTop:"9px" }} id={filllist == '' ? '' : styles.active} className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}>
                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>{!!filllist && !!filllist.name ? filllist.name : 'Upload File'}</div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div class="m-2">Upload</div>
                                                <div class={" "}><VisaCardIcon /></div>
                                            </div>

                                        </div>
                                        <input id="inputTag" className={styles.addaffafagga} type="file" onChange={(file) => {
                                            console.log(file.target.files, 'dhdhhdhhdhhd')
                                            handleFileChange(file)
                                            setFile(file.target.files[0]);
                                        }}
                                            accept=".xlsx" />
                                    </label>

                                </div>
                            </Grid>
                        </Grid>
                    </ThemeProvider>

                    {/* <div className='type-card-list'>
                    
                      
                    </div> */}

                </Modal.Body>

                <Modal.Footer>
                    <Button className='white-btn' onClick={onClose}>Cancel</Button>
                    <Button id={styles.sahshsushshss} className='green-btn' disabled={filllist === ''} onClick={() => {
                        handleShow()
                        localStorage.setItem('Bulkdatatwo', JSON.stringify(excelData))
                        onSave()
                        // handleCSVUpload()
                        // onClose()
                        // setPage(true)
                        navigate("/Counterparts?BILLSBULK=" + 'bulkadd', { replace: true, state: { BILLSBULK: 'bulkadd' } })
                    }}>Next</Button>
                </Modal.Footer>
            </Modal>
            {/* {page==true? <Bill data={page} />:''} */}

        </>


    )
}

export default Csvdata;

