import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import moment from 'moment-timezone';
import "./TransactionDetails.scss";
import { getUser } from "../../../../../../_helpers";
import { useNavigate } from "react-router-dom";


interface Props {
    onSave: any;
    onSave2: any;
    type: any;
    data: any[];
    AccountName:any;
}

interface objectType {
    id: string;
    value: string
}
export const TransactionDetails = (props: Props) => {
    const [option, setOption] = useState<any>({
        id: ''
    });

    const [otpData, setOtpData] = useState<any>();
    const [otpRender, setOtpRender] = useState(true);
    const navigate = useNavigate();

    const user = getUser()?.data ?? null;


    useEffect(() => {
        const data: any = props.data;
        setOption(data);
    }, [props.data]);

    const onSave = () => {
        props.onSave();
    }
    const onSave2 = () => {
        props.onSave2();
    }

    return (
        <div className='trans-detail-container'>
            <div className='trans-detail-container__heading'>
                Transaction Details
            </div>

            <div className='trans-detail-container__table'>
                <div className='dflex b-bot'>
                    <div className='left'>Transaction Status</div>
                    <div className='right'>Success</div>
                </div>
                {
                    (!!option.type) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Type</div>
                            {option.type == 'pay_in' || option.type == 'Pay in' ? <div id='typestyle2'>{option.type}</div> :
                                option.type == 'pay_out' || option.type == 'Pay Out' ?
                                    <div id='typestyle'>{option.type}</div> : <div id='typestyle3'>{option.type}</div>}
                        </div> : ""
                }
                {
                    (!!option.ref) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Reference</div>
                            <div className='right'>{option.ref}</div>
                        </div> : ""
                }
                {
                    (!!option.payment_channel) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Payment Channel</div>
                            <div className='right'>{option.payment_channel}</div>
                        </div> : ""
                }
                {
                    (!!option.updated_at) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Transaction Date</  div>
                            <div className='right'>{moment.tz(option.updated_at, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                        </div> : ""
                }
                {
                    (!!option.amount) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Transaction Amount</div>
                            <div className='right'>KES {!!option.amount ?
                                Number((parseFloat((option.amount))).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : 0}</div>
                        </div> : ""
                }
                {!!option.mobile && option.type == 'Pay in' && !!option.payee ?
                    <div className='dflex b-bot'>
                        <div className='left'>Sender Name</div>
                        <div className='right'>{option.payee}</div>
                    </div> : ""}
                {!!option.mobile && option.type == 'Pay in' ?
                    <div className='dflex b-bot'>
                        <div className='left'>Sender Number</div>
                        <div className='right'>
                            {!!option.mobile ? option.mobile : ""}</div>
                    </div>
                    : ""
                }
            
                {option.type == 'Pay out' ?
                    (!!option.payee) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Payee Name</div>
                            <div className='right'>{option.payee}</div>
                        </div> : "" : (!!option.beneficiary) ? <div className='dflex b-bot'>
                            <div className='left'>To</div>
                            <div className='right'>{option.type == 'Pay in' ? option.beneficiary : option.payee}</div>
                        </div> : ""
                }
             
                {option.payment_channel == "M-Pesa Paybill" && option.type == 'Pay out' && !!option.beneficiary_public_name ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.beneficiary_public_name}</div>
                    </div> : ""}
                {option.payment_channel == "M-Pesa Send Money" && option.type == 'Pay out' && !!option.beneficiary_public_name ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.beneficiary_public_name}</div>
                    </div> : ""}
                {option.type == 'Fees' && !!option.beneficiary ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.beneficiary}</div>
                    </div> : ""}
                {option.payment_channel == "M-Pesa Till" && option.type == 'Pay out' && !!option.beneficiary ? <>

                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>Merchant Till</div>
                    </div>
                </> : ""}
           
                {!!option.bank_name && option.type == 'Pay out' && option.payment_channel == "Pesalink" ?
                    <div className='dflex b-bot'>
                        <div className='left'>Bank Name</div>
                        <div className='right'>{option.bank_name}</div>
                    </div> : ""}
                {!!option.account_name && option.type == 'Pay out' && option.payment_channel == "Pesalink" ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.account_name}</div>
                    </div> : ""}
                {!!option.account_number && option.type == 'Pay out' && option.payment_channel == "Pesalink" ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Number</div>
                            <div className='right'>{option.account_number}</div>
                        </div></> : ""
                }
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Send Money' && !!option.till_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Send Money' && !!option.mobile ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Number</div>
                            <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                        </div></> : ""}
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Paybill' && !!option.paybill_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Paybill' && !!option.paybill_number ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Paybill Number</div>
                            <div className='right'>{!!option.paybill_number ? option.paybill_number : ""}</div>
                        </div></> : ""}
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Paybill' && !!option.account_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Paybill' && !!option.account_number ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Account Number</div>
                            <div className='right'>{!!option.account_number ? option.account_number : ""}</div>
                        </div></> : ""}
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Till' && !!option.till_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Till' && !!option.till_number ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Number</div>
                            <div className='right'>{!!option.till_number ? option.till_number : ""}</div>
                        </div></> : ""}
                {!!option.mobile && option.type == 'Pay out' && !!user.entity.name ?
                    <div className='dflex b-bot'>
                        <div className='left'>From</div>
                        <div className='right'>{user.entity.name}</div>
                    </div> : ""}
                    {option.payment_channel == "M-Pesa Paybill" ||
        option.payment_channel == "M-Pesa Till" ? (
          <div className="dflex b-bot">
            <div className="left">Account Name</div>
            <div className="right">{props?.AccountName ?? "N/A"}</div>
          </div>
        ) : (
          ""
        )}

                {
                    (!!option.invoice_no) ?
                        <div className='dflex b-bot'>
                            <div className='left' >Invoice No</div>
                            <div className='right' 
                            // style={{cursor:"pointer",textDecoration:"underline",fontWeight:"bold"}} 
                            // onClick={() => {
                            //                 if (!!option.payable_id) {
                            //                     window.location.href = `bills?id=${option.payable_id}`
                            //                 }
                                        // }}
                                        >{option.invoice_no}</div>
                        </div> : ""
                }
            </div>
            <div className="trans-detail-container__btn-row">

                <Button className='trans-detail-container__btn-row__green' onClick={() => onSave()}>Make Another Payment</Button>
                <Button className='trans-detail-container__btn-row__green' onClick={() => onSave2()}>Notify Payee</Button>

            </div>
        </div>
    )
}
