import { useState, useEffect } from 'react';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { userActions } from '../../../_actions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../../auth/Auth.css';
import showPwdImg from '../ShowPassword/show-password.svg';
import hidePwdImg from '../ShowPassword/hide-password.svg';
import { LoginHeader } from '../LoginHeader';
import { dispatchStore } from '../../../Store/store';
import { getUser, isUserAuthenticated } from '../../../_helpers';
import { authSelector } from '../../../_reducers/authentication.reducer';
import { Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import SimpleImageSlider from 'react-simple-image-slider';
import CarouselLogin from './carousel';
import amplitude from 'amplitude-js';
import posthog from 'posthog-js';

export interface ILogin {}

interface LoginValues {
  email: string;
  password: string;
  rememberMe: boolean;
  terms: boolean;
}
const images = [{ url: '1.gif' }, { url: '2.gif' }, { url: '3.gif' }];
const Login: React.FunctionComponent<ILogin> = () => {
  const [inputs, setInputs] = useState<LoginValues>({
    email: '',
    password: '',
    rememberMe: false,
    terms: false,
  });
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [requestRender, setrequestRender] = useState(true);
  const [responseRender, setresponseRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoShow, setAutoShow] = useState(false);
  const localUser: any = localStorage.getItem('user');

  const APIData = useSelector((state) => authSelector(state));

  useEffect(() => {
    if (requestRender && APIData?.LOGIN_REQUEST == true) {
      setLoading(APIData.LOGIN_REQUEST);
      setresponseRender(true);
      setrequestRender(false);
    }
  }, [APIData, requestRender]);

  useEffect(() => {
    if (responseRender && APIData?.LOGIN_REQUEST == false) {
      setLoading(false);
      setrequestRender(true);
      setresponseRender(false);
    }
  }, [APIData, requestRender]);

  const dispatch = useDispatch();
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Please enter a valid email address.'),
    password: Yup.string().required('Please enter your password.'),
    terms: Yup.bool().oneOf(
      [true],
      'Please accept the Terms of Use and Privacy Policy.'
    ),
  });

  const user = getUser()?.data ?? null;

  useEffect(() => {
    const useQuery = new URLSearchParams(location.search);
    let query = useQuery.get('data');
    if (!!query && query == 'auto') {
      setAutoShow(true);
    } else {
      setAutoShow(false);
    }
  }, []);

  useEffect(() => {
    if (user && isUserAuthenticated()) {
      navigate(user.hasEntity ? '/dashboard' : '/entity');
    }
  }, []);

  const handleSubmit = (e: any) => {
    setSubmitted(true);
    if (e.email && e.password) {
      const event = 'User Logged In';
      const eventProperties = {
        userId: null,
        userName: null,
        email: !!e && !!e.email ? e.email : null,
        pageUrl: window.location.href,
      };
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      const { from } = location.state || { from: { pathname: '/' } };
      dispatchStore(
        userActions.login({ email: e.email, password: e.password }, from) as any
      );
    }
  };

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  return (
    <div className="contebnar_miani_data">
      <ThemeProvider theme={theme}>
        <Grid container className="register-container_two">
          <Grid item md={6} sm={12} xs={12}>
            <div className="formBx_tow">
              <div className="login">
                <img src="/assets/sidebar-logo.png" alt="logo" />
                <p>Log In</p>
              </div>
              <Formik
                initialValues={inputs}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting, handleChange, values }) => (
                  <Form className="form-input">
                    <div className="inputBx">
                      <span>Email</span>
                      <Field
                        name="email"
                        type="text"
                        placeholder="Email"
                        className={
                          'form-text form-control' +
                          (errors.email && touched.email ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback red"
                      />
                    </div>
                    <div className="inputBx">
                      <span>Email</span>
                      <Field
                        name="email"
                        type="text"
                        placeholder="Email"
                        className={
                          'form-text form-control' +
                          (errors.email && touched.email ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback red"
                      />
                    </div>
                    <div className="inputBx">
                      <span>Password</span>
                      <div className="pwd-container">
                        <Field
                          name="password"
                          placeholder="Password"
                          type={isRevealPwd ? 'text' : 'password'}
                          className={
                            'form-text form-control' +
                            (errors.password && touched.password
                              ? ' is-invalid'
                              : '')
                          }
                          value={values.password}
                          onChange={handleChange('password')}
                        />
                        <img
                          title={
                            isRevealPwd ? 'Hide password' : 'Show password'
                          }
                          src={isRevealPwd ? hidePwdImg : showPwdImg}
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="remember">
                      <span>
                        <Link className="link" to="../forgotpassword">
                          Forgot password
                        </Link>
                      </span>
                    </div>
                    <div className="terms">
                      <Field
                        name="terms"
                        type="checkbox"
                        // checked="false"
                        // value="false"
                        className={
                          errors.terms && touched.terms ? ' is-invalid' : ''
                        }
                      />
                      <span
                        className="pl-1 m-2 font-14 "
                        style={{ color: '#000' }}
                      >
                        I have read, understood and I agree to Niobi's{' '}
                        <a
                          target="_blank"
                          className=""
                          href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf"
                        >
                          Terms of Use
                        </a>{' '}
                        and{' '}
                        <a
                          target="_blank"
                          className=""
                          href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf"
                        >
                          Privacy Policy
                        </a>
                        .
                      </span>
                      <ErrorMessage
                        name="terms"
                        component="div"
                        className="invalid-feedback red"
                      />
                    </div>

                    {/* <div className='invalid-Login'>
                                <span>The email or password is incorrect. Please retry</span>
                            </div> */}

                    {autoShow == true ? (
                      <div className="auto-logout-text">
                        You were logged out automatically for inactivity
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="inputBx">
                      <button
                        type="submit"
                        // disabled={isSubmitting}
                        className="form-button"
                      >
                        {loading == true && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        <span>Log In</span>
                      </button>
                    </div>
                    <div className="inputBx">
                      <p>
                        Don't have an account?{' '}
                        <Link to="https://niobi.co/request-a-demo/">
                          Request a demo
                        </Link>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
              <LoginHeader component="login" />
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="secend_grid">
            {/* <SimpleImageSlider
        width={"45%"}
        height={"80vh"}
        // style={{backgroundSize:"100% 100%"}}
        images={images}
        showBullets={true}
        showNavs={false}
        autoPlay={true}
      /> */}

            <CarouselLogin />
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Login;
