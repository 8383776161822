import { Grid, Tooltip } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';



const Outflows = ({bill,currancy }:any) => {
console.log(bill,"bill?.current_month_bills");

    return (
        <>  
                    <div className="main_dev_total" style={{ paddingTop: '22px' }}>
                    <div className="d-flex">
                    <div className="left">No. of transactions</div>
                    <div className="right">
                   
                        {!!bill && !!bill.total_payouts
                            ? bill.total_payouts
                            : '0'}
                    </div>
                </div>
                <div className="d-flex">
                    <div className="left">Total value</div>
                    <div className="right">
                        <span>{bill?.currency}</span>{' '}
                        {!!bill?.current_month_payouts
                            ? Number(
                                parseFloat(
                                    bill?.current_month_payouts
                                ).toFixed(2)
                            ).toLocaleString('en', {
                                minimumFractionDigits: 2,
                            })
                            : bill?.current_month_payouts ?? '0.00'}
                    </div>
                </div>
                <div style={{ paddingTop: '22px' }} className="footer">
                <div className="left"></div>
                <div className="right" id="Total_Bills">
                    <Link className="link" to="/balance">
                        View all
                        <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.19058 0.595215L5.51607 4.9207L1.19058 9.24618"
                                stroke="#257D6B"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
                        {/* <div className="d-flex">
                            <div className="left">Bills created</div>
                            <div className="right">
                               
                                {bill?.current_month_bills}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="left">Bills paid</div>
                            <div className="right">
                                {!!bill && !!bill.total_bills
                                    ? bill.total_bills
                                    : '0'}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="left">Bills due</div>
                            <div className="right">
                              
                               {bill?.outstanding_month_bills}
                            </div>
                        </div> */}
                    </div>
                    {/* <div style={{ paddingTop: '22px' }} className="footer">
                        <div className="left"></div>
                        <div className="right" id="Total_Bills">
                            <Link className="link" to="/bills">
                                View all
                                <svg
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.19058 0.595215L5.51607 4.9207L1.19058 9.24618"
                                        stroke="#257D6B"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div> */}
             
        </>
    )
}
export default Outflows;
const InfoIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 11 11"
        fill="none"
    >
        <path d="M5.3333 7.50039V4.90039" stroke="#025041" stroke-linecap="round" />
        <path
            d="M5.33333 3.16654C5.57265 3.16654 5.76666 3.36055 5.76666 3.59987C5.76666 3.83919 5.57265 4.0332 5.33333 4.0332C5.094 4.0332 4.89999 3.83919 4.89999 3.59987C4.89999 3.36055 5.094 3.16654 5.33333 3.16654Z"
            fill="#025041"
        />
        <path
            d="M3.16666 1.5798C3.82512 1.19886 4.57261 0.998833 5.33333 1.00001C7.72662 1.00001 9.66665 2.94004 9.66665 5.33333C9.66665 7.72663 7.72662 9.66666 5.33333 9.66666C2.94003 9.66666 1 7.72663 1 5.33333C1 4.54423 1.21103 3.80367 1.5798 3.16667"
            stroke="#025041"
            stroke-linecap="round"
        />
    </svg>
);
