import styles from './Bulk_payment_summary.module.scss';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import moment from "moment";
import { Button, Table } from "react-bootstrap";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isUserAuthenticated } from "../../../../../../_helpers";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { selectAllPayablesListData } from "../../../../../../_reducers/payables.reducer";
import { CssBaseline, CircularProgress } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
} declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

interface Data {
    transaction_date: Date;
    reference: string | number;
    Type: string | number;
    beneficiary: number;
    category: string;
    description: string;
    transaction_amount: string | number;
    transaction_status: string | number;
    balance: string | number;
    more_info: string | number;
}

function createData(
    transaction_date: Date,
    reference: string | number,
    Type: string | number,
    beneficiary: number,
    category: string,
    description: string,
    transaction_amount: string | number,
    transaction_status: string | number,
    balance: string | number,
    more_info: string | number
): Data {
    return {
        transaction_date,
        reference,
        Type,
        beneficiary,
        category,
        description,
        transaction_amount,
        transaction_status,
        balance,
        more_info
    };
}



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {
        id: 'transaction_date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: true,
        label: 'Payment Reference',
    },
    {
        id: 'Type',
        numeric: false,
        disablePadding: false,
        label: 'Payment Method',
    },
    {
        id: 'beneficiary',
        numeric: true,
        disablePadding: false,
        label: 'Account No.',
    },
    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'Bank Name',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Type ',
    },
    {
        id: 'transaction_amount',
        numeric: false,
        disablePadding: false,
        label: 'Beneficiary',
    },
    {
        id: 'transaction_status',
        numeric: false,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'Transaction',
    },
    {
        id: 'more_info',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    //   onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    status: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, status } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            //onRequestSort(event, property);
        };
    var newHeadcells = headCells
    // if (status == false) {
    //     newHeadcells = headCells.filter(person => person.id != 'transaction_status');
    // }
    return (
        <thead>
            <tr className={styles.baahhajhajaha}>
                {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> */}
                {newHeadcells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className="th"
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </tr>
        </thead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
        //   sx={{
        //     pl: { sm: 2 },
        //     pr: { xs: 1, sm: 1 },
        //     ...(numSelected > 0 && {
        //       bgcolor: (theme) =>
        //         alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        //     }),
        //   }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        {/* <DeleteIcon /> */}
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        {/* <FilterListIcon /> */}
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

const Bulk_payment_summary = (props: any) => {
    const rows: any[] = props.data ? props.data : [];
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('transaction_date');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [isProgress, setIsProgress] = React.useState(true)
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [panding, setPanding_data] = React.useState(false)
    const [stast_api, setStast_api] = React.useState(false)
    const [topbox_data, setTop_box_data] = React.useState<any>()
    const [transRender, setTransRender] = React.useState(false);
    const [requestRender, setrequestRender] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [responseRender, setresponseRender] = React.useState(false);
    const [otpFailed, setotpFailed] = React.useState(false);
    const [data_tanjecasan, setData_tanjeksan] = React.useState<any>()
    const [datainline, seDatainline] = React.useState<any>(false);
    const [showModal, setShow] = React.useState(true);
    const [pandinglog, setPandingdata] = React.useState(false)
    const [Displaydata, setDisplaydata] = React.useState(true)
    const [pendingclos, setPendingclos] = React.useState(false)

    const [insetapireduser, setInsetapireduser] = React.useState(true)
    const dispatch = useDispatch()

    // React.useEffect(() => {
    //     if (isUserAuthenticated() && stast_api) {
    //         setStast_api(false);

    //     }
    // }, [stast_api, isUserAuthenticated])

    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const paybill_data = useSelector((state) => selectAllPayablesListData(state))
    var dataNew = paybill_data
    if (dataNew != paybill_data) {
        dataNew = paybill_data
    }
    console.log(datainline,pendingclos,showModal,dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS?.batch_status,'datainline');

    console.log(paybill_data.INITIATE_BULK_PAYMENT_SUCCESS, 'dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS out');
    // INITIATE_BULK_PAYMENT_SUCCESS
    console.log(dataList, panding, 'pandingpanding');

    // React.useEffect(() => {
    //     if (requestRender && dataList?.GET_BULK_PAYMENTS_STATUS_REQUEST == true) {
    //         setLoading(dataList.GET_BULK_PAYMENTS_STATUS_REQUEST);
    //         setresponseRender(true);
    //         setrequestRender(false);
    //         setPanding_data(true);
    //         setotpFailed(true);
    //         setShow(true)
    //         seDatainline(true)
    //     }
    // }, [dataList, requestRender]);

    React.useEffect(() => {
        if (responseRender && dataList?.GET_BULK_PAYMENTS_STATUS_REQUEST == false) {
            // console.log('OTPrequest response OTP', 'OTP_REQUEST');
            setLoading(false);
            setrequestRender(true);
            setresponseRender(false);
        }
    }, [dataList, responseRender]);
    React.useEffect(() => {
        if (
            otpFailed &&
            dataList?.GET_BULK_PAYMENTS_STATUS_FAILURE !== undefined
        ) {
            console.log(dataList?.GET_BULK_PAYMENTS_STATUS_FAILURE, 'dataList?.GET_BULK_PAYMENTS_STATUS_FAILURE');

            setTransRender(false);
            setotpFailed(false);
            onClose()
            // setButtonType("AuthoriseTransaction")
        }
    }, [dataList, otpFailed]);
    console.log(paybill_data, stast_api && paybill_data.INITIATE_BULK_PAYMENT_SUCCESS !== undefined, "dataListdataList");
    React.useEffect(() => {
        if (Displaydata == true) {
            setDisplaydata(true)
            setTimeout(() => {
                setStast_api(true)
            }, 4000)

        }
    }, [Displaydata]);
    console.log(stast_api, paybill_data.INITIATE_BULK_PAYMENT_SUCCESS, "SDDDDDDD");

    React.useEffect(() => {
        if (stast_api && paybill_data.INITIATE_BULK_PAYMENT_SUCCESS !== undefined) {
            console.log('dtudryyyyyy');
            setStast_api(false)
            setLoading(true)
            setPanding_data(true)
            setShow(true)
            seDatainline(true)
            dispatch(paymentJourneyActions.getbulk_payments_status_active({ "data": props.id }) as any)

            // setPanding_data(true)
        }
    }, [stast_api]);
    console.log(dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS, 'stast_api')
    React.useEffect(() => {

        if (panding && dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS !== undefined) {
            console.log(dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS, 'dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS in');
            if (dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS?.batch_status == false) {
                console.log(dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS, 'dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS in in');
                setPanding_data(false)
                setLoading(true)
                setShow(true)
                seDatainline(true)
                setTimeout(() => {
                    setPendingclos(true)
                }, 120000)
                dispatch((paymentJourneyActions.getbulk_payments_status_active({ "data": props.id })) as any)
                setTimeout(() => {
                    setPanding_data(true)
                }, 1500)
                var newArray = []
                for (let index = 0; index < dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS.data.length; index++) {
                    const element = dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS.data[index];
                    if (!!element) {
                        newArray.push(element)
                    }
                }

                setData_tanjeksan(newArray)
                setTop_box_data(dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS.payout_details)
            } else if (dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS.batch_status == true) {

                console.log(dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS, 'dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS else');
                var newArray = []
                for (let index = 0; index < dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS.data.length; index++) {
                    const element = dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS.data[index];
                    if (!!element) {
                        newArray.push(element)
                    }
                }
                    
                setData_tanjeksan(newArray)
                setTop_box_data(dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS.payout_details)
                seDatainline(true)
                setTimeout(() => {
                    onClose()
                }, 200)
                setPanding_data(false)
            } else {
                // seDatainline(false)
                
                // setTimeout(() => {
                //     onClose()
                // }, 3000)
            }
            // setPanding_data(false)
            // if (dataList?.GET_BULK_PAYMENTS_STATUS_SUCCESS?.status == 'pending') {

            // }
        }

    }, [dataList, panding]);
    React.useEffect(() => {
        if (insetapireduser && paybill_data.INITIATE_BULK_PAYMENT_FAILURE !== undefined) {

            setInsetapireduser(false)
            // setTimeout(() => {
            //     setPandingdata(true)
            // }, 4000)
        }
    }, [paybill_data, insetapireduser]);
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const onClose = () => {
        setShow(false);
    }
    return (
        <>

            <Modal
                show={showModal}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby='ModalHeader'
                className={styles.topup_modal}
                centered
                size='lg'
                // size={pendingclos == true ? "lg":'sm'}
            // id={styles.maiana_contwean_abh}
            >
                <>
                    {datainline == true ?

                        <div style={{ borderRadius: "5px", background: "#FFF", width: "100%", height: "35vh", alignItems: 'center', display: "flex", justifyContent: "center" }}>
                            <div>
                                <div className={styles.heding_tesyssjsj}>Transaction in progress</div>
                                <div style={{ textAlign: "center", padding: "15px 0px 0px 0px" }}>
                                    {/* <span className="spinner-border spinner-border-md mr-1"></span> */}
                                    {pendingclos == true ? <>
                                            <p className="des_clos_popup">The payment is still being processed. We request for your patience as we work to complete the process.</p>
                                            <p className="des_clos_popup">In the meantime, please do not attempt making the transaction again. You can review the transaction status on the balance screen under pending transactions</p>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Button className="balance__save-btn" style={{ marginTop: "13px" }} onClick={() => { window.location.href = '/balance' }}>Close</Button>
                                            </div>
                                        </>:<img width={75} height={75} src="/Niobi Loading Animation-Cross.gif" />
                                         }
                                </div>

                            </div>

                        </div>
                        // : datainline == false ?
                        //     <div style={{ borderRadius: "5px", background: "#FFF", width: "100%", height: "35vh", alignItems: 'center', display: "flex", justifyContent: "center" }}>
                        //         <div>

                        //             <div style={{ textAlign: "center", padding: "0px 0px 15px 0px" }}>
                        //                 <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        //                     <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z" fill="#025041" />
                        //                 </svg>
                        //             </div>
                        //             <div className={styles.heding_tesyssjsj}>Payment Successful </div>

                        //         </div>

                        //     </div> 
                            : ""
                    }</> </Modal>
            <div className="balance">
                <div style={{ padding: "18px 32px 18px 40px" }} className="balance__header">
                    <div className="balance__header__name">Bulk Payment Summary</div>

                </div>
                <div className={styles.main_card_contean_data}>
                    <div style={{ margin: "32px 13px" }} className="balance__card">
                        <div className="balance__card__total">Total Successful Payout Amount</div>
                        <div className="balance__card__row">
                            <span className="balance__card__unit">KES</span>
                            {/* <span className="balance__card__amount">{!!balance ? Number(parseFloat(balance).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}):balance ?? "0.00"}</span> */}
                            <span style={{ color: "#515151" }} className="balance__card__amount">
                                {!!topbox_data ? Number((topbox_data.gross_total_succeeded/100).toFixed(2)).
                                    toLocaleString('en', { minimumFractionDigits: 2 })
                                    : "0.00"}
                            </span>

                        </div>
                    </div>
                    <div style={{ margin: "32px 13px", padding: "14px 3px 14px 10px" }} className="balance__card">
                        <div className="balance__card__total">Total Successful Payout Transactions</div>
                        <div className="balance__card__row">
                            {/* <span className="balance__card__amount">{!!balance ? Number(parseFloat(balance).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}):balance ?? "0.00"}</span> */}
                            <span style={{ color: "#04715C" }} className="balance__card__amount">

                                {!!topbox_data ? topbox_data.transactions_succeeded : 0}
                            </span>

                        </div>
                    </div>
                    <div style={{ margin: "32px 13px" }} className="balance__card">
                        <div className="balance__card__total">Total Failed Payout Amount</div>
                        <div className="balance__card__row">
                            <span className="balance__card__unit">KES</span>
                            <span style={{ color: "#515151" }} className="balance__card__amount">
                                {!!topbox_data ? Number((topbox_data.gross_total_failed/100).toFixed(2)).
                                    toLocaleString('en', { minimumFractionDigits: 2 })
                                    : "0.00"}
                            </span>

                        </div>
                    </div>
                    <div style={{ margin: "32px 13px" }} className="balance__card">
                        <div className="balance__card__total">Total Failed Transactions</div>
                        <div className="balance__card__row">
                            {/* <span className="balance__card__amount">{!!balance ? Number(parseFloat(balance).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}):balance ?? "0.00"}</span> */}
                            <span style={{ color: "#DE1C06" }} className="balance__card__amount">
                                {!!topbox_data ? topbox_data.transactions_failed : 0}
                            </span>

                        </div>
                    </div>
                </div>
                <div className="balance__table">
                    <ThemeProvider theme={theme}>

                        <div className='contenar_the_data'>

                            <TableContainer>
                                <Table className={styles.tebal_contean_data}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        rowCount={rows.length}
                                        status={false}
                                    />
                                    <tbody className={styles.ajSDjkjadjdkdjd}>

                                        {!!data_tanjecasan &&
                                            data_tanjecasan
                                                .map((element: any, index: number) => {
                                                    var TAmount = element.amount;

                                                    var tagclass = '';
                                                    var textclass = '';
                                                    var tagname = element.type;
                                                    if (element.type == 'Pay in') {
                                                        tagclass = 'tag_payin-tag'
                                                        textclass = 'text_payin-tag'
                                                        tagname = 'Pay In'
                                                    } else if (element.type == 'Pay out') {
                                                        tagclass = 'tag_payout-tag'
                                                        textclass = 'text_payout-tag'
                                                        tagname = 'Pay Out'
                                                        TAmount = -element.amount;
                                                    } else if (element.type == 'Fees') {
                                                        tagclass = 'tag_fees-tag'
                                                        textclass = 'text_fees-tag'
                                                        tagname = 'Fees'
                                                        TAmount = -element.amount;
                                                    }
                                                    var trans_cat = [];
                                                    console.log(element.categories, "element.categories");

                                                    if (!!element && !!element.categories) {
                                                        var categories = JSON.parse(element.categories)
                                                        for (let index = 0; index < categories.length; index++) {
                                                            const records = categories[index];
                                                            console.log(records, "records");

                                                            if (!!records && !!records.name) {
                                                                console.log(records, "records");

                                                                trans_cat.push(records.name);
                                                            }
                                                        }
                                                    }
                                                    console.log(element, trans_cat, 'trans_cat')
                                                    const transCategory = trans_cat.toString();


                                                    return (
                                                        // element.success == false ?
                                                        // <TableRow  >
                                                        //     <TableCell>{element.message}</TableCell>
                                                        // </TableRow> :
                                                        <TableRow  >

                                                            <TableCell><p className={'tebaldateformette'}>
                                                                {moment(element.created_at).format("DD-MMM-YYYY")}
                                                            </p></TableCell>
                                                            <TableCell>{element.ref}</TableCell>
                                                            <TableCell><p className={'tebaldateformette'}>
                                                                {element.payment_channel}</p></TableCell>
                                                            <TableCell><p className={'tebaldateformette'}>
                                                                {element.payment_channel == 'M-Pesa Till' ? element.till_number : element.payment_channel == 'Pesalink' ? element.account_number : element.payment_channel == "M-Pesa Paybill" ? element.account_reference : element.mobile}</p></TableCell>
                                                            <TableCell><p className={'tebaldateformette'}>
                                                                {!!element.bank_name ? element.bank_name : ""}</p></TableCell>
                                                            <TableCell className="type tag">
                                                                <span className={`tag_color ${tagclass}`}>
                                                                    {tagname}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell><p className={'tebaldateformette'}>
                                                                {element.payee}</p></TableCell>
                                                            <TableCell className='balance-category'>
                                                                <p className={'tebaldateformette'}>
                                                                    {transCategory}</p></TableCell>


                                                            <TableCell>KES {!!element.amount ? Number(parseFloat(element.amount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : ""}</TableCell>
                                                            <TableCell>
                                                                <p
                                                                    className={element.status == 'failed' ? styles.Failed_calss :
                                                                        element.status == 'success' ? styles.Success_calss : styles.Pending_calss}>
                                                                    {element.status}</p></TableCell>
                                                        </TableRow>

                                                    );
                                                })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </tbody>
                                </Table>
                            </TableContainer>
                        </div>

                        {/* </Box> */}


                    </ThemeProvider>
                    <div className={styles.main_contenar_listts_footer}>
                        <Button className='auth-trans-container__btn-row__white'
                            onClick={() => { window.location.href = "/balance" }}
                        //  onClick={() => onCancel()}
                        >
                            Go Back</Button>
                        <Button className='auth-trans-container__btn-row__green'
                            onClick={() => { window.location.href = "/balance" }}
                        //  onClick={() => onCancel()}
                        >
                            Make Another Payment</Button>
                    </div>
                </div>
            </div>


        </>
    )
}
export default Bulk_payment_summary;