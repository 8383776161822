import { Grid, Typography } from '@mui/material';
import styles from './LinkPage.module.scss';
import { Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import { FacebookShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import { useDispatch, useSelector } from "react-redux";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import { selectPaymentJourneyData } from "../../../../../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../../../../../_actions/paymentJourney.actions";
const LinkPage = (props) => {
    console.log(props.updetid, "sdadASddddd");
    const [balance, setBalance] = useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [datadesplay, setDatadesplay] = useState(true)
    const [balanceRender, setBalanceRender] = useState(false);
    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    console.log(balance, "balance");
    const [open_copy, setOpen_copy] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen_copy(false);
    };

    const handleTooltipOpen = () => {
        setOpen_copy(true);
        setTimeout(() => {
            setOpen_copy(false)
        }, 3000)
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (balanceRender && dataList?.GETSHAREABLEPDFLINK_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(dataList.GETSHAREABLEPDFLINK_SUCCESS);
            setDatadesplay(false)
        }
    }, [dataList, balanceRender]);
    const handleClose_twoo = () => {
        setAnchorEl(null);
    };
    console.log(props?.data?.data?.link, "props?.data?.data?.link");
    return (
        <>
            <Grid container>
                <Grid item md={2}>

                </Grid>
                <Grid item md={8} xs={12}>
                    <Button className={styles.GOback_button} onClick={() => {

                        navigate("/settings/Payment_Link")



                    }}>
                        <KeyboardBackspaceIcon /> Go Back
                    </Button>
                    <div className={styles.divclassName_linkpage}>

                        <div className={styles.className_mainlikadd}>
                            <p>Payment link</p>
                            <div className={styles.Typography_div_clsss}>
                                <Typography>
                                    {props?.data?.data?.link}
                                </Typography>
                            </div>
                            <div className={styles.button_threeorr}>
                                <Button onClick={() => {
                                    if (!!props?.updetid) {
                                        window.location.reload()
                                    } else {
                                        props.onClose()
                                        navigate("/settings/CeretePaymentLink?short_code=" + props?.data?.data?.short_code, { replace: true, state: { short_code: props?.data?.data?.short_code } })
                                    }
                                }}>
                                    <Edit />Edit
                                </Button>



                                <CopyToClipboard text={props?.data?.data?.link}
                                // onCopy={() => {
                                //     handleTooltipOpen()
                                //     console.log('copy')
                                // }}
                                >
                                    {/* <ClickAwayListener onClickAway={handleTooltipClose}> */}
                                        {/* <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener

                                            onClose={handleTooltipClose}
                                            open={open_copy}
                                            title="link copied"
                                        > */}
                                            <Button 
                                            data-tooltip-id="my-tooltip-click"
                                            // onClick={() => { handleTooltipOpen() }}
                                            >

                                            <Copy />Copy link

                                            </Button>
                                        {/* </Tooltip> */}
                                    {/* </ClickAwayListener> */}
                                </CopyToClipboard>


                                <Button onClick={handleClick}>
                                    <Sreea />Share
                                </Button>
                            </div>
                        </div>

                    </div>
                </Grid>
                <Tooltip
                id="my-tooltip-click"
                content="link has been copied"
                events={['click']}
            />
                <Grid item md={2}>

                </Grid>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose_twoo}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ "& .MuiMenu-list": { padding: "0px" } }}
                >
                    <div style={{ padding: "5px" }}>
                        <div style={{ height: "27px" }}>
                            <WhatsappShareButton
                                url={props?.data?.data?.link}
                                onClick={handleClose_twoo}
                            >
                                Send via WhatsApp
                            </WhatsappShareButton>
                        </div>
                        <div style={{ height: "27px" }}>
                            <EmailShareButton
                                // onClick={handleClose_twoo}
                                url={props?.data?.data?.link}
                            // body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${!balance ? "" : balance.data.path}${'\n'}`}
                            >
                                Send via Email
                            </EmailShareButton>

                        </div>
                    </div>
                </Menu>
            </Grid>
        </>
    )
}
export default LinkPage;

const Edit = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
        <path d="M12.084 4.50002L14.334 6.75002M10.584 15H16.584M4.58398 12L3.83398 15L6.83398 14.25L15.5235 5.56052C15.8047 5.27923 15.9627 4.89776 15.9627 4.50002C15.9627 4.10227 15.8047 3.72081 15.5235 3.43952L15.3945 3.31052C15.1132 3.02931 14.7317 2.87134 14.334 2.87134C13.9362 2.87134 13.5548 3.02931 13.2735 3.31052L4.58398 12Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
const Copy = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
        <path d="M8.125 0C8.55598 0 8.9693 0.171205 9.27405 0.475951C9.57879 0.780698 9.75 1.19402 9.75 1.625V10.625C9.75 10.8384 9.70797 11.0497 9.6263 11.2469C9.54464 11.444 9.42494 11.6232 9.27405 11.774C9.12315 11.9249 8.94401 12.0446 8.74686 12.1263C8.54971 12.208 8.3384 12.25 8.125 12.25H2.125C1.9116 12.25 1.70029 12.208 1.50314 12.1263C1.30598 12.0446 1.12685 11.9249 0.975951 11.774C0.825056 11.6232 0.705359 11.444 0.623695 11.2469C0.542031 11.0497 0.5 10.8384 0.5 10.625V1.625C0.5 1.19402 0.671205 0.780698 0.975951 0.475951C1.2807 0.171205 1.69402 0 2.125 0H8.125ZM8.75 1.625C8.75 1.28 8.47 1 8.125 1H2.125C1.78 1 1.5 1.28 1.5 1.625V10.625C1.5 10.97 1.78 11.25 2.125 11.25H8.125C8.47 11.25 8.75 10.97 8.75 10.625V1.625ZM10.5 1.8745C11.087 2.1195 11.5 2.699 11.5 3.3745V10.75C11.5 11.1768 11.4159 11.5994 11.2526 11.9937C11.0893 12.388 10.8499 12.7463 10.5481 13.0481C9.9386 13.6576 9.11195 14 8.25 14H3.875C3.55411 14 3.2404 13.9051 2.97338 13.7271C2.70636 13.5492 2.49798 13.2962 2.3745 13H8.25C8.84674 13 9.41903 12.7629 9.84099 12.341C10.2629 11.919 10.5 11.3467 10.5 10.75V1.8745Z" fill="white" />
    </svg>
)
const Sreea = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path d="M9.33464 8.48193C8.87019 8.48193 8.45464 8.66265 8.13686 8.94578L3.77964 6.44578C3.81019 6.30723 3.83464 6.16867 3.83464 6.0241C3.83464 5.87952 3.81019 5.74096 3.77964 5.60241L8.08797 3.12651C8.41797 3.42771 8.85186 3.61446 9.33464 3.61446C10.3491 3.61446 11.168 2.80723 11.168 1.80723C11.168 0.807229 10.3491 0 9.33464 0C8.32019 0 7.5013 0.807229 7.5013 1.80723C7.5013 1.95181 7.52575 2.09036 7.5563 2.22892L3.24797 4.70482C2.91797 4.40361 2.48408 4.21687 2.0013 4.21687C0.986858 4.21687 0.167969 5.0241 0.167969 6.0241C0.167969 7.0241 0.986858 7.83133 2.0013 7.83133C2.48408 7.83133 2.91797 7.64458 3.24797 7.34337L7.59908 9.8494C7.56852 9.9759 7.55019 10.1084 7.55019 10.241C7.55019 11.2108 8.35075 12 9.33464 12C10.3185 12 11.1191 11.2108 11.1191 10.241C11.1191 9.27108 10.3185 8.48193 9.33464 8.48193ZM9.33464 1.20482C9.67075 1.20482 9.94575 1.4759 9.94575 1.80723C9.94575 2.13855 9.67075 2.40964 9.33464 2.40964C8.99852 2.40964 8.72352 2.13855 8.72352 1.80723C8.72352 1.4759 8.99852 1.20482 9.33464 1.20482ZM2.0013 6.62651C1.66519 6.62651 1.39019 6.35542 1.39019 6.0241C1.39019 5.69277 1.66519 5.42169 2.0013 5.42169C2.33741 5.42169 2.61241 5.69277 2.61241 6.0241C2.61241 6.35542 2.33741 6.62651 2.0013 6.62651ZM9.33464 10.8554C8.99852 10.8554 8.72352 10.5843 8.72352 10.253C8.72352 9.92169 8.99852 9.6506 9.33464 9.6506C9.67075 9.6506 9.94575 9.92169 9.94575 10.253C9.94575 10.5843 9.67075 10.8554 9.33464 10.8554Z" fill="white" />
    </svg>
)