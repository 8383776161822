import React from 'react';
import { authHeader, handleResponse } from '../_helpers';
import { getEnvironment } from '../_helpers/environmentProperties';

const localStorageList = localStorage.getItem('apiMode');
const baseURL =
  localStorageList == 'production'
    ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL
  ;


console.log(localStorageList,baseURL, "getEnvironment()");
export const userService = {
  login,
  logout,
  register,
  sendEmail,
  updatePassword,
  forgotPassword,
  verifyToken,
  changePassword,
  getOtp,
  registerByInvite,
  sendOtp,
  loginOtpVerify,
  ResendOtp,
  LoginToken
};

function login(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURL}/login`, requestOptions).then(handleResponse);
}
function LoginToken(user,baseURLData) {
  console.log(baseURLData,"baseURLDatabaseURLData");
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURLData}/payments/get-user-details`, requestOptions).then(handleResponse);
}
function sendOtp(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURL}/login-new`, requestOptions).then(handleResponse);
}
function ResendOtp(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURL}/resend-login-otp`, requestOptions).then(
    handleResponse
  );
}
function loginOtpVerify(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURL}/login-otp-verify`, requestOptions).then(
    handleResponse
  );
}
function logout() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    // body: JSON.stringify(user.req)
  };
  return fetch(`${baseURL}/logout`, requestOptions).then(handleResponse);
}

function register(URLApi,user) {
  console.log(user,"dsfszdffsf");
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user.req),
  };
  return fetch(`${URLApi}/register/portal`, requestOptions).then(handleResponse);
}
function sendEmail(user) {
  console.log(user,"dsfszdffsf");
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURL}/send-welcome-email`, requestOptions).then(handleResponse);
}

function updatePassword(user) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(user.req),
  };
  return fetch(`${baseURL}/change-password`, requestOptions).then(
    handleResponse
  );
}

function forgotPassword(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURL}/forget-password`, requestOptions).then(
    handleResponse
  );
}

function verifyToken(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${baseURL}/verify-token`, requestOptions).then(handleResponse);
}

function changePassword(user) {
  const requestOptions = {
    method: 'Post',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(user.req),
  };
  return fetch(`${baseURL}/verify-account`, requestOptions).then(
    handleResponse
  );
}

function getOtp() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-otp`, requestOptions).then(handleResponse);
}

function registerByInvite(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user.req),
  };
  return fetch(`${baseURL}/register-by-invitation`, requestOptions).then(
    handleResponse
  );
}
