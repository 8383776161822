import { Grid } from '@mui/material';
import './contean_bill.scss'
import No_bill from './components/No_bill/No_bill';
import Example from './components/bill_data/Bill_data';
import Document_details from './components/Document_details/Document_details';
import From_bill from './components/from_bill/from_bill';
const Contean_Bill = (props: any) => {
    console.log(props?.data?.status, "props?.data");

    return (

        <>
            <Grid container style={{background: "#F6F6F6"}}>
                <Grid item md={6} sm={12} xs={12} style={{background: "#fff",margin:"6px 0px"}}>
                    <Example data={props?.data.data} />
                </Grid>
                <Grid item md={6} sm={12} xs={12} >
                    
                    {props?.data?.data?.status == "new" || props?.data?.data?.status == "draft" ?
                        <From_bill setData={props.setDATA} data={props?.data} />
                        :props?.data?.data?.status == "approve_in_progress" ||
                         props?.data?.data?.status == "rejected" ||
                         props?.data?.data?.status == "paid" ||
                         props?.data?.data?.status == "canceled" ||
                         props?.data?.data?.status == "waiting_to_be_paid" ?
                        <Document_details data={props?.data} />:""
                    }
                </Grid>
            </Grid>
        </>
    )
}
export default Contean_Bill;