import ApiLink from './ApiLink';
const ApiDocumentation = () => {

    return (
        <div className='gethelp'>
            <div className="balance__header">
                <div className="balance__header__name">API Documentation </div>

            </div>
            <div className="dashboard-container">
                <div className="box_add_new_data">
                   <ApiLink/>
                </div>

            </div>
        </div>
    )
}
export default ApiDocumentation;