import { Box, Typography } from "@mui/material";
import styles from "./Pay.module.scss";
import { Formik, Field, ErrorMessage } from "formik";
import { Button, InputGroup, Form } from "react-bootstrap";

import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
  getBase64,
  getKESFormate,
} from "../../../../../../../../_helpers/common";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../../../_reducers/paymentJourney.reducer";
interface EntityRequest {
  First_name: string;
  Last_name: string;
  Street_Address: string;
  Email: string;
  country_id: string;
  amount: string;
  Mobile: number;
  currency: string;
}
interface countryProps {
  dialCode: string;
}
interface Props {
  nextpage: any;
  payload: any;
  setpayload: any;
  data: any;
  entityid: any;
  shortcode: any;
}

interface payloadProps {
  first_name: any;
  last_name: any;
  email: any;
  country_id: any;
  short_code: any;
  entity_id: any;
  address: any;
  mobile?: any;
  amount?: any;
}
const Detailspayment = (props: any) => {
  const [enableButton, setEnableButton] = useState(false);
  const [country, setCountry] = useState<any>();
  const [startgetapi, setStartgetapi] = useState(true);
  const [startgetapicountry, setStartgetapicountry] = useState(true);
  const [render, setRender] = useState(true);
  const [CurrenciesList, setCurrenciesList] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const numberMask = createNumberMask({
    prefix: "",
    suffix: "", // This will put the dollar sign at the end, with a space.
  });
  const [entity, setEntity] = useState<EntityRequest>({
    First_name: "",
    Last_name: "",
    Street_Address: "",
    Email: "",
    country_id: props?.country,
    amount: props?.data?.Amount,
    Mobile: 0,
    currency: props?.data?.Currancy,
  });
  const [submitData, setsubmitData] = useState(false);
  const dispatch = useDispatch();
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const validationSchema = Yup.object().shape({
    First_name: Yup.string().required("Please enter your first name."),
    Last_name: Yup.string().required("Please enter your last name."),
    Email: Yup.string()
      .required("Please enter a valid email address.")
      .email("Please enter a valid email address."),
    Street_Address: Yup.string().required("Please enter the street address."),
    Country: Yup.string().required(
      "Please select the country where the business is located."
    ),
    // amount: Yup.string()
    //     .required('Enter a Amount in the required field'),
    // Mobile: Yup.number()
    //     .required('Enter a Mobile in the required field'),
  });
  console.log(
    entity,
    props,
    props?.data?.Amount,
    entity.amount,
    "entityentity"
  );

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(paymentJourneyActions.getCurrencies() as any);
      dispatch(paymentJourneyActions.getCountries() as any);
    }
  }, [render]);
  // GETCOUNTRIES_SUCCESS
  useEffect(() => {
    if (startgetapi && dataList?.GETCURRENCIES_SUCCESS !== undefined) {
      setCurrenciesList(dataList?.GETCURRENCIES_SUCCESS);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  const Countries = (data: any) => {
    dispatch(paymentJourneyActions.getCountriesByCurrency(data) as any);
    setTimeout(() => {
      setStartgetapicountry(true);
    }, 4000);
  };
  useEffect(() => {
    if (startgetapicountry && dataList?.GETCOUNTRIES_SUCCESS !== undefined) {
      setCountryList(dataList?.GETCOUNTRIES_SUCCESS);

      setStartgetapicountry(false);
    }
  }, [startgetapicountry, dataList]);
  const handleSubmit = () => {};
  return (
    <>
      <Box className={styles.from_contean_data}>
        <Typography className={styles.heding_from}>
          Please enter your details
        </Typography>
        <Formik
          initialValues={entity}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting,
            handleChange,
            values,
            setFieldValue,
          }) => (
            <div className="company-details-container__wrapper">
              {/* <div style={{padding:'20px 0px',textAlign:'center'}} className='company-details-container__heading'>Business Management</div> */}
              <div className="input-form-wrapper dot">
                <label>First name</label>
                <input
                  name="First_name"
                  disabled
                  type="text"
                  placeholder="First name"
                  onChange={(e: any) => {
                    handleChange(e);
                    setEnableButton(true);
                  }}
                  style={{ background: "#fff" }}
                  className={
                    "text-field" +
                    (errors.First_name && touched.First_name
                      ? " is-invalid"
                      : "")
                  }
                />

                <ErrorMessage
                  name="First_name"
                  component="div"
                  className="invalid-feedback red"
                />
              </div>
              <div className="input-form-wrapper">
                <label>Last name</label>
                <input
                  name="Last_name"
                  disabled
                  style={{ background: "#fff" }}
                  type="text"
                  placeholder="Last name"
                  onChange={(e: any) => {
                    handleChange(e);
                    setEnableButton(true);
                  }}
                  className={
                    "text-field" +
                    (errors.Last_name && touched.Last_name ? " is-invalid" : "")
                  }
                />

                <ErrorMessage
                  name="Last_name"
                  component="div"
                  className="invalid-feedback red"
                />
              </div>
              <div className="input-form-wrapper dot">
                <label>Email</label>
                <input
                  name="Email"
                  type="text"
                  placeholder="Email"
                  disabled
                  style={{ background: "#fff" }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setEnableButton(true);
                  }}
                  className={
                    "text-field" +
                    (errors.Email && touched.Email ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="Email"
                  component="div"
                  className="invalid-feedback red"
                />
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className="input-form-wrapper dot"
                  style={{ width: "100%", padding: 0 }}
                >
                  <label>Currency</label>
                  <Form.Select
                    disabled
                    name="currency"
                    onChange={(e: any) => {
                      handleChange(e);
                      // const findcurrencies: any = CurrenciesList.find((data: any) => data.id == e.target.value)
                      // Countries(e.target.value);
                      // console.log(findcurrencies,CurrenciesList,e.target.value,"findcurrencies");
                    }}
                    value={values.currency}
                    className="select_box_kes"
                  >
                    <option selected value="">
                      Currency
                    </option>
                    {CurrenciesList?.map((arrayElement: any, index: number) => {
                      return (
                        <option value={arrayElement.currency} key={index + 1}>
                          {arrayElement.currency}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <ErrorMessage
                    name="currency"
                    component="div"
                    className="invalid-feedback red"
                  />
                </div>
              </div>
              <div className="input-form-wrapper dot">
                <label>Country</label>
                <Form.Select
                  disabled
                  name="country_id"
                  onChange={(e: any) => {
                    // setCountry(e);
                    handleChange(e);
                  }}
                  className={"text-field"}
                  value={values.country_id}
                >
                  <option selected value="">
                    Select country
                  </option>
                  {CountryList?.map((arrayElement: any, index: number) => {
                    return (
                      <option value={arrayElement.id} key={index + 1}>
                        {arrayElement.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <ErrorMessage
                  name="country_id"
                  component="div"
                  className="invalid-feedback red"
                />
              </div>
              <div className="className_divmenu">
                <div style={{ width: "100%" }}>
                  <div className="input-form-wrapper dot ">
                    <label>Amount</label>
                    <InputGroup
                      className="doc-input-wrapper input"
                      style={{ padding: 0 }}
                    >
                      <MaskedInput
                        mask={numberMask}
                        disabled
                        style={{ height: "100%" }}
                        // className={'text-field border-green' + (errors.amount && touched.amount ? ' is-invalid' : '')}
                        value={props?.data?.Amount}
                        className="doc-form-input form-control"
                        placeholder="Total"
                        // value={(data.amount <= 0 || !data.amount ? values.amount: values.amount)}
                        //onChange={handleSubmit}
                        name="amount"
                        onChange={(e) => {
                          const kesValue = getKESFormate(e.target.value);
                          const obj = e;

                          console.log(kesValue, "kesValue");
                          handleChange(e);
                        }}
                        aria-describedby="basic-addon3"
                      />
                    </InputGroup>
                    {!values.amount && submitData == true ? (
                      <div className={styles.ErrorMessage}>
                        Please enter the amount
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="input-form-wrapper dot">

                                    <label>Mobile</label>
                                    <PhoneInput
                                        inputProps={{
                                            name: 'Mobile',
                                            required: true,
                                            autoFocus: true
                                        }}
                                        // name="Mobile"
                                        country={'ke'}
                                        prefix=''
                                        // value={submittedData?.phone_number}
                                        onChange={(value, country: countryProps, e, formattedValue) => {
                                            handleChange(e);
                                            console.log(value, e.target.value, "asfsaFfff");

                                        }}
                                        inputClass={"inputBx-mobile"}
                                        autoFormat={false}
                                    />
                                    {touched.Mobile && errors.Mobile ? (
                                        <div style={{ display: "block" }} className="invalid-feedback">{errors.Mobile}</div>
                                    ) : null}

                                </div> */}
              <Button
                style={{ marginTop: "20px", width: "100%" }}
                className="payDetails-modal__btn-row__green"
                variant="primary"
                type="submit"
                // disabled={loading}
                disabled
                onClick={() => {
                  setsubmitData(true);
                }}
              >
                {/* {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                Next
              </Button>
            </div>
          )}
        </Formik>
      </Box>
    </>
  );
};
export default Detailspayment;
