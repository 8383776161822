import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../../Settings.scss';

interface Props {
  onSave: any;
}

export const DocumentsSubmitted = (props: Props) => {
  const onSave = () => {
    props.onSave();
  };

  return (
    <div className="document-submitted">
      <div className="document-submitted__heading">
        Account Setup Awaiting Verification.
      </div>

      <div className="document-submitted__desc">
        <span>Congratulations!</span>
        <br />
        You have successfully completed the account setup and your account is
        pending verification on our side.
        <br />
        <br />
        In the mean time, you access to certain features on our platform and we
        will let you know as soon as the verification is processed by our team,
        typically within 1 business day.
        <br />
        <br />
        In case of any queries, reach out to us for support{' '}
        <span>@niobi.co</span>
      </div>
      <Button className="document-submitted__btn" onClick={() => onSave()}>
        Go back{' '}
      </Button>
    </div>
  );
};
